/* eslint-disable */
import React, { Component } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'
import './styles.scss'

class Datepicker extends Component {
  isWeekday(date) {
    const day = moment(date).day()
    return day !== 0 && day !== 6
  }

  render() {
    const {
      className,
      disabled,
      pickDate,
      onChange,
      placeholder,
      dateFormat,
      minDate,
      maxDate,
      open,
      selectsStart,
      selectsEnd,
      startDate,
      endDate,
      excludeDates,
      ...rest
    } = this.props
    return (
      <div className="datepicker-container">
        <DatePicker
          customInput={ !open ?
            <div
              className={
                disabled
                  ? `${className || ''} input-datepicker-disabled`
                  : `${className || ''} input-datepicker`
              }
            >
              <div className="datepicker-text">
                {pickDate ? moment(pickDate).format(dateFormat || 'DD/MM/YYYY') : placeholder}
              </div>
            </div>
           : ""}
          disabled={disabled}
          selected={pickDate}
          onChange={onChange}
          placeholderText={placeholder || 'от'}
          dateFormat={dateFormat || 'dd/MM/yyyy'}
          minDate={minDate || moment().subtract(3, 'months').toDate()}
          maxDate={maxDate}
          excludeDates={excludeDates}
          {...rest}
        />
      </div>
    )
  }
}

export default Datepicker
