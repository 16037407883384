/* eslint-disable */
import { Button, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "./MobileChatActions.module.scss";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { connect } from "react-redux";
import {
  labelsList,
  labelsCreate,
  labelsDelete,
  labelsEdit,
} from "../../../actions/labels";
import { ticketActions } from "../../../actions/tickets";
import ModalComponent from "../ModalComponent/ModalComponent";
import { Divider } from "@mui/material";
import { toast } from "react-toastify";
import { Menu, MenuItem } from "@mui/material";

const MobileChatActions = ({
  handleClose,
  open,
  systemId,
  handleOpenTeamManagementModal,
  handleOpenChangeAssigneeModal,
  handleOpenCompleteTopicModal,
  handleOpenPaymentModal,
  completed,
  handleRequestVote,
  handleReminder,
  handleUnreadTicket,
  handleArchiveTicket,
  special,
  setSelectedAction,
  currentTicket,
  setForTopicModal,
  handleOpenTicket
}) => {
  return (
    <Paper className={styles["assign-topic-modal"]}>
      <div className={styles["actions-container"]}>
        <MenuItem
          onClick={() => {
            setForTopicModal(true);
            handleClose();
          }}
          sx={{ borderBottom: "1px solid #0dc1ce", margin: "0 8px" }}
        >
          <object
            data={"/chat-actions-icons/info.svg"}
            width={"21px"}
            height={"21px"}
            aria-labelledby={"for-ticket"}
          />
          <span style={{ paddingLeft: "5px" }}>За темата</span>
        </MenuItem>
        {completed && (
        <MenuItem
          onClick={() => {
            handleOpenTicket();
            handleClose();
          }}
          sx={{ borderBottom: "1px solid #0dc1ce", margin: "0 8px" }}
        >
          <object
            data={"/chat-actions-icons/open-chat.svg"}
            width={"21px"}
            height={"21px"}
            aria-labelledby={"open-chat"}
          />
          <span style={{ paddingLeft: "5px" }}>Отвори чат</span>
        </MenuItem>
      )}
        {!completed && (
          <MenuItem
            onClick={() => {
              handleUnreadTicket();
              handleClose();
            }}
            sx={{ borderBottom: "1px solid #0dc1ce", margin: "0 8px" }}
          >
            <object
              data={"/chat-actions-icons/mark-as-unread.svg"}
              width={"21px"}
              height={"21px"}
              aria-labelledby={"mark-unread"}
            />
            <span style={{ paddingLeft: "5px" }}>Отбележи като непрочетен</span>
          </MenuItem>
        )}
        {!completed && !special ? (
          <MenuItem
            onClick={() => {
              handleOpenTeamManagementModal();
              handleClose();
            }}
            sx={{ borderBottom: "1px solid #0dc1ce", margin: "0 8px" }}
          >
            <object
              data={"/chat-actions-icons/team-managment.svg"}
              width={"21px"}
              height={"21px"}
              aria-labelledby={"team-management"}
            />
            <span style={{ paddingLeft: "5px" }}>Управление екип</span>
          </MenuItem>
        ) : null}
        {!completed && (
          <div>
            <MenuItem
              onClick={() => {
                if (special) {
                  handleUnreadTicket();
                  setSelectedAction("responsible");
                  handleClose();
                } else {
                  handleOpenChangeAssigneeModal();
                  handleClose();
                }
              }}
              sx={{ borderBottom: "1px solid #0dc1ce", margin: "0 8px" }}
            >
              <object
                data={"/chat-actions-icons/change-assignee.svg"}
                width={"21px"}
                height={"21px"}
                aria-labelledby={"change-assignee"}
              />
              <span style={{ paddingLeft: "5px" }}>Смени отговорник</span>
            </MenuItem>
          </div>
        )}
        {!completed && (
          <div>
            <MenuItem
              onClick={() => {
                if (special) {
                  handleUnreadTicket();
                  setSelectedAction("voting");
                  handleClose();
                } else {
                  handleRequestVote();
                  handleClose();
                }
              }}
              sx={{ borderBottom: "1px solid #0dc1ce", margin: "0 8px" }}
            >
              <object
                data={"/chat-actions-icons/request-rating.svg"}
                width={"21px"}
                height={"21px"}
                aria-labelledby={"request-rating"}
              />
              <span style={{ paddingLeft: "5px" }}>Изпрати за оценка</span>
            </MenuItem>
            {systemId.slice(systemId.length - 3).includes("265") && !special ? (
              <MenuItem
                onClick={() => {
                  handleClose();
                  handleOpenPaymentModal(true);
                }}
                sx={{ borderBottom: "1px solid #0dc1ce", margin: "0 8px" }}
              >
                <object
                  data={"/chat-actions-icons/credit-card.svg"}
                  width={"21px"}
                  height={"21px"}
                  aria-labelledby={"request-rating"}
                />
                <span style={{ paddingLeft: "5px" }}>Инициирай плащане</span>
              </MenuItem>
            ) : null}
            <MenuItem
              onClick={() => {
                if (special) {
                  handleUnreadTicket();
                  setSelectedAction("reminder");
                  handleClose();
                } else {
                  handleReminder();
                  handleClose();
                }
              }}
              sx={{ borderBottom: "1px solid #0dc1ce", margin: "0 8px" }}
            >
              <object
                data={"/chat-actions-icons/mail.svg"}
                width={"21px"}
                height={"21px"}
                aria-labelledby={"mail"}
              />
              <span style={{ paddingLeft: "5px" }}>Изпрати напомнящ имейл</span>
            </MenuItem>
            <MenuItem
              onClick={() => {
                if (special) {
                  setSelectedAction("completed");
                  handleUnreadTicket();
                  handleClose();
                } else {
                  handleOpenCompleteTopicModal();
                  handleClose();
                }
              }}
              sx={{ borderBottom: "1px solid #0dc1ce", margin: "0 8px" }}
            >
              <object
                data={"/chat-actions-icons/complete.svg"}
                width={"21px"}
                height={"21px"}
                aria-labelledby={"complete"}
              />
              <span style={{ paddingLeft: "5px" }}>
                Отбележи като завършена
              </span>
            </MenuItem>
          </div>
        )}
        <MenuItem
          sx={{ borderBottom: "1px solid #0dc1ce", margin: "0 8px" }}
          onClick={() => {
            if (special) setSelectedAction("archive");
            handleArchiveTicket();
            handleClose();
          }}
        >
          <object
            data={"/chat-actions-icons/archive.svg"}
            width={"21px"}
            height={"21px"}
            aria-labelledby={"archive"}
          />
          <span style={{ paddingLeft: "5px" }}>Архивирай тема</span>
        </MenuItem>
      </div>
    </Paper>
  );
};
export default MobileChatActions;
