/* eslint-disable */
import { ofType, ActionsObservable } from "redux-observable";
import { switchMap, catchError, map, mergeMap } from "rxjs/operators";
import { supportSocketConnected, supportTypes } from "../actions/support";
import { ticketTypes } from "../actions/tickets";
import { notesTypes } from "../actions/notes";
import { fromEvent } from "rxjs";
import { store } from "../config/stores";
import io from "socket.io-client";
import { API_URL } from "../config/settings";
import { systemTypes } from "../actions/system";

let supportSocket;
const SOCKET_SUPPORT_EVENT = "socket-message";

export const supportConnectEpic = (action$, state$) => {
  return action$.pipe(
    ofType(supportTypes.SUPPORT_SOCKET_CONNECT),
    map(() => {
      const token = store.getState().login.token;
      const query = `?token=${token}`;
      if (supportSocket) {
        supportSocket.disconnect();
      }
      supportSocket = io(`${API_URL}${query}`);

      if (supportSocket) {
        supportSocket.emit(SOCKET_SUPPORT_EVENT, {
          action: "joinSystemChannel",
        });
      }

      return supportSocketConnected();
    })
  );
};

export const supportListenerEpic = (action$, state$) => {
  return action$.pipe(
    ofType(supportTypes.SUPPORT_SOCKET_CONNECTED),
    mergeMap(() =>
      fromEvent(supportSocket, SOCKET_SUPPORT_EVENT).pipe(
        switchMap(({ action, payload }) => {
          return ActionsObservable.create((obs) => {
            const systemID = store.getState()?.systems?.currentSystem?.system?._id;
            const currentTicketID = store.getState()?.tickets?.currentTicket?._id;
            const isFiltered = store.getState()?.tickets?.isFiltered;
            const filters = store.getState()?.tickets?.filters;
            const isScrollToMessage = localStorage.getItem("scrollToMessage");
            if (isScrollToMessage) {
              obs.next({ type: supportTypes.SUPPORT_SCROLL_TO_MESSAGE, payload: localStorage.getItem("scrollToMessage") });
              localStorage.removeItem("scrollToMessage");
            }

            console.log(payload);
            // console.log(action, payload, systemID, currentTicketID, store.getState()?.tickets);
            if (systemID === undefined || systemID === payload.system) {
              obs.next({ type: action, payload });

              if (action === "socket/systemMessage" && !isFiltered) {
                obs.next({
                  type: ticketTypes.GET_TICKETS_FOR_SYSTEM,
                  payload: {
                    system: payload.system,
                    pageNumber: 1,
                  },
                });
                obs.next({
                  type: systemTypes.GET_CURRENT_SYSTEM,
                  payload: payload.system,
                });
              } else if (action === "socket/systemMessage" && isFiltered) {
                obs.next({
                  type: ticketTypes.REPLACE_TICKET,
                  payload: payload,
                });
              }
            }

            if (currentTicketID === payload?.ticket && payload?.type === "note") {
              obs.next({
                type: notesTypes.GET_NOTES_LIST,
                payload: {
                  ticket: currentTicketID,
                  noPagination: true,
                },
              });
            }

            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            obs.complete();
          });
        })
      )
    )
  );
};

export const supportSenderEpic = (action$) =>
  action$.pipe(
    ofType(supportTypes.SUPPORT_SOCKET_EMIT_EVENT),
    switchMap(({ payload }) => {
      return ActionsObservable.create((obs) => {
        supportSocket.emit(SOCKET_SUPPORT_EVENT, payload);
        obs.complete();
      });
    })
  );

export const supportDisconnectEpic = (action$) => {
  return action$.pipe(
    ofType(supportTypes.SUPPORT_SOCKET_DISCONNECT),
    switchMap(() =>
      ActionsObservable.create((obs) => {
        if (supportSocket) {
          supportSocket.disconnect();
        }
        obs.complete();
      })
    )
  );
};
