/* eslint-disable */
import React from "react";
import { Card, CardContent, Divider } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import styles from "./SystemCard.module.scss";

export default function SystemCard({
  user,
  arg1,
  arg2,
  arg3,
  arg4,
  arg5,
  handleClick,
  systems,
}) {
  return (
    <>
      {window.innerWidth > 900 ? (
        <Card elevation={1} className={styles["system-card-container"]}>
          <CardContent className={styles["card-content"]} onClick={handleClick}>
            <div className={styles["card-info"]}>
              <p
                style={{
                  width: "145px",
                  whiteSpace: user ? "nowrap" : "normal",
                  maxWidth: user && "12%",
                }}
              >
                {arg1}
              </p>
              <Divider
                style={{ backgroundColor: "black" }}
                orientation="vertical"
              />
              <p
                style={{
                  width: systems ? "150px" : "230px",
                  maxWidth: user && "22%",
                  lineBreak: user && "anywhere",
                }}
              >
                {arg2}
              </p>
              <Divider
                style={{ backgroundColor: "black" }}
                orientation="vertical"
              />
              <p style={{ width: "145px" }}>{arg3}</p>
              <Divider
                style={{ backgroundColor: "black" }}
                orientation="vertical"
              />
              <p style={{ width: "150px" }}>{arg4}</p>
              {arg5 !== null && (
                <>
                  <Divider
                    style={{ backgroundColor: "black" }}
                    orientation="vertical"
                  />
                  <p
                    style={{
                      overflowX: "auto",
                      whiteSpace: "nowrap",
                      width: "490px",
                    }}
                  >
                    {arg5.replaceAll(",", "\n|\n")}
                  </p>
                </>
              )}
            </div>
            <ArrowForwardIcon
              fontSize="large"
              style={{ color: "#0DC1CE", cursor: "pointer" }}
              onClick={handleClick}
            />
          </CardContent>
        </Card>
      ) : (
        <>
          <Card elevation={1} className={styles["system-card-container"]}>
            <CardContent
              className={styles["card-content"]}
              onClick={handleClick}
              style={{alignItems:"center"}}
            >
              <h4>{arg1}</h4>
              <div className={styles["mobile-arrow"]}/>
            </CardContent>
          </Card>
        </>
      )}
    </>
  );
}
