/* eslint-disable */
import React, { useEffect, useState } from "react";
import styles from "./TemplatesModal.module.scss";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ModalComponent from "../ModalComponent/ModalComponent";
import DeleteUserModal from "../DeleteUserModal/DeleteUserModal";
import { Divider } from "@mui/material";
import DeleteTemplateModal from "./DeleteTemplateModal";
import EditTemplateModal from "./EditTemplateModal";
import { toast } from "react-toastify";

const AnswerContainer = ({
  i,
  setSelTemplate,
  selTemplate,
  setEditTemplateModal,
  setOpenDeleteTemplateModal,
  template,
  setCurrentMessage
}) => {
  const [secondAnchorEl,setSecondAnchorEl] = useState(null);
  const secondOpen = Boolean(secondAnchorEl);
  const handleSecondClick = (event) => {
    setSecondAnchorEl(event.currentTarget);
  };
  const handleSecondDropDownClose = () => {
    setSecondAnchorEl(null);
  };

  return (
    <div className={styles["answer-container"]} key={i}>
      <div className={styles["answer"]}>
        <MoreVertIcon
          style={{ cursor: "pointer" }}
          id={i}
          onClick={handleSecondClick}
        />
        <Menu
          anchorEl={secondAnchorEl}
          open={secondOpen}
          onClose={handleSecondDropDownClose}
          id={`${i + 1}`}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          PaperProps={{
            style: {
              width: 200,
            },
          }}
        >
          <MenuItem
            onClick={() => {
              setSelTemplate(template);
              setEditTemplateModal(true);
              handleSecondDropDownClose();
            }}
          >
            <div className={styles["edit-group"]}></div>{" "}
            <p style={{ fontSize: "13px" }}>Редактирай шаблон</p>
          </MenuItem>
          <Divider sx={{ background: "#0DC1CE" }} />
          <MenuItem
            onClick={() => {
              setOpenDeleteTemplateModal(true);
              setSelTemplate(template);
              handleSecondDropDownClose();
            }}
          >
            <div
              className={`${styles["edit-group"]} ${styles["delete-group"]}`}
            ></div>{" "}
            <p style={{ fontSize: "13px" }}>Изтрий шаблон</p>
          </MenuItem>
        </Menu>
        <p style={{width:"92%",wordBreak:"break-word",padding:"10px"}}>{template?.text}</p>
      </div>
      <div
        className={`${styles["copy-icon"]} ${
          selTemplate === i && styles["copy-ready"]
        }`}
        onClick={() => {
          setSelTemplate(i);
          setCurrentMessage(template?.text);
          navigator.clipboard.writeText(template?.text);
          toast.success("Template copied successfully.", { autoClose: "2000" });
        }}
      />
    </div>
  );
};

export default AnswerContainer;
