/* eslint-disable */
import { Box, Modal, Paper } from "@mui/material";
import React, { useEffect } from "react";
import styles from "../ChatActions/Activity/Activity.module.scss";
import moment from "moment";



const fieldNames = [
  "Номер на рама",
  "Собственик",
  "ЕГН/EИК",
  "Град",
  "Адрес",
  "(J) Категория",
  "(B) Първа регистрация",
  "(P.1) Обем на двигател",
  "(P.2) Мощност на двигателя",
  "(P.3) Гориво",
  "(S.1) Брой места",
  "(G) Маса на ПС",
  "(F.1) Техническа допустима максимална маса",
  "(F.3) Допустима максимална маса от състав на ПС",
];

const typeNames = [
  "VIN",
  "fullName",
  "PIN",
  "region",
  "address",
  "vehicleCategory",
  "firstRegistrationDate",
  "engineCapacity",
  "enginePower",
  "fuelType",
  "seats",
  "vehicleWeight",
  "maximumWeight",
  "maxCompositionWeight",
];

const VehicleModalComponent = ({ open, handleClose, currentVehicle,mobileActions,mobileModal,special }) => {
  const style = {
    position: "absolute",
    top: mobileModal ? "50%" : "55%",
    width: mobileModal ? "100%" : "unset",
    height: mobileModal ? "100%" : "unset",
    left: mobileModal? "50%" : "80%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 30,
  };
  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Paper className={styles["activity-modal"]} style={{width: window.innerWidth < 900 && "100%",height: window.innerWidth < 900 && "100%"}}>
          <div className={styles["activity-heading-container"]}>
            <h3>ДАННИ ТАЛОН</h3>
            <p onClick={handleClose} style={{ cursor: "pointer" }}>
              Скрий {`>`}
            </p>
          </div>
          <div className={styles["activity-window"]}>
            {fieldNames.map((field, i) => {
              return (
                <div className={styles.activity} key={i}>
                  <p>{field}</p>
                  <h4 style={{ marginTop: "1%" }}>
                    {i === 1
                      ? currentVehicle?.[typeNames[i]] == undefined
                        ? currentVehicle?.companyName
                        : currentVehicle?.[typeNames[i]]
                      : i === 2
                      ? currentVehicle?.[typeNames[i]] == undefined
                        ? currentVehicle?.UIC
                        : currentVehicle?.[typeNames[i]]
                      : i === 6
                      ? moment(currentVehicle?.[typeNames[i]])?.format(
                          "DD.MM.YYYY"
                        )
                      : currentVehicle?.[typeNames[i]]}
                    {i > 10 ? " кг." : i === 8 && " kW"}
                  </h4>
                </div>
              );
            })}
          </div>
        </Paper>
      </Box>
    </Modal>
  );
};

export default VehicleModalComponent;
