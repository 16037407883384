/* eslint-disable */
import React from "react";
import { Route, Routes as Switch, useLocation } from "react-router-dom";
import Login from "../../screens/Login/Login";
import SystemPage from "../../screens/SystemPage/SystemPage";
import AmarantPage from "../../screens/AmarantPage/AmarantPage";
import ImmotechPage from "../../screens/ImmotechPage/ImmotechPage";
import TopicsPage from "../../screens/TopicsPage/TopicsPage";
import SystemUsersPage from "../../screens/SystemUsersPage/SystemUsersPage";
import AllUsersPage from "../../screens/AllUsersPage/AllUsersPage";
import AddUserPage from "../../screens/AddUserPage/AddUserPage";
import EditProfilePage from "../../screens/EditProfilePage/EditProfilePage";
import ChatPage from "../../screens/ChatPage/ChatPage";
import PrivateRoute from "../../PrivateRoute";
import Header from "../Header/Header";
import { connect } from "react-redux";
import CreateOffer from "../ChatActions/Offer/CreateOffer";
import EditOffer from "../ChatActions/Offer/EditOffer";
import AddPolicyToUser from "../ChatActions/Offer/AddPolicyToUser";

function Routes() {
  const location = useLocation();

  return (
    <>
      {location.pathname !== "/login" && <Header />}
      <div className="dashboard-wrap" id="dashboard">
        <Switch>
          <Route exact path="/login" element={<Login />} />
          <Route
            exact
            path="/"
            element={
              <PrivateRoute>
                <SystemPage />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/system/:slug"
            element={
              <PrivateRoute>
                <TopicsPage />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/system/:slug/users"
            element={
              <PrivateRoute>
                <SystemUsersPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/system/:slug/add-users"
            element={
              <PrivateRoute>
                <AddUserPage />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/add-users"
            element={
              <PrivateRoute>
                <AddUserPage />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/users"
            element={
              <PrivateRoute>
                <AllUsersPage />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/profile/:slug"
            element={
              <PrivateRoute>
                <EditProfilePage />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/system/:slug/:topicId/chat"
            element={
              <PrivateRoute>
                <ChatPage />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/createOffer/:slug"
            element={
              <PrivateRoute>
                <CreateOffer />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/editOffer/:slug/:offerID"
            element={
              <PrivateRoute>
                <EditOffer />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/addPolicyToUser/:slug/:customerID/:offerID"
            element={
              <PrivateRoute>
                <AddPolicyToUser />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/addPolicyToUser/:topicID/:customerID/no-offer"
            element={
              <PrivateRoute>
                <AddPolicyToUser />
              </PrivateRoute>
            }
          />
          <Route
            path="/amarant/user/:id"
            element={
              <PrivateRoute>
                <AmarantPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/immotech/user/:id"
            element={
              <PrivateRoute>
                <ImmotechPage />
              </PrivateRoute>
            }
          />
        </Switch>
      </div>
    </>
  );
}

export default Routes;
