/* eslint-disable */
import { ofType, ActionsObservable } from "redux-observable";
import { ajax } from "rxjs/ajax";
import { switchMap, catchError } from "rxjs/operators";
import { userTypes } from "../actions/users";
import { API_URL } from "../config/settings";
import { Headers } from "../utils/Headers";
import { toast } from "react-toastify";

export const getAllUsers = (action$) => {
  return action$.pipe(
    ofType(userTypes.GET_ALL_USERS),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API_URL}/user/list`,
        method: "POST",
        headers: Headers.auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: userTypes.GET_ALL_USERS_SUCCESS,
              payload: response.payload,
            });
            obs.complete();
          });
        }),
        catchError((err) => {
          toast.error(err.response?.message);
        })
      );
    })
  );
};

export const createUserEpic = (action$) => {
  return action$.pipe(
    ofType(userTypes.CREATE_USER),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API_URL}/user/create`,
        method: "POST",
        headers: Headers.auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: userTypes.CREATE_USER_SUCCESS,
            });
            obs.complete();
          });
        }),
        catchError((err) => {
          console.log(err);
          toast.error(err.response?.message);
        })
      );
    })
  );
};

export const getCurrentUser = (action$) => {
  return action$.pipe(
    ofType(userTypes.GET_CURRENT_USER),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API_URL}/user/${payload}`,
        method: "GET",
        headers: Headers.auth(),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: userTypes.GET_CURRENT_USER_SUCCESS,
              payload: response.payload,
            });
            obs.complete();
          });
        }),
        catchError((err) => {
          toast.error(err.response?.message);
        })
      );
    })
  );
};

export const editUser = (action$) => {
  return action$.pipe(
    ofType(userTypes.EDIT_CURRENT_USER),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API_URL}/user/${payload._id}`,
        method: "PUT",
        headers: Headers.auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: userTypes.EDIT_CURRENT_USER_SUCCESS,
              payload: response.payload.docs,
            });
            //window.location.href = `/profile/${payload._id}`;
            obs.complete();
          });
        }),
        catchError((err) => {
          toast.error(err.response?.message);
        })
      );
    })
  );
};

export const deleteUser = (action$) => {
  return action$.pipe(
    ofType(userTypes.DELETE_CURRENT_USER),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API_URL}/user/${payload}`,
        method: "DELETE",
        headers: Headers.auth(),
      }).pipe(
        switchMap(() => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: userTypes.DELETE_CURRENT_USER_SUCCESS,
            });
            obs.complete();
          });
        }),
        catchError((err) => {
          toast.error(err.response?.message);
        })
      );
    })
  );
};

export const changeUserPassword = (action$) => {
  return action$.pipe(
    ofType(userTypes.CHANGE_USER_PASSWORD),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API_URL}/user/password`,
        method: "PUT",
        headers: Headers.auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: userTypes.CHANGE_USER_PASSWORD_SUCCESS,
            });
            toast.success(response.payload.message);
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: userTypes.CHANGE_USER_PASSWORD_FAIL,
            });
            toast.error(err.response?.message);
          });
        })
      );
    })
  );
};

export const getCurrentSystemUsersEpic = (action$) => {
  return action$.pipe(
    ofType(userTypes.GET_CURRENT_SYSTEM_USERS),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API_URL}/user/list`,
        method: "POST",
        headers: Headers.auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            payload?.onSuccess(response.payload.docs);
            obs.next({
              type: userTypes.GET_CURRENT_SYSTEM_USERS_SUCCESS,
              payload: response.payload.docs,
            });
            obs.complete();
          });
        }),
        catchError((err) => {
          toast.error(err.response?.message);
        })
      );
    })
  );
};
