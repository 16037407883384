/* eslint-disable */
import { Box, Button } from "@mui/material";
import React, { useEffect, useState, useRef, useCallback } from "react";
import SystemCard from "../SystemCard/SystemCard";
import styles from "./AllUsers.module.scss";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { getAllUsers } from "../../actions/users";
import { format } from "date-fns";

function AllUsers({ getAllUsers, allUsers, allUsersInfo, user }) {
  const [reverseSort, setReverseSort] = useState(false);
  const [filters, setFilters] = useState("");
  const totalPages = allUsersInfo?.totalPages;
  const [nextPage, setNextPage] = useState(1);
  const observer = useRef();
  const handleLoadUsersRef = useCallback(
    (node) => {
      if (observer.current) {
        observer.current.disconnect();
      }
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && nextPage < totalPages) {
          if (nextPage < totalPages) {
            setNextPage((prevPageNumber) => prevPageNumber + 1);
          }
        }
      });
      if (node) {
        observer.current.observe(node);
      }
    },
    [totalPages]
  );
  useEffect(() => {
    if (filters) {
      const payload = {
        sortBy: { [filters]: reverseSort ? -1 : 1 },
      };
      getAllUsers(payload);
    } else {
      const payload = {
        pageNumber: nextPage,
      };
      getAllUsers(payload);
    }
  }, [getAllUsers, filters, nextPage, reverseSort]);
  const navigate = useNavigate();
  const handleNavigate = (userId) => {
    navigate(`/profile/${userId}`);
  };
  return (
    <div className={styles["all-users-container"]}>
      <div className={styles["heading-container"]}>
        <h3 className={styles["system-heading"]}>Потребители в системата</h3>
        {user?.role === "admin" && (
          <Button
            onClick={() => navigate("/add-users")}
            disableElevation
            disableRipple
            disableFocusRipple
            variant="contained"
            sx={{
              color: "white",
              backgroundColor: "#01D088",
              cursor: "pointer",
              "&:hover": {
                backgroundColor: "#01D088",
              },
            }}
          >
            Добави
          </Button>
        )}
      </div>
      <div>
        <div className={styles["system-content"]}>
          <div className={styles["filters-container"]}>
            <Button
              style={{ width: "150px" }}
              onClick={() => {
                setFilters("name");
                setReverseSort(!reverseSort);
              }}
              disableElevation
              disableRipple
              disableFocusRipple
              endIcon={
                <KeyboardArrowDownIcon
                  style={
                    filters === "name" && reverseSort
                      ? { transform: "rotate(180deg)" }
                      : {}
                  }
                />
              }
              className={styles["filter-button"]}
            >
              Имена
            </Button>
            <Button
              onClick={() => {
                setFilters("email");
                setReverseSort(!reverseSort);
              }}
              style={{ width: "220px" }}
              disableElevation
              disableRipple
              disableFocusRipple
              endIcon={
                <KeyboardArrowDownIcon
                  style={
                    filters === "email" && reverseSort
                      ? { transform: "rotate(180deg)" }
                      : {}
                  }
                />
              }
              className={styles["filter-button"]}
            >
              Имейл
            </Button>
            <Button
              style={{ width: "210px" }}
              onClick={() => {
                setFilters("createdAt");
                setReverseSort(!reverseSort);
              }}
              disableElevation
              disableRipple
              disableFocusRipple
              endIcon={
                <KeyboardArrowDownIcon
                  style={
                    filters === "createdAt" && reverseSort
                      ? { transform: "rotate(180deg)" }
                      : {}
                  }
                />
              }
              className={styles["filter-button"]}
            >
              Добавен на
            </Button>
            <Button
              onClick={() => {
                setFilters("_responsibleCount");
                setReverseSort(!reverseSort);
              }}
              style={{ width: "150px" }}
              disableElevation
              disableRipple
              disableFocusRipple
              endIcon={
                <KeyboardArrowDownIcon
                  style={
                    filters === "_responsibleCount" && reverseSort
                      ? { transform: "rotate(180deg)" }
                      : {}
                  }
                />
              }
              className={styles["filter-button"]}
            >
              Назначени
            </Button>
            <Button
              onClick={() => {
                setFilters("systems");
                setReverseSort(!reverseSort);
              }}
              style={{ width: "150px" }}
              disableElevation
              disableRipple
              disableFocusRipple
              endIcon={
                <KeyboardArrowDownIcon
                  style={
                    filters === "systems" && reverseSort
                      ? { transform: "rotate(180deg)" }
                      : {}
                  }
                />
              }
              className={styles["filter-button"]}
            >
              В системи
            </Button>
          </div>
          <Box
            sx={{
              overflow: "auto",
              height: "calc(100vh - 300px)",
            }}
          >
            {allUsers.map((user) => (
              <div ref={handleLoadUsersRef} key={user.id}>
                <SystemCard
                  user
                  key={user?.id}
                  arg1={user?.name}
                  arg2={user?.email}
                  arg3={format(new Date(user?.createdAt), "dd.MM.yyyy")}
                  arg4={user?._responsibleCount}
                  arg5={user?.systems.map((system) => system.name).toString()}
                  handleClick={() => handleNavigate(user.id)}
                />
              </div>
            ))}
          </Box>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  allUsers: state.users.allUsers,
  allUsersInfo: state.users.allUsersInfo,
  user: state.login.user,
});

const mapDispatchToProps = (dispatch) => ({
  getAllUsers: (payload) => dispatch(getAllUsers(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AllUsers);
