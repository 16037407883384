/* eslint-disable */
import { Paper } from "@mui/material";
import React from "react";
import styles from "./PaperComponent.module.scss";

export default function PaperComponent({ children }) {
  return (
    <Paper elevation={3} className={styles["paper-container"]}>
      {children}
    </Paper>
  );
}
