/* eslint-disable */
import { ofType, ActionsObservable } from "redux-observable";
import { ajax } from "rxjs/ajax";
import { switchMap, catchError } from "rxjs/operators";
import { labelsTypes } from "../actions/labels";
import { API_URL } from "../config/settings";
import { Headers } from "../utils/Headers";
import { toast } from "react-toastify";

export const labelsListEpic = (action$) => {
    return action$.pipe(
      ofType(labelsTypes.LABELS_LIST),
      switchMap(({ payload }) => {
        return ajax({
          url: `${API_URL}/label/list`,
          method: "POST",
          headers: Headers.auth(),
          body: JSON.stringify(payload),
        }).pipe(
          switchMap(({ response }) => {
            return ActionsObservable.create((obs) => {
              obs.next({
                type: labelsTypes.LABELS_LIST_SUCCESS,
                payload: response.payload.docs,
              });
              obs.complete();
            });
          }),
          catchError((err) => {
            return ActionsObservable.create((obs) => {
              if (err.status === 401) {
                window.location.href = "/login";
              } else {
                toast.error(err?.response?.message);
              }
            });
          })
        );
      })
    );
};

export const labelCreateEpic = (action$) => {
    return action$.pipe(
      ofType(labelsTypes.LABELS_CREATE),
      switchMap(({ payload }) => {
        return ajax({
          url: `${API_URL}/label/create`,
          method: "POST",
          headers: Headers.auth(),
          body: JSON.stringify(payload),
        }).pipe(
          switchMap(({ response }) => {
            return ActionsObservable.create((obs) => {
              payload.onSuccess(response.payload);
              toast.success("Successfully created label.");
              obs.complete();
            });
          }),
          catchError((err) => {
            return ActionsObservable.create((obs) => {
              if (err.status === 401) {
                window.location.href = "/login";
              } else {
                toast.error(err.response.message);
              }
            });
          })
        );
      })
    );
};

export const labelEditEpic = (action$) => {
    return action$.pipe(
      ofType(labelsTypes.LABELS_EDIT),
      switchMap(({ payload }) => {
        return ajax({
          url: `${API_URL}/label/${payload.id}`,
          method: "PUT",
          headers: Headers.auth(),
          body: JSON.stringify(payload.payloadAction),
        }).pipe(
          switchMap(({ response }) => {
            return ActionsObservable.create((obs) => {
              toast.success("Successfully updated label.");
              payload.onSuccess(response);
              obs.complete();
            });
          }),
          catchError((err) => {
            return ActionsObservable.create((obs) => {
              if (err.status === 401) {
                window.location.href = "/login";
              } else {
                toast.error(err.response.message);
              }
            });
          })
        );
      })
    );
};

export const labelDeleteEpic = (action$) => {
    return action$.pipe(
      ofType(labelsTypes.LABELS_DELETE),
      switchMap(({ payload }) => {
        return ajax({
          url: `${API_URL}/label/delete`,
          method: "DELETE",
          headers: Headers.auth(),
          body: JSON.stringify(payload.array),
        }).pipe(
          switchMap(({ response }) => {
            return ActionsObservable.create((obs) => {
              toast.success("Successfully deleted label.");
              payload.onSuccess();
              obs.complete();
            });
          }),
          catchError((err) => {
            return ActionsObservable.create((obs) => {
              if (err.status === 401) {
                window.location.href = "/login";
              } else {
                toast.error(err.response.message);
              }
            });
          })
        );
      })
    );
};