/* eslint-disable */
import React, { useEffect, useState } from "react";
import {
  AppBar,
  Container,
  Toolbar,
  IconButton,
  Avatar,
  Menu,
  MenuItem,
  Divider,
  Paper,
} from "@mui/material";
import styles from "./Header.module.scss";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import ModalComponent from "../Modals/ModalComponent/ModalComponent";
import UserInfoModal from "../Modals/UserInfoModal/UserInfoModal";
import EditPasswordModal from "../Modals/EditPasswordModal/EditPasswordModal";
import { connect } from "react-redux";
import { deauthenticate } from "../../actions/login";
import { getCurrentUser } from "../../actions/users";
import { User } from "../../utils/Authenticate";
import { Headers } from "../../utils/Headers";

function Header({
  currentSystem,
  deauthenticate,
  getCurrentUser,
  currentUser,
}) {
  const navigate = useNavigate();
  const [userCredentials, setUserCredentials] = useState({});
  const handleCredentials = async () => {
    const resp = User.loadLocalStorageItems();
    setUserCredentials(resp?.user);
  };
  useEffect(() => {
    handleCredentials();
  }, []);

  const logo = currentSystem?.system?.logo;
  const [openUserInfo, setOpenUserInfo] = useState(false);
  const handleOpenUserInfo = () => setOpenUserInfo(true);
  const handleCloseUserInfo = () => setOpenUserInfo(false);

  const [openEditPassword, setOpenEditPassword] = useState(false);
  const handleOpenEditPassword = () => setOpenEditPassword(true);
  const handleCloseEditPassword = () => setOpenEditPassword(false);
  let location = useLocation();
  useEffect(() => {
    if (location.pathname === "/") {
      setIsActive({ systems: true, users: false });
    } else if (
      location.pathname.includes("users") ||
      location.pathname.includes("profile")
    ) {
      setIsActive({ systems: false, users: true });
    }
  }, [location]);
  const [isActive, setIsActive] = useState({ systems: false, users: false });
  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    // handleCloseUserMenu();
    deauthenticate();
  };

  return (
    <AppBar elevation={2} position="relative" color={"transparent"}>
      <div style={{ padding: window.innerWidth < 900 ? "0px 15px" :"0px 35px" }}>
        <Toolbar disableGutters className={styles["header-toolbar"]}>
          {window.innerWidth > 900 &&
          currentSystem &&
          location.pathname !== "/" &&
          location.pathname !== "/users" &&
          !location.pathname.includes("chat") ? (
            <div style={{ display: "flex", alignItems: "center" }}>
              <ArrowBackIosNewIcon
                style={{ cursor: "pointer" }}
                onClick={() => navigate(-1)}
              />
              {location.pathname.includes("add-users") && (
                <p>Добавяне на потребители</p>
              )}
              {location.pathname.includes("system") &&
                !location.pathname.includes("users") &&
                !location.pathname.includes("chat") && (
                  <p>{currentSystem?.system?.name} помощен център</p>
                )}
              {location.pathname.includes("users") &&
                location.pathname.includes("system") &&
                !location.pathname.includes("add-users") && (
                  <p>Потребители в системата</p>
                )}
              {location.pathname.includes("profile") && (
                <p>Редакция на потребител</p>
              )}
            </div>
          ) : (
            <img
              style={{ width: "200px", height: "auto" }}
              src="/header-logo.png"
              alt="header-logo"
            />
          )}
          {window.innerWidth > 900 && (
            <div className={styles.tabs}>
              {logo && location.pathname.includes("system") && !location.pathname.includes("chat") ? (
                <img
                  src={logo}
                  alt="app-logo"
                  style={{ width: "135px", height: "auto" }}
                />
              ) : (
                <>
                  <NavLink
                    className={isActive.systems ? styles["active"] : undefined}
                    to="/"
                  >
                    Системи
                  </NavLink>
                  <NavLink
                    className={isActive.users ? styles["active"] : undefined}
                    to="/users"
                  >
                    Потребители
                  </NavLink>
                </>
              )}
            </div>
          )}

          <IconButton onClick={handleOpenUserMenu}>
            <Avatar
              src={userCredentials?.avatar}
              alt="avatar"
              style={{ border: "0.3px solid #0dc1ce" }}
            />
          </IconButton>
          <Menu
            className={styles["user-menu"]}
            PaperProps={{
              style: {
                width: "400px",
              },
            }}
            MenuListProps={{
              disablePadding: true,
            }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            <Paper elevation={1} className={styles["menu-options"]}>
              <h3>Профил и настройки</h3>
              <CloseIcon
                fontSize="medium"
                onClick={handleCloseUserMenu}
                style={{ cursor: "pointer" }}
              />
            </Paper>
            <div className={styles["user-info"]}>
              <Avatar src={userCredentials?.avatar} alt="avatar" />
              <div>
                <h3>{userCredentials?.name}</h3>
                <p>{userCredentials?.email}</p>
              </div>
            </div>
            <MenuItem
              sx={{
                ml: 1,
                "&.MuiButtonBase-root:hover": {
                  bgcolor: "transparent",
                },
              }}
              className={styles["menu-item"]}
              onClick={() => {
                handleOpenUserInfo();
                handleCloseUserMenu();
              }}
            >
              <AccountCircleOutlinedIcon
                fontSize="large"
                style={{ color: "#0DC1CE" }}
              />{" "}
              Моят профил
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleLogout();
              }}
              sx={{
                ml: 1,
                "&.MuiButtonBase-root:hover": {
                  bgcolor: "transparent",
                },
              }}
              className={styles["menu-item"]}
            >
              <LogoutIcon fontSize="large" style={{ color: "#FD416C" }} /> Изход
            </MenuItem>
          </Menu>
        </Toolbar>
      </div>
      <ModalComponent
        open={openUserInfo}
        handleClose={handleCloseUserInfo}
        children={
          <UserInfoModal
            getCurrentUser={getCurrentUser}
            userCredentials={userCredentials}
            currentUser={currentUser}
            handleClose={handleCloseUserInfo}
            handleOpenEditPassword={handleOpenEditPassword}
          />
        }
      />
      <ModalComponent
        open={openEditPassword}
        handleClose={handleCloseEditPassword}
        children={
          <EditPasswordModal
            currentUser={userCredentials}
            handleClose={handleCloseEditPassword}
          />
        }
      />
    </AppBar>
  );
}
const mapDispatchToProps = (dispatch) => ({
  deauthenticate: () => dispatch(deauthenticate()),
  getCurrentUser: (payload) => dispatch(getCurrentUser(payload)),
});

const mapStateToProps = (state) => ({
  currentSystem: state.systems.currentSystem,
  currentUser: state.users.currentUser,
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
