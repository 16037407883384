/* eslint-disable */
import { createStore, applyMiddleware, compose } from "redux";
import { routerMiddleware } from "react-router-redux";
import logger from "redux-logger";
import reducers from "../reducers";
import { createEpicMiddleware } from "redux-observable";
import { rootEpic } from "../epics";

const epicMiddleware = createEpicMiddleware();
const createHistory = require("history").createBrowserHistory;

const history = createHistory();

let store = {};

const middleware = [];
middleware.push(routerMiddleware(history));
middleware.push(epicMiddleware);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const createStoreWithMiddleware = composeEnhancers(
  applyMiddleware(...middleware)
)(createStore);

store = createStoreWithMiddleware(reducers);

epicMiddleware.run(rootEpic);

export { store, history };
