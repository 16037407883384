/* eslint-disable */
import React, { useEffect } from "react";
import { Paper, Button, Avatar, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./UserInfoModal.module.scss";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

export default function UserInfoModal({
  handleClose,
  handleOpenEditPassword,
  getCurrentUser,
  currentUser,
  userCredentials
}) {
  const navigate = useNavigate();

  useEffect(() => {
    getCurrentUser(userCredentials._id);
  },[])


  return (
    <Paper
      style={{
        display: "flex",
        flexDirection: "column",
        width: "800px",
        height: "400px",
        padding: "25px",
      }}
    >
      <div className={styles["heading-container"]}>
        <h4>ЛИЧНИ ДАННИ</h4>
        <CloseIcon onClick={handleClose} />
      </div>
      <div className={styles["user-info-container"]}>
        <div className={styles["options-left"]}>
          <Avatar
            sx={{ width: 142, height: 142, marginBottom: "27px" }}
            src={currentUser?.avatar}
            alt="avatar"
          />
          <Button
            onClick={() => {
              navigate(`/profile/${currentUser?._id}`);
              handleClose();
            }}
            disableRipple
            disableFocusRipple
            variant="contained"
            sx={{
              marginRight: "1rem",
              color: "black",
              backgroundColor: "#FFFFFF",
              cursor: "pointer",
              "&:hover": {
                backgroundColor: "#FFFFFF",
              },
            }}
          >
            Редакция
          </Button>
        </div>
        <div className={styles["options-right"]}>
          <TextField
            className={styles.textfield}
            disabled
            value={currentUser?.name}
          />
          <h4>Данни за вход</h4>
          <TextField
            className={styles.textfield}
            disabled
            value={currentUser?.email}
          />
          <div className={styles["password-field-container"]}>
            <TextField
              className={styles.textfield}
              disabled
              value={"dummypassword"}
              type={"password"}
            />
            <EditIcon
              onClick={() => {
                handleClose();
                handleOpenEditPassword();
              }}
            />
          </div>
        </div>
      </div>
      <div className={styles["systems-container"]}>
        <h5>Системи</h5>
        <p style={{ color: "#0DC1CE", fontWeight: "bold",width:"90%",whiteSpace:"nowrap",overflowX:"scroll",marginTop:"2%" }} className={styles["systems-list"]}>
          {currentUser?.systems?.map((system) => system.name).toString().replaceAll(",","\n | \n")}
        </p>
      </div>
    </Paper>
  );
}
