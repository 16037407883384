/* eslint-disable */
import React, { useState } from "react";
import { Divider, Paper } from "@mui/material";
import styles from "./HelpCenterSideTools.module.scss";
import { ClickAwayListener } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setTicketFilters } from "../../../actions/tickets";

const OpenedSideTools = ({ getTicketsForSystem, setNextt, setNextPage, setSelectedFilter, setIsCompletedQuery, currentSystem, selectedFilter, slug, nextt, TopicSelector, setQuery, query, payload, filteredInfo, setSideFiltersActivated }) => {
  const dispatch = useDispatch();
  const filters = useSelector(({ tickets }) => tickets?.filters) || {};
  return (
    <Paper elevation={0}>
      <Paper
        className={`${styles["created-topics-container"]}`}
        elevation={2}
        onClick={() => {
          const newFilters = { ...filters, openOnly: true };
          dispatch(setTicketFilters(newFilters));
          getTicketsForSystem({
            system: slug,
            pageNumber: 1,
            filtered: true,
            ...payload,
            ...newFilters,
          });
          setNextt(nextt + 1);
          setNextPage(0);
          setSelectedFilter({ opened: true });
          setIsCompletedQuery(false);
          setSideFiltersActivated(true);
        }}
      >
        <div className={`${styles["opened-topics-icon"]} ${styles.icon}`} style={{ width: "40px", height: "40px" }}></div>
        <h4 className={styles["users-number"]}>{filteredInfo.current.totalTickets > 0 ? filteredInfo.current.opened.total : currentSystem?.opened?.total}</h4>
        <div
          style={{
            display: "flex",
            width: "90%",
            justifyContent: "space-between",
          }}
        >
          <p className={styles["paper-header"]}>ОТВОРЕНИ</p>
          {selectedFilter.opened && <TopicSelector />}
        </div>
      </Paper>
      <div style={{ display: "flex" }}>
        <div style={{ border: "2px solid #0DC1CE", borderRadius: "10px" }} />
        <div style={{ width: "100%" }}>
          <ClickAwayListener onClickAway={() => {}}>
            <div
              className={styles["more-options"]}
              onClick={() => {
                const newFilters = { ...filters, withoutResponsible: true, noAnswered: false, waitAnswer: false, waitScore: false };
                dispatch(setTicketFilters(newFilters));
                getTicketsForSystem({
                  system: slug,
                  pageNumber: 1,
                  filtered: true,
                  ...newFilters,
                  ...payload,
                });
                setNextt(nextt + 1);
                setNextPage(0);
                setSelectedFilter({ unassigned: true });
                setIsCompletedQuery(false);
                setSideFiltersActivated(true);
              }}
            >
              <div className={styles.options}>
                <p
                  className={styles.notification}
                  style={{
                    border: "1px solid #FD416C",
                  }}
                >
                  <b>{filteredInfo.current.totalTickets > 0 ? filteredInfo.current.opened.noResponsible : currentSystem?.opened?.noResponsible}</b>
                </p>
                <p style={{ fontSize: window.innerWidth < 1500 && "11px" }}>НЕНАЗНАЧЕНИ</p>
              </div>
              {selectedFilter.unassigned && <TopicSelector />}
            </div>
          </ClickAwayListener>
          <ClickAwayListener onClickAway={() => {}}>
            <div
              className={styles["more-options"]}
              onClick={() => {
                const newFilters = { ...filters, noAnswered: true, withoutResponsible: false, waitAnswer: false, waitScore: false };
                dispatch(setTicketFilters(newFilters));
                getTicketsForSystem({
                  system: slug,
                  pageNumber: 1,
                  filtered: true,
                  ...newFilters,
                  ...payload,
                });
                setNextt(nextt + 1);
                setNextPage(0);
                setSelectedFilter({ unanswered: true, opened: false });
                setIsCompletedQuery(false);
                setSideFiltersActivated(true);
              }}
            >
              <div className={styles.options}>
                <p
                  className={styles.notification}
                  style={{
                    border: "1px solid #FF7C3B",
                  }}
                >
                  <b>{filteredInfo.current.totalTickets > 0 ? filteredInfo.current.opened.noAnswered : currentSystem?.opened?.noAnswered}</b>
                </p>
                <p style={{ fontSize: window.innerWidth < 1500 && "11px" }}>НЕОТГОВОРЕНИ</p>
              </div>
              {selectedFilter.unanswered && <TopicSelector />}
            </div>
          </ClickAwayListener>
          <ClickAwayListener onClickAway={() => {}}>
            <div
              className={styles["more-options"]}
              onClick={() => {
                const newFilters = { ...filters, waitAnswer: true, noAnswered: false, withoutResponsible: false, waitScore: false };
                dispatch(setTicketFilters(newFilters));
                getTicketsForSystem({
                  system: slug,
                  pageNumber: 1,
                  filtered: true,
                  ...newFilters,
                });
                setNextt(nextt + 1);
                setNextPage(0);
                setSelectedFilter({ waitAnswer: true, opened: false });
                setIsCompletedQuery(false);
                setSideFiltersActivated(true);
              }}
            >
              <div className={styles.options}>
                <p
                  className={styles.notification}
                  style={{
                    border: "1px solid #8244EB",
                  }}
                >
                  <b>{filteredInfo.current.totalTickets > 0 ? filteredInfo.current.opened.waitAnswer : currentSystem?.opened?.waitAnswer}</b>
                </p>
                <p style={{ fontSize: window.innerWidth < 1500 && "11px" }}>В ОЧАКВАНЕ НА ОТГОВОР</p>
              </div>
              {selectedFilter.waitAnswer && <TopicSelector />}
            </div>
          </ClickAwayListener>
          <ClickAwayListener onClickAway={() => {}}>
            <div
              className={styles["more-options"]}
              onClick={() => {
                const newFilters = { ...filters, waitScore: true, waitAnswer: false, noAnswered: false, withoutResponsible: false };
                dispatch(setTicketFilters(newFilters));
                getTicketsForSystem({
                  system: slug,
                  pageNumber: 1,
                  filtered: true,
                  ...newFilters,
                  ...payload,
                });
                setNextt(nextt + 1);
                setNextPage(0);
                setSelectedFilter({ waitScore: true, opened: false });
                setIsCompletedQuery(false);
                setSideFiltersActivated(true);
              }}
            >
              <div className={styles.options}>
                <p
                  className={styles.notification}
                  style={{
                    border: "1px solid #100424",
                  }}
                >
                  <b>{filteredInfo.current.totalTickets > 0 ? filteredInfo.current.opened.waitVote : currentSystem?.opened?.waitVote}</b>
                </p>
                <p style={{ fontSize: window.innerWidth < 1500 && "11px" }}>В ОЧАКВАНЕ НА OЦЕНКА</p>
              </div>
              {selectedFilter.waitScore && <TopicSelector />}
            </div>
          </ClickAwayListener>
        </div>
      </div>
    </Paper>
  );
};

export default OpenedSideTools;
