import React from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import Routes from "./components/routes/Routes";
import "./App.scss";

import { BrowserRouter } from "react-router-dom";

const theme = createTheme({
  typography: {
    fontFamily: ["Lexis-Book", "sans-serif"].join(","),
  },
});

function App() {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <Routes />
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
