/* eslint-disable */
import { Button, FormControl, Paper, TextField } from "@mui/material";
import React, { useState } from "react";
import styles from "../ChangeTopicNameModal/ChangeTopicNameModal.module.scss";
import CloseIcon from "@mui/icons-material/Close";

const TextLabelModal = ({
  handleClose,
  slug,
  labelsCreate,
  labelsEdit,
  labelList,
  label,
  edit
}) => {
  const [name, setName] = useState(edit ? label?.name : "");

  return (
    <Paper className={styles["assign-topic-modal"]}>
      <div className={styles["assign-topic-heading-container"]}>
        <h3>{edit ? "РЕДАКТИРАНЕ" : "ДОБАВЯНЕ"} НА ЕТИКЕТ</h3>
        <CloseIcon onClick={handleClose} />
      </div>
      <FormControl style={{ marginTop: "-3%" }}>
        <p style={{ padding: "10px" }}>Заглавие</p>
        <TextField
          value={name}
          onChange={(e) => setName(e.target.value)}
          sx={{ padding: "10px" }}
          variant="outlined"
          id="search-field"
          size="small"
        />
      </FormControl>
      <Paper elevation={10} style={{ padding: "10px" }}>
        <Button
          onClick={() => {
            if(!edit){
              labelsCreate({
                system:slug,
                labels:[name],
                onSuccess:() =>{
                  labelList({
                    system:slug,
                    noPagination:true
                  })
                }
              })
            }
            else{
              labelsEdit({
                id: label?._id,
                payloadAction: {
                  name: name,
                },
                onSuccess:() => {
                  labelList({
                    system:slug,
                    noPagination:true
                  })
                }
              })
            }
            handleClose();
          }}
          disableElevation
          disableRipple
          disableFocusRipple
          disabled={name.length > 2 ? false : true}
          variant="contained"
          sx={{
            width: "100%",
            color: "white",
            backgroundColor: "#01d088",
            cursor: "pointer",
            "&:hover": {
              backgroundColor: "#01d088",
            },
          }}
        >
          Добави
        </Button>
      </Paper>
    </Paper>
  );
};

export default TextLabelModal;
