/* eslint-disable */
import { toast } from "react-toastify";
import { ofType, ActionsObservable } from "redux-observable";
import { ajax } from "rxjs/ajax";
import { switchMap, catchError } from "rxjs/operators";
import { amarantFieldsTypes } from "../actions/amarantFields";
import { AMARANT2_API_URL, API_URL } from "../config/settings";
import { Headers } from "../utils/Headers";

export const getVehicles = (action$) => {
  return action$.pipe(
    ofType(amarantFieldsTypes.LIST_VEHICLES),
    switchMap(({ payload }) => {
      return ajax({
        url: `${AMARANT2_API_URL}/customerSupport/listVehicles`,
        method: "POST",
        headers: Headers.basicAuth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: amarantFieldsTypes.LIST_VEHICLES_SUCCESS,
              payload: response.payload,
            });
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            if (err.status === 401) {
              window.location.href = "/login";
            } else {
            }
          });
        })
      );
    })
  );
};

export const getPolicies = (action$) => {
  return action$.pipe(
    ofType(amarantFieldsTypes.LIST_POLICIES),
    switchMap(({ payload }) => {
      return ajax({
        url: `${AMARANT2_API_URL}/customerSupport/listPolicies`,
        method: "POST",
        headers: Headers.basicAuth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: amarantFieldsTypes.LIST_POLICIES_SUCCESS,
              payload: response.payload,
            });
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            if (err.status === 401) {
              window.location.href = "/login";
            } else {
            }
          });
        })
      );
    })
  );
};

export const getVignettes = (action$) => {
  return action$.pipe(
    ofType(amarantFieldsTypes.LIST_VIGNETTES),
    switchMap(({ payload }) => {
      return ajax({
        url: `${AMARANT2_API_URL}/customerSupport/listVignettes`,
        method: "POST",
        headers: Headers.basicAuth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: amarantFieldsTypes.LIST_VIGNETTES_SUCCESS,
              payload: response.payload,
            });
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            if (err.status === 401) {
              window.location.href = "/login";
            } else {
            }
          });
        })
      );
    })
  );
};

export const getFines = (action$) => {
  return action$.pipe(
    ofType(amarantFieldsTypes.LIST_FINES),
    switchMap(({ payload }) => {
      return ajax({
        url: `${AMARANT2_API_URL}/customerSupport/listFines`,
        method: "POST",
        headers: Headers.basicAuth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: amarantFieldsTypes.LIST_FINES_SUCCESS,
              payload: response.payload,
            });
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            if (err.status === 401) {
              window.location.href = "/login";
            } else {
            }
          });
        })
      );
    })
  );
};

export const getErrors = (action$) => {
  return action$.pipe(
    ofType(amarantFieldsTypes.LIST_ERRORS),
    switchMap(({ payload }) => {
      return ajax({
        url: `${AMARANT2_API_URL}/customerSupport/listErrors`,
        method: "POST",
        headers: Headers.basicAuth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: amarantFieldsTypes.LIST_ERRORS_SUCCESS,
              payload: response.payload,
            });
            if (payload?.onSuccess) payload?.onSuccess(response?.payload);
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            if (err.status === 401) {
              window.location.href = "/login";
            } else {
            }
          });
        })
      );
    })
  );
};

export const getDocuments = (action$) => {
  return action$.pipe(
    ofType(amarantFieldsTypes.LIST_DOCUMENTS),
    switchMap(({ payload }) => {
      return ajax({
        url: `${AMARANT2_API_URL}/customerSupport/listDrivingLicenses`,
        method: "POST",
        headers: Headers.basicAuth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: amarantFieldsTypes.LIST_DOCUMENTS_SUCCESS,
              payload: response.payload,
            });
            if (payload?.onSuccess) payload?.onSuccess(response?.payload);
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            if (err.status === 401) {
              window.location.href = "/login";
            } else {
            }
          });
        })
      );
    })
  );
};

export const getUserInfo = (action$) => {
  return action$.pipe(
    ofType(amarantFieldsTypes.USER_INFO),
    switchMap(({ payload }) => {
      return ajax({
        url: `${AMARANT2_API_URL}/customerSupport/userInfo`,
        method: "POST",
        headers: Headers.basicAuth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            payload.onSuccess(response.payload);
            obs.next({
              type: amarantFieldsTypes.USER_INFO_SUCCESS,
              payload: response.payload,
            });
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            console.log(err);
            toast.error(err?.response?.message);
            if (err.status === 401) {
              window.location.href = "/login";
            } else {
            }
          });
        })
      );
    })
  );
};

export const editUserInfo = (action$) => {
  return action$.pipe(
    ofType(amarantFieldsTypes.EDIT_USER_INFO),
    switchMap(({ payload }) => {
      return ajax({
        url: `${AMARANT2_API_URL}/customerSupport/userInfo`,
        method: "PUT",
        headers: Headers.basicAuth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            payload.onSuccess(response.payload);
            obs.next({
              type: amarantFieldsTypes.EDIT_USER_SUCCESS,
              payload: response.payload,
            });
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            if (err.status === 401) {
              window.location.href = "/login";
            } else {
            }
          });
        })
      );
    })
  );
};
export const checkAmarantUserEpic = (action$) => {
  return action$.pipe(
    ofType(amarantFieldsTypes.CHECK_AMARANT_USER),
    switchMap(({ payload }) => {
      return ajax({
        url: `${AMARANT2_API_URL}/customerSupport/getCustomerSupportId`,
        method: "POST",
        headers: Headers.basicAuth(),
        body: JSON.stringify({
          phoneNumber: payload.phone,
        }),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            if (response?.payload?.found) {
              payload.onSuccess(response);
            } else {
              payload.onError();
            }
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            toast.error("Invalid phone number, must start with +359");
          });
        })
      );
    })
  );
};
export const sendTemplateEpic = (action$) => {
  return action$.pipe(
    ofType(amarantFieldsTypes.SEND_TEMPLATE),
    switchMap(({ payload }) => {
      return ajax({
        url: `${AMARANT2_API_URL}/customerSupport/sendEmail`,
        method: "POST",
        headers: Headers.basicAuth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            payload.onSuccess(response);
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            console.log(err);
          });
        })
      );
    })
  );
};
