/* eslint-disable */
import { activityTypes } from "../actions/activity";

const initialState = {
  activity: [],
};

const activity = (state = initialState, { type, payload }) => {
  switch (type) {
    case activityTypes.GET_ACTIVITY_SUCCESS:
      return { ...state, activity: payload };
    default:
      return state;
  }
};

export default activity;
