/* eslint-disable */
import { Box, Modal, Paper, Popover, Typography } from "@mui/material";
import React, { useState } from "react";
import styles from "./AmarantComponent.module.scss";

const RequestModal = ({ open, handleClose, data, token, mobileModal, special, fromError, pin, _id }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const style = {
    position: "absolute",
    top: mobileModal ? "50%" : "33%",
    left: special === true ? (window.innerWidth < 1600 ? "70%" : "83%") : "50%",
    width: mobileModal ? "100%" : "45%",
    height: mobileModal ? "100%" : "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 30,
  };

  const openPopover = Boolean(anchorEl);
  const id = openPopover ? "simple-popover" : undefined;
  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Paper
          className={styles["activity-modal"]}
          style={{
            justifyContent: "flex-start",
            padding: "1vw",
            width: "100%",
          }}
        >
          <div className={styles["errorModal"]} style={{ width: "100%", position: "unset" }}>
            <div className={styles["headerModal"]} style={{ width: "97%" }}>
              <b
                style={{
                  textTransform: "uppercase",
                  fontSize: "16px",
                  marginLeft: window.innerWidth < 900 && "3%",
                }}
              >
                {fromError ? "ЗАЯВКА" : "ТОКЕН"}
              </b>
              <div
                className={styles["closeModal"]}
                onClick={() => {
                  handleClose();
                }}
              />
            </div>
            <div className={styles["bodyModal"]} style={{ border: "none" }}>
              <div className={styles["innerBox"]}>
                <p style={{ overflowWrap: "break-word" }}>{token === true ? data : JSON.stringify(data?.requestData, null, " ")}</p>
              </div>
              <div
                className={styles["buttonContainer"]}
                style={{
                  width: "95%",
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "4%",
                }}
              >
                <div
                  className={styles["copyButton"]}
                  onClick={(event) => {
                    navigator.clipboard.writeText(token === true ? data : JSON.stringify(data?.requestData, "", 2));
                    handleClick(event);
                  }}
                >
                  Копирай
                </div>
              </div>
            </div>
          </div>
          <div className="pin-modal">
            <h3 style={{ textAlign: "center" }}>PIN: {pin ? pin : "----"}</h3>
          </div>
          <div className="pin-modal" style={{marginTop:"10px"}}>
            <h3 style={{ textAlign: "center" }}>ID: {_id ? _id : "----"}</h3>
          </div>
        </Paper>
      </Box>
    </Modal>
  );
};

export default RequestModal;
