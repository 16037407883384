/* eslint-disable */
import { Divider } from "@mui/material";
import React, { useEffect, useState, useRef, useCallback } from "react";
import styles from "./AmarantComponent.module.scss";
import { listErrors, getUserInfo, editUserInfo, sendTemplate } from "../../actions/amarantFields";
import { getTicketsForSystem, editCustomerName } from "../../actions/tickets";
import { connect } from "react-redux";
import moment from "moment";
import VehiclesComponent from "./VehiclesComponent";
import PoliciesComponent from "./PoliciesComponent";
import VignettesComponent from "./VignettesComponent";
import FinesComponent from "./FinesComponent";
import ErrorsComponent from "./ErrorsComponent";
import HistoryComponent from "./HistoryComponent";
import VerifiedModal from "./VerifiedModal";
import OldTicketsModal from "./OldTicketsModal";
import RequestModal from "./RequestModal";
import useKeyPress from "../../utils/KeyboardShortcuts";
import { useNavigate } from "react-router";
import DocumentsComponent from "./DocumentsComponent";

const buttonNames = ["Aвтомобили", "Застраховки", "Винетки", "Глоби към КАТ", "Грешки", "История абонамент", "Документи"];

function AmarantComponent({ listErrors, getUserInfo, editUserInfo, sendTemplate, user, errors, totalErrors, totalPages, getTicketsForSystem, tickets, editCustomerName }) {
  const [selectedTab, setSelectedTab] = useState(0);
  const tableRef = useRef();
  const [openVerifiedModal, setOpenVerifiedModal] = useState(false);
  const [openOldTicketsModal, setOpenOldTicketsModal] = useState(false);
  const [requestModal, setRequestModal] = useState(false);
  const customerID = window.location.href.split("/")[5];
  const navigate = useNavigate();

  useEffect(() => {
    getUserInfo({
      //customerSupportId: "6231bc7bc8696d0016d4af13",
      customerSupportId: customerID,
      onSuccess: (res) => {
        getTicketsForSystem({
          system: "61ee8b87a07ddf1eb8268265",
          noPagination: true,
          customers: [customerID],
          customerAll: true,
        });
        listErrors({
          //customerSupportId: "6231bc7bc8696d0016d4af13",
          customerSupportId: customerID,
          pageNumber: 1,
        });
      },
    });
  }, []);

  const onKeyPress = (event) => {
    if (event.key === "Escape") {
      navigate(-1);
    }
  };

  useKeyPress(["Escape"], onKeyPress);

  return (
    <div className={styles["amarant-container"]}>
      <div className={styles["upper-info-container"]}>
        <p style={{ marginLeft: "1%" }}>
          Потребител: <b>{user?.name + " " + user?.lastName}</b>
        </p>
        <Divider
          style={{
            backgroundColor: "#0DC1CE",
            height: window.innerWidth < 900 ? "2%" : "50%",
            width: window.innerWidth < 900 ? "98%" : "unset",
            marginLeft: "1%",
          }}
          orientation={window.innerWidth < 900 ? "horizontal" : "vertical"}
        />
        <p style={{ marginLeft: "1%" }}>
          Телефон: <b>{user?.phoneNumber}</b>
        </p>
        <Divider
          style={{
            backgroundColor: "#0DC1CE",
            height: window.innerWidth < 900 ? "2%" : "50%",
            width: window.innerWidth < 900 ? "98%" : "unset",
            marginLeft: "1%",
          }}
          orientation={window.innerWidth < 900 ? "horizontal" : "vertical"}
        />
        <p style={{ marginLeft: "1%" }}>
          Имейл: <b>{user?.email}</b>
        </p>
        <Divider
          style={{
            backgroundColor: "#0DC1CE",
            height: window.innerWidth < 900 ? "2%" : "50%",
            width: window.innerWidth < 900 ? "98%" : "unset",
            marginLeft: "1%",
          }}
          orientation={window.innerWidth < 900 ? "horizontal" : "vertical"}
        />
        <p style={{ marginLeft: "1%" }}>
          Регистрация: <b>{moment(user?.createdAt).format("DD.MM.YYYY")}</b>
        </p>
        <Divider
          style={{
            backgroundColor: "#0DC1CE",
            height: window.innerWidth < 900 ? "2%" : "50%",
            width: window.innerWidth < 900 ? "98%" : "unset",
            marginLeft: "1%",
          }}
          orientation={window.innerWidth < 900 ? "horizontal" : "vertical"}
        />
        <p style={{ marginLeft: "1%" }}>
          Абонамент: <b>{user?.subscriptionValidUntil ? (moment(user?.subscriptionValidUntil).isAfter(moment()) ? `Активен до ${moment(user?.subscriptionValidUntil).format("DD.MM.YYYY | HH:mm")}` : `Изтекъл на ${moment(user?.subscriptionValidUntil).format("DD.MM.YYYY | HH:mm")}`) : "Не"}</b>
        </p>

        <Divider
          style={{
            backgroundColor: "#0DC1CE",
            height: window.innerWidth < 900 ? "2%" : "50%",
            width: window.innerWidth < 900 ? "98%" : "unset",
            marginLeft: "1%",
          }}
          orientation={window.innerWidth < 900 ? "horizontal" : "vertical"}
        />
        <div className={styles["helper-cont"]} style={{ display: "flex", marginLeft: "1%" }}>
          <p style={{ display: "flex" }}>
            Верифициран: <b style={{ marginLeft: "3%" }}>{user.agreement ? " Да" : " Не"}</b>
          </p>
          {user.agreement && (
            <div
              className={styles["info-icon"]}
              style={{
                width: "30px",
                height: window.innerWidth < 900 ? "20px" : "unset",
                marginLeft: "4%",
                display: user?.agreement === undefined && "none",
              }}
              onClick={() => setOpenVerifiedModal(true)}
            />
          )}
        </div>
        <Divider
          style={{
            backgroundColor: "#0DC1CE",
            height: window.innerWidth < 900 ? "2%" : "50%",
            width: window.innerWidth < 900 ? "98%" : "unset",
            marginLeft: "1%",
          }}
          orientation={window.innerWidth < 900 ? "horizontal" : "vertical"}
        />
        <p style={{ marginLeft: "1%" }}>
          OS: <b style={{ textTransform: "uppercase" }}>{user?.os}</b>
        </p>
        <Divider
          style={{
            backgroundColor: "#0DC1CE",
            height: window.innerWidth < 900 ? "2%" : "50%",
            width: window.innerWidth < 900 ? "98%" : "unset",
            marginLeft: "1%",
          }}
          orientation={window.innerWidth < 900 ? "horizontal" : "vertical"}
        />
        <div className={styles["helper-cont"]} style={{ display: "flex" }}>
          <p style={{ marginLeft: "2%", whiteSpace: "nowrap", display: "flex" }}>
            Предишни тикети: <b style={{ textTransform: "uppercase", marginLeft: "2%" }}>{tickets?.length}</b>
          </p>

          <div
            className={styles["info-icon"]}
            style={{
              width: "30px",
              marginLeft: "4%",
              height: window.innerWidth < 900 ? "20px" : "unset",
            }}
            onClick={() => setOpenOldTicketsModal(true)}
          />
        </div>
        <Divider
          style={{
            backgroundColor: "#0DC1CE",
            height: window.innerWidth < 900 ? "2%" : "50%",
            width: window.innerWidth < 900 ? "98%" : "unset",
            marginLeft: "1%",
          }}
          orientation={window.innerWidth < 900 ? "horizontal" : "vertical"}
        />
        <div className={styles["helper-cont"]} style={{ display: "flex" }}>
          <p style={{ marginLeft: "4%", display: "flex" }}>Token:</p>
          <div
            className={styles["info-icon"]}
            style={{
              width: "30px",
              marginLeft: "4%",
              height: window.innerWidth < 900 ? "20px" : "unset",
            }}
            onClick={() => {
              setRequestModal(true);
            }}
          />
        </div>
      </div>
      <div className={styles["lower-info-container"]}>
        <div className={styles["buttons-container"]}>
          {buttonNames.map((name, i) => {
            return (
              <div
                className={`${styles["button-container"]} ${i === selectedTab && styles["active"]} ${i === 4 && totalErrors < 1 ? styles["inactive"] : null}`}
                id={i}
                key={i + 10}
                onClick={() => {
                  setSelectedTab(i);
                }}
              >
                {i === 4 ? name + ` (${totalErrors})` : name}
              </div>
            );
          })}
        </div>
        {selectedTab === 0 ? (
          <VehiclesComponent customerID={customerID} />
        ) : selectedTab === 1 ? (
          <PoliciesComponent customerID={customerID} />
        ) : selectedTab === 2 ? (
          <VignettesComponent customerID={customerID} />
        ) : selectedTab === 3 ? (
          <FinesComponent customerID={customerID} />
        ) : selectedTab === 4 ? (
          <ErrorsComponent errors={errors} tableRef={tableRef} totalPages={totalPages} listErrors={listErrors} customerID={customerID} />
        ) : selectedTab === 5 && user ? (
          <HistoryComponent user={user} />
        ) : selectedTab === 6 ? (
          <DocumentsComponent customerID={customerID} />
        ) : (
          ""
        )}
      </div>
      <VerifiedModal open={openVerifiedModal} handleClose={() => setOpenVerifiedModal(false)} customerSupportID={customerID} editUserInfo={editUserInfo} currentUser={user} getUserInfo={getUserInfo} mobileModal={window.innerWidth < 900 && true} sendTemplate={sendTemplate} editCustomerName={editCustomerName} />
      <OldTicketsModal open={openOldTicketsModal} tickets={tickets} mobileModal={window.innerWidth < 900 && true} handleClose={() => setOpenOldTicketsModal(false)} />
      <RequestModal open={requestModal} handleClose={() => setRequestModal(false)} data={user?.token} pin={user?.passcode} _id={user?._id} mobileModal={window.innerWidth < 900 && true} token={true} />
    </div>
  );
}

const mapStateToProps = (state) => ({
  errors: state.amarantFields.errors,
  user: state.amarantFields.user,
  currentTicket: state.tickets.currentTicket,
  tickets: state.tickets.tickets,
  totalErrors: state.amarantFields.totalErrors,
  totalPages: state.amarantFields.totalPages,
});

const mapDispatchToProps = (dispatch) => ({
  listErrors: (payload) => dispatch(listErrors(payload)),
  getUserInfo: (payload) => dispatch(getUserInfo(payload)),
  editUserInfo: (payload) => dispatch(editUserInfo(payload)),
  getTicketsForSystem: (payload) => dispatch(getTicketsForSystem(payload)),
  sendTemplate: (payload) => dispatch(sendTemplate(payload)),
  editCustomerName: (payload) => dispatch(editCustomerName(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AmarantComponent);
