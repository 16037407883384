/* eslint-disable */
import React, { useEffect, useState } from "react";
import styles from "./TemplatesModal.module.scss";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ModalComponent from "../ModalComponent/ModalComponent";
import DeleteUserModal from "../DeleteUserModal/DeleteUserModal";
import { Divider } from "@mui/material";
import DeleteTemplateModal from "./DeleteTemplateModal";
import EditTemplateModal from "./EditTemplateModal";
import AnswerContainer from "./AnswerContainer";
import { toast } from "react-toastify";

const TemplateTextModal = ({
  groups,
  selGroup,
  setSelGroup,
  editGroupModal,
  setEditGroupModal,
  slug,
  templates,
  setCurrentMessage
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selTemplate, setSelTemplate] = useState(null);
  const [openDeleteTemplateModal, setOpenDeleteTemplateModal] = useState(false);
  const [editTemplateModal, setEditTemplateModal] = useState(false);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    //setEditGroupModal(true);
    setAnchorEl(event.currentTarget);
  };
  const handleDropDownClose = () => {
    setAnchorEl(null);
  };


  return (
    <div className={styles["template-answers-container"]}>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <h3>{selGroup?.name}</h3>
        <MoreVertIcon style={{ cursor: "pointer" }} onClick={handleClick} />
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleDropDownClose}
          id={"1234"}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          PaperProps={{
            style: {
              width: 200,
            },
          }}
        >
          <MenuItem
            onClick={() => {
              setEditGroupModal(true);
              setSelTemplate(null);
              handleDropDownClose();
            }}
          >
            <div className={styles["edit-group"]}></div>{" "}
            <p style={{ fontSize: "13px" }}>Редактирай тема</p>
          </MenuItem>
          <Divider sx={{ background: "#0DC1CE" }} />
          <MenuItem
            onClick={() => {
              setOpenDeleteModal(true);
              handleDropDownClose();
            }}
          >
            <div
              className={`${styles["edit-group"]} ${styles["delete-group"]}`}
            ></div>{" "}
            <p style={{ fontSize: "13px" }}>Изтрий тема</p>
          </MenuItem>
        </Menu>
      </div>
      {templates?.map((template, i) => {
        return (
          <AnswerContainer
            template={template}
            i={i}
            setSelTemplate={setSelTemplate}
            setEditTemplateModal={setEditTemplateModal}
            setOpenDeleteTemplateModal={setOpenDeleteTemplateModal}
            selTemplate={selTemplate}
            key={i}
            setCurrentMessage={setCurrentMessage}
          />
        );
      })}

      <ModalComponent
        open={openDeleteModal}
        handleClose={() => {
          setOpenDeleteModal(false);
        }}
        children={
          <DeleteUserModal
            id={selGroup?._id}
            systemId={slug}
            setSelGroup={setSelGroup}
            handleClose={() => {
              setOpenDeleteModal(false);
            }}
          />
        }
      />
      <ModalComponent
        open={openDeleteTemplateModal}
        handleClose={() => {
          setOpenDeleteTemplateModal(false);
        }}
        children={
          <DeleteTemplateModal
            template={selTemplate}
            id={selGroup?._id}
            handleClose={() => {
              setOpenDeleteTemplateModal(false);
            }}
          />
        }
      />
      <ModalComponent
        open={editTemplateModal}
        handleClose={() => {
          setEditTemplateModal(false);
        }}
        children={
          <EditTemplateModal
            template={selTemplate}
            handleClose={() => {
              setEditTemplateModal(false);
            }}
            slug={slug}
          />
        }
      />
    </div>
  );
};

export default TemplateTextModal;
