import {  uploadFileDirName } from "./files";

export const uploadMedia = async (selectedMedia, onSuccess, handleCloseLoading, system) => {
  let stack = [];
  for (let i = 0; i < selectedMedia.length; i++) {
    try {
      const res = await uploadFileDirName(selectedMedia[i], system);
      stack.push(res);
    } catch (err) {
      console.log(err);
      break;
    }
  }
  onSuccess && onSuccess(stack);
  handleCloseLoading();
};
