export const immotechFieldsTypes = {
  USER_INFO: "immotechFields/USER_INFO",
  USER_INFO_SUCCESS: "immotechFields/USER_INFO_SUCCESS",
  CHECK_IMMOTECH_USER: "immotechFields/CHECK_IMMOTECH_USER",
};

export const getImmotechUserInfo = (payload) => ({
  type: immotechFieldsTypes.USER_INFO,
  payload,
});
export const checkImmotechUser = (payload) => ({
  type: immotechFieldsTypes.CHECK_IMMOTECH_USER,
  payload,
});
