/* eslint-disable */
import { Button, FormControl, Paper, TextField } from "@mui/material";
import React, { useState } from "react";
import styles from "./AmarantComponent.module.scss";
import CloseIcon from "@mui/icons-material/Close";
import { uploadFile } from "../../utils/files";
import { toast } from "react-toastify";
import moment from "moment";
import { nanoid } from "nanoid";
import { editCustomerName } from "../../actions/tickets";
import { useDispatch } from "react-redux";

const EditNameModal = ({ handleClose, currentUser, editUserInfo, customerSupportID, getUserInfo, sendTemplate, editCustomerName }) => {
  const [firstName, setFirstName] = useState(currentUser?.agreement?.fullName.split(" ")[0]);
  const [middleName, setMiddleName] = useState(currentUser?.agreement?.fullName.split(" ")[1]);
  const [lastName, setLastName] = useState(currentUser?.agreement?.fullName.split(" ")[2]);
  const dispatch = useDispatch();
  return (
    <Paper className={styles["assign-topic-modal"]}>
      {window.innerWidth < 900 ? (
        <div>
          <div className={styles["assign-topic-heading-container"]}>
            <h3>РЕДАКТИРАНЕ НА ИМЕНА</h3>
            <CloseIcon
              onClick={handleClose}
              style={{
                width: "1.3em",
                height: "1.3em",
              }}
            />
          </div>
          <FormControl style={{ width: "100%", padding: "1vw" }}>
            <p style={{ padding: "10px" }}>Име</p>
            <TextField value={firstName} onChange={(e) => setFirstName(e.target.value)} sx={{ padding: "10px" }} variant="outlined" id="search-field" size="small" />
            <p style={{ padding: "10px" }}>Презиме</p>
            <TextField value={middleName} onChange={(e) => setMiddleName(e.target.value)} sx={{ padding: "10px" }} variant="outlined" id="search-field" size="small" />
            <p style={{ padding: "10px" }}>Фамилия</p>
            <TextField value={lastName} onChange={(e) => setLastName(e.target.value)} sx={{ padding: "10px" }} variant="outlined" id="search-field" size="small" />
          </FormControl>
        </div>
      ) : (
        <>
          <div className={styles["assign-topic-heading-container"]}>
            <h3>РЕДАКТИРАНЕ НА ИМЕНА</h3>
            <CloseIcon
              onClick={handleClose}
              style={{
                width: window.innerWidth < 900 && "1.3em",
                height: window.innerWidth < 900 && "1.3em",
              }}
            />
          </div>
          <FormControl style={{ marginTop: "-3%" }}>
            <p style={{ padding: "10px" }}>Име</p>
            <TextField value={firstName} onChange={(e) => setFirstName(e.target.value)} sx={{ padding: "10px" }} variant="outlined" id="search-field" size="small" />
            <p style={{ padding: "10px" }}>Презиме</p>
            <TextField value={middleName} onChange={(e) => setMiddleName(e.target.value)} sx={{ padding: "10px" }} variant="outlined" id="search-field" size="small" />
            <p style={{ padding: "10px" }}>Фамилия</p>
            <TextField value={lastName} onChange={(e) => setLastName(e.target.value)} sx={{ padding: "10px" }} variant="outlined" id="search-field" size="small" />
          </FormControl>
        </>
      )}

      <Paper elevation={10} style={{ padding: "10px" }}>
        <Button
          onClick={() => {
            editUserInfo({
              customerSupportId: customerSupportID,
              name: firstName,
              middleName: middleName,
              lastName: lastName,
              onSuccess: (res) => {
                editCustomerName({
                  id: customerSupportID,
                  body: {
                    name: res?.fullName,
                  },
                });
                const requestOptions = {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: "Basic R290U29tZVBERkJybzpZZWFoTWF0ZUdldFRoaXNGdWNraW5nUERGIUAyIUBA",
                  },
                  body: JSON.stringify(res?.getPdfRequest),
                };
                fetch("https://pdf.fidweb.net", requestOptions)
                  .then((res) => res.blob())
                  .then((blob) => {
                    var file = new File([blob], `VUZLAGATELEN_DOGOVOR_${nanoid()}.pdf`);
                    uploadFile(file).then((data) => {
                      sendTemplate({
                        customerSupportId: customerSupportID,
                        subject: "Амарант - редакция данни",
                        template: "d-6d85b69a71944a0bbece4aa094bb7a05",
                        dynamicData: {
                          date: moment().format("DD.MM.YYYY"),
                          address: res?.agreement?.address,
                          phoneNumber: res?.agreement?.phoneNumber,
                          fullName: res?.agreement?.fullName,
                          PIN: res?.agreement?.PIN,
                          subject: "Амарант - редакция данни",
                          url: data?.location,
                        },
                        onSuccess: (res) => {
                          toast.success("Email sent successfully!");
                        },
                      });
                    });
                  });
              },
            });
            getUserInfo({
              customerSupportId: customerSupportID,
              onSuccess: (res) => {},
            });
            handleClose();
          }}
          disableElevation
          disableRipple
          disableFocusRipple
          disabled={firstName.length > 2 && lastName.length > 2 ? false : true}
          variant="contained"
          sx={{
            width: "100%",
            color: "white",
            backgroundColor: "#01d088",
            cursor: "pointer",
            "&:hover": {
              backgroundColor: "#01d088",
            },
          }}
        >
          Запази
        </Button>
      </Paper>
    </Paper>
  );
};

export default EditNameModal;
