/* eslint-disable */
import { Divider,Button } from "@mui/material";
import React, { useEffect, useState, useRef, useCallback } from "react";
import styles from "./ImmotechComponent.module.scss";
import { getImmotechUserInfo } from "../../actions";
import { getTicketsForSystem } from "../../actions/tickets";
import RequestModal from "../AmarantComponent/RequestModal";
import OldTicketsModal from "../AmarantComponent/OldTicketsModal"
import { connect } from "react-redux";
import moment from "moment";

const ImmotechComponent = ({
  immotechUser,
  getImmotechUserInfo,
  getTicketsForSystem,
  tickets,
}) => {
  const customerID = window.location.href.split("/")[5];
  const [tokenModal, setTokenModal] = useState(false);
  const [openOldTicketsModal, setOpenOldTicketsModal] = useState(false);

  useEffect(() => {
    getImmotechUserInfo({
      customerSupportId: customerID,
    });
    getTicketsForSystem({
        system: "61ee8bcaa07ddf1eb8268269",
        noPagination: true,
        customers: [customerID],
        customerAll: true,
      });
  }, []);

  return (
    <div className={styles["amarant-container"]}>
      <div className={styles["upper-info-container"]}>
        <p style={{ marginLeft: "1%" }}>
          Потребител: <b>{immotechUser?.fullName || "---"}</b>
        </p>
        <Divider
          style={{
            backgroundColor: "#0DC1CE",
            height: window.innerWidth < 900 ? "2%" : "50%",
            width: window.innerWidth < 900 ? "98%" : "unset",
            marginLeft: "1%",
          }}
          orientation={window.innerWidth < 900 ? "horizontal" : "vertical"}
        />
        <p style={{ marginLeft: "1%" }}>
          Телефон: <b>{immotechUser?.phoneNumber || "---"}</b>
        </p>
        <Divider
          style={{
            backgroundColor: "#0DC1CE",
            height: window.innerWidth < 900 ? "2%" : "50%",
            width: window.innerWidth < 900 ? "98%" : "unset",
            marginLeft: "1%",
          }}
          orientation={window.innerWidth < 900 ? "horizontal" : "vertical"}
        />
        <p style={{ marginLeft: "1%" }}>
          Имейл: <b>{immotechUser?.email || "---"}</b>
        </p>
        <Divider
          style={{
            backgroundColor: "#0DC1CE",
            height: window.innerWidth < 900 ? "2%" : "50%",
            width: window.innerWidth < 900 ? "98%" : "unset",
            marginLeft: "1%",
          }}
          orientation={window.innerWidth < 900 ? "horizontal" : "vertical"}
        />
        <p style={{ marginLeft: "1%" }}>
          Регистрация:{" "}
          <b>{moment(immotechUser?.createdAt).format("DD.MM.YYYY")}</b>
        </p>
        <Divider
          style={{
            backgroundColor: "#0DC1CE",
            height: window.innerWidth < 900 ? "2%" : "50%",
            width: window.innerWidth < 900 ? "98%" : "unset",
            marginLeft: "1%",
          }}
          orientation={window.innerWidth < 900 ? "horizontal" : "vertical"}
        />
        <p style={{ marginLeft: "1%" }}>
          OS:{" "}
          <b style={{ textTransform: "uppercase" }}>
            {immotechUser?.os || "---"}
          </b>
        </p>
        <Divider
          style={{
            backgroundColor: "#0DC1CE",
            height: window.innerWidth < 900 ? "2%" : "50%",
            width: window.innerWidth < 900 ? "98%" : "unset",
            marginLeft: "1%",
          }}
          orientation={window.innerWidth < 900 ? "horizontal" : "vertical"}
        />
        <div className={styles["helper-cont"]} style={{ display: "flex" }}>
          <p
            style={{ marginLeft: "2%", whiteSpace: "nowrap", display: "flex" }}
          >
            Предишни теми:{" "}
            <b style={{ textTransform: "uppercase", marginLeft: "2%" }}>
              {tickets?.length}
            </b>
          </p>

          <div
            className={styles["info-icon"]}
            style={{
              width: "30px",
              marginLeft: "4%",
              height: window.innerWidth < 900 ? "20px" : "unset",
            }}
            onClick={() => setOpenOldTicketsModal(true)}
          />
        </div>
        <Divider
          style={{
            backgroundColor: "#0DC1CE",
            height: window.innerWidth < 900 ? "2%" : "50%",
            width: window.innerWidth < 900 ? "98%" : "unset",
            marginLeft: "1%",
          }}
          orientation={window.innerWidth < 900 ? "horizontal" : "vertical"}
        />
        <div className={styles["helper-cont"]} style={{ display: "flex" }}>
          <p style={{ marginLeft: "4%", display: "flex" }}>Token:</p>
          <div
            className={styles["info-icon"]}
            style={{
              width: "30px",
              marginLeft: "4%",
              height: window.innerWidth < 900 ? "20px" : "unset",
            }}
            onClick={() => {
              setTokenModal(true);
            }}
          />
        </div>
      </div>
      <div className={styles["lower-info-container"]}>
        <div className={styles["empty-immotech-image"]} />
        <p style={{ margin: "20px 0px",textAlign: window.innerWidth < 900 && "center" }}>
          За да прегледате всички детайли за потребителя,влезте в Immotech админ
          панела
        </p>
        <Button
          sx={{ boxShadow: 1 }}
          style={{
            backgroundColor: "#00D297",
            color: "white",
            textTransform:"capitalize",
            borderRadius: "4px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width:window.innerWidth < 900 ? "90%" :"30%"
          }}
          onClick={() => {
            window.open("https://immotech-admin-dev.herokuapp.com/")
          }}
        >
          <span>Преглед</span>
        </Button>
      </div>
      <RequestModal
        open={tokenModal}
        handleClose={() => setTokenModal(false)}
        data={immotechUser?.token}
        pin={immotechUser?.pin}
        mobileModal={window.innerWidth < 900 && true}
        token={true}
      />
      <OldTicketsModal
        open={openOldTicketsModal}
        tickets={tickets}
        mobileModal={window.innerWidth < 900 && true}
        handleClose={() => setOpenOldTicketsModal(false)}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  immotechUser: state.immotechFields.immotechUser,
  tickets: state.tickets.tickets,
});

const mapDispatchToProps = (dispatch) => ({
  getTicketsForSystem: (payload) => dispatch(getTicketsForSystem(payload)),
  getImmotechUserInfo: (payload) => dispatch(getImmotechUserInfo(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ImmotechComponent);
