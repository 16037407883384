/* eslint-disable */
import { moment } from "moment";

export function filterByProp(arr, word, field) {
  switch (word) {
    case "plate":
      arr.sort((a, b) => {
        if (!a[field] && !b[field]) {
          return 0;
        } else if (a[field] && !b[field]) {
          return -1;
        } else if (!a[field] && b[field]) {
          return 1;
        } else {
          const value1 = a[field].toString().toUpperCase();
          const value2 = b[field].toString().toUpperCase();
          if (value1 < value2) {
            return -1;
          } else if (value1 > value2) {
            return 1;
          } else {
            return 0;
          }
        }
      });
      break;
    case "reversePlate":
      arr.sort((a, b) => {
        if (!a[field] && !b[field]) {
          return 0;
        } else if (a[field] && !b[field]) {
          return 1;
        } else if (!a[field] && b[field]) {
          return -1;
        } else {
          const value1 = a[field].toString().toUpperCase();
          const value2 = b[field].toString().toUpperCase();
          if (value1 < value2) {
            return 1;
          } else if (value1 > value2) {
            return -1;
          } else {
            return 0;
          }
        }
      });
      break;
    case "createdAt":
      arr.sort((a, b) => {
        var c = new Date(a.createdAt);
        var d = new Date(b.createdAt);
        return c - d;
      });
      break;
    case "reverseCreatedAt":
      arr.sort((a, b) => {
        var c = new Date(a.createdAt);
        var d = new Date(b.createdAt);
        return d - c;
      });
      break;
    case "time":
      arr.sort((a, b) => {
        var c = moment(a.createdAt).format("HH:mm");
        var d = moment(b.createdAt).format("HH:mm");
        if (!c && !d) {
          return 0;
        } else if (c && !d) {
          return 1;
        } else if (!c && d) {
          return -1;
        } else {
          const value1 = c.toString().toUpperCase();
          const value2 = d.toString().toUpperCase();
          if (value1 < value2) {
            return 1;
          } else if (value1 > value2) {
            return -1;
          } else {
            return 0;
          }
        }
      });
      break;
    case "reverseTime":
      arr.sort((a, b) => {
        var c = moment(a.createdAt).format("HH:mm");
        var d = moment(b.createdAt).format("HH:mm");
        if (!a[field] && !b[field]) {
          return 0;
        } else if (a[field] && !b[field]) {
          return -1;
        } else if (!a[field] && b[field]) {
          return 1;
        } else {
          const value1 = a[field].toString().toUpperCase();
          const value2 = b[field].toString().toUpperCase();
          if (value1 < value2) {
            return -1;
          } else if (value1 > value2) {
            return 1;
          } else {
            return 0;
          }
        }
      });
      break;
    case "price":
      arr.sort((a, b) => {
        return a[field] - b[field];
      });
      break;
    case "reversePrice":
      arr.sort((a, b) => {
        return b[field] - a[field];
      });
    case "activated":
      arr.sort((a, b) => {
        const aHasNewApp = a?.newApp === "Да";
        const aHasActivationDate = a?.activationDate === "Да";
        const bHasNewApp = b?.newApp === "Да";
        const bHasActivationDate = b?.activationDate === "Да";
      
        if (aHasNewApp || aHasActivationDate) {
          if (!(bHasNewApp || bHasActivationDate)) {
            return -1; // a should come before b
          }
        } else if (bHasNewApp || bHasActivationDate) {
          return 1; // b should come before a
        }
      
        return 0; // elements are equal in this sorting criteria
      });
  }
}

export const calculateMonetary = (expression) => {
  return Math.round((expression + Number.EPSILON) * 100) / 100;
};
