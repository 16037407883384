export const userTypes = {
  GET_ALL_USERS: "users/GET_ALL_USERS",
  GET_ALL_USERS_SUCCESS: "users/GET_ALL_USERS_SUCCESS",
  GET_CURRENT_USER: "users/GET_CURRENT_USER",
  GET_CURRENT_USER_SUCCESS: "users/GET_CURRENT_USER_SUCCESS",
  EDIT_CURRENT_USER: "users/EDIT_CURRENT_USER",
  EDIT_CURRENT_USER_SUCCESS: "users/EDIT_CURRENT_USER_SUCCESS",
  DELETE_CURRENT_USER: "users/DELETE_CURRENT_USER",
  DELETE_CURRENT_USER_SUCCESS: "users/DELETE_CURRENT_USER_SUCCESS",
  CHANGE_USER_PASSWORD: "users/CHANGE_USER_PASSWORD",
  CHANGE_USER_PASSWORD_FAIL: "users/CHANGE_USER_PASSWORD_FAIL",
  CHANGE_USER_PASSWORD_SUCCESS: "users/CHANGE_USER_PASSWORD_SUCCESS",
  GET_CURRENT_SYSTEM_USERS: "users/GET_CURRENT_SYSTEM_USERS",
  GET_CURRENT_SYSTEM_USERS_SUCCESS: "users/GET_CURRENT_SYSTEM_USERS_SUCCESS",
  CREATE_USER: "users/CREATE_USER",
  CREATE_USER_SUCCESS: "users/CREATE_USER_SUCCESS",
};

export const getAllUsers = (payload) => ({
  type: userTypes.GET_ALL_USERS,
  payload,
});

export const getCurrentUser = (payload) => ({
  type: userTypes.GET_CURRENT_USER,
  payload,
});

export const createUser = (payload) => ({
  type: userTypes.CREATE_USER,
  payload,
});

export const editCurrentUser = (payload) => ({
  type: userTypes.EDIT_CURRENT_USER,
  payload,
});

export const deleteCurrentUser = (payload) => ({
  type: userTypes.DELETE_CURRENT_USER,
  payload,
});

export const changeUserPassword = (payload) => ({
  type: userTypes.CHANGE_USER_PASSWORD,
  payload,
});

export const getCurrentSystemUsers = (payload) => ({
  type: userTypes.GET_CURRENT_SYSTEM_USERS,
  payload,
});
