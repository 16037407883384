/* eslint-disable */
import { Avatar, Box, Modal, Paper } from "@mui/material";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { getActivityList } from "../../../actions/activity";
import { format } from "date-fns";
import styles from "./Activity.module.scss";

const style = {
  position: "absolute",
  top: "55%",
  left: "80%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 30,
};

function Activity({ open, handleClose, activity, getActivityList }) {
  const { topicId } = useParams();
  useEffect(() => {
    if(open){
      getActivityList({ ticket: topicId });

    }
  }, [open]);
  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Paper className={styles["activity-modal"]}>
          <div className={styles["activity-heading-container"]}>
            <h3>АКТИВНОСТ</h3>
            <p onClick={handleClose} style={{ cursor: "pointer" }}>
              Скрий {`>`}
            </p>
          </div>
          <div className={styles["activity-window"]}>
            {activity?.map((activityItem) => (
              <div className={styles.activity} key={activityItem?.id}>
                <p>
                  {" "}
                  {format(new Date(activityItem?.updatedAt), "dd.MM.yyyy")} |
                  {format(new Date(activityItem?.updatedAt), "HH:mm")}
                </p>
                <div className={styles["avatar-container"]}>
                  <Avatar src={activityItem?.from?.avatar} style={{width:"30px",height:"30px"}} />
                  <p style={{marginLeft:"2%"}}>{activityItem?.from?.name}</p>
                </div>
                <h4>{activityItem?.text}</h4>
              </div>
            ))}
          </div>
        </Paper>
      </Box>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  activity: state.activity.activity,
});

const mapDispatchToProps = (dispatch) => ({
  getActivityList: (payload) => dispatch(getActivityList(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Activity);
