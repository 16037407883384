/* eslint-disable */
import { omit } from "lodash";
import { ticketTypes } from "../actions/tickets";

const initialState = {
  tickets: [],
  ticketsInfo: {},
  currentTicket: {},
  currentTicketOffer: {},
  isFiltered: false,
  filters: {},
  filterTickets: {
    openOnly: [],
    withoutResponsible: [],
    noAnswered: [],
    completedOnly: [],
    archivedOnly: [],
  },
};

const handleTickets = (state, payload, body) => {
  let newState = [...state];
  if (payload.page === 1) {
    state = [];
    newState = payload.docs;
  } else {
    newState = [...state, ...payload.docs];
  }

  return newState;
};

const handleReplaceTicketIfExist = (state, payload) => {
  if (state) {
    let newState = [...state];
    if (payload?.length === 1 && !!newState?.filter((ticket) => ticket?._id === payload?.[0]?._id)?.length) {
      const roomIndex = newState?.findIndex((ticket) => ticket?._id === payload?.[0]?._id);
      newState[roomIndex] = payload?.[0];
      return newState;
    } else return state;
  } else return state;
};

const tickets = (state = initialState, { type, payload, body }) => {
  switch (type) {
    case ticketTypes.GET_TICKETS_FOR_SYSTEM_SUCCESS:
      return {
        ...state,
        tickets: handleTickets(state.tickets, payload, body),
        ticketsInfo: omit(payload, "docs"),
        isFiltered: body?.filtered,
      };
    case ticketTypes.GET_CURRENT_TICKET_SUCCESS:
      return { ...state, currentTicket: payload };
    case ticketTypes.TICKET_ACTIONS_SUCCESS:
      return { ...state, currentTicket: payload };
    case ticketTypes.GET_CURRENT_TICKET_ACCEPTED_OFFER_SUCCESS:
      return { ...state, currentTicketOffer: payload };
    case ticketTypes.REPLACE_TICKET:
      return {
        ...state,
        tickets: handleReplaceTicketIfExist(state.tickets, payload?.payload, body),
      };
    case ticketTypes.RESET_TICKETS:
      return { ...state, tickets: [], ticketsInfo: {} };

    case ticketTypes.SET_FILTERS:
      return { ...state, filters: payload };
    default:
      return state;
  }
};

export default tickets;
