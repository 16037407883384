/* eslint-disable */
import { Box, Modal, Paper, InputBase } from "@mui/material";
import React, { useState, useRef, useCallback } from "react";
import SendIcon from "@mui/icons-material/Send";
import styles from "./Notes.module.scss";
import { format } from "date-fns";
import { createNote, getNotesList, sendEmails } from "../../../actions/notes";
import { connect } from "react-redux";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { User } from "../../../utils/Authenticate";

const style = {
  position: "absolute",
  top: "55%",
  left: "80%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 30,
};

function Notes({ open, handleClose, notesInfo, notes = [], getNotesList, createNote, sendEmails }) {
  const totalPages = notesInfo.totalPages;
  const [nextPage, setNextPage] = useState(1);
  const currentUser = User.loadLocalStorageItems();
  const userId = currentUser.user._id;
  const { topicId } = useParams();

  useEffect(() => {
    if (open) getNotesList({ ticket: topicId, noPagination: true });
  }, [getNotesList, nextPage, open]);

  const [currentNote, setCurrentNote] = useState("");

  const handleChange = (event) => {
    setCurrentNote(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    createNote({ ticket: topicId, text: currentNote });
    setCurrentNote("");
  };
  const messages = [...notes].reverse();

  const observer = useRef(null);
  const handleLoadNotesRef = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting === true && nextPage <= totalPages) setNextPage((prevPageNumber) => prevPageNumber + 1);
      });
      if (node) observer.current.observe(node);
    },
    [totalPages]
  );

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Paper className={styles["notes-modal"]}>
          <div className={styles["notes-heading-container"]}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <h3 style={{ marginTop: "-3%" }}>БЕЛЕЖКИ</h3>
              <div
                className={styles["sendEmails"]}
                onClick={() => {
                  sendEmails({
                    id: topicId,
                  });
                  handleClose();
                }}
              ></div>
            </div>
            <p onClick={handleClose} style={{ cursor: "pointer", marginTop: "-3%" }}>
              Скрий {`>`}
            </p>
          </div>
          <div className={styles["notes-window"]}>
            <div className={styles["notes-history"]}>
              {messages?.reverse()?.map((message, index) => (
                <div ref={handleLoadNotesRef} className={`${styles.message} ${message?.author?._id === userId ? styles.me : ""}`} key={index}>
                  {message?.author?._id === userId ? (
                    <>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end",
                        }}
                      >
                        <h5>{message?.author?.name}</h5>
                        <p className={styles.time}>
                          {format(new Date(message?.createdAt), "dd.MM")} | {format(new Date(message?.createdAt), "HH:mm")}
                        </p>
                      </div>
                      <div className={styles["message-body"]}>{message?.text}</div>
                    </>
                  ) : (
                    <>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-start",
                        }}
                      >
                        <h5>{message?.author?.name}</h5>
                        <p className={styles.time}>
                          {format(new Date(message?.createdAt), "dd.MM")} | {format(new Date(message?.createdAt), "HH:mm")}
                        </p>
                      </div>
                      <div className={styles["message-body"]}>{message?.text}</div>
                    </>
                  )}
                </div>
              ))}
            </div>
          </div>
          <div className={styles["notes-form"]}>
            <Paper
              onSubmit={handleSubmit}
              component={"form"}
              sx={{
                p: "2px 5px",
                display: "flex",
                alignItems: "center",
                width: "100%",
              }}
            >
              <InputBase sx={{ ml: 2, flex: 1 }} onChange={handleChange} value={currentNote} />
            </Paper>
            <SendIcon onClick={handleSubmit} fontSize="medium" style={{ color: "#01D088", cursor: "pointer" }} />
          </div>
        </Paper>
      </Box>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  notes: state.notes.notes,
  notesInfo: state.notes.notesInfo,
});

const mapDispatchToProps = (dispatch) => ({
  getNotesList: (payload) => dispatch(getNotesList(payload)),
  createNote: (payload) => dispatch(createNote(payload)),
  sendEmails: (payload) => dispatch(sendEmails(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Notes);
