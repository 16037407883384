/* eslint-disable */
import { Paper } from "@mui/material";
import React from "react";
import styles from "./Ratings.module.scss";

export default function Ratings({ message }) {
  return (
    <div className={styles["ratings-container"]}>
      <Paper
        className={styles["rating"]}
        elevation={1}
        style={
          message?.text === "3"
            ? { background: "#01D088", color: "#FFFFFF" }
            : { background: "#EDFFF9" }
        }
      >
        ОТЛИЧНО ОБСЛУЖВАНЕ!
      </Paper>
      <Paper
        className={styles["rating"]}
        elevation={1}
        style={
          message?.text === "2"
            ? { background: "#8244EB", color: "#FFFFFF" }
            : { background: "#F2F3FF" }
        }
      >
        СРЕДНА РАБОТА, МОЖЕ И ОЩЕ!
      </Paper>
      <Paper
        className={styles["rating"]}
        elevation={1}
        style={
          message?.text === "1"
            ? { background: "#FD416C", color: "#FFFFFF" }
            : { background: "#FFEFF2" }
        }
      >
        ПОД ВСЯКАКВА КРИТИКА!
      </Paper>
    </div>
  );
}
