/* eslint-disable */
import React from "react";
import { Paper, Button } from "@mui/material";
import { deleteCurrentUser } from "../../../actions/users";
import { groupDelete,groupsList } from "../../../actions/template";
import { useNavigate } from "react-router";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";

function DeleteUserModal({ handleClose, deleteCurrentUser, id,systemId, groupDelete,groupsList,template,setSelGroup }) {
  const { slug } = useParams();
  const navigate = useNavigate();

  const handleDelete = () => {
    if (id?.length > 0) {
      groupDelete({
        array:[id],
        onSuccess:() => {
          setSelGroup(null);
          groupsList({
            system:systemId,
            noPagination:true,
            onSuccess:(res) => {}
          })
        }
      });
    } else {
      deleteCurrentUser(slug);
      Swal.fire("Успешно изтриване.", "", "success").then(() => {
        navigate("/users");
      });
    }
    handleClose();
  };

  return (
    <Paper
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-evenly",
        width: "550px",
        height: "220px",
      }}
    >
      <h4>ИЗТРИВАНЕ НА {id?.length > 0 ? "ТЕМА" : "ПОТРЕБИТЕЛ"}</h4>
      <p>Моля потвърдете, че искате да изтриете {id?.length > 0 ? "тази тема" :"този потребител"}</p>
      <div style={{ display: "flex" }}>
        <Button
          onClick={() => handleClose(false)}
          disableRipple
          disableFocusRipple
          variant="contained"
          sx={{
            marginRight: "1rem",
            color: "black",
            backgroundColor: "#FFFFFF",
            cursor: "pointer",
            "&:hover": {
              backgroundColor: "#FFFFFF",
            },
          }}
        >
          Откажи
        </Button>
        <Button
          onClick={handleDelete}
          disableRipple
          disableFocusRipple
          variant="contained"
          sx={{
            marginRight: "1rem",
            color: "#FFFFFF",
            backgroundColor: "#FD416C",
            cursor: "pointer",
            "&:hover": {
              backgroundColor: "#FD416C",
            },
          }}
        >
          Изтрий
        </Button>
      </div>
    </Paper>
  );
}

const mapDispatchToProps = (dispatch) => ({
  deleteCurrentUser: (payload) => dispatch(deleteCurrentUser(payload)),
  groupDelete: (payload) => dispatch(groupDelete(payload)),
  groupsList: (payload) => dispatch(groupsList(payload)),
});

export default connect(null, mapDispatchToProps)(DeleteUserModal);
