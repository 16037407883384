/* eslint-disable */
import { ofType, ActionsObservable } from "redux-observable";
import { ajax } from "rxjs/ajax";
import { switchMap, catchError } from "rxjs/operators";
import { systemTypes } from "../actions/system";
import { API_URL } from "../config/settings";
import { Headers } from "../utils/Headers";

export const getAllSystems = (action$) => {
  return action$.pipe(
    ofType(systemTypes.GET_ALL_SYSTEMS),
    switchMap(() => {
      return ajax({
        url: `${API_URL}/system/all`,
        method: "GET",
        headers: Headers.auth(),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: systemTypes.GET_ALL_SYSTEMS_SUCCESS,
              payload: response.payload,
            });
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            if (err.status === 401) {
              window.location.href = "/login";
            } else {
            }
          });
        })
      );
    })
  );
};

export const getCurrentSystem = (action$) => {
  return action$.pipe(
    ofType(systemTypes.GET_CURRENT_SYSTEM),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API_URL}/system/stats/${payload}`,
        method: "GET",
        headers: Headers.auth(),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: systemTypes.GET_CURRENT_SYSTEM_SUCCESS,
              payload: response.payload,
            });
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            if (err.status === 401) {
            } else {
            }
          });
        })
      );
    })
  );
};

export const getSystemsList = (action$) => {
  return action$.pipe(
    ofType(systemTypes.LIST_SYSTEMS),
    switchMap(({ payload:{payload,onSuccess} }) => {
      return ajax({
        url: `${API_URL}/system/list`,
        method: "POST",
        headers: Headers.auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: systemTypes.LIST_SYSTEMS_SUCCESS,
              payload: response.payload,
            });
            onSuccess(response);
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            if (err.status === 401) {
              window.location.href = "/login";
            } else {
            }
          });
        })
      );
    })
  );
};
