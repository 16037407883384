/* eslint-disable */
import { labelsTypes } from "../actions/labels";

const initialState = {
  labels: [],
};

const labels = (state = initialState, { type, payload }) => {
  switch (type) {
    case labelsTypes.LABELS_LIST_SUCCESS:
      return { ...state, labels: payload };
    default:
      return state;
  }
};

export default labels;
