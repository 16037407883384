/* eslint-disable */
import { ofType, ActionsObservable } from "redux-observable";
import { ajax } from "rxjs/ajax";
import { switchMap, catchError } from "rxjs/operators";
import { ticketTypes } from "../actions/tickets";
import { API_URL } from "../config/settings";
import { Headers } from "../utils/Headers";

export const getTicketsList = (action$) => {
  return action$.pipe(
    ofType(ticketTypes.GET_TICKETS_FOR_SYSTEM),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API_URL}/ticket/list`,
        method: "POST",
        headers: Headers.auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: ticketTypes.GET_TICKETS_FOR_SYSTEM_SUCCESS,
              payload: response.payload,
              body: payload,
            });
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            if (err.status === 401) {
              window.location.href = "/login";
            } else {
              console.log(err);
            }
          });
        })
      );
    })
  );
};

export const getCurrentTicketEpic = (action$) => {
  return action$.pipe(
    ofType(ticketTypes.GET_CURRENT_TICKET),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API_URL}/ticket/${payload.id}`,
        method: "GET",
        headers: Headers.auth(),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            if (payload?.onSuccess) payload.onSuccess(response.payload);
            obs.next({
              type: ticketTypes.GET_CURRENT_TICKET_SUCCESS,
              payload: response.payload,
            });
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            if (err.status === 401) {
              window.location.href = "/login";
            } else {
              console.log(err);
            }
          });
        })
      );
    })
  );
};

export const ticketActionsEpic = (action$) => {
  return action$.pipe(
    ofType(ticketTypes.TICKET_ACTIONS),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API_URL}/ticket/${payload.id}`,
        method: "PUT",
        headers: Headers.auth(),
        body: JSON.stringify(payload.payloadAction),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: ticketTypes.TICKET_ACTIONS_SUCCESS,
              payload: response.payload,
            });
            payload.onSuccess();
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            if (err.status === 401) {
              window.location.href = "/login";
            } else {
              console.log(err);
            }
          });
        })
      );
    })
  );
};

export const editMultipleTickets = (action$) => {
  return action$.pipe(
    ofType(ticketTypes.EDIT_MULTIPLE_TICKETS),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API_URL}/ticket/multi`,
        method: "PUT",
        headers: Headers.auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            payload.onSuccess();
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            if (err.status === 401) {
              window.location.href = "/login";
            } else {
              console.log(err);
            }
          });
        })
      );
    })
  );
};

export const ticketSendReminder = (action$) => {
  return action$.pipe(
    ofType(ticketTypes.TICKET_SEND_REMINDER),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API_URL}/ticket/reminder`,
        method: "POST",
        headers: Headers.auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            if (err.status === 401) {
              window.location.href = "/login";
            } else {
              console.log(err);
            }
          });
        })
      );
    })
  );
};

export const multipleVotes = (action$) => {
  return action$.pipe(
    ofType(ticketTypes.MULTIPLE_VOTING),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API_URL}/ticket/vote`,
        method: "POST",
        headers: Headers.auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            payload.onSuccess();
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            if (err.status === 401) {
              window.location.href = "/login";
            } else {
              console.log(err);
            }
          });
        })
      );
    })
  );
};

export const getChatHistory = (action$) => {
  return action$.pipe(
    ofType(ticketTypes.GET_CHAT_HISTORY),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API_URL}/ticket/export/630deb815618e000163f9d93`,
        method: "GET",
        responseType: "blob",
        headers: Headers.auth(),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            const newFile = response.blob();
            const fileURL = URL.createObjectURL(newFile);
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            if (err.status === 401) {
              window.location.href = "/login";
            } else {
              console.log(err);
            }
          });
        })
      );
    })
  );
};

export const createTicketEpic = (action$) => {
  return action$.pipe(
    ofType(ticketTypes.CREATE_TICKET),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API_URL}/ticket/create`,
        method: "POST",
        headers: Headers.auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            payload.onSuccess(response);
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            if (err.status === 401) {
              window.location.href = "/login";
            } else {
              console.log(err);
            }
          });
        })
      );
    })
  );
};

export const getCurrentTicketAcceptedOfferEpic = (action$) => {
  return action$.pipe(
    ofType(ticketTypes.GET_CURRENT_TICKET_ACCEPTED_OFFER),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API_URL}/ticket/offers/${payload}`,
        method: "GET",
        headers: Headers.auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: ticketTypes.GET_CURRENT_TICKET_ACCEPTED_OFFER_SUCCESS,
              payload: response.payload,
            });
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            if (err.status === 401) {
              window.location.href = "/login";
            } else {
              console.log(err);
            }
          });
        })
      );
    })
  );
};
export const editCustomerNameEpic = (action$) => {
  return action$.pipe(
    ofType(ticketTypes.EDIT_CUSTOMER_NAME),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API_URL}/customer/${payload.id}`,
        method: "PUT",
        headers: Headers.auth(),
        body: JSON.stringify(payload.body), // name
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            payload?.onSuccess(response);
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            if (err.status === 401) {
              window.location.href = "/login";
            } else {
              console.log(err);
            }
          });
        })
      );
    })
  );
};
