/* eslint-disable */
import { Box, Modal } from "@mui/material";
import React from "react";

export default function ModalComponent({
  children,
  open,
  handleClose,
  special,
  mobileActions,
  mobileModal,
}) {
  const style = {
    position: "absolute",
    top: mobileActions ? "70%" : "50%",
    width: mobileModal ? "100%" : "unset",
    height: mobileModal ? "100%" : "unset",
    left: special === true ? (window.innerWidth < 1600 ? "70%" : "83%") : "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 30,
    borderRadius: window.innerWidth < 900 && "8px",
  };
  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>{children}</Box>
    </Modal>
  );
}
