/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { connect, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getCurrentSystem } from "../../actions/system";
import { getTicketsForSystem } from "../../actions/tickets";
import HelpCenterSideTools from "./HelpCenterSideTools/HelpCenterSideTools";
import Topics from "./Topics/Topics";
import { getCurrentSystemUsers } from "../../actions/users";
import useKeyPress from "../../utils/KeyboardShortcuts";
import { toast } from "react-toastify";
import _ from "lodash";

function HelpCenter({ currentSystem, getCurrentSystem, getTicketsForSystem, tickets, getCurrentSystemUsers, currentSystemUsers, isFiltered }) {
  const [fetchNewTickets, setFetchNewTickets] = useState(false);
  const [nextPage, setNextPage] = useState(1);
  const [filterDate, setFilterDate] = useState(false);
  const [isCompletedQuery, setIsCompletedQuery] = useState(false);
  const [query, setQuery] = useState({});
  const { slug } = useParams();
  const [sideFiltersActivated, setSideFiltersActivated] = useState(false);
  const [activeInnerFilters, setActiveInnerFilters] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState({});

  const filters = useSelector(({ tickets }) => tickets?.filters) || {};

  const filteredInfo = useRef({
    totalTickets: 0,
    opened: {
      total: 0,
      noResponsible: 0,
      noAnswered: 0,
      waitAnswer: 0,
      waitVote: 0,
    },
    completed: {
      total: 0,
      withoutRating: 0,
      withRating: 0,
      hiRating: 0,
      middleRating: 0,
      lowRating: 0,
    },
    archived: 0,
    deleted: 0,
  });

  const [payload, setPayload] = useState({
    mineResponsible: false,
    inTeam: false,
    specialCustomers: false,
    withPayment: false,
    labels: [],
    startDate: undefined,
    endDate: undefined,
    period: "created", // enum [ 'created', 'activity' ]
  });
  useEffect(() => {
    getCurrentSystem(slug);
    getCurrentSystemUsers({ system: slug, noPagination: true, onSuccess: () => {} });
    getTicketsForSystem({
      system: slug,
      pageNumber: nextPage,
      ...query,
    });
  }, [getCurrentSystem]);

  useEffect(() => {
    if (nextPage > 1) {
      getTicketsForSystem({
        system: slug,
        pageNumber: nextPage,
        filtered: isFiltered,
        ...filters,
      });
    }
  }, [getCurrentSystem, nextPage]);

  const onKeyPress = (event) => {
    if (event.key === "q" || event.key === "ч" || event.key === "я") {
      getTicketsForSystem({
        system: slug,
        noPagination: true,
        mineResponsible: true,
      });
      setActiveInnerFilters(true);
      setPayload({
        ...payload,
        mineResponsible: true,
        inTeam: false,
        specialCustomers: false,
        withPayment: false,
      });
      toast.success("Successfully filtered {myResponsible:true}");
    } else if (event.key === "w" || event.key === "в" || event.key === "ш") {
      getTicketsForSystem({
        system: slug,
        noPagination: true,
        inTeam: true,
      });
      setActiveInnerFilters(true);
      setPayload({
        ...payload,
        mineResponsible: false,
        inTeam: true,
        specialCustomers: false,
        withPayment: false,
      });
      toast.success("Successfully filtered {inTeam:true}");
    } else if (event.key === "f" || event.key === "ф") {
      setPayload({
        mineResponsible: false,
        inTeam: false,
        specialCustomers: false,
        withPayment: false,
        labels: [],
        startDate: undefined,
        endDate: undefined,
        period: "created",
      });
      setNextPage(1);
      setQuery({});
      setActiveInnerFilters(false);
      setSelectedFilter({});
      filteredInfo.current.totalTickets = 0;
      getTicketsForSystem({
        system: slug,
        pageNumber: 1,
      });
      toast.success("Filters cleared successfully");
    }
  };

  useKeyPress(["q", "w", "f", "я", "ч", "в", "ш", "ф"], onKeyPress);

  return (
    <div style={{ display: "flex" }}>
      <Topics
        setFetchNewTickets={setFetchNewTickets}
        nextPage={nextPage}
        setNextPage={setNextPage}
        tickets={tickets}
        setQuery={setQuery}
        query={query}
        isCompletedQuery={isCompletedQuery}
        getTicketsForSystem={getTicketsForSystem}
        slug={slug}
        setFilterDate={setFilterDate}
        getCurrentSystem={getCurrentSystem}
        currentSystemUsers={currentSystemUsers}
        filteredInfo={filteredInfo}
        payload={payload}
        setPayload={setPayload}
        sideFiltersActivated={sideFiltersActivated}
        activeInnerFilters={activeInnerFilters}
        setActiveInnerFilters={setActiveInnerFilters}
      />
      {window.innerWidth > 900 && (
        <HelpCenterSideTools
          setNextPage={setNextPage}
          currentSystem={currentSystem}
          setQuery={setQuery}
          query={query}
          setIsCompletedQuery={setIsCompletedQuery}
          isCompletedQuery={isCompletedQuery}
          getTicketsForSystem={getTicketsForSystem}
          slug={slug}
          nextPage={nextPage}
          payload={payload}
          filteredInfo={filteredInfo}
          setSideFiltersActivated={setSideFiltersActivated}
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
        />
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  currentSystem: state.systems.currentSystem,
  tickets: state.tickets.tickets,
  ticketsInfo: state.tickets.ticketsInfo,
  isFiltered: state.tickets.isFiltered,
  currentSystemUsers: state.users.currentSystemUsers,
});

const mapDispatchToProps = (dispatch) => ({
  getTicketsForSystem: (payload) => dispatch(getTicketsForSystem(payload)),
  getCurrentSystem: (payload) => dispatch(getCurrentSystem(payload)),
  getCurrentSystemUsers: (payload) => dispatch(getCurrentSystemUsers(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HelpCenter);
