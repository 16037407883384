/* eslint-disable */
import React from "react";
import { Paper, Button } from "@mui/material";
import Swal from "sweetalert2";

export default function CompleteTopicModal({ handleClose, handleCompleted,special,vote }) {
  return (
    <Paper
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-evenly",
        width: "550px",
        height: "220px",
      }}
    >
      <h4>{vote ? "Изпрати за оценка" : "Завършване на тема"}</h4>
      <p>Сигурни ли сте, че искате да {vote ? "изпратите за оценка" : "завършите тази тема?"}</p>
      <div style={{ display: "flex" }}>
        <Button
          onClick={() => handleClose()}
          disableRipple
          disableFocusRipple
          variant="contained"
          sx={{
            marginRight: "1rem",
            color: "black",
            backgroundColor: "#FFFFFF",
            cursor: "pointer",
            "&:hover": {
              backgroundColor: "#FFFFFF",
            },
          }}
        >
          Откажи
        </Button>
        <Button
          onClick={() => {
            handleCompleted();
            handleClose();
            if(!special) Swal.fire("Успешно завършихте темата.","","success");
          }}
          disableRipple
          disableFocusRipple
          variant="contained"
          sx={{
            marginRight: "1rem",
            color: "#FFFFFF",
            backgroundColor: "#01D088",
            cursor: "pointer",
            "&:hover": {
              backgroundColor: "#01D088",
            },
          }}
        >
          {vote ? "Изпрати" : "Завърши чат"}
        </Button>
      </div>
    </Paper>
  );
}
