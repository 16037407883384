export const systemTypes = {
  GET_ALL_SYSTEMS: "system/GET_ALL_SYSTEMS",
  GET_ALL_SYSTEMS_SUCCESS: "system/GET_ALL_SYSTEMS_SUCCESS",
  GET_CURRENT_SYSTEM: "system/GET_CURRENT_SYSTEM",
  GET_CURRENT_SYSTEM_SUCCESS: "system/GET_CURRENT_SYSTEM_SUCCESS",
  LIST_SYSTEMS: "system/LIST_SYSTEMS",
  LIST_SYSTEMS_SUCCESS: "system/LIST_SYSTEMS_SUCCESS",
};

export const getAllSystems = () => ({
  type: systemTypes.GET_ALL_SYSTEMS,
});

export const getCurrentSystem = (payload) => ({
  type: systemTypes.GET_CURRENT_SYSTEM,
  payload,
});

export const listSystems = (payload) => ({
  type: systemTypes.LIST_SYSTEMS,
  payload,
});
