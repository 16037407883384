/* eslint-disable */
import React, { useState } from "react";
import { Menu, MenuItem } from "@mui/material";
import { API_URL } from "../../../config/settings";
import { useNavigate } from "react-router";

export default function ChatDropDownMenu({
  anchorEl,
  handleClose,
  newChat,
  open,
  systemId,
  handleOpenTeamManagementModal,
  handleOpenChangeAssigneeModal,
  handleOpenCompleteTopicModal,
  handleOpenChangeTopicNameModal,
  handleOpenTemplatesModal,
  handleOpenLabelsModal,
  handleOpenPaymentModal,
  handleOpenNotes,
  handleOpenActivity,
  completed,
  handleOpenTicket,
  handleReminder,
  handleUnreadTicket,
  handleArchiveTicket,
  handleOpenSearchBar,
  special,
  handleClearFilters,
  setSelectedAction,
  token,
  currentTicket,
  handleLabelsAdd,
  setOpenVoteModal,
  handleOpenStartChatModal,
  handleNavigateOffer,
  topicId,
}) {
  const fetchPDFfile = () => {
    fetch(`${API_URL}/ticket/export/${currentTicket?._id}`, {
      method: "GET",
      headers: { Authorization: token },
    })
      .then((result) => result.blob())
      .then((blob) => {
        const data = window.URL.createObjectURL(blob);
        const text = document.createTextNode("Изтегли");
        const link = document.createElement("a");
        link.appendChild(text);
        document.body.appendChild(link);
        link.href = data;
        link.download = `chat-${currentTicket?._id + "44"}.pdf`;
        link.click();
        document.body.removeChild(link);
      })
      .catch((e) => console.log(e.message));
  };

  const navigate = useNavigate();

  return (
    <Menu
      elevation={2}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      anchorEl={anchorEl}
      onClose={handleClose}
      open={open}
    >
      {completed && (
        <MenuItem
          onClick={() => {
            handleOpenTicket();
            handleClose();
          }}
          sx={{ borderBottom: "1px solid #0dc1ce", margin: "0 8px" }}
        >
          <object data={"/chat-actions-icons/open-chat.svg"} width={"21px"} height={"21px"} aria-labelledby={"open-chat"} />
          <span style={{ paddingLeft: "5px" }}>Отвори чат</span>
        </MenuItem>
      )}
      {!special && (
        <MenuItem
          onClick={() => {
            handleOpenSearchBar();
            handleClose();
          }}
          sx={{ borderBottom: "1px solid #0dc1ce", margin: "0 8px" }}
        >
          <object data={"/chat-actions-icons/search.svg"} width={"21px"} height={"21px"} aria-labelledby={"search"} />
          <span style={{ paddingLeft: "5px" }}>Търси в чат</span>
        </MenuItem>
      )}

      {!completed && (
        <MenuItem
          onClick={() => {
            handleUnreadTicket();
            if (special) {
              setSelectedAction("unread");
            }
            handleClose();
          }}
          sx={{ borderBottom: "1px solid #0dc1ce", margin: "0 8px" }}
        >
          <object data={"/chat-actions-icons/mark-as-unread.svg"} width={"21px"} height={"21px"} aria-labelledby={"mark-unread"} />
          <span style={{ paddingLeft: "5px" }}>Отбележи като непрочетен</span>
        </MenuItem>
      )}
      {!completed && !special ? (
        <MenuItem
          onClick={() => {
            handleOpenTeamManagementModal();
            handleClose();
          }}
          sx={{ borderBottom: "1px solid #0dc1ce", margin: "0 8px" }}
        >
          <object data={"/chat-actions-icons/team-managment.svg"} width={"21px"} height={"21px"} aria-labelledby={"team-management"} />
          <span style={{ paddingLeft: "5px" }}>Управление екип</span>
        </MenuItem>
      ) : null}
      {!completed && (
        <>
          <MenuItem
            onClick={() => {
              if (special) {
                handleUnreadTicket();
                setSelectedAction("responsible");
                handleClose();
              } else {
                handleOpenChangeAssigneeModal();
                handleClose();
              }
            }}
            sx={{ borderBottom: "1px solid #0dc1ce", margin: "0 8px" }}
          >
            <object data={"/chat-actions-icons/change-assignee.svg"} width={"21px"} height={"21px"} aria-labelledby={"change-assignee"} />
            <span style={{ paddingLeft: "5px" }}>Смени отговорник {!special && "(M)"}</span>
          </MenuItem>
          {!special && (
            <MenuItem
              onClick={() => {
                handleOpenChangeTopicNameModal();
                handleClose();
              }}
              sx={{ borderBottom: "1px solid #0dc1ce", margin: "0 8px" }}
            >
              <object data={"/chat-actions-icons/change-assignee.svg"} width={"21px"} height={"21px"} aria-labelledby={"change-assignee"} />
              <span style={{ paddingLeft: "5px" }}>Редакция име на тема (Т)</span>
            </MenuItem>
          )}
        </>
      )}
      {!completed && !special ? (
        <MenuItem
          onClick={() => {
            handleOpenTemplatesModal();
            handleClose();
          }}
          sx={{ borderBottom: "1px solid #0dc1ce", margin: "0 8px" }}
        >
          <object data={"/chat-actions-icons/templates.svg"} width={"21px"} height={"21px"} aria-labelledby={"templates"} />
          <span style={{ paddingLeft: "5px" }}>Шаблони за съобщения {!special && "(Z)"}</span>
        </MenuItem>
      ) : null}
      {!completed && !special ? (
        <>
          {
            <MenuItem
              onClick={() => {
                handleNavigateOffer();
                handleClose();
              }}
              sx={{ borderBottom: "1px solid #0dc1ce", margin: "0 8px" }}
            >
              <object data={"/chat-actions-icons/templates.svg"} width={"21px"} height={"21px"} aria-labelledby={"templates"} />
              <span style={{ paddingLeft: "5px" }}>Създай оферти {!special && "(O)"}</span>
            </MenuItem>
          }
        </>
      ) : null}
      {!completed ? (
        <MenuItem
          onClick={() => {
            if (special) {
              handleLabelsAdd();
              setSelectedAction("labels");
            } else handleOpenLabelsModal();
            handleClose();
          }}
          sx={{ borderBottom: "1px solid #0dc1ce", margin: "0 8px" }}
        >
          <object data={"/chat-actions-icons/label.svg"} width={"21px"} height={"21px"} aria-labelledby={"label"} />
          <span style={{ paddingLeft: "5px" }}>Етикети</span>
        </MenuItem>
      ) : null}
      {!special && (
        <MenuItem
          onClick={() => {
            handleOpenNotes();
            handleClose();
          }}
          sx={{ borderBottom: "1px solid #0dc1ce", margin: "0 8px" }}
        >
          <object data={"/chat-actions-icons/notes.svg"} width={"21px"} height={"21px"} aria-labelledby={"notes"} />
          <span style={{ paddingLeft: "5px" }}>Бележки</span>
        </MenuItem>
      )}
      {!special && (
        <MenuItem
          onClick={() => {
            fetchPDFfile();
            handleClose();
          }}
          sx={{ borderBottom: "1px solid #0dc1ce", margin: "0 8px" }}
        >
          <object data={"/chat-actions-icons/export-chat.svg"} width={"21px"} height={"21px"} aria-labelledby={"notes"} />
          <span style={{ paddingLeft: "5px" }}>Експорт на чат</span>
        </MenuItem>
      )}
      {!special && (
        <MenuItem
          onClick={() => {
            handleOpenActivity();
            handleClose();
          }}
          sx={{ borderBottom: "1px solid #0dc1ce", margin: "0 8px" }}
        >
          <object data={"/chat-actions-icons/activity.svg"} width={"21px"} height={"21px"} aria-labelledby={"activity"} />
          <span style={{ paddingLeft: "5px" }}>Активност</span>
        </MenuItem>
      )}
      {!completed && (
        <>
          <MenuItem
            onClick={() => {
              if (special) {
                handleUnreadTicket();
                setSelectedAction("voting");
                handleClose();
              } else {
                //handleRequestVote();
                setOpenVoteModal(true);
                handleClose();
              }
            }}
            sx={{ borderBottom: "1px solid #0dc1ce", margin: "0 8px" }}
          >
            <object data={"/chat-actions-icons/request-rating.svg"} width={"21px"} height={"21px"} aria-labelledby={"request-rating"} />
            <span style={{ paddingLeft: "5px" }}>Изпрати за оценка {!special && "(E)"}</span>
          </MenuItem>
          {systemId.slice(systemId.length - 3).includes("265") && !special ? (
            <>
              <MenuItem
                onClick={() => {
                  handleClose();
                  handleOpenPaymentModal(true);
                }}
                sx={{ borderBottom: "1px solid #0dc1ce", margin: "0 8px" }}
              >
                <object data={"/chat-actions-icons/credit-card.svg"} width={"21px"} height={"21px"} aria-labelledby={"request-rating"} />
                <span style={{ paddingLeft: "5px" }}>Инициирай плащане</span>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClose();
                  navigate(`/addPolicyToUser/${topicId}/${currentTicket?.customer?._id}/no-offer`);
                }}
                sx={{ borderBottom: "1px solid #0dc1ce", margin: "0 8px" }}
              >
                <object data={"/chat-actions-icons/add-policy2.svg"} width={"21px"} height={"21px"} aria-labelledby={"request-rating"} />
                <span style={{ paddingLeft: "5px" }}>
                  Добавяне на полица <span style={{ fontSize: "14px" }}>(без оферта и плащане)</span>
                </span>
              </MenuItem>
            </>
          ) : null}

          <MenuItem
            onClick={() => {
              if (special) {
                handleUnreadTicket();
                setSelectedAction("reminder");
                handleClose();
              } else {
                handleReminder();
                handleClose();
              }
            }}
            sx={{ borderBottom: "1px solid #0dc1ce", margin: "0 8px" }}
          >
            <object data={"/chat-actions-icons/mail.svg"} width={"21px"} height={"21px"} aria-labelledby={"mail"} />
            <span style={{ paddingLeft: "5px" }}>Изпрати напомнящ имейл</span>
          </MenuItem>
          <MenuItem
            onClick={() => {
              if (special) {
                setSelectedAction("completed");
                handleUnreadTicket();
                handleClose();
              } else {
                handleOpenCompleteTopicModal();
                handleClose();
              }
            }}
            sx={{ borderBottom: "1px solid #0dc1ce", margin: "0 8px" }}
          >
            <object data={"/chat-actions-icons/complete.svg"} width={"21px"} height={"21px"} aria-labelledby={"complete"} />
            <span style={{ paddingLeft: "5px" }}>Отбележи като завършена {!special && "(S)"}</span>
          </MenuItem>
        </>
      )}
      <MenuItem
        sx={{ borderBottom: "1px solid #0dc1ce", margin: "0 8px" }}
        onClick={() => {
          if (special) setSelectedAction("archive");
          handleArchiveTicket();
          handleClose();
        }}
      >
        <object data={"/chat-actions-icons/archive.svg"} width={"21px"} height={"21px"} aria-labelledby={"archive"} />
        <span style={{ paddingLeft: "5px" }}>Архивирай тема</span>
      </MenuItem>
      {special && newChat ? (
        <>
          <MenuItem
            sx={{ borderBottom: "1px solid #0dc1ce", margin: "0 8px" }}
            onClick={() => {
              handleUnreadTicket();
              setSelectedAction("delete");
              handleClose();
            }}
          >
            <object data={"/chat-actions-icons/delete.svg"} width={"21px"} height={"21px"} aria-labelledby={"delete"} />
            <span style={{ paddingLeft: "5px", color: "#FD416C" }}>Изтрий теми</span>
          </MenuItem>
          <MenuItem
            sx={{ margin: "0 8px", borderBottom: "1px solid #0dc1ce" }}
            onClick={() => {
              handleClearFilters();
              handleClose();
            }}
          >
            <object data={"/chat-actions-icons/filter.svg"} width={"21px"} height={"21px"} aria-labelledby={"clear"} />
            <span style={{ paddingLeft: "5px" }}>Изчисти филтри</span>
          </MenuItem>
          <MenuItem
            sx={{ margin: "0 8px" }}
            onClick={() => {
              handleOpenStartChatModal();
              handleClose();
            }}
          >
            <object data={"/chat-actions-icons/start-chat.svg"} width={"21px"} height={"21px"} aria-labelledby={"start"} />
            <span style={{ paddingLeft: "5px" }}>Инициирай чат</span>
          </MenuItem>
        </>
      ) : null}
    </Menu>
  );
}
