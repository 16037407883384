export const amarantFieldsTypes = {
  LIST_VEHICLES: "amarantFields/LIST_VEHICLES",
  LIST_VEHICLES_SUCCESS: "amarantFields/LIST_VEHICLES_SUCCESS",
  LIST_POLICIES: "amarantFields/LIST_POLICIES",
  LIST_POLICIES_SUCCESS: "amarantFields/LIST_POLICIES_SUCCESS",
  LIST_VIGNETTES: "amarantFields/LIST_VIGNETTES",
  LIST_VIGNETTES_SUCCESS: "amarantFields/LIST_VIGNETTES_SUCCESS",
  LIST_FINES: "amarantFields/LIST_FINES",
  LIST_FINES_SUCCESS: "amarantFields/LIST_FINES_SUCCESS",
  LIST_ERRORS: "amarantFields/LIST_ERRORS",
  LIST_ERRORS_SUCCESS: "amarantFields/LIST_ERRORS_SUCCESS",
  LIST_DOCUMENTS: "amarantFields/LIST_DOCUMENTS",
  LIST_DOCUMENTS_SUCCESS: "amarantFields/LIST_DOCUMENTS_SUCCESS",
  USER_INFO: "amarantFields/USER_INFO",
  USER_INFO_SUCCESS: "amarantFields/USER_INFO_SUCCESS",
  EDIT_USER_INFO: "amarantFields/EDIT_USER_INFO",
  EDIT_USER_SUCCESS: "amarantFields/EDIT_USER_SUCCESS",
  CHECK_AMARANT_USER: "amarantFields/CHECK_AMARANT_USER",
  SEND_TEMPLATE: "amarantFields/SEND_TEMPLATE",
};

export const listVehicles = (payload) => ({
  type: amarantFieldsTypes.LIST_VEHICLES,
  payload,
});

export const listPolicies = (payload) => ({
  type: amarantFieldsTypes.LIST_POLICIES,
  payload,
});

export const listVignettes = (payload) => ({
  type: amarantFieldsTypes.LIST_VIGNETTES,
  payload,
});

export const listFines = (payload) => ({
  type: amarantFieldsTypes.LIST_FINES,
  payload,
});

export const listErrors = (payload) => ({
  type: amarantFieldsTypes.LIST_ERRORS,
  payload,
});

export const listDocuments = (payload) => ({
  type: amarantFieldsTypes.LIST_DOCUMENTS,
  payload,
});

export const getUserInfo = (payload) => ({
  type: amarantFieldsTypes.USER_INFO,
  payload,
});

export const editUserInfo = (payload) => ({
  type: amarantFieldsTypes.EDIT_USER_INFO,
  payload,
});
export const checkAmarantUser = (payload) => ({
  type: amarantFieldsTypes.CHECK_AMARANT_USER,
  payload,
});
export const sendTemplate = (payload) => ({
  type: amarantFieldsTypes.SEND_TEMPLATE,
  payload,
});
