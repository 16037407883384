/* eslint-disable */
import { Button, FormControl, InputAdornment, Paper, TextField } from "@mui/material";
import React, { useState } from "react";
import styles from "./PaymentModal.module.scss";
import CloseIcon from "@mui/icons-material/Close";
import { createPayment } from "../../../actions/payment";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { listAgencies } from "../../../actions/offer";
import { getCurrentTicketAcceptedOffer } from "../../../actions/tickets";

const PaymentModal = ({ handleClose, createPayment, currentTicket, listAgencies, agencies, getCurrentTicketAcceptedOffer, currentTicketOffer }) => {
  const [price, setPrice] = useState(null);
  const [description, setDescription] = useState("");

  useEffect(() => {
    getCurrentTicketAcceptedOffer(currentTicket?._id);
    listAgencies({
      onSuccess: (res) => {},
    });
  }, []);

  console.log(currentTicketOffer);
  return (
    <Paper className={styles["assign-topic-modal"]}>
      <div className={styles["assign-topic-heading-container"]}>
        <h3>Иницииране на плащане</h3>
        <CloseIcon onClick={handleClose} />
      </div>
      <FormControl style={{ marginTop: "-3%" }}>
        <p style={{ padding: "10px" }}>Сума за плащане</p>
        <TextField
          value={price}
          onChange={(e) => setPrice(e.target.value)}
          sx={{ padding: "10px" }}
          variant="outlined"
          id="price-input"
          size="small"
          type="number"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <p className={styles["suffix"]}>lv</p>
              </InputAdornment>
            ),
          }}
        />
      </FormControl>
      <FormControl style={{ marginTop: "-3%" }}>
        <p style={{ padding: "10px" }}>Основание</p>
        <TextField
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          sx={{ padding: "10px" }}
          variant="outlined"
          id="description-input"
          multiline={true}
          rows={3}
        />
      </FormControl>
      <Paper elevation={10} style={{ padding: "10px" }}>
        <Button
          onClick={() => {
            if (price.includes(",")) price.replace(",", ".");

            if (price > 0 && description.length > 0) {
              let payload = {
                customerId: currentTicket?.customer?._id,
                amount: Number(price),
                description: description,
                ticket: currentTicket?._id,
              };
              if (currentTicketOffer?.offer)
                payload.discountMetadata = {
                  discountCampaignRef: currentTicket?.promoData?._id,
                  originalAmount: currentTicketOffer?.offer?.originalPremium,
                  discountedAmount: currentTicketOffer?.offer?.premium,
                  policyType: currentTicket?.promoData?.policyType,
                  insuranceAgency: agencies?.filter((agency) => agency?._id === currentTicketOffer?.offer?.insuranceCompanyId)[0].platform,
                };

              createPayment(payload);
              handleClose();
            } else {
              toast.error("Някое от полетата не е попълнено!");
            }
          }}
          disableElevation
          disableRipple
          disableFocusRipple
          disabled={price ? false : true}
          variant="contained"
          sx={{
            width: "100%",
            color: "white",
            backgroundColor: "#01d088",
            cursor: "pointer",
            "&:hover": {
              backgroundColor: "#01d088",
            },
          }}
        >
          Потвърди
        </Button>
      </Paper>
    </Paper>
  );
};

const mapStateToProps = (state) => ({
  user: state.login.user,
  agencies: state.offer.agencies,
  currentTicketOffer: state.tickets.currentTicketOffer,
});

const mapDispatchToProps = (dispatch) => ({
  createPayment: (payload) => dispatch(createPayment(payload)),
  listAgencies: (payload) => dispatch(listAgencies(payload)),
  getCurrentTicketAcceptedOffer: (payload) => dispatch(getCurrentTicketAcceptedOffer(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentModal);
