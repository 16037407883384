/* eslint-disable */
import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Paper,
  Grid,
  FormControl,
  TextField,
  InputAdornment,
  IconButton,
  Button,
  Stack,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import styles from "./Login.module.scss";
import { loginSignIn } from "../../actions/login";
import { connect } from "react-redux";

const MobileLogin = ({
  formik,
  showPassword,
  setShowPassword,
  handleClickShowPassword,
  handleMouseDownPassword,
}) => {
  return (
    <div className={styles["inner-login-container"]}>
      <form onSubmit={formik.handleSubmit}>
        <Stack className={styles["login-form"]} direction={"column"} style={{padding:"12px"}}>
          <img
            style={{
              width: "165px",
              height: "118px",
              alignSelf: "center",
            }}
            src="/login-assets/Logo-Log-In.svg"
          />
          <img src="/login-assets/login-left-picture.png" />
          <h4 className={styles.heading} style={{ alignSelf: "center" }}>
            ЗДРАВЕЙТЕ!
          </h4>
          <FormControl className={styles.FormControl} style={{marginTop:"30px"}}>
            <label htmlFor="email">Имейл</label>
            <TextField
              id="email"
              type="email"
              size="small"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            ></TextField>
          </FormControl>
          <FormControl className={styles.FormControl}>
            <label htmlFor="password">Парола</label>
            <TextField
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              size="small"
              id="password"
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            ></TextField>
          </FormControl>
          <Button
            disabled={!(formik.isValid && formik.dirty)}
            type="submit"
            variant="container"
            className={styles["submit-button"]}
          >
            ВХОД
          </Button>
        </Stack>
      </form>
    </div>
  );
};

export default MobileLogin;
