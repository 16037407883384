/* eslint-disable */
import { Button, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "./LabelsModal.module.scss";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { connect } from "react-redux";
import {
  labelsList,
  labelsCreate,
  labelsDelete,
  labelsEdit,
} from "../../../actions/labels";
import { ticketActions } from "../../../actions/tickets";
import ModalComponent from "../ModalComponent/ModalComponent";
import { Divider } from "@mui/material";
import TextLabelModal from "./TextLabelModal";
import LabelsContainer from "./LabelsContainer";
import DeleteLabel from "./DeleteLabel";
import { toast } from "react-toastify";

const LabelsModal = ({
  slug,
  handleClose,
  labelsList,
  labelsCreate,
  labelsDelete,
  labelsEdit,
  labels,
  ticketActions,
  currentTicket,
  selectedTickets,
  editMultipleTickets
}) => {
  const [createModal, setCreateModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [selLabel, setSelLabel] = useState("");
  const [payload,setPayload] = useState(currentTicket?.labels?.length > 0 ? currentTicket?.labels?.map(label => label?._id) : []);

  useEffect(() => {
    labelsList({
      noPagination: true,
      system: slug,
    });
  }, []);


  return (
    <Paper className={styles["assign-topic-modal"]}>
      <div className={styles["assign-topic-heading-container"]}>
        <h3>ДОБАВЯНЕ НА ЕТИКЕТИ</h3>
        <Button
          onClick={() => {
            if(selectedTickets && selectedTickets?.length > 0){
              editMultipleTickets({
                action:"label",
                tickets:selectedTickets,
                labels:payload,
                onSuccess:() => {
                  toast.success("Tickets updated successfully.")
                  handleClose();
                }
              })
            } 
            else{
              const finalPayload = {
                payloadAction: { labels:payload },
                id: currentTicket?._id,
                onSuccess: () => {
                 toast.success("Ticket updated successfully.");
                 handleClose();
                },
              };
            ticketActions(finalPayload)
            }
           
          }}
          disableElevation
          disableRipple
          disableFocusRipple
          variant="contained"
          sx={{
            width: "30%",
            color: "white",
            backgroundColor: "#0DC1CE",
            textTransform:"none",
            cursor: "pointer",
            "&:hover": {
              backgroundColor: "#0DC1CE",
            },
          }}
        >
          <b>Запази</b>
        </Button>
        <p onClick={handleClose} style={{ cursor: "pointer" }}>
          Скрий {`>`}
        </p>
      </div>
      <div className={styles["labels-container"]}>
        {labels?.length > 0 ? (
          labels?.map((label, i) => {
            return (
              <LabelsContainer
                label={label}
                i={i}
                setEditModal={setEditModal}
                setDeleteModal={setDeleteModal}
                setSelLabel={setSelLabel}
                key={i}
                setPayload={setPayload}
                payload={payload}
              />
            );
          })
        ) : (
          <div
            style={{
              height: "90%",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p>Нямате добавени етикети</p>
            <div className={styles["empty-template"]} />
          </div>
        )}
      </div>
      <Divider />
      <div style={{ width: "100%", padding: "5px" }}>
        <Button
          onClick={() => {
            setCreateModal(true);
          }}
          disableElevation
          disableRipple
          disabled={false}
          variant="contained"
          sx={{
            width: "100%",
            color: "black",
            backgroundColor: "white",
            border: "2px dotted",
            borderColor: "#0DC1CE",
            cursor: "pointer",
            "&:hover": {
              backgroundColor: "white",
            },
          }}
        >
          Добави етикет
        </Button>
      </div>
      <ModalComponent
        open={createModal || editModal}
        handleClose={() => {
          setCreateModal(false);
          setEditModal(false);
        }}
        children={
          <TextLabelModal
            slug={slug}
            edit={editModal ? true : false}
            labelsCreate={labelsCreate}
            labelList={labelsList}
            labelsEdit={labelsEdit}
            label={selLabel}
            handleClose={() => {
              setCreateModal(false);
              setEditModal(false);
            }}
          />
        }
      />
      <ModalComponent
        open={deleteModal}
        handleClose={() => {
          setDeleteModal(false);
        }}
        children={
          <DeleteLabel
            slug={slug}
            labelsDelete={labelsDelete}
            labelsList={labelsList}
            label={selLabel}
            handleClose={() => {
              setDeleteModal(false);
            }}
          />
        }
      />
    </Paper>
  );
};

const mapStateToProps = (state) => ({
  labels: state.labels.labels,
  currentTicket: state.tickets.currentTicket,
});

const mapDispatchToProps = (dispatch) => ({
  labelsList: (payload) => dispatch(labelsList(payload)),
  labelsCreate: (payload) => dispatch(labelsCreate(payload)),
  labelsEdit: (payload) => dispatch(labelsEdit(payload)),
  labelsDelete: (payload) => dispatch(labelsDelete(payload)),
  ticketActions: (payload) => dispatch(ticketActions(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LabelsModal);
