/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Paper, Button } from "@mui/material";
import styles from "./AddUserPage.module.scss";
import AddUserForm from "../../components/AddUserForm/AddUserForm";
import { getAllSystems } from "../../actions/system";
import { connect } from "react-redux";
import { values } from "lodash";

function AddUserPage({ getAllSystems, allSystems }) {
  const { slug } = useParams();
  const [saveChangesTrigger, setSaveChangesTrigger] = useState(false);
  const [formNumber, setFormNumber] = useState(1);
  const [values, setValues] = useState([
    {
      name: "",
      email: "",
      password: "",
      role: "",
      receiveEmails: {
        newTicket: true,
      },
      systems: {
        amarant: false,
        digidrive: false,
        enRpi: false,
        fightScout: false,
        hydroPro: false,
        immotech: false,
        motoza: false,
        ppa: false,
        soCourt: false,
        synergon: false,
        wink: false,
      },
    },
  ]);

  useEffect(() => {
    getAllSystems();
  }, [getAllSystems]);

  const currentSystem = allSystems.find((system) => system._id === slug);

  return (
    <div className={styles["add-user-page-container"]}>
      <Paper elevation={3} className={styles["add-user-toolbar"]}>
        <h3 className={styles["system-heading"]}>Потребители в системата</h3>
        <Button
          onClick={() => {
            setSaveChangesTrigger(!saveChangesTrigger);
          }}
          disableElevation
          disableRipple
          disableFocusRipple
          variant="contained"
          disabled={
            values
              .map((user) =>
                user.name.length > 2 &&
                user.email.length > 2 &&
                user.password.length > 2
                  ? true
                  : false
              )
              .includes(false)
              ? true
              : false
          }
          sx={{
            color: "white",
            backgroundColor: "#01D088",
            cursor: "pointer",
            "&:hover": {
              backgroundColor: "#01D088",
            },
          }}
        >
          Добави
        </Button>
      </Paper>
      <div className={styles["form-container"]}>
        {[...Array(formNumber)].map((_, index) => (
          <AddUserForm
            saveChangesTrigger={saveChangesTrigger}
            key={index}
            index={index + 1}
            currentSystem={currentSystem}
            values={values}
            setValues={setValues}
            setFormNumber={setFormNumber}
            setSaveChangesTrigger={setSaveChangesTrigger}
            formNumber={formNumber}
          />
        ))}
      </div>
      <Button
        onClick={() => {
          const newValues = values.slice(0);
          newValues.push({
            name: "",
            email: "",
            password: "",
            role: "",
            receiveEmails: {
              newTicket: true,
            },
            systems: {
              amarant: false,
              digidrive: false,
              enRpi: false,
              fightScout: false,
              hydroPro: false,
              immotech: false,
              motoza: false,
              ppa: false,
              soCourt: false,
              synergon: false,
              wink: false,
            },
          });
          setValues(newValues);
        }}
        disableElevation
        disableRipple
        disableFocusRipple
        variant="contained"
        sx={{
          float: "right",
          color: "white",
          backgroundColor: "#01D088",
          cursor: "pointer",
          "&:hover": {
            backgroundColor: "#01D088",
          },
        }}
      >
        Добави нов
      </Button>
    </div>
  );
}
const mapStateToProps = (state) => ({
  allSystems: state.systems.allSystems,
});

const mapDispatchToProps = (dispatch) => ({
  getAllSystems: () => dispatch(getAllSystems()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddUserPage);
