/* eslint-disable */
import React, { useEffect, useState } from "react";
import styles from "./AddUserForm.module.scss";
import {
  FormControl,
  Paper,
  TextField,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Radio,
} from "@mui/material";
import Delete from "@mui/icons-material/Delete";
import { useParams, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { getAllSystems } from "../../actions/system";
import { createUser } from "../../actions/users";
import Swal from "sweetalert2";
import { SwipeLeftAlt } from "@mui/icons-material";

function AddUserForm({
  index,
  setFormNumber,
  formNumber,
  allSystems,
  getAllSystems,
  values,
  setValues,
  createUser,
  currentSystem,
  saveChangesTrigger = false,
  setSaveChangesTrigger,
}) {
  const navigate = useNavigate();

  const handleCheckBoxClick = (index, name, i) => {
    if (values[i].systems[name]) {
      const newValues = values.slice(0);
      newValues[i].systems[name] = false;
      setValues(newValues);
    } else {
      const newValues = values.slice(0);
      newValues[i].systems[name] = true;
      setValues(newValues);
    }
  };

  useEffect(() => {
    if (saveChangesTrigger === true) {
      const checked = values.map((user) => {
        if (!/(.+)@(.+){2,}\.(.+){2,}/.test(user.email)) return false;
        else return true;
      });

      if (checked.includes(false)) {
        Swal.fire("Някой от въведените имейли не е правилен.", "", "error");
        setSaveChangesTrigger(false);
      } else {
        const payload = [];
        values.map((user) => {
          const selectedSystems = Object.keys(user.systems).filter(
            (system) => user.systems[system] === true
          );
          const parsedSystems = allSystems.filter((system) =>
            selectedSystems.includes(system.constant)
          );
          const systemIdsArray = parsedSystems.map((system) => system._id);
          const temp = {
            name: user.name !== "" && user.name,
            email: user.email !== "" && user.email,
            password: user.password !== "" && user.password,
            role: user.role !== "" && user.role,
            receiveEmails: user.receiveEmails && user.receiveEmails,
            systems: [...systemIdsArray],
          };
          payload.push(temp);
        });
        createUser(payload);
        Swal.fire("Успешно създаване.", "", "success");
        navigate(-1);
      }
    }
  }, [saveChangesTrigger]);

  useEffect(() => {
    if (currentSystem !== undefined) {
      values[0].systems[currentSystem?.constant] = true;
    }
  }, [currentSystem]);

  return (
    <>
      {values.map((user, i) => {
        return (
          <Paper elevation={3} className={styles["form-container"]} key={i}>
            <>
              <div className={styles["heading-container"]}>
                <h3>Потребител {i + 1}</h3>
                {values.length > 1 && (
                  <Delete
                    style={{ color: "#FD416C" }}
                    onClick={() => {
                      const newValues = values.slice(0);
                      newValues.splice(i, 1);
                      setValues(newValues);
                    }}
                  />
                )}
              </div>
              <div className={styles["form-inputs"]}>
                <FormControl variant="outlined" style={{ width: "25%" }}>
                  <label htmlFor="name">Имена</label>
                  <TextField
                    className={styles["textfield"]}
                    size="small"
                    id="name"
                    type="text"
                    name="name"
                    value={user.name}
                    onChange={(e) => {
                      const newValues = values.slice(0);
                      newValues[i].name = e.target.value;
                      setValues(newValues);
                    }}
                  />
                </FormControl>
                <FormControl variant="outlined" style={{ width: "25%" }}>
                  <label htmlFor="email">Имейл</label>
                  <TextField
                    className={styles["textfield"]}
                    id="email"
                    size="small"
                    name="email"
                    type="email"
                    value={user.email}
                    onChange={(e) => {
                      const newValues = values.slice(0);
                      newValues[i].email = e.target.value;
                      setValues(newValues);
                    }}
                  />
                </FormControl>
                <FormControl variant="outlined" style={{ width: "25%" }}>
                  <label htmlFor="password">Парола</label>
                  <TextField
                    className={styles["textfield"]}
                    size="small"
                    id="name"
                    type="text"
                    name="name"
                    value={user.password}
                    onChange={(e) => {
                      const newValues = values.slice(0);
                      newValues[i].password = e.target.value;
                      setValues(newValues);
                    }}
                  />
                </FormControl>
                <FormControl variant="outlined" style={{ width: "25%" }}>
                  <label htmlFor="password">Роля</label>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="female"
                    name="radio-buttons-group"
                    style={{
                      display: "flex",
                      width: "100%",
                      flexDirection: "row",
                    }}
                  >
                    <FormControlLabel
                      checked={user.role === "admin"}
                      control={<Radio />}
                      onChange={() => {
                        const newValues = values.slice(0);
                        newValues[i].role = "admin";
                        setValues(newValues);
                      }}
                      label="Админ"
                    />
                    <FormControlLabel
                      checked={user.role === "agent"}
                      onChange={() => {
                        const newValues = values.slice(0);
                        newValues[i].role = "agent";
                        setValues(newValues);
                      }}
                      control={<Radio />}
                      label="Aгент"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              <FormControl component={"fieldset"} variant="standard">
                <FormLabel component="legend">Системи</FormLabel>
                <FormGroup className={styles["form-checkboxes"]}>
                  {allSystems.map((system, index) => (
                    <FormControlLabel
                      key={index}
                      sx={{ ml: "5px" }}
                      control={
                        <div
                          className={styles["outer-checkbox"]}
                          onClick={() =>
                            handleCheckBoxClick(index, system.constant, i)
                          }
                          style={{
                            borderColor:
                              user.systems[system.constant] === true
                                ? "#0DC1CE"
                                : "#000",
                          }}
                        >
                          <div
                            className={`${styles["inner-checkbox"]} ${
                              user.systems[system.constant] === true &&
                              styles["show"]
                            } `}
                          />
                        </div>
                      }
                      label={
                        <p
                          onClick={() => {
                            handleCheckBoxClick(index, system.constant, i);
                          }}
                        >
                          {system.name}
                        </p>
                      }
                    />
                  ))}
                </FormGroup>
              </FormControl>
              <FormControl variant="outlined" style={{ width: "55%" }}>
                <label htmlFor="password">Получаване на имейли</label>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="all"
                  name="radio-buttons-group"
                  style={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "row",
                  }}
                >
                  <FormControlLabel
                    checked={user.receiveEmails.newTicket}
                    control={<Radio />}
                    onChange={() => {
                      const newValues = values.slice(0);
                      newValues[i].receiveEmails.newTicket = true;
                      setValues(newValues);
                    }}
                    label="Всички"
                  />
                  <FormControlLabel
                    checked={!user.receiveEmails.newTicket}
                    onChange={() => {
                      const newValues = values.slice(0);
                      newValues[i].receiveEmails.newTicket = false;
                      setValues(newValues);
                    }}
                    control={<Radio />}
                    label="Ако е отговорник"
                  />
                </RadioGroup>
              </FormControl>
            </>
          </Paper>
        );
      })}
    </>
  );
}

const mapStateToProps = (state) => ({
  allSystems: state.systems.allSystems,
});

const mapDispatchToProps = (dispatch) => ({
  getAllSystems: () => dispatch(getAllSystems()),
  createUser: (payload) => dispatch(createUser(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddUserForm);
