/* eslint-disable */
import { Box, Button } from "@mui/material";
import React, { useEffect, useState, useRef, useCallback } from "react";
import SystemCard from "../SystemCard/SystemCard";
import styles from "./System.module.scss";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useNavigate } from "react-router-dom";
import { getAllSystems, listSystems } from "../../actions/system";
import { connect } from "react-redux";
import { format, set } from "date-fns";
import { CribSharp } from "@mui/icons-material";

function System({ listSystems, systemsList, systemsListInfo }) {
  const totalPages = systemsListInfo?.totalPages;
  const [nextPage, setNextPage] = useState(1);
  const [filters, setFilters] = useState("lastMessage");
  const [sortedSystems, setSortedSystems] = useState([]);
  const hasNext = systemsListInfo?.hasNextPage;

  const navigate = useNavigate();
  const handleNavigate = (id) => {
    navigate(`/system/${id}`);
  };
  const observer = useRef();
  const handleLoadSystemsRef = useCallback(
    (node) => {
      if (observer.current) {
        observer.current.disconnect();
      }
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && nextPage <= totalPages) {
          if (nextPage < totalPages) {
            setNextPage((prevPageNumber) => prevPageNumber + 1);
          }
        }
      });
      if (node) {
        observer.current.observe(node);
      }
    },
    [totalPages]
  );

  useEffect(() => {
    const payload = {
      pageNumber: nextPage,
    };
    listSystems({
      payload,
      onSuccess: (res) => {
        if (filters !== "lastMessage") {
          if (filters.includes("reverseLastMessage")) {
            setSortedSystems(
              sortedSystems.concat(res.payload.docs).sort((a, b) => {
                var c = new Date(a.lastMessage);
                var d = new Date(b.lastMessage);
                return c - d;
              })
            );
          } else {
            setSortedSystems(sortedSystems.concat(res.payload.docs));
            sortSystemsByFilter(filters);
          }
        } else {
          setSortedSystems(
            sortedSystems.concat(res.payload.docs).sort((a, b) => {
              var c = new Date(a.lastMessage);
              var d = new Date(b.lastMessage);
              return d - c;
            })
          );
        }
      },
    });
    sortSystemsByFilter(filters);
  }, [listSystems, nextPage]);

  const sortSystemsByFilter = (filter) => {
    switch (filter) {
      case "noResponsible":
        sortedSystems.sort((a, b) => {
          if (a.noResponsible < b.noResponsible) return -1;

          if (a.noResponsible > b.noResponsible) return 1;

          return 0;
        });
        break;

      case "noAnswered":
        sortedSystems.sort((a, b) => {
          if (a.noAnswered < b.noAnswered) return -1;

          if (a.noAnswered > b.noAnswered) return 1;

          return 0;
        });
        break;

      case "lastMessage":
        sortedSystems.sort((a, b) => {
          var c = new Date(a.lastMessage);
          var d = new Date(b.lastMessage);
          return d - c;
        });
        setFilters("lastMessage");
        break;

      case "fromCustomer":
        sortedSystems.sort((a, b) => {
          if (!a["fromCustomer"] && !b["fromCustomer"]) {
            return 0;
          } else if (a["fromCustomer"] && !b["fromCustomer"]) {
            return -1;
          } else if (!a["fromCustomer"] && b["fromCustomer"]) {
            return 1;
          } else {
            const value1 = a["fromCustomer"].toString().toUpperCase(); // ignore upper and lowercase
            const value2 = b["fromCustomer"].toString().toUpperCase(); // ignore upper and lowercase
            if (value1 < value2) {
              return -1;
            } else if (value1 > value2) {
              return 1;
            } else {
              return 0;
            }
          }
        });
        break;
    }
  };

  const reverseSortSystemsByFilter = (filter) => {
    switch (filter) {
      case "reverseNoResponsible":
        sortedSystems.sort((a, b) => {
          if (a.noResponsible < b.noResponsible) return 1;

          if (a.noResponsible > b.noResponsible) return -1;

          return 0;
        });
        break;

      case "reverseNoAnswered":
        sortedSystems.sort((a, b) => {
          if (a.noAnswered < b.noAnswered) return 1;

          if (a.noAnswered > b.noAnswered) return -1;

          return 0;
        });
        break;

      case "reverseLastMessage":
        sortedSystems.sort((a, b) => {
          var c = new Date(a.lastMessage);
          var d = new Date(b.lastMessage);
          return c - d;
        });
        setFilters("reverseLastMessage");
        break;

      case "reverseFromCustomer":
        sortedSystems.sort((a, b) => {
          if (!a["fromCustomer"] && !b["fromCustomer"]) {
            return 0;
          } else if (a["fromCustomer"] && !b["fromCustomer"]) {
            return 1;
          } else if (!a["fromCustomer"] && b["fromCustomer"]) {
            return -1;
          } else {
            const value1 = a["fromCustomer"].toString().toUpperCase(); // ignore upper and lowercase
            const value2 = b["fromCustomer"].toString().toUpperCase(); // ignore upper and lowercase
            if (value1 < value2) {
              return 1;
            } else if (value1 > value2) {
              return -1;
            } else {
              return 0;
            }
          }
        });
        break;
    }
  };

  return (
    <div className={styles["system-container"]}>
      <h3
        className={styles["system-heading"]}
        style={{ marginTop: window.innerWidth < 900 && "0px" }}
      >
        Систeми
      </h3>
      <div>
        <div className={styles["system-content"]}>
          {window.innerWidth > 900 && (
            <div className={styles["filters-container"]}>
              <Button
                style={{ width: "150px" }}
                onClick={() => {}}
                disableElevation
                disableRipple
                disableFocusRipple
                endIcon={
                  <KeyboardArrowDownIcon
                    style={
                      filters === "name" ? { transform: "rotate(180deg)" } : {}
                    }
                  />
                }
                className={styles["filter-button"]}
              >
                Система
              </Button>
              <Button
                style={{ width: "200px" }}
                onClick={() => {
                  if (filters === "noResponsible") {
                    reverseSortSystemsByFilter("reverseNoResponsible");
                    setFilters("reverseNoResponsible");
                  } else {
                    setFilters("noResponsible");
                    sortSystemsByFilter("noResponsible");
                  }
                }}
                disableElevation
                disableRipple
                disableFocusRipple
                endIcon={
                  <KeyboardArrowDownIcon
                    style={
                      filters === "noResponsible"
                        ? { transform: "rotate(180deg)" }
                        : {}
                    }
                  />
                }
                className={styles["filter-button"]}
              >
                Неназначени
              </Button>
              <Button
                style={{ width: "130px" }}
                onClick={() => {
                  if (filters === "noAnswered") {
                    reverseSortSystemsByFilter("reverseNoAnswered");
                    setFilters("reverseNoAnswered");
                  } else {
                    setFilters("noAnswered");
                    sortSystemsByFilter("noAnswered");
                  }
                }}
                disableElevation
                disableRipple
                disableFocusRipple
                endIcon={
                  <KeyboardArrowDownIcon
                    style={
                      filters === "noAnswered"
                        ? { transform: "rotate(180deg)" }
                        : {}
                    }
                  />
                }
                className={styles["filter-button"]}
              >
                Неотговорени
              </Button>
              <Button
                style={{ width: "200px" }}
                onClick={() => {
                  if (filters === "lastMessage") {
                    reverseSortSystemsByFilter("reverseLastMessage");
                    setFilters("reverseLastMessage");
                  } else {
                    setFilters("lastMessage");
                    sortSystemsByFilter("lastMessage");
                  }
                }}
                disableElevation
                disableRipple
                disableFocusRipple
                endIcon={
                  <KeyboardArrowDownIcon
                    style={
                      filters === "lastMessage"
                        ? { transform: "rotate(180deg)" }
                        : {}
                    }
                  />
                }
                className={styles["filter-button"]}
              >
                Последно съобщение
              </Button>
              <Button
                style={{ width: "150px" }}
                onClick={() => {
                  if (filters === "fromCustomer") {
                    reverseSortSystemsByFilter("reverseFromCustomer");
                    setFilters("reverseFromCustomer");
                  } else {
                    setFilters("fromCustomer");
                    sortSystemsByFilter("fromCustomer");
                  }
                }}
                disableElevation
                disableRipple
                disableFocusRipple
                endIcon={
                  <KeyboardArrowDownIcon
                    style={
                      filters === "fromCustomer"
                        ? { transform: "rotate(180deg)" }
                        : {}
                    }
                  />
                }
                className={styles["filter-button"]}
              >
                Изпратено от
              </Button>
            </div>
          )}

          <Box
            sx={{
              overflow: "auto",
              height:
                window.innerWidth < 900
                  ? "calc(100vh - 170px)"
                  : "calc(100vh - 300px)",
            }}
          >
            {sortedSystems.map((system) => (
              <div ref={handleLoadSystemsRef} key={system.id}>
                <SystemCard
                  key={system.id}
                  arg1={system.name}
                  arg2={`${system.noResponsible} теми`}
                  arg3={`${system.noAnswered} теми`}
                  arg4={
                    system.lastMessage !== null
                      ? format(new Date(system.lastMessage), "dd.MM.yyyy")
                      : "Няма съобщения"
                  }
                  arg5={
                    system.fromCustomer !== null
                      ? system.fromCustomer
                      : "Няма съобщения"
                  }
                  systems
                  handleClick={() => handleNavigate(system._id)}
                />
              </div>
            ))}
          </Box>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  systemsList: state.systems.systemsList,
  systemsListInfo: state.systems.systemsListInfo,
});

const mapDispatchToProps = (dispatch) => ({
  getAllSystems: () => dispatch(getAllSystems()),
  listSystems: (payload) => dispatch(listSystems(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(System);
