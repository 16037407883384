/* eslint-disable */
import { toast } from "react-toastify";
import { ofType, ActionsObservable } from "redux-observable";
import { ajax } from "rxjs/ajax";
import { switchMap, catchError } from "rxjs/operators";
import { immotechFieldsTypes } from "../actions";
import { IMMOTECH_API_URL } from "../config/settings";
import { Headers } from "../utils/Headers";

export const checkImmotechUserEpic = (action$) => {
  return action$.pipe(
    ofType(immotechFieldsTypes.CHECK_IMMOTECH_USER),
    switchMap(({ payload }) => {
      return ajax({
        url: `${IMMOTECH_API_URL}/support/get-user-by-phone`,
        method: "POST",
        headers: Headers.basicImmotechAuth(),
        body: JSON.stringify({
          phoneNumber: payload.phone,
        }),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            payload.onSuccess(response);
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            if (err.status === 401) {
              window.location.href = "/login";
            } else {
              toast.error(err?.response?.error);
            }
          });
        })
      );
    })
  );
};

export const getImmotechUserInfoEpic = (action$) => {
  return action$.pipe(
    ofType(immotechFieldsTypes.USER_INFO),
    switchMap(({ payload }) => {
      return ajax({
        url: `${IMMOTECH_API_URL}/support`,
        method: "POST",
        headers: Headers.basicImmotechAuth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: immotechFieldsTypes.USER_INFO_SUCCESS,
              payload: response,
            });
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            console.log(err);
          });
        })
      );
    })
  );
};
