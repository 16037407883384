/* eslint-disable */
import { Button, FormControl, Paper, TextField } from "@mui/material";
import React, { useState } from "react";
import styles from "../ChangeTopicNameModal/ChangeTopicNameModal.module.scss";
import CloseIcon from "@mui/icons-material/Close";
import {
  groupsList,
  groupEdit,
  templateEdit,
  templatesList,
} from "../../../actions/template";
import { connect } from "react-redux";
import ModalComponent from "../ModalComponent/ModalComponent";

const EditTemplateModal = ({
  handleClose,
  groupEdit,
  slug,
  group,
  groupsList,
  template,
  templateEdit,
  templatesList,
  setSelGroup
}) => {
  const [inputName, setInputName] = useState(template ? template?.text : group?.name);

  return (
    <Paper className={styles["assign-topic-modal"]}>
      <div className={styles["assign-topic-heading-container"]}>
        <h3>РЕДАКТИРАНЕ НА {template ? `ШАБЛОН` : "ТЕМА"}</h3>
        <CloseIcon onClick={handleClose} />
      </div>
      <FormControl style={{ marginTop: "-3%" }}>
        <p style={{ padding: "10px" }}>
          {template ? "Tекст съобщение" : "Име на тема"}
        </p>
        <TextField
          value={inputName}
          onChange={(e) => setInputName(e.target.value)}
          sx={{ padding: "10px" }}
          variant="outlined"
          id="search-field"
          size="small"
        />
      </FormControl>
      <Paper elevation={10} style={{ padding: "10px" }}>
        <Button
          onClick={() => {
            if (template) {
              templateEdit({
                id:template?._id,
                payloadAction:{
                  text:inputName
                },
                onSuccess:(res) => {
                  templatesList({
                    noPagination:true,
                    group:res.payload.group._id
                  })
                }
              })
            } else {
              groupEdit({
                id: group?._id,
                payloadAction: {
                  name: inputName,
                },
                onSuccess: (res) => {
                  setSelGroup(res.payload)
                  groupsList({
                    noPagination: true,
                    system: slug,
                    onSuccess:(res) => {}
                  });
                },
              });
            }

            handleClose();
          }}
          disableElevation
          disableRipple
          disableFocusRipple
          disabled={inputName.length > 2 ? false : true}
          variant="contained"
          sx={{
            width: "100%",
            color: "white",
            backgroundColor: "#01d088",
            cursor: "pointer",
            "&:hover": {
              backgroundColor: "#01d088",
            },
          }}
        >
          Запази
        </Button>
      </Paper>
    </Paper>
  );
};

const mapDispatchToProps = (dispatch) => ({
  groupEdit: (payload) => dispatch(groupEdit(payload)),
  groupsList: (payload) => dispatch(groupsList(payload)),
  templateEdit: (payload) => dispatch(templateEdit(payload)),
  templatesList: (payload) => dispatch(templatesList(payload)),
});

export default connect(null, mapDispatchToProps)(EditTemplateModal);
