import S3FileUpload from "react-s3";
import { nanoid } from "nanoid";
import { AMARANT2_API_URL, AMARANT_S3_CREDENTIALS, S3_CREDENTIALS } from "../config/settings";
import { Headers } from "./Headers";

export const uploadFile = (file) =>
  S3FileUpload.uploadFile(
    new File([file], file?.name || nanoid(), {
      type: file?.type || "",
      lastModified: file?.lastModified || new Date(),
    }),
    S3_CREDENTIALS
  );

export const uploadFileDirName = (file, system) => {
  const s3Credentials = { ...S3_CREDENTIALS, dirName: system };

  return S3FileUpload.uploadFile(
    new File([file], file?.name || nanoid(), {
      type: file?.type || "",
      lastModified: file?.lastModified || new Date(),
    }),
    s3Credentials
  );
};

export const uploadFileAmarant = async (file, location = "/customerSupportUploads/") => {
  let filename = file.name;
  filename = filename.split("%").join("").split(" ").join("");
  filename = `${location}${filename}` || nanoid();
  const response = await fetch(`${AMARANT2_API_URL}/cloudServices/s3Upload`, { method: "POST", headers: Headers.basicAmarantUploadAuth(), body: JSON.stringify({ objectKey: filename, mimeType: file?.type ?? "" }) });
  if (!response.ok) throw new Error("Грешка");
  const {
    payload: { presignedUrl, url },
  } = await response.json();
  const response2 = await fetch(presignedUrl, { method: "PUT", body: file, headers: { "Content-Type": file?.type ?? "" } });
  if (!response2.ok) throw new Error("Грешка");
  return { location: url };
};
// S3FileUpload.uploadFile(
//   new File([file], file?.name || nanoid(), {
//     type: file?.type || "",
//     lastModified: file?.lastModified || new Date(),
//   }),
//   credentials
// );

export const deleteFileAmarant = () =>
  S3FileUpload.deleteFile("Simeon-rangelov", {
    ...AMARANT_S3_CREDENTIALS,
    dirName: `customerSupportUploads/offer.63b6cc199f84cf0016321c5c.06.07.2023`,
  });

export const uploadFiles = (files) => {
  const promises = [];
  Array.from(files).forEach((f) =>
    promises.push(
      S3FileUpload.uploadFile(
        new File([f], f?.name || nanoid(), {
          type: f?.type,
          lastModified: f?.lastModified || new Date(),
        }),
        S3_CREDENTIALS
      )
    )
  );
  return Promise.all(promises);
};

export const getPresignedUrlView = async (fileUrl) => {
  const response = await fetch(`${AMARANT2_API_URL}/cloudServices/s3downloadBA/${nanoid()}`, { method: "POST", headers: Headers.basicAmarantAuth(), body: JSON.stringify({ url: fileUrl }) });
  if (!response.ok) throw new Error("Грешка");
  const { payload } = await response.json();
  return payload?.presignedUrl;
};
