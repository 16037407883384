export const loginTypes = {
  LOGIN_VALIDATE_TOKEN: "login/LOGIN_VALIDATE_TOKEN",
  SET_LOGIN_FIELDS: "login/SET_LOGIN_FIELDS",
  LOGIN_SIGN_IN: "login/LOGIN_SIGN_IN",
  LOGIN_SIGN_UP: "login/LOGIN_SIGN_UP",
  LOAD_USER_CREDENTIALS: "login/LOAD_USER_CREDENTIALS",
  LOGIN_EDIT_USER: "login/LOGIN_EDIT_USER",
  LOGIN_EDIT_USER_SUCCESS: "login/LOGIN_EDIT_USER_SUCCESS",
  DEAUTHENTICATE: "login/DEAUTHENTICATE",
};
export const loginValidateToken = () => ({
  type: loginTypes.LOGIN_VALIDATE_TOKEN,
});

export const setLoginFields = (payload) => ({
  type: loginTypes.SET_LOGIN_FIELDS,
  payload,
});

export const loginSignIn = (payload) => ({
  type: loginTypes.LOGIN_SIGN_IN,
  payload,
});

export const loginSignUp = (payload) => ({
  type: loginTypes.LOGIN_SIGN_UP,
  payload,
});

export const loginEditUser = (payload) => ({
  type: loginTypes.LOGIN_EDIT_USER,
  payload,
});

export const loadUserCredentials = (payload) => ({
  type: loginTypes.LOAD_USER_CREDENTIALS,
  payload,
});

export const deauthenticate = () => ({
  type: loginTypes.DEAUTHENTICATE,
});
