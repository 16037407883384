export const labelsTypes = {

    LABELS_LIST:'LABELS/LABELS_LIST',
    LABELS_LIST_SUCCESS:'LABELS/LABELS_LIST_SUCCESS',
    LABELS_CREATE:'LABELS/LABELS_CREATE',
    LABELS_EDIT:'LABELS/LABELS_EDIT',
    LABELS_DELETE:'LABELS/LABELS_DELETE',
}

export const labelsList = (payload) => ({
    type: labelsTypes.LABELS_LIST,
    payload,
});

export const labelsCreate = (payload) => ({
    type:labelsTypes.LABELS_CREATE,
    payload
})

export const labelsEdit = (payload) => ({
    type:labelsTypes.LABELS_EDIT,
    payload
})  

export const labelsDelete = (payload) => ({
    type:labelsTypes.LABELS_DELETE,
    payload
})
