/* eslint-disable */
import { Button, FormControl, Paper, TextField } from "@mui/material";
import React, { useState } from "react";
import styles from "../ChangeTopicNameModal/ChangeTopicNameModal.module.scss";
import CloseIcon from "@mui/icons-material/Close";
import {
  groupsList,
  groupCreate,
  templateCreate,
  templatesList,
} from "../../../actions/template";
import { connect } from "react-redux";

const AddTemplateModal = ({
  handleClose,
  groupCreate,
  slug,
  groupsList,
  selGroup,
  templateCreate,
  templatesList,
  setSelGroup
}) => {
  const [name, setName] = useState("");

  return (
    <Paper className={styles["assign-topic-modal"]}>
      <div className={styles["assign-topic-heading-container"]}>
        <h3>
          {selGroup
            ? `ДОБАВЯНЕ НА ШАБЛОН - ${selGroup?.name}`
            : "ДОБАВЯНЕ НА ТЕМА"}
        </h3>
        <CloseIcon onClick={handleClose} />
      </div>
      <FormControl style={{ marginTop: "-3%" }}>
        <p style={{ padding: "10px" }}>Име на {selGroup ? "шаблон" : "тема"}</p>
        <TextField
          value={name}
          onChange={(e) => setName(e.target.value)}
          sx={{ padding: "10px" }}
          variant="outlined"
          id="search-field"
          size="small"
        />
      </FormControl>
      <Paper elevation={10} style={{ padding: "10px" }}>
        <Button
          onClick={() => {
            if (selGroup) {
              templateCreate({
                group:selGroup?._id,
                templates:[name],
                onSuccess:() => {
                  templatesList({
                    group:selGroup?._id,
                    noPagination:true
                  })
                }
              })
            } else {
              groupCreate({
                system: slug,
                groups: [name],
                onSuccess: (res) => {
                  setSelGroup(res[0]);
                  groupsList({
                    system: slug,
                    noPagination: true,
                    onSuccess:(res) => {}
                  });
                  templatesList({
                    group:res[0]?._id,
                    noPagination:true
                  })
                },
              });
            }

            handleClose();
          }}
          disableElevation
          disableRipple
          disableFocusRipple
          disabled={name.length > 2 ? false : true}
          variant="contained"
          sx={{
            width: "100%",
            color: "white",
            backgroundColor: "#01d088",
            cursor: "pointer",
            "&:hover": {
              backgroundColor: "#01d088",
            },
          }}
        >
          Добави
        </Button>
      </Paper>
    </Paper>
  );
};

const mapDispatchToProps = (dispatch) => ({
  groupCreate: (payload) => dispatch(groupCreate(payload)),
  groupsList: (payload) => dispatch(groupsList(payload)),
  templateCreate: (payload) => dispatch(templateCreate(payload)),
  templatesList: (payload) => dispatch(templatesList(payload)),
});

export default connect(null, mapDispatchToProps)(AddTemplateModal);
