export const synergonFieldsTypes = {
  GET_USER_INFO: "synergonFields/GET_USER_INFO",
  GET_USER_INFO_HYDROPRO: "synergonFields/GET_USER_INFO_HYDROPRO",
};

export const getEnergySystemsUserInfo = (payload) => ({
  type: synergonFieldsTypes.GET_USER_INFO,
  payload,
});
export const getHydroProUserInfo = (payload) => ({
  type: synergonFieldsTypes.GET_USER_INFO_HYDROPRO,
  payload,
});