/* eslint-disable */
import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";
import systems from "./system";
import login from "./login";
import users from "./users";
import tickets from "./tickets";
import support from "./support";
import activity from "./activity";
import amarantFields from "./amarantFields";
import notes from "./notes";
import templates from "./template";
import labels from "./labels";
import immotechFields from "./immotechFields";
import offer from "./offer";

import starter from "./starter";

export default combineReducers({
  starter,
  routerReducer,
  systems,
  login,
  users,
  tickets,
  activity,
  support,
  notes,
  templates,
  amarantFields,
  immotechFields,
  labels,
  offer
});
