import {
  deauthenticate,
  loadUserCredentials,
  loginEditUser,
} from "../actions/login";
import { supportSocketDisconnect } from "../actions/support";
import { store } from "../config/stores";

export class User {
  static loadLocalStorageItems() {
    const credentials = JSON.parse(localStorage.getItem("credentials"));
    if (credentials) {
      store.dispatch(loadUserCredentials(credentials));
    }
    return credentials;
  }

  static storeUserCredentials(credentials) {
    store.dispatch(loadUserCredentials(credentials));
    return localStorage.setItem("credentials", JSON.stringify(credentials));
  }

  static removeUserCredentials() {
    return localStorage.removeItem("credentials");
  }
}

export const userDeauthenticate = () => {
  store.dispatch(supportSocketDisconnect());
  const handleDeauthenticate = async () => {
    store.dispatch(deauthenticate());
    User.removeUserCredentials();
  };

  const payload = {
    user: {
      pushToken: null,
    },
    onSuccess: handleDeauthenticate,
    onError: handleDeauthenticate,
  };
  store.dispatch(loginEditUser(payload));
};
