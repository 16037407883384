/* eslint-disable */
import { ofType, ActionsObservable } from "redux-observable";
import { ajax } from "rxjs/ajax";
import { switchMap, catchError } from "rxjs/operators";
import { templateTypes } from "../actions/template";
import { API_URL } from "../config/settings";
import { Headers } from "../utils/Headers";
import { toast } from "react-toastify";

export const groupsListEpic = (action$) => {
    return action$.pipe(
      ofType(templateTypes.GROUPS_LIST),
      switchMap(({ payload }) => {
        return ajax({
          url: `${API_URL}/template/group/list`,
          method: "POST",
          headers: Headers.auth(),
          body: JSON.stringify(payload),
        }).pipe(
          switchMap(({ response }) => {
            return ActionsObservable.create((obs) => {
              payload.onSuccess(response);
              obs.next({
                type: templateTypes.GROUPS_LIST_SUCCESS,
                payload: response.payload.docs,
              });
              obs.complete();
            });
          }),
          catchError((err) => {
            return ActionsObservable.create((obs) => {
              if (err.status === 401) {
                window.location.href = "/login";
              } 
            });
          })
        );
      })
    );
};

export const groupCreateEpic = (action$) => {
    return action$.pipe(
      ofType(templateTypes.GROUP_CREATE),
      switchMap(({ payload }) => {
        return ajax({
          url: `${API_URL}/template/group/create`,
          method: "POST",
          headers: Headers.auth(),
          body: JSON.stringify(payload),
        }).pipe(
          switchMap(({ response }) => {
            return ActionsObservable.create((obs) => {
              payload.onSuccess(response.payload);
              toast.success("Successfully created group.");
              obs.complete();
            });
          }),
          catchError((err) => {
            return ActionsObservable.create((obs) => {
              if (err.status === 401) {
                window.location.href = "/login";
              } else {
                toast.error(err.response.message);
              }
            });
          })
        );
      })
    );
};

export const groupEditEpic = (action$) => {
    return action$.pipe(
      ofType(templateTypes.GROUP_EDIT),
      switchMap(({ payload }) => {
        return ajax({
          url: `${API_URL}/template/group/${payload.id}`,
          method: "PUT",
          headers: Headers.auth(),
          body: JSON.stringify(payload.payloadAction),
        }).pipe(
          switchMap(({ response }) => {
            return ActionsObservable.create((obs) => {
              toast.success("Successfully updated group.");
              payload.onSuccess(response);
              obs.complete();
            });
          }),
          catchError((err) => {
            return ActionsObservable.create((obs) => {
              if (err.status === 401) {
                window.location.href = "/login";
              } else {
                toast.error(err.response.message);
              }
            });
          })
        );
      })
    );
};

export const groupDeleteEpic = (action$) => {
    return action$.pipe(
      ofType(templateTypes.GROUP_DELETE),
      switchMap(({ payload }) => {
        return ajax({
          url: `${API_URL}/template/group/delete`,
          method: "DELETE",
          headers: Headers.auth(),
          body: JSON.stringify(payload.array),
        }).pipe(
          switchMap(({ response }) => {
            return ActionsObservable.create((obs) => {
              toast.success("Successfully deleted group.");
              payload.onSuccess();
              obs.complete();
            });
          }),
          catchError((err) => {
            return ActionsObservable.create((obs) => {
              if (err.status === 401) {
                window.location.href = "/login";
              } else {
                toast.error(err.response.message);
              }
            });
          })
        );
      })
    );
};

export const templateListEpic = (action$) => {
  return action$.pipe(
    ofType(templateTypes.TEMPLATE_LIST),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API_URL}/template/list`,
        method: "POST",
        headers: Headers.auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: templateTypes.TEMPLATE_LIST_SUCCESS,
              payload: response.payload.docs,
            });
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            if (err.status === 401) {
              window.location.href = "/login";
            } 
          });
        })
      );
    })
  );
};

export const templateCreateEpic = (action$) => {
  return action$.pipe(
    ofType(templateTypes.TEMPLATE_CREATE),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API_URL}/template/create`,
        method: "POST",
        headers: Headers.auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            payload.onSuccess();
            toast.success("Successfully created templates.");
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            if (err.status === 401) {
              window.location.href = "/login";
            } else {
              toast.error(err.response.message);
            }
          });
        })
      );
    })
  );
};

export const templateEditEpic = (action$) => {
  return action$.pipe(
    ofType(templateTypes.TEMPLATE_EDIT),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API_URL}/template/${payload.id}`,
        method: "PUT",
        headers: Headers.auth(),
        body: JSON.stringify(payload.payloadAction),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            toast.success("Successfully updated template.");
            payload.onSuccess(response);
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            if (err.status === 401) {
              window.location.href = "/login";
            } else {
              toast.error(err.response.message);
            }
          });
        })
      );
    })
  );
};

export const templateDeleteEpic = (action$) => {
  return action$.pipe(
    ofType(templateTypes.TEMPLATE_DELETE),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API_URL}/template/delete`,
        method: "DELETE",
        headers: Headers.auth(),
        body: JSON.stringify(payload.array),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            toast.success("Successfully deleted template.");
            payload.onSuccess();
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            if (err.status === 401) {
              window.location.href = "/login";
            } else {
              toast.error(err.response.message);
            }
          });
        })
      );
    })
  );
};
