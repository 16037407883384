/* eslint-disable */
import { Box, Button, Divider } from "@mui/material";
import React, { useEffect, useState, useRef, useCallback } from "react";
import styles from "./AmarantComponent.module.scss";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { listFines } from "../../actions/amarantFields";
import { connect } from "react-redux";
import moment from "moment";
import { filterByProp } from "../../utils/helperFunctions";

const columnNames = ["Име", "Фиш №", "Сума", "Получено плащане", "Дата на плащане", "Час на плащане"];

function FinesComponent({ fines, listFines, customerID }) {
  const [filter, setFilter] = useState("");
  const [allFines, setAllFines] = useState([]);

  useEffect(() => {
    listFines({
      //customerSupportId: "6231bc7bc8696d0016d4af13",
      customerSupportId: customerID,
    });
  }, []);

  return (
    <div style={{ overflowY: "auto", height: "95%", width: "100%" }}>
      {window.innerWidth < 900 ? (
        <>
          <div className={styles["tabs-container"]} style={{ padding: "0px" }}>
            {fines.map((fine, i) => {
              return (
                <div className={styles["row-container"]} style={{ marginTop: "5%", display: "block", height: "unset" }} key={i}>
                  <div className={styles["mobile-box"]}>
                    <p style={{ marginBottom: "1%", fontSize: "14px" }}>{columnNames[0]}</p>
                    <b style={{ marginBottom: "1%", fontSize: "14px" }}>{fine.drivingLicense.fullName}</b>
                  </div>
                  <Divider />
                  <div className={styles["mobile-box"]}>
                    <p style={{ marginBottom: "1%", fontSize: "14px" }}>{columnNames[1]}</p>
                    <b style={{ marginBottom: "1%", fontSize: "14px" }}>{fine.document}</b>
                  </div>
                  <Divider />
                  <div className={styles["mobile-box"]}>
                    <p style={{ marginBottom: "1%", fontSize: "14px" }}>{columnNames[2]}</p>
                    <b style={{ marginBottom: "1%", fontSize: "14px" }}>{fine.price}</b>
                  </div>
                  <Divider />
                  <div className={styles["mobile-box"]}>
                    <p style={{ marginBottom: "1%", fontSize: "14px" }}>{columnNames[3]}</p>
                    <b style={{ marginBottom: "1%", fontSize: "14px" }}>{fine.paid ? "Да" : "Не"}</b>
                  </div>
                  <Divider />
                  <div className={styles["mobile-box"]}>
                    <p style={{ marginBottom: "1%", fontSize: "14px" }}>{columnNames[4]}</p>
                    <b style={{ marginBottom: "1%", fontSize: "14px" }}>{moment(fine.updatedAt).format("DD.MM.YYYY")}</b>
                  </div>
                  <Divider />
                  <div className={styles["mobile-box"]}>
                    <p style={{ marginBottom: "1%", fontSize: "14px" }}>{columnNames[5]}</p>
                    <b style={{ marginBottom: "1%", fontSize: "14px" }}>{moment(fine.updatedAt).format("HH:mm")}</b>
                  </div>
                  <Divider />
                </div>
              );
            })}
          </div>
        </>
      ) : (
        <>
          <div className={styles["columns-container"]}>
            <div className={styles["upper-names"]}>
              <div className={`${styles["name-icon"]}`} style={{ width: "13%" }}>
                <p>{columnNames[0]}</p>
                <KeyboardArrowDownIcon
                  style={{
                    transform: filter === "name" && "rotate(180deg)",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (filter === "name") {
                      setFilter("reverseName");
                      filterByProp(fines, "reversePlate", "user");
                    } else {
                      setFilter("name");
                      filterByProp(fines, "plate", "user");
                    }
                  }}
                />
              </div>
              <div className={`${styles["name-icon"]}`} style={{ width: "8%" }}>
                <p>{columnNames[1]}</p>
                <KeyboardArrowDownIcon
                  style={{
                    transform: filter === "fineNumber" && "rotate(180deg)",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (filter === "fineNumber") {
                      setFilter("reverseFineNumber");
                    } else {
                      setFilter("fineNumber");
                    }
                  }}
                />
              </div>
              <div className={`${styles["name-icon"]}`} style={{ width: "8%" }}>
                <p>{columnNames[2]}</p>
                <KeyboardArrowDownIcon
                  style={{
                    transform: filter === "amount" && "rotate(180deg)",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (filter === "amount") {
                      setFilter("reverseAmount");
                    } else {
                      setFilter("amount");
                    }
                  }}
                />
              </div>
              <div className={`${styles["name-icon"]}`} style={{ width: "8%" }}>
                <p>{columnNames[3]}</p>
                <KeyboardArrowDownIcon
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {}}
                />
              </div>
              <div className={`${styles["name-icon"]}`} style={{ width: "10%" }}>
                <p>{columnNames[4]}</p>
                <KeyboardArrowDownIcon
                  style={{
                    transform: filter === "paidDate" && "rotate(180deg)",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (filter === "paidDate") {
                      setFilter("reversePaidDate");
                      filterByProp(fines, "reverseCreatedAt", "updatedAt");
                    } else {
                      setFilter("paidDate");
                      filterByProp(fines, "createdAt", "updatedAt");
                    }
                  }}
                />
              </div>
              <div className={`${styles["name-icon"]}`} style={{ width: "10%" }}>
                <p>{columnNames[5]}</p>
                <KeyboardArrowDownIcon
                  style={{
                    transform: filter === "paidTime" && "rotate(180deg)",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (filter === "paidTime") {
                      setFilter("reversePaidTime");
                      fines.sort((a, b) => {
                        var c = moment(a.updatedAt).format("HH:mm");
                        var d = moment(b.updatedAt).format("HH:mm");
                        if (!c && !d) {
                          return 0;
                        } else if (c && !d) {
                          return 1;
                        } else if (!c && d) {
                          return -1;
                        } else {
                          const value1 = c.toString().toUpperCase();
                          const value2 = d.toString().toUpperCase();
                          if (value1 < value2) {
                            return 1;
                          } else if (value1 > value2) {
                            return -1;
                          } else {
                            return 0;
                          }
                        }
                      });
                    } else {
                      setFilter("paidTime");
                      fines.sort((a, b) => {
                        var c = moment(a.updatedAt).format("HH:mm");
                        var d = moment(b.updatedAt).format("HH:mm");
                        if (!c && !d) {
                          return 0;
                        } else if (c && !d) {
                          return -1;
                        } else if (!c && d) {
                          return 1;
                        } else {
                          const value1 = c.toString().toUpperCase();
                          const value2 = d.toString().toUpperCase();
                          if (value1 < value2) {
                            return -1;
                          } else if (value1 > value2) {
                            return 1;
                          } else {
                            return 0;
                          }
                        }
                      });
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <div className={styles["tabs-container"]} style={{}}>
            {fines.map((outterFine, i) => {
              return outterFine?.fines?.map((fine, j) => {
                return (
                  <div className={styles["row-container"]}>
                    <div className={styles["data"]} style={{ width: "13%" }}>
                      <b>{outterFine.drivingLicense.fullName}</b>
                    </div>
                    <Divider
                      style={{
                        backgroundColor: "#D3D3D3",
                        height: "65%",
                      }}
                      orientation="vertical"
                    />
                    <div className={styles["data"]} style={{ width: "8%" }}>
                      <b>{fine.document}</b>
                    </div>
                    <Divider
                      style={{
                        backgroundColor: "#D3D3D3",
                        height: "65%",
                      }}
                      orientation="vertical"
                    />
                    <div className={styles["data"]} style={{ width: "8%" }}>
                      <b>{fine.price} лв.</b>
                    </div>
                    <Divider
                      style={{
                        backgroundColor: "#D3D3D3",
                        height: "65%",
                      }}
                      orientation="vertical"
                    />
                    <div className={styles["data"]} style={{ width: "8%" }}>
                      <b>{outterFine.paid ? "Да" : "Не"}</b>
                    </div>
                    <Divider
                      style={{
                        backgroundColor: "#D3D3D3",
                        height: "65%",
                      }}
                      orientation="vertical"
                    />
                    <div className={styles["data"]} style={{ width: "10%" }}>
                      <b>{moment(outterFine.updatedAt).format("DD.MM.YYYY")}</b>
                    </div>
                    <Divider
                      style={{
                        backgroundColor: "#D3D3D3",
                        height: "65%",
                      }}
                      orientation="vertical"
                    />
                    <div className={styles["data"]} style={{ width: "10%" }}>
                      <b>{moment(outterFine.updatedAt).format("HH:mm")}</b>
                    </div>
                  </div>
                );
              });
            })}
          </div>
        </>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  fines: state.amarantFields.fines,
});

const mapDispatchToProps = (dispatch) => ({
  listFines: (payload) => dispatch(listFines(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FinesComponent);
