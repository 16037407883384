export const ticketTypes = {
  GET_TICKETS_FOR_SYSTEM: "tickets/GET_TICKETS_FOR_SYSTEM",
  GET_TICKETS_FOR_SYSTEM_SUCCESS: "tickets/GET_TICKETS_FOR_SYSTEM_SUCCESS",
  TICKET_ACTIONS: "tickets/TICKET_ACTIONS",
  TICKET_ACTIONS_SUCCESS: "tickets/TICKET_ACTIONS_SUCCESS",
  GET_CURRENT_TICKET: "tickets/GET_CURRENT_TICKET",
  GET_CURRENT_TICKET_SUCCESS: "tickets/GET_CURRENT_TICKET_SUCCESS",
  GET_FILTERED_TICKETS: "tickets/GET_FILTERED_TICKETS",
  GET_FILTERED_TICKETS_SUCCESS: "tickets/GET_FILTERED_TICKETS_SUCCESS",
  GET_CURRENT_TICKET_ACCEPTED_OFFER: "tickets/GET_CURRENT_TICKET_ACCEPTED_OFFER",
  GET_CURRENT_TICKET_ACCEPTED_OFFER_SUCCESS: "tickets/GET_CURRENT_TICKET_ACCEPTED_OFFER_SUCCESS",
  EDIT_MULTIPLE_TICKETS: "tickets/EDIT_MULTIPLE_TICKETS",
  EDIT_MULTIPLE_TICKETS_SUCCESS: "tickets/EDIT_MULTIPLE_TICKETS_SUCCESS",
  REPLACE_TICKET: "tickets/REPLACE_TICKET",

  TICKET_SEND_REMINDER: "tickets/TICKET_SEND_REMINDER",

  MULTIPLE_VOTING: "tickets/MULTIPLE_VOTING",

  GET_CHAT_HISTORY: "tickets/GET_CHAT_HISTORY",

  CREATE_TICKET: "tickets/CREATE_TICKET",
  EDIT_CUSTOMER_NAME: "tickets/EDIT_CUSTOMER_NAME",

  SET_FILTERS: "tickets/SET_FILTERS",
};

export const getTicketsForSystem = (payload) => ({
  type: ticketTypes.GET_TICKETS_FOR_SYSTEM,
  payload,
});

export const ticketActions = (payload) => ({
  type: ticketTypes.TICKET_ACTIONS,
  payload,
});

export const multipleVoting = (payload) => ({
  type: ticketTypes.MULTIPLE_VOTING,
  payload,
});

export const editMultipleTickets = (payload) => ({
  type: ticketTypes.EDIT_MULTIPLE_TICKETS,
  payload,
});

export const getCurrentTicket = (payload) => ({
  type: ticketTypes.GET_CURRENT_TICKET,
  payload,
});

export const sendReminder = (payload) => ({
  type: ticketTypes.TICKET_SEND_REMINDER,
  payload,
});

export const getChatHistory = (payload) => ({
  type: ticketTypes.GET_CHAT_HISTORY,
  payload,
});

export const createTicket = (payload) => ({
  type: ticketTypes.CREATE_TICKET,
  payload,
});
export const getCurrentTicketAcceptedOffer = (payload) => ({
  type: ticketTypes.GET_CURRENT_TICKET_ACCEPTED_OFFER,
  payload,
});
export const editCustomerName = (payload) => ({
  type: ticketTypes.EDIT_CUSTOMER_NAME,
  payload,
});

export const setTicketFilters = (payload) => ({
  type: ticketTypes.SET_FILTERS,
  payload,
});
