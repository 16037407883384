/* eslint-disable */
import { Button, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "./TemplatesModal.module.scss";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { connect } from "react-redux";
import { groupsList, templatesList } from "../../../actions/template";
import ModalComponent from "../ModalComponent/ModalComponent";
import AddTemplateModal from "./AddTemplateModal";
import EditTemplateModal from "./EditTemplateModal";
import TemplateGroupsModal from "./TemplateGroupsModal";
import TemplateTextModal from "./TemplateTextModal";
import { Divider } from "@mui/material";

const TemplatesModal = ({
  handleClose,
  groupsList,
  slug,
  groups,
  templates,
  templatesList,
  setCurrentMessage
}) => {
  const [selGroup, setSelGroup] = useState(null);
  const [addGroupModal, setAddGroupModal] = useState(false);
  const [editGroupModal, setEditGroupModal] = useState(false);
  const [addTemplateModal, setAddTemplateModal] = useState(false);

  useEffect(() => {
      groupsList({
        noPagination: true,
        system: slug,
        onSuccess:(res) => {
          setSelGroup(res.payload.docs?.[0])
        },
      });
  }, []);

  return (
    <Paper className={styles["assign-topic-modal"]}>
      <div className={styles["assign-topic-heading-container"]}>
        <h3>ШАБЛОНИ ЗА СЪОБЩЕНИЯ</h3>
        <Button
          onClick={() => {
            setAddGroupModal(true);
          }}
          disableElevation
          disableRipple
          disableFocusRipple
          variant="contained"
          sx={{
            width: "30%",
            color: "white",
            backgroundColor: "#0DC1CE",
            textTransform:"none",
            cursor: "pointer",
            "&:hover": {
              backgroundColor: "#0DC1CE",
            },
          }}
        >
          <b>Добави тема</b>
        </Button>
        <p onClick={handleClose} style={{ cursor: "pointer" }}>
          Скрий {`>`}
        </p>
      </div>
      {groups.length > 0 ? (
        <>
          <TemplateGroupsModal
            groups={groups}
            selGroup={selGroup}
            setSelGroup={setSelGroup}
            templatesList={templatesList}
          />
          {selGroup ? (
            <TemplateTextModal
              selGroup={selGroup}
              setEditGroupModal={setEditGroupModal}
              editGroupModal={editGroupModal}
              setSelGroup={setSelGroup}
              groupList={groupsList}
              slug={slug}
              templates={templates}
              setCurrentMessage={setCurrentMessage}
            />
          ) : (
            <div
              style={{
                height: "85%",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p>Нямате добавени шаблони</p>
              <div className={styles["empty-template-temp"]} />
            </div>
          )}
          <Divider />
          <div style={{ width: "100%", padding: "5px" }}>
            <Button
              onClick={() => {
                setAddTemplateModal(true);
              }}
              disableElevation
              disableRipple
              disabled={selGroup ? false : true}
              variant="contained"
              sx={{
                width: "100%",
                color: "black",
                backgroundColor: "white",
                border: "2px dotted",
                borderColor: "#01d088",
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: "white",
                },
              }}
            >
              Добави
            </Button>
          </div>
        </>
      ) : (
        <div
          style={{
            height: "90%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p>Нямате добавени теми</p>
          <div className={styles["empty-template"]} />
        </div>
      )}
      <ModalComponent
        open={addGroupModal || addTemplateModal}
        handleClose={() => {
          setAddGroupModal(false);
          setAddTemplateModal(false);
        }}
        children={
          <AddTemplateModal
            slug={slug}
            selGroup={addTemplateModal === true ? selGroup : null}
            setSelGroup={setSelGroup}
            handleClose={() => {
              setAddGroupModal(false);
              setAddTemplateModal(false);
            }}
          />
        }
      />
      <ModalComponent
        open={editGroupModal}
        handleClose={() => {
          setEditGroupModal(false);
        }}
        children={
          <EditTemplateModal
            group={selGroup}
            setSelGroup={setSelGroup}
            handleClose={() => {
              setEditGroupModal(false);
            }}
            slug={slug}
          />
        }
      />
      
    </Paper>
  );
};
const mapStateToProps = (state) => ({
  groups: state.templates.groups,
  templates: state.templates.templates,
});

const mapDispatchToProps = (dispatch) => ({
  groupsList: (payload) => dispatch(groupsList(payload)),
  templatesList: (payload) => dispatch(templatesList(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TemplatesModal);
