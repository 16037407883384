/* eslint-disable */
import { offerTypes } from "../actions/offer";

const initialState = {
  agencies: [],
  currentOffer: {},
};
const offer = (state = initialState, { type, payload }) => {
  switch (type) {
    case offerTypes.LIST_AGENCIES_SUCCESS:
      return {
        ...state,
        agencies: payload,
      };
    case offerTypes.GET_CURRENT_OFFER_SUCCESS:
      return {
        ...state,
        currentOffer: payload,
      };
    default:
      return state;
  }
};

export default offer;
