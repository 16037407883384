/* eslint-disable */
import { immotechFieldsTypes } from "../actions";

const initialState = {
  immotechUser: {},
};

const immotechFields = (state = initialState, { type, payload }) => {
  switch (type) {
    case immotechFieldsTypes.USER_INFO_SUCCESS:
      return {
        ...state,
        immotechUser: payload,
      };
    default:
      return state;
  }
};

export default immotechFields;
