/* eslint-disable */
import { Divider, InputAdornment, InputBase, Paper, Select, Button, FormControl, RadioGroup, FormControlLabel, Radio } from "@mui/material";

import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import styles from "./Topics.module.scss";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { setTicketFilters } from "../../../actions/tickets";

const LeftToolbar = ({ handleInputValue, inputValue, setNextPage, setQuery, getTicketsForSystem, getCurrentSystem, searchBy, setSearchBy, radioBtnValue, handleRadioBtnChangeSortBy, searchQuery, slug, query }) => {
  const dispatch = useDispatch();
  const filters = useSelector(({ tickets }) => tickets?.filters) || {};
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        width: "80%",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginRight: "3%",
        }}
      >
        <p className="field-title" style={{ marginBottom: "2%" }}>
          Търси във всички теми
        </p>
        <Paper
          component={"form"}
          sx={{
            p: "2px 4px",
            display: "flex",
            alignItems: "center",
            width: window.innerWidth < 1450 ? 170 : 250,
            marginRight: "3%",
          }}
        >
          <InputBase
            onChange={(e) => handleInputValue(e)}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                if (inputValue.length < 1) Swal.fire("Полето е празно.", "", "error");
                else {
                  setNextPage(1);
                  const newFilters = { ...filters, [radioBtnValue]: inputValue };
                  dispatch(
                    setTicketFilters({
                      ...newFilters,
                    })
                  );
                  getTicketsForSystem({
                    system: slug,
                    pageNumber: 1,
                    filtered: true,
                    ...newFilters,
                  });
                  getCurrentSystem(slug);
                }
              }
            }}
            value={inputValue}
            sx={{ ml: 1, flex: 1 }}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            }
            endAdornment={
              <InputAdornment
                position="end"
                className={styles["end-button"]}
                style={{
                  backgroundColor: "#0DC1CE",
                  height: "55px",
                  borderRadius: "4px",
                  width: "43px",
                  marginRight: "-1%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <SearchIcon
                  position="middle"
                  style={{ color: "white", cursor: "pointer" }}
                  onClick={() => {
                    if (inputValue.length < 1) Swal.fire("Полето е празно.", "", "error");
                    else {
                      setNextPage(1);
                      const newFilters = { ...filters, [radioBtnValue]: inputValue };
                      dispatch(
                        setTicketFilters({
                          ...newFilters,
                        })
                      );
                      getTicketsForSystem({
                        system: slug,
                        pageNumber: 1,
                        filtered: true,
                        ...newFilters,
                      });
                      getCurrentSystem(slug);
                    }
                  }}
                />
              </InputAdornment>
            }
            placeholder="Търси по"
          />
          <Divider />
        </Paper>
      </div>
      <div className="field-container" style={{ display: "flex", flexDirection: "column" }}>
        <p className="field-title" style={{ marginBottom: "4%" }}>
          Търси по
        </p>
        <Paper
          sx={{
            p: "2px 4px",
            display: "flex",
            alignItems: "center",
            width: 150,
            height: 38,
            marginRight: "3%",
          }}
        >
          <FormControl
            sx={{
              m: 0,
              width: 170,
              height: 40,
              border: 0,
              mt: 0.5,
            }}
          >
            <Select
              variant="standard"
              sx={{}}
              disableUnderline={true}
              onChange={(e) => {
                e.stopPropagation();
              }}
              onClick={() => {
                if (!searchBy) {
                  setSearchBy(true);
                }
              }}
              renderValue={() => {
                return searchQuery;
              }}
              displayEmpty
              style={{ height: "35px", border: "0", outline: "none" }}
              open={searchBy}
            >
              <FormControl>
                <RadioGroup
                  value={radioBtnValue}
                  onChange={handleRadioBtnChangeSortBy}
                  style={{
                    padding: "1vw",
                    zIndex: "0",
                    boxShadow: "0px 5px 15px 0px #00347026",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <h3>Търсене по:</h3>
                    <b
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setSearchBy(false);
                      }}
                    >
                      X
                    </b>
                  </div>
                  <FormControlLabel
                    value="title"
                    control={
                      <Radio
                        sx={{
                          "&.Mui-checked": {
                            color: "#0DC1CE",
                          },
                        }}
                      />
                    }
                    label="Заглавие Тема"
                  />
                  <FormControlLabel
                    value="keyword"
                    control={
                      <Radio
                        sx={{
                          "&.Mui-checked": {
                            color: "#0DC1CE",
                          },
                        }}
                      />
                    }
                    label="Ключова дума"
                  />
                  <FormControlLabel
                    value="responsible"
                    control={
                      <Radio
                        sx={{
                          "&.Mui-checked": {
                            color: "#0DC1CE",
                          },
                        }}
                      />
                    }
                    label="Назначен"
                  />
                  <FormControlLabel
                    value="customer"
                    control={
                      <Radio
                        sx={{
                          "&.Mui-checked": {
                            color: "#0DC1CE",
                          },
                        }}
                      />
                    }
                    label="Външен потребител"
                  />
                </RadioGroup>
                <div
                  className="button-container"
                  style={{
                    height: "60px",
                    boxShadow: "0px 5px 15px 0px #00347026",
                    display: "flex",
                    alignItems: "center",
                    zIndex: "3",
                  }}
                >
                  <div
                    className="button"
                    style={{
                      height: "40px",
                      width: "95%",
                      marginLeft: "2.5%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "#0DC1CE",
                      color: "white",
                      cursor: "pointer",
                      borderRadius: "5px",
                    }}
                    onClick={() => {
                      setSearchBy(false);
                      dispatch(setTicketFilters({ ...filters, title: null, keyword: null, responsible: null, customer: null }));
                    }}
                  >
                    ЗАДАЙ
                  </div>
                </div>
              </FormControl>
            </Select>
          </FormControl>
        </Paper>
      </div>
    </div>
  );
};

export default LeftToolbar;
