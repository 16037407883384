/* eslint-disable */
import React, { useEffect, useState } from "react";
import styles from "./EditProfileForm.module.scss";
import {
  FormControl,
  Paper,
  TextField,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import { getAllSystems } from "../../actions/system";
import { editCurrentUser } from "../../actions/users";
import Swal from "sweetalert2";

function EditProfileForm({
  enableEdit = false,
  currentUser = {},
  allSystems,
  getAllSystems,
  saveChangesTrigger = false,
  editCurrentUser,
  currentSystem,
  values,
  setValues,
}) {
  const navigate = useNavigate();

  useEffect(() => {
    if (saveChangesTrigger === true) {
      const selectedSystems = Object.keys(values.systems).filter(
        (system) => values.systems[system] === true
      );
      const parsedSystems = allSystems.filter((system) =>
        selectedSystems.includes(system.constant)
      );
      const systemIdsArray = parsedSystems.map((system) => system._id);
      const payload = {
        _id: currentUser._id,
        name: values.name !== "" ? values.name : currentUser.name,
        email: values.email !== "" ? values.email : currentUser.email,
        password:
          values.password !== "" ? values.password : currentUser.password,
        role: values.role !== "" ? values.role : currentUser.role,
        receiveEmails: values.receiveEmails
          ? values.receiveEmails
          : currentUser.receiveEmails,
        systems: [...systemIdsArray],
      };
      editCurrentUser(payload);
      Swal.fire("Успешно редактирахте", "", "success").then(() => {
        navigate("/users");
      });
    }
  }, [saveChangesTrigger]);

  useEffect(() => {
    getAllSystems();
  }, [getAllSystems]);

  useEffect(() => {
    const systemNames = currentUser?.systems?.map((system) => system.name);
    setValues({
      name: currentUser?.name,
      email: currentUser?.email,
      password: "",
      role: currentUser?.role,
      receiveEmails: currentUser?.receiveEmails,
      systems: {
        amarant: systemNames
          ?.map((name) => (name.includes("Amarant") ? true : false))
          .includes(true)
          ? true
          : false,
        digidrive: systemNames
          ?.map((name) => (name.includes("DigiDrive") ? true : false))
          .includes(true)
          ? true
          : false,
        enRpi: systemNames
          ?.map((name) => (name.includes("EnRPI") ? true : false))
          .includes(true)
          ? true
          : false,
        fightScout: systemNames
          ?.map((name) => (name.includes("Fight Scout") ? true : false))
          .includes(true)
          ? true
          : false,
        hydroPro: systemNames
          ?.map((name) => (name.includes("HydroPro") ? true : false))
          .includes(true)
          ? true
          : false,
        immotech: systemNames
          ?.map((name) => (name.includes("Immotech") ? true : false))
          .includes(true)
          ? true
          : false,
        motoza: systemNames
          ?.map((name) => (name.includes("Motoza") ? true : false))
          .includes(true)
          ? true
          : false,
        ppa: systemNames
          ?.map((name) => (name.includes("PPA") ? true : false))
          .includes(true)
          ? true
          : false,
        soCourt: systemNames
          ?.map((name) => (name.includes("SoCourt") ? true : false))
          .includes(true)
          ? true
          : false,
        synergon: systemNames
          ?.map((name) => (name.includes("Synergon") ? true : false))
          .includes(true)
          ? true
          : false,
        wink: systemNames
          ?.map((name) => (name.includes("Wink") ? true : false))
          .includes(true)
          ? true
          : false,
        geowash: systemNames
          ?.map((name) => (name.includes("GeoWash") ? true : false))
          .includes(true)
          ? true
          : false,
        "energy-retc": systemNames
          ?.map((name) => (name.includes("RETC") ? true : false))
          .includes(true)
          ? true
          : false,
        "energy-op": systemNames
          ?.map((name) => (name.includes("Energy Op") ? true : false))
          .includes(true)
          ? true
          : false,
        "energy-zagora": systemNames
          ?.map((name) => (name.includes("Zagora Energy") ? true : false))
          .includes(true)
          ? true
          : false,
        "energy-proakt": systemNames
          ?.map((name) => (name.includes("Proakt") ? true : false))
          .includes(true)
          ? true
          : false,
        "energy-energama": systemNames
          ?.map((name) => (name.includes("Energama") ? true : false))
          .includes(true)
          ? true
          : false,
          "energy-ws": systemNames
          ?.map((name) => (name.includes("W&S Energy") ? true : false))
          .includes(true)
          ? true
          : false,
      },
    });
  }, [currentUser]);

  const handleChange = (event, checkboxIdentifier = false) => {
    if (checkboxIdentifier) {
      setValues({
        ...values,
        systems: {
          ...values.systems,
          [event.target.name]: event.target.checked,
        },
      });
    } else {
      setValues({
        ...values,
        [event.target.name]: event.target.value,
      });
    }
  };

  const handleCheckBoxClick = (index, name) => {
    if (values.systems[name]) {
      setValues({
        ...values,
        systems: {
          ...values.systems,
          [name]: false,
        },
      });
    } else {
      setValues({
        ...values,
        systems: {
          ...values.systems,
          [name]: true,
        },
      });
    }
  };

  useEffect(() => {
    values.systems[currentSystem?.constant] = true;
  }, [currentSystem]);


  return (
    <Paper elevation={3} className={styles["form-container"]}>
      <div className={styles["heading-container"]}>
        <h3>Данни за потребителя</h3>
      </div>
      <div className={styles["form-inputs"]}>
        <FormControl variant="outlined">
          <label htmlFor="name">Имена</label>
          <TextField
            disabled={!enableEdit}
            className={styles["textfield"]}
            size="small"
            id="name"
            type="text"
            name="name"
            value={values.name}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl variant="outlined">
          <label htmlFor="email">Имейл</label>
          <TextField
            disabled
            className={styles["textfield"]}
            id="email"
            size="small"
            name="email"
            type="email"
            value={values.email}
            onChange={handleChange}
          />
        </FormControl>
        {enableEdit && (
          <FormControl variant="outlined">
            <label htmlFor="password">Нова Парола</label>
            <TextField
              required
              className={styles["textfield"]}
              id="password"
              size="small"
              type="text"
              name="password"
              values={values.password}
              onChange={handleChange}
            />
          </FormControl>
        )}
        <FormControl
          variant="outlined"
          style={{ width: "25%", paddingLeft: "30px" }}
          disabled={!enableEdit}
        >
          <label htmlFor="password">Роля</label>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="female"
            name="radio-buttons-group"
            style={{ display: "flex", width: "100%", flexDirection: "row" }}
          >
            <FormControlLabel
              checked={values.role === "admin"}
              control={<Radio />}
              name={"role"}
              onChange={() => {
                setValues({ ...values, role: "admin" });
              }}
              label="Админ"
            />
            <FormControlLabel
              checked={values.role === "agent"}
              name={"role"}
              onChange={() => {
                setValues({ ...values, role: "agent" });
              }}
              control={<Radio />}
              label="Aгент"
            />
          </RadioGroup>
        </FormControl>
      </div>
      <FormControl component={"fieldset"} variant="standard">
        <FormLabel component="legend">Системи</FormLabel>
        {enableEdit === true ? (
          <FormGroup className={styles["form-checkboxes"]}>
            {allSystems.map((system, index) => (
              <FormControlLabel
                key={index}
                sx={{ ml: "5px" }}
                control={
                  <div
                    className={styles["outer-checkbox"]}
                    onClick={() => handleCheckBoxClick(index, system.constant)}
                    style={{
                      borderColor:
                        values.systems[system.constant] === true
                          ? "#0DC1CE"
                          : "#000",
                    }}
                  >
                    <div
                      className={`${styles["inner-checkbox"]} ${
                        values.systems[system.constant] === true &&
                        styles["show"]
                      } `}
                    />
                  </div>
                }
                label={
                  <p
                    onClick={() => handleCheckBoxClick(index, system.constant)}
                  >
                    {system.name}
                  </p>
                }
              />
            ))}
          </FormGroup>
        ) : (
          <b style={{ marginTop: "0.3%" }}>
            {currentUser?.systems?.map((system) => system.name).toString() +
              "\n"}
          </b>
        )}
      </FormControl>
      <FormControl
        variant="outlined"
        style={{ width: "100%" }}
        disabled={!enableEdit}
      >
        <label htmlFor="password">Получаване на имейли</label>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue="female"
          name="radio-buttons-group"
          style={{ display: "flex", width: "100%", flexDirection: "row" }}
        >
          <FormControlLabel
            checked={values?.receiveEmails?.newTicket}
            control={<Radio />}
            name={"receiveEmails"}
            onChange={() => {
              setValues({
                ...values,
                receiveEmails: {
                  newTicket: true,
                },
              });
            }}
            label="Винаги"
          />
          <FormControlLabel
            checked={!values?.receiveEmails?.newTicket}
            name={"receiveEmails"}
            onChange={() => {
              setValues({
                ...values,
                receiveEmails: {
                  newTicket: false,
                },
              });
            }}
            control={<Radio />}
            label="Ако е отговорник"
          />
        </RadioGroup>
      </FormControl>
    </Paper>
  );
}

const mapStateToProps = (state) => ({
  allSystems: state.systems.allSystems,
});

const mapDispatchToProps = (dispatch) => ({
  getAllSystems: () => dispatch(getAllSystems()),
  editCurrentUser: (payload) => dispatch(editCurrentUser(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditProfileForm);
