/* eslint-disable */
import { loginTypes } from "../actions/login";

const initialState = {
  token: null,
  user: {},
};
const login = (state = initialState, { type, payload }) => {
  switch (type) {
    case loginTypes.SET_LOGIN_FIELDS:
    case loginTypes.LOAD_USER_CREDENTIALS:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};

export default login;
