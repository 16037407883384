/* eslint-disable */
import React, { useEffect, useState } from "react";
import styles from "./Offer.module.scss";
import Inputs from "../../Inputs";
import { useForm, Controller } from "react-hook-form";
import { connect } from "react-redux";
import { listAgencies, getCurrentOffer, deleteOffer, editOffer } from "../../../actions/offer";
import { getCurrentTicket } from "../../../actions/tickets";
import moment from "moment";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { calculateMonetary } from "../../../utils/helperFunctions";

const customStyles = {
  dropdownIndicator: (base, state) => ({
    ...base,
    color: "#0083E5", // Custom colour
    transition: "all .2s ease",
    transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
  }),
  option: (provided, state) => ({
    ...provided,
    borderBottom: "1px dotted pink",
    color: state.isSelected ? "white" : "black",
    backgroundColor: state.isSelected ? "#0083E5" : "white",
    padding: 10,
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
    width: (window.innerWidth / 100) * 18,
    height: 40,
    display: "flex",
    border: "1px solid rgb(165, 164, 164)",
    borderRadius: "7px",
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition };
  },
};

const customStylesHigher = {
  dropdownIndicator: (base, state) => ({
    ...base,
    color: "#0083E5", // Custom colour
    transition: "all .2s ease",
    transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
  }),
  option: (provided, state) => ({
    ...provided,
    borderBottom: "1px dotted pink",
    color: state.isSelected ? "white" : "black",
    backgroundColor: state.isSelected ? "#0083E5" : "white",
    padding: 10,
  }),
  valueContainer: (base) => ({
    ...base,
    overflowY: "scroll",
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
    width: (window.innerWidth / 100) * 21,
    display: "flex",
    height: 70,
    border: "1px solid rgb(165, 164, 164)",
    borderRadius: "7px",
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition };
  },
};

const instalPairs = [
  {
    key: "installmentOneDate",
    label: "Първа вноска падеж",
    sumKey: "installmentOneSum",
  },
  {
    key: "installmentTwoDate",
    label: "Втора вноска падеж",
    sumKey: "installmentTwoSum",
  },
  {
    key: "installmentThreeDate",
    label: "Трета вноска падеж",
    sumKey: "installmentThreeSum",
  },
  {
    key: "installmentFourDate",
    label: "Четвърта вноска падеж",
    sumKey: "installmentFourSum",
  },
];

const liquidationOptions = ["Официален сервиз", "Доверен сервиз", "Експертна оценка"];
const viewOptions = ["Да", "Не"];
const additionalSecurityOptions = ["Да", "Не"];
const premiumInfoOptions = [
  "Всички суми са в лева с включен данък.",
  "Застрахователните премии са крайни, с включени отстъпки.",
  "В качеството си на 'Специален потребител', на мобилното приложение Амарант в офертата е калкулирана и допълнителна преференциална отстъпка за Вас",
  "Застрахователните премии са валидни до промяна в тарифата на застрахователя или рисковите обстоятелства",
];

const EditOffer = ({ listAgencies, agencies, getCurrentOffer, deleteOffer, editOffer, getCurrentTicket, currentTicket, currentOffer }) => {
  const [instCount, setInstCount] = useState(1);
  const ticketID = window.location.href.split("/")[4];
  const offerID = window.location.href.split("/")[5];
  const [fullDiscount, setFullDiscount] = useState({
    type: null,
    percent: null,
    discount: null,
    byCompany: null,
  });
  const navigate = useNavigate();

  const {
    register,
    control,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    startDate: "",
    installmentsCount: 1,
    installmentOneDate: "",
    installmentTwoDate: null,
    installmentThreeDate: null,
    installmentFourDate: null,
    insuranceCompanyName: "",
    insuranceCompanyId: "",
    insuranceSum: null,
    premium: null,
    installmentOneSum: null,
    installmentTwoSum: null,
    installmentThreeSum: null,
    installmentFourSum: null,
    documents: "",
    generalConditions: "",
    additionalInfo: "",
    liquidation: "",
    view: "",
    security: "",
    premiumInfo: [],
  });

  useEffect(() => {
    listAgencies();
    getCurrentOffer({
      id: offerID,
      onSuccess: (res) => {
        setFullDiscount({
          byCompany: res?.ticket?.promoData?.discountCampaign?.customDiscounts?.filter((disc) => res?.ticket?.promoData?.policyType === disc?.policyType)?.[0]?.customDiscountsPerInsuranceAgency,
          type: res?.ticket?.promoData?.policyType,
          percent: res?.ticket?.promoData?.discountCampaign?.discountInPercent,
          discount: Number(res?.discountInfo?.match(/\d+/g)[0]),
        });
        setValue("startDate", moment(res.startDate.split("T")[0])._d);
        setValue("installmentsCount", res?.installmentsCount);
        setInstCount(res?.installmentsCount);
        setValue("installmentOneDate", moment(res?.installmentOneDate.split("T")[0])._d);
        setValue("installmentTwoDate", res?.installmentTwoDate ? moment(res?.installmentTwoDate.split("T")[0])._d : null);
        setValue("installmentThreeDate", res?.installmentThreeDate ? moment(res?.installmentThreeDate.split("T")[0])._d : null);
        setValue("installmentFourDate", res?.installmentFourDate ? moment(res?.installmentFourDate.split("T")[0])._d : null);
        setValue("insuranceCompanyName", res?.insuranceCompanyName);
        setValue("insuranceCompanyId", res?.insuranceCompanyId);
        setValue("insuranceSum", res?.insuranceSum);
        setValue("premium", res?.premium);
        setValue("originalPremium", res?.originalPremium);
        setValue("installmentOneSum", res?.installmentOneSum);
        setValue("installmentTwoSum", res?.installmentTwoSum || null);
        setValue("installmentThreeSum", res?.installmentThreeSum || null);
        setValue("installmentFourSum", res?.installmentFourSum || null);
        setValue("documents", res?.documents[0]);
        setValue("generalConditions", res?.generalConditions[0]);
        setValue("liquidation", res?.liquidation);
        setValue("view", res?.view);
        setValue("security", res?.security);
        setValue(
          "premiumInfo",
          res?.premiumInfo?.map((info) => {
            return {
              label: info,
              value: info,
            };
          })
        );
        setValue("additionalInfo", res?.additionalInfo);
      },
    });
  }, []);

  const calculatePercentDiscount = (premium, discount) => {
    if (discount > 0 && premium) {
      const formula = premium - ((premium / 1.02) * discount) / 100;
      // const VAT = 0.02;
      // const discountMultiplier = 1 - discount / 100;
      // const discountableAmount = calculateMonetary(e - e * VAT);
      // const discountedPremium = calculateMonetary(discountableAmount * discountMultiplier);
      return calculateMonetary(formula);
    } else return premium;
  };

  const handleOfferWithoutDiscount = (e) => {
    const payload = {
      offerID: offerID,
      payload: {
        startDate: moment(e.startDate).format("YYYY-MM-DD"),
        installmentsCount: e.installmentsCount,
        installmentOneDate: moment(e.installmentOneDate).format("YYYY-MM-DD"),
        installmentTwoDate: e.installmentTwoDate ? moment(e.installmentTwoDate).format("YYYY-MM-DD") : undefined,
        installmentThreeDate: e.installmentThreeDate ? moment(e.installmentThreeDate).format("YYYY-MM-DD") : undefined,
        installmentFourDate: e.installmentFourDate ? moment(e.installmentFourDate).format("YYYY-MM-DD") : undefined,
        insuranceCompanyName: e.insuranceCompanyName,
        insuranceCompanyId: e.insuranceCompanyId,
        insuranceSum: Number(e.insuranceSum),
        premium: Number(e.premium),
        installmentOneSum: Number(e.installmentOneSum),
        installmentTwoSum: Number(e.installmentTwoSum),
        installmentThreeSum: Number(e.installmentThreeSum),
        installmentFourSum: Number(e.installmentFourSum),
        documents: [e.documents],
        generalConditions: [e.generalConditions],
        additionalInfo: e.additionalInfo,
        liquidation: e?.liquidation,
        view: e?.view,
        security: e?.security,
        premiumInfo: e?.premiumInfo.map((info) => info.value),
        onSuccess: (res) => {
          toast.success("Offer edited successfully");
          navigate(-1);
        },
      },
    };
    // console.log(payload);
    editOffer(payload);
  };

  const handleOfferWithDiscount = (e) => {
    const discountedAmount = fullDiscount.percent ? calculatePercentDiscount(e?.originalPremium, fullDiscount.discount) : e?.originalPremium - fullDiscount.discount;
    const payload = {
      offerID: offerID,
      payload: {
        startDate: moment(e.startDate).format("YYYY-MM-DD"),
        installmentsCount: e.installmentsCount,
        installmentOneDate: moment(e.installmentOneDate).format("YYYY-MM-DD"),
        installmentTwoDate: e.installmentTwoDate ? moment(e.installmentTwoDate).format("YYYY-MM-DD") : undefined,
        installmentThreeDate: e.installmentThreeDate ? moment(e.installmentThreeDate).format("YYYY-MM-DD") : undefined,
        installmentFourDate: e.installmentFourDate ? moment(e.installmentFourDate).format("YYYY-MM-DD") : undefined,
        insuranceCompanyName: e.insuranceCompanyName,
        insuranceCompanyId: e.insuranceCompanyId,
        insuranceSum: Number(e.insuranceSum),
        originalPremium: Number(e.originalPremium),
        installmentOneSum: +(discountedAmount / instCount).toFixed(2),
        installmentTwoSum: instCount >= 2 ? +(discountedAmount / instCount).toFixed(2) : Number(e.installmentTwoSum),
        installmentThreeSum: instCount >= 3 ? +(discountedAmount / instCount).toFixed(2) : Number(e.installmentThreeSum),
        installmentFourSum: instCount === 4 ? +(discountedAmount / instCount).toFixed(2) : Number(e.installmentFourSum),
        documents: [e.documents],
        generalConditions: [e.generalConditions],
        additionalInfo: e.additionalInfo,
        liquidation: e?.liquidation,
        view: e?.view,
        security: e?.security,
        premium: Number(discountedAmount),
        discountInfo: fullDiscount?.percent ? `-${fullDiscount.discount}%` : `-${fullDiscount.discount}лв.`,
        premiumInfo: e?.premiumInfo.map((info) => info.value),
        onSuccess: (res) => {
          toast.success("Offer edited successfully");
          navigate(-1);
        },
      },
    };
    const installmentsSum = payload.payload?.installmentOneSum + payload.payload?.installmentTwoSum + payload.payload?.installmentThreeSum + payload.payload?.installmentFourSum;
    if (installmentsSum < payload.payload.premium) payload.payload.installmentOneSum += payload.payload.premium - installmentsSum;

    // console.log(payload);
    editOffer(payload);
  };

  return (
    <div className={styles["amarant-container"]}>
      <div className={styles["upper-info-container"]}>
        <h2 className={styles["title"]}>Редактиране на оферта</h2>
        <div
          style={{
            display: "flex",
            width: "15%",
          }}
        >
          <Inputs.Button
            text="Изтрий"
            className="delete"
            style={{ marginRight: "3%", width: "130px" }}
            onClick={handleSubmit((e) => {
              deleteOffer({
                id: offerID,
                onSuccess: () => {
                  toast.success("Offer deleted successfully!");
                  navigate(-1);
                },
              });
            })}
          />
          <Inputs.Button
            text="Запази"
            className="green"
            style={{ marginRight: "1%", width: "130px" }}
            onClick={handleSubmit((e) => {
              if (!currentOffer?.originalPremium) handleOfferWithoutDiscount(e);
              else handleOfferWithDiscount(e);
            })}
          />
        </div>
      </div>
      <div className={styles["middle-info-container"]}>
        <h2 className={styles["title"]}>Основни параметри</h2>
        <div className={styles["main-parameters-container"]}>
          <Controller
            control={control}
            name={"startDate"}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <Inputs.DatePicker
                label={"Начална дата"}
                compulsory
                custom
                style={{ width: "12%", height: "100%" }}
                value={value}
                onChange={(e) => {
                  setValue("startDate", e);
                }}
                className={error && "failed"}
              />
            )}
            rules={{ required: true }}
          />
          <Controller
            control={control}
            name={"installmentsCount"}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <div style={{ margin: "0% 3%" }}>
                <label>Брой вноски</label>
                <div style={{ display: "flex" }}>
                  <Inputs.Button
                    text={1}
                    selected={value === 1}
                    style={{ marginRight: "3%" }}
                    onClick={() => {
                      setValue("installmentsCount", 1);
                      setInstCount(1);
                    }}
                  />
                  <Inputs.Button
                    text={2}
                    selected={value === 2}
                    style={{ marginRight: "3%" }}
                    onClick={() => {
                      setValue("installmentsCount", 2);
                      setValue(instalPairs[2].key, undefined);
                      setValue(instalPairs[2].sumKey, null);
                      setValue(instalPairs[3].key, undefined);
                      setValue(instalPairs[3].sumKey, null);
                      setInstCount(2);
                    }}
                  />
                  <Inputs.Button
                    text={3}
                    selected={value === 3}
                    style={{ marginRight: "3%" }}
                    onClick={() => {
                      setValue("installmentsCount", 3);
                      setValue(instalPairs[3].key, undefined);
                      setValue(instalPairs[3].sumKey, null);
                      setInstCount(3);
                    }}
                  />
                  <Inputs.Button
                    text={4}
                    selected={value === 4}
                    style={{ marginRight: "3%" }}
                    onClick={() => {
                      setValue("installmentsCount", 4);
                      setInstCount(4);
                    }}
                  />
                </div>
              </div>
            )}
            rules={{ required: true }}
          />
          {Array(instCount)
            .fill("")
            .map((el, i) => {
              return (
                <Controller
                  control={control}
                  key={i}
                  name={instalPairs[i].key}
                  render={({ field: { value, onChange }, fieldState: { error } }) => (
                    <Inputs.DatePicker
                      label={instalPairs[i].label}
                      compulsory
                      custom
                      style={{ width: "12%", height: "100%", margin: "0% 2%" }}
                      value={value}
                      onChange={(e) => {
                        setValue(instalPairs[i].key, e);
                      }}
                      className={error && "failed"}
                    />
                  )}
                  rules={{ required: true }}
                />
              );
            })}
          <div className="input-container" style={{ width: "5%" }}>
            <label style={{ display: "flex", width: "100%", fontSize: "0.8rem", marginBottom: "5px" }}>Отстъпка</label>
            <p style={{ marginTop: "10px", color: currentTicket?.promoData && "#01D088" }}>{currentTicket?.promoData ? "Да" : "Не"}</p>
          </div>
        </div>
      </div>
      <div className={styles["lower-info-container"]}>
        <div style={{ display: "flex", width: "100%", alignItems: "center" }}>
          <h2 className={styles["title"]}>Оферта 1</h2>
        </div>
        <div>
          <div className={styles["main-parameters-container"]}>
            <Controller
              control={control}
              name={`insuranceCompanyName`}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <Inputs.Dropdown
                  label={"Застрахователна компания"}
                  optionsArray={agencies?.map((agency) => {
                    return {
                      label: agency?.name,
                      value: agency?._id,
                    };
                  })}
                  compulsory
                  edit={true}
                  value={value}
                  customStyles={customStyles}
                  style={{ width: "30%", height: "100%" }}
                  single
                  className={error && "failed"}
                  onChange={(e) => {
                    setValue(`insuranceCompanyName`, e.label);
                    setValue(`insuranceCompanyId`, e.value);
                    if (fullDiscount.byCompany)
                      setFullDiscount((prevState) => {
                        return {
                          ...prevState,
                          discount: prevState.byCompany?.filter((comp) => comp?.insuranceAgency === e.value)?.[0]?.discount,
                        };
                      });
                  }}
                />
              )}
              rules={{ required: true }}
            />

            <Inputs.TextInput label={"Застрахователна сума"} suffix={"лв."} style={{ width: "12%", marginRight: "1%" }} className={errors?.insuranceSum ? "failed" : ""} {...register(`insuranceSum`)} />
            {!currentOffer?.originalPremium ? (
              <Controller
                control={control}
                name={`premium`}
                render={({ field: { value, onChange }, fieldState: { error } }) => <Inputs.TextInput label={"Премия"} compulsory suffix={"лв."} value={value} type="number" style={{ width: "9%", marginRight: "1%" }} className={errors.offers?.[i]?.premium ? "failed" : ""} onChange={(e) => setValue(`premium`, Number(e.target.value))} />}
                rules={{ required: true }}
              />
            ) : (
              <>
                <Controller
                  control={control}
                  name={`originalPremium`}
                  render={({ field: { value, onChange }, fieldState: { error } }) => (
                    <>
                      <Inputs.TextInput label={"Премия"} compulsory suffix={"лв."} type="number" value={value} style={{ width: "9%", marginRight: "1%" }} className={errors.offers?.[i]?.originalPremium ? "failed" : ""} onChange={(e) => setValue(`originalPremium`, Number(e.target.value))} />
                      <Inputs.TextInput label={"Премия с отстъпка"} suffix={"лв."} value={fullDiscount.percent ? calculatePercentDiscount(value, fullDiscount.discount)?.toFixed(2) : (value - fullDiscount.discount).toFixed(2)} disabled style={{ width: "9%", marginRight: "1%" }} />
                      {Array(instCount)
                        .fill("")
                        .map((el, j) => (
                          <Inputs.TextInput label={`Вноска ${j + 1}`} compulsory disabled={true} key={j + 1} value={fullDiscount.percent ? +(calculatePercentDiscount(value, fullDiscount.discount) / instCount).toFixed(2) : +((value - fullDiscount.discount) / instCount).toFixed(2)} suffix={"лв."} style={{ width: "9%", marginRight: "1%" }} />
                        ))}
                    </>
                  )}
                  rules={{ required: true }}
                />
              </>
            )}

            {!currentOffer?.originalPremium &&
              Array(instCount)
                .fill("")
                .map((el, j) => {
                  return (
                    <Inputs.TextInput
                      label={`Вноска ${j + 1}`}
                      compulsory
                      key={j + 1}
                      suffix={"лв."}
                      style={{ width: "9%", marginRight: "1%" }}
                      className={errors.offers?.[i]?.[instalPairs[j].sumKey] ? "failed" : ""}
                      {...register(`${instalPairs[j].sumKey}`, {
                        required: true,
                      })}
                    />
                  );
                })}
          </div>
          <div className={styles["main-parameters-container-files"]}>
            <Controller
              control={control}
              name={`documents`}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <>
                  <Inputs.ImageInput
                    label={"Информационен документ"}
                    compulsory
                    style={{ width: "420px", margin: "10px 0px" }}
                    value={value}
                    specialDir={true}
                    info={{
                      keyword: "offer",
                      id: ticketID,
                      date: moment().format("DD.MM.YYYY"),
                    }}
                    key={"documents"}
                    onChange={(e) => setValue(`documents`, e)}
                    setValue={setValue}
                    className={error && "failed"}
                  />
                </>
              )}
              rules={{ required: true }}
            />
            <Controller
              control={control}
              name={`generalConditions`}
              render={({ field: { value, onChange }, fieldState: { error } }) => <Inputs.ImageInput label={"Общи условия"} compulsory style={{ width: "420px", margin: "10px 0px" }} value={value} onChange={(e) => setValue(`generalConditions`, e)} setValue={setValue} className={error && "failed"} />}
              rules={{ required: true }}
            />
          </div>
          <div className={styles["main-parameters-container"]}>
            <Controller
              control={control}
              name={`liquidation`}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <Inputs.Dropdown
                  label={"Тип ликвидация"}
                  optionsArray={liquidationOptions?.map((liq) => {
                    return {
                      label: liq,
                      value: liq,
                    };
                  })}
                  customStyles={customStyles}
                  style={{ width: "23%", height: "100%" }}
                  single
                  edit
                  value={value}
                  className={error && "failed"}
                  onChange={(e) => {
                    setValue(`liquidation`, e.value);
                  }}
                />
              )}
            />
            <Controller
              control={control}
              name={`view`}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <Inputs.Dropdown
                  label={"Изискване за оглед"}
                  optionsArray={viewOptions?.map((view) => {
                    return {
                      label: view,
                      value: view,
                    };
                  })}
                  customStyles={customStyles}
                  style={{ width: "23%", height: "100%" }}
                  single
                  edit
                  value={value}
                  onChange={(e) => {
                    setValue(`view`, e.value);
                  }}
                />
              )}
            />
            <Controller
              control={control}
              name={`security`}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <Inputs.Dropdown
                  label={"Mонтиране на допълнително средство за защита"}
                  optionsArray={additionalSecurityOptions?.map((opt) => {
                    return {
                      label: opt,
                      value: opt,
                    };
                  })}
                  customStyles={customStyles}
                  style={{ width: "23%", height: "100%" }}
                  single
                  edit
                  value={value}
                  onChange={(e) => {
                    setValue(`security`, e.value);
                  }}
                />
              )}
            />
            <Controller
              control={control}
              name={`premiumInfo`}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <Inputs.Dropdown
                  label={"Премия"}
                  optionsArray={premiumInfoOptions?.map((opt) => {
                    return {
                      label: opt,
                      value: opt,
                    };
                  })}
                  customStyles={customStylesHigher}
                  style={{ width: "23%", height: "100%" }}
                  value={value}
                  edit
                  onChange={(e) => {
                    setValue(`premiumInfo`, e);
                  }}
                />
              )}
            />
          </div>
          <Inputs.TextInput
            label={"Допълнителна информация за офертата"}
            compulsory
            style={{ width: "100%" }}
            className={errors.additionalInfo ? "failed" : ""}
            {...register(`additionalInfo`, {
              required: true,
            })}
          />
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  agencies: state.offer.agencies,
  currentTicket: state.tickets.currentTicket,
  currentOffer: state.offer.currentOffer,
});
const mapDispatchToProps = (dispatch) => ({
  listAgencies: (payload) => dispatch(listAgencies(payload)),
  getCurrentOffer: (payload) => dispatch(getCurrentOffer(payload)),
  deleteOffer: (payload) => dispatch(deleteOffer(payload)),
  editOffer: (payload) => dispatch(editOffer(payload)),
  getCurrentTicket: (payload) => dispatch(getCurrentTicket(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(EditOffer);
