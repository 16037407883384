/* eslint-disable */
import { ofType, ActionsObservable } from "redux-observable";
import { switchMap, catchError } from "rxjs/operators";
import { ajax } from "rxjs/ajax";
import { deauthenticate, loginTypes } from "../actions/login";
import { Headers } from "../utils/Headers";
import { User } from "../utils/Authenticate";
import { API_URL } from "../config/settings";
import { toast } from "react-toastify";

export const loginValidateTokenEpic = (action$) => {
  return action$.pipe(
    ofType(loginTypes.LOGIN_VALIDATE_TOKEN),
    switchMap(() => {
      return ajax({
        url: `${API_URL}/user/check`,
        method: "POST",
        headers: Headers.auth(),
        body: JSON.stringify({ email: "dummyEmailCheck@abc.bg" }),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            if (err.status === 401) {
              toast.error(err.response?.message);
              User.removeUserCredentials();
              window.location.href = "/login";
              obs.next(deauthenticate());
              obs.complete();
            }
          });
        })
      );
    })
  );
};

export const loginRequestEpic = (action$) => {
  return action$.pipe(
    ofType(loginTypes.LOGIN_SIGN_IN),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API_URL}/user/login`,
        method: "POST",
        headers: Headers.get(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            if (payload.onSuccess) {
              payload.onSuccess(response);
            }
            const credentials = {
              token: response.token,
              user: response.user,
            };
            toast.success("Successfully logged in!");
            User.storeUserCredentials(credentials);
            window.location.href = "/";
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            toast.error(err.response?.message);
            obs.complete();
          });
        })
      );
    })
  );
};

export const logoutRequestEpic = (action$) => {
  return action$.pipe(
    ofType(loginTypes.DEAUTHENTICATE),
    switchMap(() => {
      return ajax({
        url: `${API_URL}/user/logout`,
        method: "GET",
        headers: Headers.auth(),
      }).pipe(
        switchMap(({ response }) => {
          User.removeUserCredentials();
          window.location.href = "/login";
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            if (err.status === 401) {
            } else {
            }
          });
        })
      );
    })
  );
};
