/* eslint-disable */
import { omit } from "lodash";
import { systemTypes } from "../actions/system";

const initialState = {
  allSystems: [],
  currentSystem: {},
  systemsList: [],
  systemsListInfo: {},
};

const handleSystems = (state, payload) => {
  let newState = [...state];
  if (payload.page === 1 || payload.hasPrevPage === false) {
    newState = [...payload.docs];
  } else {
    newState = [...state, ...payload.docs];
  }
  return newState;
};

const systems = (state = initialState, { type, payload }) => {
  switch (type) {
    case systemTypes.GET_ALL_SYSTEMS_SUCCESS:
      return { ...state, allSystems: payload };
    case systemTypes.LIST_SYSTEMS_SUCCESS:
      return {
        ...state,
        systemsList: handleSystems(state.systemsList, payload),
        systemsListInfo: omit(payload, "docs"),
      };
    case systemTypes.GET_CURRENT_SYSTEM_SUCCESS:
      return { ...state, currentSystem: payload };
    default:
      return state;
  }
};

export default systems;
