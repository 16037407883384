/* eslint-disable */
import { ofType, ActionsObservable } from "redux-observable";
import { ajax } from "rxjs/ajax";
import { switchMap, catchError } from "rxjs/operators";
import { synergonFieldsTypes } from "../actions";
import { Headers } from "../utils/Headers";

export const getSynergonUserInfo = (action$) => {
  return action$.pipe(
    ofType(synergonFieldsTypes.GET_USER_INFO),
    switchMap(({ payload }) => {
      return ajax({
        url: `${payload.url}/user/getSystemUser`,
        method: "POST",
        headers: Headers.get(),
        body: JSON.stringify(payload.payload),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            payload.onSuccess(response);
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            console.log(err);
          });
        })
      );
    })
  );
};
export const getHydroProUserInfoEpic = (action$) => {
  return action$.pipe(
    ofType(synergonFieldsTypes.GET_USER_INFO_HYDROPRO),
    switchMap(({ payload }) => {
      return ajax({
        url: `${payload.url}/customerSupport/getCustomerSupportId`,
        method: "POST",
        headers: Headers.basicAuth(),
        body: JSON.stringify(payload.payload),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            payload.onSuccess(response.payload);
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            console.log(err);
          });
        })
      );
    })
  );
};