/* eslint-disable */
import React, { useState } from "react";
import { Divider, Paper } from "@mui/material";
import styles from "./HelpCenterSideTools.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import OpenedSideTools from "./OpenedSideTools";
import CompletedSideTools from "./CompletedSideTools";
import { useDispatch, useSelector } from "react-redux";
import { setTicketFilters } from "../../../actions/tickets";

function TopicSelector() {
  return (
    <div style={{ display: "flex", alignItems: "center", width: "30%", justifyContent: "flex-end" }}>
      <div className={styles["selector"]} />
    </div>
  );
}

export default function HelpCenterSideTools({ setQuery, currentSystem, setIsCompletedQuery, setNextPage, IsCompletedQuery, getTicketsForSystem, nextPage, query, payload, filteredInfo, setSideFiltersActivated, selectedFilter, setSelectedFilter }) {
  const dispatch = useDispatch();
  const filters = useSelector(({ tickets }) => tickets?.filters) || {};
  let { slug } = useParams();
  const [nextt, setNextt] = useState(1);

  return (
    <Paper className={styles["side-tools-container"]} elevation={3}>
      <OpenedSideTools
        getTicketsForSystem={getTicketsForSystem}
        setNextPage={setNextPage}
        setNextt={setNextt}
        setSelectedFilter={setSelectedFilter}
        setIsCompletedQuery={setIsCompletedQuery}
        currentSystem={currentSystem}
        selectedFilter={selectedFilter}
        slug={slug}
        nextt={nextt}
        TopicSelector={TopicSelector}
        setQuery={setQuery}
        query={query}
        payload={payload}
        filteredInfo={filteredInfo}
        setSideFiltersActivated={setSideFiltersActivated}
      />
      <CompletedSideTools
        getTicketsForSystem={getTicketsForSystem}
        setNextPage={setNextPage}
        setNextt={setNextt}
        setSelectedFilter={setSelectedFilter}
        setIsCompletedQuery={setIsCompletedQuery}
        currentSystem={currentSystem}
        selectedFilter={selectedFilter}
        slug={slug}
        nextt={nextt}
        TopicSelector={TopicSelector}
        setQuery={setQuery}
        query={query}
        payload={payload}
        filteredInfo={filteredInfo}
        setSideFiltersActivated={setSideFiltersActivated}
      />
      <Paper elevation={2}>
        <Paper
          className={styles["created-topics-container"]}
          elevation={2}
          onClick={() => {
            const newFilters = { ...filters, archivedOnly: true };
            dispatch(setTicketFilters(newFilters));
            getTicketsForSystem({
              system: slug,
              pageNumber: 1,
              filtered: true,
              ...newFilters,
            });
            setNextt(nextt + 1);
            setNextPage(0);
            setSelectedFilter({ archived: true });
            setIsCompletedQuery(false);
            setSideFiltersActivated(true);
          }}
        >
          <div className={`${styles["archived-topics-icon"]} ${styles.icon}`} style={{ width: "40px", height: "40px" }}></div>
          <h4 className={styles["users-number"]}>{filteredInfo.current.totalTickets > 0 ? (filteredInfo.current.archived <= 0 ? "-" : filteredInfo.current.archived) : currentSystem?.archived}</h4>
          <div
            style={{
              display: "flex",
              width: "90%",
              justifyContent: "space-between",
            }}
          >
            <p className={styles["paper-header"]}>АРХИВИРАНИ</p>
            {selectedFilter.archived && <TopicSelector />}
          </div>
        </Paper>
      </Paper>
      <Paper elevation={2}>
        <Paper
          className={styles["created-topics-container"]}
          elevation={2}
          onClick={() => {
            const newFilters = { ...filters, deletedOnly: true };
            dispatch(setTicketFilters(newFilters));
            getTicketsForSystem({
              system: slug,
              pageNumber: 1,
              filtered: true,
              ...newFilters,
            });
            setNextt(nextt + 1);
            setNextPage(0);
            setSelectedFilter({ deleted: true });
            setIsCompletedQuery(false);
            setSideFiltersActivated(true);
          }}
        >
          <div className={`${styles["deleted-topics-icon"]} ${styles.icon}`} style={{ width: "40px", height: "40px" }}></div>
          <h4 className={styles["users-number"]}>{filteredInfo.current.totalTickets > 0 ? "-" : currentSystem?.deleted}</h4>
          <div
            style={{
              display: "flex",
              width: "90%",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <p className={styles["paper-header"]}>ИЗТРИТИ</p>
            {selectedFilter.deleted && <TopicSelector />}
          </div>
        </Paper>
      </Paper>
      <p style={{ padding: "10px" }}>
        Общ брой теми в системата: <b>{filteredInfo.current.totalTickets > 0 ? filteredInfo.current.totalTickets : currentSystem?.totalTickets}</b>
      </p>
    </Paper>
  );
}
