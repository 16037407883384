import { supportSocketConnect } from "../actions/support";
import { store } from "../config/stores";
import { User } from "./Authenticate";

export const handleValidateTokenAndSocket = (credentials) => {
  User.loadLocalStorageItems();
  if (credentials && store.getState().support.connected === false) {
    store.dispatch(supportSocketConnect());
  }
};
