/* eslint-disable */
import React from "react";

export default function Highlight({ children: text = "", tags = [] }) {
  if (!tags?.length) return text;
  const matches = [...text.matchAll(new RegExp(tags.join("|"), "ig"))];
  const startText = text.slice(0, matches[0]?.index);

  return (
    <span>
      {startText}
      {matches.map((match, index) => {
        const startIndex = match.index;
        const currentText = match[0];
        const endIndex = startIndex + currentText.length;
        const nextIndex = matches[index + 1]?.index;
        const untilNextText = text.slice(endIndex, nextIndex);
        return (
          <span key={index}>
            <mark style={{ background: "#0DC1CE" }}>{currentText}</mark>
            {untilNextText}
          </span>
        );
      })}
    </span>
  );
}
