/* eslint-disable */
import { toast } from "react-toastify";
import { ofType, ActionsObservable } from "redux-observable";
import { ajax } from "rxjs/ajax";
import { switchMap, catchError } from "rxjs/operators";
import { offerTypes } from "../actions/offer";
import { AMARANT2_API_URL, API_URL } from "../config/settings";
import { Headers } from "../utils/Headers";

export const getAgenciesEpic = (action$) => {
  return action$.pipe(
    ofType(offerTypes.LIST_AGENCIES),
    switchMap(({ payload }) => {
      return ajax({
        url: `${AMARANT2_API_URL}/insuranceAgency`,
        method: "GET",
        headers: Headers.get(),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            if (payload?.onSuccess) payload?.onSuccess(response.payload);
            obs.next({
              type: offerTypes.LIST_AGENCIES_SUCCESS,
              payload: response.payload,
            });
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            if (err.status === 401) {
              window.location.href = "/login";
            } else {
            }
          });
        })
      );
    })
  );
};
export const createOfferEpic = (action$) => {
  return action$.pipe(
    ofType(offerTypes.CREATE_OFFER),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API_URL}/offer/create`,
        method: "POST",
        headers: Headers.auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            console.log(response);
            payload.onSuccess();
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            if (err.status === 401) {
              window.location.href = "/login";
            } else {
              toast.error(err?.response?.message);
            }
          });
        })
      );
    })
  );
};

export const getCurrentOfferEpic = (action$) => {
  return action$.pipe(
    ofType(offerTypes.GET_CURRENT_OFFER),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API_URL}/offer/${payload.id}`,
        method: "GET",
        headers: Headers.auth(),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: offerTypes.GET_CURRENT_OFFER_SUCCESS,
              payload: response.payload,
            });
            payload.onSuccess(response.payload);
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            if (err.status === 401) {
              window.location.href = "/login";
            } else {
              toast.error(err?.response?.message);
            }
          });
        })
      );
    })
  );
};

export const editOfferEpic = (action$) => {
  return action$.pipe(
    ofType(offerTypes.EDIT_OFFER),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API_URL}/offer/${payload.offerID}`,
        method: "PUT",
        headers: Headers.auth(),
        body: JSON.stringify(payload.payload),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            payload.payload.onSuccess();
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            if (err.status === 401) {
              window.location.href = "/login";
            } else {
              toast.error(err?.response?.message);
            }
          });
        })
      );
    })
  );
};

export const deleteOfferEpic = (action$) => {
  return action$.pipe(
    ofType(offerTypes.DELETE_OFFER),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API_URL}/offer/${payload.id}`,
        method: "DELETE",
        headers: Headers.auth(),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            payload.onSuccess();
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            if (err.status === 401) {
              window.location.href = "/login";
            } else {
              toast.error(err?.response?.message);
            }
          });
        })
      );
    })
  );
};

export const addPolicyToUserEpic = (action$) => {
  return action$.pipe(
    ofType(offerTypes.ADD_POLICY_TO_USER),
    switchMap(({ payload }) => {
      return ajax({
        url: `${AMARANT2_API_URL}/customerSupport/addPolicy`,
        method: "POST",
        headers: Headers.basicAuth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            console.log(response);
            payload.onSuccess(response.payload);
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            if (err.status === 401) {
              window.location.href = "/login";
            } else {
              toast.error(err?.response?.message);
            }
          });
        })
      );
    })
  );
};

export const takeDiscountForOfferEpic = (action$) => {
  return action$.pipe(
    ofType(offerTypes.ADD_POLICY_TO_USER),
    switchMap(({ payload }) => {
      return ajax({
        url: `${AMARANT2_API_URL}/customerSupport/addPolicy`,
        method: "POST",
        headers: Headers.auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            if (payload?.onSuccess) payload.onSuccess(response.payload);

            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            if (err.status === 401) {
              window.location.href = "/login";
            } else {
              toast.error(err?.response?.message);
            }
          });
        })
      );
    })
  );
};
