export const supportTypes = {
  SUPPORT_SOCKET_CONNECT: "socket/SUPPORT_SOCKET_CONNECT",
  SUPPORT_SOCKET_CONNECTED: "socket/SUPPORT_SOCKET_CONNECTED",
  SUPPORT_SOCKET_EMIT_EVENT: "socket/SUPPORT_SOCKET_EMIT_EVENT",
  SUPPORT_SOCKET_DISCONNECT: "socket/SUPPORT_SOCKET_DISCONNECT",

  SUPPORT_INIT: "socket/init",
  SUPPORT_JOIN_TICKET: "socket/joinTicket",
  SUPPORT_GET_MESSAGES: "socket/getMessages",
  SUPPORT_TICKET_MESSAGES: "socket/ticketMessages",
  SUPPORT_NEW_MESSAGE: "socket/message",
  SUPPORT_LEAVE_ALL: "socket/leaveAllTickets",
  SUPPORT_MY_TICKETS: "socket/myTickets",
  SUPPORT_MARK_ALL_READ: "socket/markAllRead",
  SUPPORT_VOTE_TICKET: "socket/voteTicket",
  SUPPORT_SCROLL_TO_MESSAGE: "socket/scrollToMessage",
  CLEAR_SUPPORT_MESSAGE: "CLEAR_SUPPORT_MESSAGE",
};

export const supportSocketConnect = () => ({
  type: supportTypes.SUPPORT_SOCKET_CONNECT,
});

export const supportSocketConnected = () => ({
  type: supportTypes.SUPPORT_SOCKET_CONNECTED,
});

export const supportSocketEmit = (payload) => ({
  type: supportTypes.SUPPORT_SOCKET_EMIT_EVENT,
  payload,
});

export const clearSupportMessage = () => ({
  type: supportTypes.CLEAR_SUPPORT_MESSAGE,
});
export const supportSocketDisconnect = () => ({
  type: supportTypes.SUPPORT_SOCKET_DISCONNECT,
});
