/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { forwardRef } from "react";
import "./styles.scss";
import Select, { components } from "react-select";
import { add } from "lodash";

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <div
        className="close-icon"
        style={{
          backgroundImage: `url(/chat-actions-icons/arrow-down.svg)`,
          width: "20px",
          height: "20px",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      />
    </components.DropdownIndicator>
  );
};

const Dropdown = forwardRef(
  ({ optionsArray, disabled, onChange, className, label, compulsory, style, single, customStyles, value, edit, addPolicy, pair }, ref) => {
    return (
      <div className="input-container" style={style ? style : { width: "100%", marginLeft: "-1.3%" }}>
        {label && (
          <label
            style={{
              display: "flex",
              width: "100%",
              fontSize: "0.8rem",
              marginBottom: "5px",
            }}
          >
            {label} {compulsory && <span style={{ paddingLeft: "5px", color: "black" }}>*</span>}
          </label>
        )}
        <Select
          options={optionsArray?.map((opt) => {
            return { label: opt.label, value: opt.value };
          })}
          className={className}
          ref={ref}
          isDisabled={disabled}
          value={
            edit
              ? !single
                ? value
                : {
                    label: value,
                    value: value,
                  }
              : addPolicy && pair
          }
          isMulti={!single}
          styles={customStyles}
          components={{
            IndicatorSeparator: () => null,
            DropdownIndicator,
          }}
          onChange={onChange}
        />
      </div>
    );
  }
);

export default Dropdown;
