/* eslint-disable */
import { templateTypes } from "../actions/template";

const initialState = {
  groups: [],
  templates: [],
};

const templates = (state = initialState, { type, payload }) => {
  switch (type) {
    case templateTypes.GROUPS_LIST_SUCCESS:
      return { ...state, groups: payload };
    case templateTypes.TEMPLATE_LIST_SUCCESS:
      return { ...state, templates: payload };
    default:
      return state;
  }
};

export default templates;
