/* eslint-disable */
import React, { useState } from "react";
import {
  Paper,
  TextField,
  FormControl,
  Button,
  InputAdornment,
  Stack,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import styles from "./EditPasswordModal.module.scss";
import { useFormik } from "formik";
import * as Yup from "yup";
import { changeUserPassword } from "../../../actions/users";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

const validationSchema = Yup.object({
  oldPassword: Yup.string("Въведи старата парола").required(
    "Това поле е задължително."
  ),
  //   .email("Въведи валиден имейл"),
  newPassword: Yup.string("Въведи новата парола").required(
    "Това поле е задължително."
  ),
  // .min(6, "The minimum length should be 6 characters.")
  //   .matches(/^[aA-zZ\s\d]+$/, "Special characters are not allowed."),
});

function EditPasswordModal({
  handleClose,
  changePasswordAction,
  currentUser,
  changeUserPassword,
}) {
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
    },
    validationSchema,
    onSubmit: () => {
      changeUserPassword(formik.values);
    },
  });
  const [showOldPassword, setShowOldPassword] = useState(false);

  const handleClickShowOldPassword = () => {
    setShowOldPassword(!showOldPassword);
  };

  const [showNewPassword, setShowNewPassword] = useState(false);

  const handleClickShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  if (changePasswordAction.success === true) {
    handleClose();
  }
  return (
    <Paper
      style={{
        display: "flex",
        flexDirection: "column",
        width: "450px",
        height: "400px",
        padding: "25px",
      }}
    >
      <div className={styles["heading-container"]}>
        <h4>РЕДАКЦИЯ НА ПАРОЛА</h4>
        <CloseIcon onClick={handleClose} />
      </div>
      <form onSubmit={formik.handleSubmit}>
        <Stack className={styles["edit-password-form"]} direction={"column"}>
          <FormControl className={styles.FormControl}>
            <label
              style={
                changePasswordAction.wrongPassword === true
                  ? { color: "red" }
                  : {}
              }
              htmlFor="oldPassword"
            >
              Стара Парола
            </label>
            <TextField
              style={{ paddingTop: "10px" }}
              value={formik.values.oldPassword}
              onChange={formik.handleChange}
              error={
                formik.touched.oldPassword ||
                Boolean(formik.errors.oldPassword) ||
                changePasswordAction.wrongPassword === true
              }
              helperText={
                changePasswordAction.wrongPassword === true
                  ? "Грешна парола"
                  : ""
              }
              size="small"
              id="oldPassword"
              type={showOldPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowOldPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showOldPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            ></TextField>
          </FormControl>
          <FormControl className={styles.FormControl}>
            <label htmlFor="newPassword">Нова Парола</label>
            <TextField
              style={{ paddingTop: "10px" }}
              value={formik.values.newPassword}
              onChange={formik.handleChange}
              error={
                formik.touched.newPassword && Boolean(formik.errors.newPassword)
              }
              helperText={
                formik.touched.newPassword && formik.errors.newPassword
              }
              size="small"
              id="newPassword"
              type={showNewPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowNewPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showNewPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            ></TextField>
          </FormControl>
          <Button
            disabled={!(formik.isValid && formik.dirty)}
            type="submit"
            variant="container"
            className={styles["submit-button"]}
          >
            Запази
          </Button>
        </Stack>
      </form>
    </Paper>
  );
}

const mapStateToProps = (state) => ({
  changePasswordAction: state.users.changePasswordAction,
});

const mapDispatchToProps = (dispatch) => ({
  changeUserPassword: (payload) => dispatch(changeUserPassword(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditPasswordModal);
