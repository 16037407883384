const PROD = true;

export const API_URL = PROD ? "https://cs-api.fidweb.net" : "https://customer-support-testing.herokuapp.com";

export const IMMOTECH_API_URL = PROD ? "https://immotech-2-prod.herokuapp.com" : "https://immotech-api.herokuapp.com";

export const AMARANT2_API_URL = PROD ? "https://amarant.herokuapp.com" : "https://amarant-dev2.herokuapp.com";

// export const AMARANT2_API_URL = "https://tests.mexus.org";
const S3_CREDENTIALS_DEV = {
  bucketName: "dev-fidweb",
  region: "eu-central-1",
  accessKeyId: "AKIA5L7PZ7W5KYJIFZTM",
  secretAccessKey: "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",
};

const S3_CREDENTIALS_PROD = {
  bucketName: "csupport",
  region: "eu-central-1",
  accessKeyId: "AKIA5L7PZ7W5BTWIWIEY",
  secretAccessKey: "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",
};

export const S3_CREDENTIALS = PROD ? S3_CREDENTIALS_PROD : S3_CREDENTIALS_DEV;

export const AMARANT_S3_CREDENTIALS = {
  bucketName: "amarant-rpa",
  region: "eu-central-1",
  accessKeyId: "AKIA5L7PZ7W5CHICD2X5",
  secretAccessKey: "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",
};
