/* eslint-disable */
import { toast } from "react-toastify";
import { ofType, ActionsObservable } from "redux-observable";
import { ajax } from "rxjs/ajax";
import { switchMap, catchError } from "rxjs/operators";
import Swal from "sweetalert2";
import { notesTypes } from "../actions/notes";
import { API_URL } from "../config/settings";
import { Headers } from "../utils/Headers";

export const getNotesListEpic = (action$) => {
  return action$.pipe(
    ofType(notesTypes.GET_NOTES_LIST),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API_URL}/note/list`,
        method: "POST",
        headers: Headers.auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: notesTypes.GET_NOTES_LIST_SUCCESS,
              payload: response.payload,
            });
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            if (err.status === 401) {
              window.location.href = "/login";
            } else {
            }
          });
        })
      );
    })
  );
};

export const createNoteEpic = (action$) => {
  return action$.pipe(
    ofType(notesTypes.CREATE_NOTE),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API_URL}/note/create`,
        method: "POST",
        headers: Headers.auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: notesTypes.CREATE_NOTE_SUCCESS,
              payload: response.payload,
            });
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            if (err.status === 401) {
              window.location.href = "/login";
            } else {
            }
          });
        })
      );
    })
  );
};

export const sendEmails = (action$) => {
  return action$.pipe(
    ofType(notesTypes.SEND_REMINDER_EMAILS),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API_URL}/note/email/${payload.id}`,
        method: "GET",
        headers: Headers.auth(),
        body:JSON.stringify(payload)
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            toast.success(response.payload.message)
            Swal.fire("Упсешно изпратихме мейл.","","success")
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            if (err.status === 401) {
              window.location.href = "/login";
            } else {
              toast(err)
            }
          });
        })
      );
    })
  );
};
