export const templateTypes = {
    GROUPS_LIST:'template/GROUPS_LIST',

    GROUPS_LIST_SUCCESS:'template/GROUPS_LIST_SUCCESS',
    GROUP_CREATE:'template/GROUP_CREATE',
    GROUP_EDIT:'template/GROUP_EDIT',
    GROUP_DELETE:'template/GROUP_DELETE',

    TEMPLATE_LIST:'template/TEMPLATE_LIST',
    TEMPLATE_LIST_SUCCESS:'template/TEMPLATE_LIST_SUCCESS',
    TEMPLATE_CREATE:'template/TEMPLATE_CREATE',
    TEMPLATE_EDIT:'template/TEMPLATE_EDIT',
    TEMPLATE_DELETE:'template/TEMPLATE_DELETE',
}

export const groupsList = (payload) => ({
    type: templateTypes.GROUPS_LIST,
    payload,
});

export const groupCreate = (payload) => ({
    type:templateTypes.GROUP_CREATE,
    payload
})

export const groupEdit = (payload) => ({
    type:templateTypes.GROUP_EDIT,
    payload
})  

export const groupDelete = (payload) => ({
    type:templateTypes.GROUP_DELETE,
    payload
})

export const templatesList = (payload) => ({
    type: templateTypes.TEMPLATE_LIST,
    payload,
});

export const templateCreate = (payload) => ({
    type:templateTypes.TEMPLATE_CREATE,
    payload
})

export const templateEdit = (payload) => ({
    type:templateTypes.TEMPLATE_EDIT,
    payload
})  

export const templateDelete = (payload) => ({
    type:templateTypes.TEMPLATE_DELETE,
    payload
})
  