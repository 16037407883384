/* eslint-disable */
import React from "react";
import { Paper, Button } from "@mui/material";
import { deleteCurrentUser } from "../../../actions/users";
import { templateDelete, templatesList } from "../../../actions/template";
import { useNavigate } from "react-router";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";

function DeleteTemplateModal({
  handleClose,
  template,
  templateDelete,
  templatesList,
  id,
}) {
  
  const handleDelete = () => {
    templateDelete({
      array: [template?._id],
      onSuccess: () => {
        templatesList({
          group: id,
          noPagination: true,
        });
      },
    });
    handleClose();
  };

  return (
    <Paper
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-evenly",
        width: "550px",
        height: "220px",
      }}
    >
      <h4>ИЗТРИВАНЕ НА ШАБЛОН</h4>
      <p>Моля потвърдете, че искате да изтриете този шаблон</p>
      <div style={{ display: "flex" }}>
        <Button
          onClick={() => handleClose(false)}
          disableRipple
          disableFocusRipple
          variant="contained"
          sx={{
            marginRight: "1rem",
            color: "black",
            backgroundColor: "#FFFFFF",
            cursor: "pointer",
            "&:hover": {
              backgroundColor: "#FFFFFF",
            },
          }}
        >
          Откажи
        </Button>
        <Button
          onClick={handleDelete}
          disableRipple
          disableFocusRipple
          variant="contained"
          sx={{
            marginRight: "1rem",
            color: "#FFFFFF",
            backgroundColor: "#FD416C",
            cursor: "pointer",
            "&:hover": {
              backgroundColor: "#FD416C",
            },
          }}
        >
          Изтрий
        </Button>
      </div>
    </Paper>
  );
}

const mapDispatchToProps = (dispatch) => ({
  templatesList: (payload) => dispatch(templatesList(payload)),
  templateDelete: (payload) => dispatch(templateDelete(payload)),
});

export default connect(null, mapDispatchToProps)(DeleteTemplateModal);
