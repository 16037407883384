/* eslint-disable */
import React, { useEffect } from "react";
import styles from "./TemplatesModal.module.scss";

const TemplateGroupsModal = ({groups,selGroup,setSelGroup,templatesList}) => {

  useEffect(() => {
    groups.sort((a,b) => {
      if ( a?.name < b?.name ) return -1;
      if ( a?.name > b?.name ) return 1;
      return 0;
    })
  },[groups])

  useEffect(() => {
    templatesList({
      group:selGroup?._id,
      noPagination:true
    })
  },[selGroup])

  return (
    <div className={styles["template-topics-container"]}>
      <div
        style={{
          minWidth: groups?.length > 3 ? "unset" : "100%",
          display: "flex",
          borderBottom: "1px solid black",
          height: "30px",
        }}
      >
        {groups?.map((group, i) => {
          return (
            <div
              className={`${styles["template-topic"]} ${
                selGroup?.name === group?.name && styles["selected"]
              }`}
              key={i}
              onClick={() => {
                setSelGroup(group);
                templatesList({
                  group:group?._id,
                  noPagination:true
                })
              }}
            >
              {group?.name}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TemplateGroupsModal;
