export const paymentTypes = {
   PAYMENT_CREATE:"PAYMENT/CREATE",
   PAYMENT_CREATE_SUCCESS:"PAYMENT/CREATE_SUCCESS"
};
  
export const createPayment = (payload) => ({
    type: paymentTypes.PAYMENT_CREATE,
    payload,
  });
 
  