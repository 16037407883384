/* eslint-disable */
import React from "react";
import { InputAdornment, Paper, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ArrowUpwardRoundedIcon from "@mui/icons-material/ArrowUpwardRounded";
import ArrowDownwardRoundedIcon from "@mui/icons-material/ArrowDownwardRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import styles from "./Search.module.scss";

export default function Search({
  setSearchTags,
  matches,
  handleCloseSearchBar,
}) {
  return (
    <Paper elevation={3} className={styles["search-container"]}>
      <TextField
        onChange={(e) => setSearchTags([...e.target.value.split(" "), ""])}
        variant="outlined"
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      {/* <div className={styles["action-buttons"]}>
        <ArrowUpwardRoundedIcon fontSize="large" />
        <ArrowDownwardRoundedIcon fontSize="large" />
      </div> */}
      <CloseRoundedIcon fontSize="large" onClick={handleCloseSearchBar} />
    </Paper>
  );
}
