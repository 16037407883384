/* eslint-disable */
import { Divider, InputAdornment, InputBase, Paper, Select, Button, FormControl, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import ChatCard from "../../ChatCard/ChatCard";
import { connect } from "react-redux";
import React, { useCallback, useRef, useState, useEffect } from "react";
import styles from "./Topics.module.scss";
import moment from "moment";
import Swal from "sweetalert2";
import ChatDropDownMenu from "../../ChatActions/ChatDropDownMenu/ChatDropDownMenu";
import { editMultipleTickets } from "../../../actions/tickets";
import ModalComponent from "../../Modals/ModalComponent/ModalComponent";
import ChangeAssigneeModal from "../../Modals/ChangeAssigneeModal/ChangeAssigneeModal";
import { sendReminder, getChatHistory } from "../../../actions/tickets";
import { multipleVoting } from "../../../actions/tickets";
import { labelsList } from "../../../actions/labels";
import LeftToolbar from "./LeftToolbar";
import FilterTopicsModal from "../../Modals/FilterTopicsModal/FilterTopicsModal";
import LabelsModal from "../../Modals/LabelsModal/LabelsModal";
import StartChatModal from "../../Modals/StartChatModal/StartChatModal";

const energySlugs = {
  "61efa788ffba481f0c813335": "https://synergon-fast-prod.fidweb.net",
  "6218914072d2615dc48bc1b3": "https://erp-api.energy-op.eu",
  "6267bf9eeac1000099c5afc6": "https://retc-api.fidweb.net",
  "640b008b7c8dc62a195b7660": "https://zagora-api.fidweb.net",
  "640b00987c8dc62a195b7663": "https://proakt-api.fidweb.net",
  "640b009d7c8dc62a195b7666": "https://energama-api.fidweb.net",
  "61efa76dffba481f0c81332c": "https://erp-hydropro-api.herokuapp.com",
  "643030f780dead605384a55f": "https://wsenergy-api.fidweb.net",
};

function Topics({ tickets, ticketsInfo, isCompletedQuery = false, setQuery, query, setNextPage, nextPage, getTicketsForSystem, slug, editMultipleTickets, currentSystemUsers, getCurrentSystem, sendReminder, multipleVoting, labelsList, labels, filteredInfo, payload, setPayload, activeInnerFilters, setActiveInnerFilters }) {
  let totalPages = ticketsInfo.totalPages;
  const observer = useRef();

  const infiniteScrollRef = useCallback(
    (node) => {
      if (observer.current) {
        observer.current.disconnect();
      }
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && nextPage <= totalPages) {
          if (nextPage < totalPages) {
            setNextPage((prevPageNumber) => prevPageNumber + 1);
          }
        }
      });
      if (node) {
        observer.current.observe(node);
      }
    },
    [totalPages]
  );

  const [searchQuery, setSearchQuery] = useState("Заглавие Тема");
  const [radioBtnValue, setRadioBtnValue] = useState("title");
  const [searchBy, setSearchBy] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const [searchDateBy, setSearchDateBy] = useState(false);
  const [searchSecondQuery, setSearchSecondQuery] = useState("Дата на създаване");
  const [radioBtnValueSecond, setRadioBtnValueSecond] = useState("created");

  const [startDate, setStartDate] = useState(moment()._d);
  const [endDate, setEndDate] = useState(moment()._d);
  const [isClicked, setIsClicked] = useState(false);
  const [selectedAction, setSelectedAction] = useState("");
  const [selectedTickets, setSelectedTickets] = useState([]);

  const handleRadioBtnChangeSecond = (event) => {
    switch (event.target.value) {
      case "created":
        setSearchSecondQuery("Дата на създаване");
        setPayload({ ...payload, period: "created" });
        break;
      case "activity":
        setSearchSecondQuery("Последна активност");
        setPayload({ ...payload, period: "activity" });
        break;
      default:
        setSearchSecondQuery("Дата на създаване");
        setPayload({ ...payload, period: "created" });
        break;
    }
    setRadioBtnValueSecond(event.target.value);
  };

  const handleRadioBtnChangeSortBy = (event) => {
    switch (event.target.value) {
      case "title":
        setSearchQuery("Заглавие Тема");
        setInputValue("");
        break;
      case "keyword":
        setSearchQuery("Ключова дума");
        setInputValue("");
        break;
      case "responsible":
        setSearchQuery("Назначен");
        setInputValue("");
        break;
      case "customer":
        setSearchQuery("Външен потребител");
        setInputValue("");
        break;
      default:
        setSearchBy("Заглавие Тема");
        setInputValue("");
        break;
    }
    setRadioBtnValue(event.target.value);
  };

  const handleInputValue = (e) => {
    setInputValue(e.target.value);
  };
  const [actionsAnchor, setActionsAnchor] = useState(null);
  const openActions = Boolean(actionsAnchor);
  const handleOpenActions = (event) => {
    setActionsAnchor(event.currentTarget);
  };

  const handleCloseActions = () => {
    setActionsAnchor(null);
  };

  const handleClearFilters = () => {
    setNextPage(1);
    setQuery({});
    setInputValue("");
    setStartDate(moment());
    setEndDate(moment());
    getTicketsForSystem({
      system: slug,
      pageNumber: 1,
    });
    setPayload({
      mineResponsible: false,
      inTeam: false,
      specialCustomers: false,
      withPayment: false,
      labels: [],
      startDate: undefined,
      endDate: undefined,
      period: "created",
    });
    filteredInfo.current.totalTickets = 0;
  };
  const handleUnreadTicket = () => {
    setIsClicked(true);
  };
  const handleArchiveTicket = () => {
    setIsClicked(true);
  };

  const [openChangeAssigneeModal, setOpenChangeAssigneeModal] = useState(false);
  const handleOpenChangeAssigneeModal = () => setOpenChangeAssigneeModal(true);
  const handleCloseChangeAssigneeModal = () => setOpenChangeAssigneeModal(false);
  const [currentAsignee, setCurrentAsignee] = useState(null);
  const [openFilterModal, setOpenFilterModal] = useState(false);

  useEffect(() => {
    if (currentAsignee) {
      editMultipleTickets({
        action: selectedAction,
        tickets: selectedTickets,
        responsible: currentAsignee,
        onSuccess: () => {
          getTicketsForSystem({
            system: slug,
            noPagination: true,
          });
          setIsClicked(false);
        },
      });
    }
  }, [currentAsignee]);

  useEffect(() => {
    labelsList({
      system: slug,
      noPagination: true,
    });
  }, []);

  const [openLabelsModal, setOpenLabelsModal] = useState(false);
  const handleOpenLabeslModal = () => setOpenLabelsModal(true);
  const handleCloseLabelsModal = () => setOpenLabelsModal(false);

  const [openStartChatModal, setOpenStartChatModal] = useState(false);
  const handleOpenStartChatModal = () => setOpenStartChatModal(true);
  const handleCloseStartChatModal = () => setOpenStartChatModal(false);

  const handleLabelsAdd = () => {
    setIsClicked(true);
  };

  useEffect(() => {
    if (activeInnerFilters && filteredInfo.current.totalTickets < 1) {
      filteredInfo.current.totalTickets = tickets?.length < 1 ? 1 : tickets?.length;
      tickets?.map((ticket) => {
        if (ticket?.archivedAt) filteredInfo.current.archived += 1;
        else if (ticket?.completedBy) {
          filteredInfo.current.completed.total += 1;
          if (ticket?.rating === 0) filteredInfo.current.completed.withoutRating += 1;
          else {
            filteredInfo.current.completed.withRating += 1;
            if (ticket?.rating === 1) filteredInfo.current.completed.lowRating += 1;
            if (ticket?.rating === 2) filteredInfo.current.completed.middleRating += 1;
            if (ticket?.rating === 3) filteredInfo.current.completed.hiRating += 1;
          }
        } else {
          filteredInfo.current.opened.total += 1;
          if (!ticket?.responsible) filteredInfo.current.opened.noResponsible += 1;
          if (ticket?._lastMessage?.type?.includes("Customer")) filteredInfo.current.opened.noAnswered += 1;
          if (ticket?._lastMessage?.type?.includes("User") && !ticket?._lastMessage?.variant?.includes("vote")) filteredInfo.current.opened.waitAnswer += 1;
          if (ticket?._lastMessage?.variant?.includes("vote")) filteredInfo.current.opened.waitVote += 1;
        }
      });
      setSearchSecondQuery("Последна активност");
    } else {
      setSearchSecondQuery("Последна активност");
    }
  }, [tickets]);

  // console.log(tickets);
  return (
    <Paper className={styles["topics-container"]} elevation={3}>
      {window.innerWidth > 900 && (
        <div className={styles["toolbar"]}>
          {!isClicked ? (
            <>
              <LeftToolbar
                handleInputValue={handleInputValue}
                inputValue={inputValue}
                setNextPage={setNextPage}
                setQuery={setQuery}
                getCurrentSystem={getCurrentSystem}
                getTicketsForSystem={getTicketsForSystem}
                searchBy={searchBy}
                setSearchBy={setSearchBy}
                radioBtnValue={radioBtnValue}
                handleRadioBtnChangeSortBy={handleRadioBtnChangeSortBy}
                searchQuery={searchQuery}
                slug={slug}
                query={query}
              />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "1%",
                }}
              >
                <Button
                  sx={{ boxShadow: 1 }}
                  style={{
                    backgroundColor: "#FFFFFF",
                    borderRadius: "4px",
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    marginTop: "2%",
                    marginRight: "2%",
                    minWidth: "58px",
                  }}
                  onClick={() => {
                    setOpenFilterModal(true);
                  }}
                >
                  <div
                    className={`${styles["filter-icon"]}`}
                    onClick={() => {
                      //window.open("https://customer-support-dev.herokuapp.com/ticket/export/630deb815618e000163f9d93")
                    }}
                  />
                  {activeInnerFilters && <div className={styles["circle"]} />}
                </Button>
                <Button
                  sx={{ boxShadow: 1 }}
                  style={{
                    backgroundColor: "#0DC1CE",
                    color: "white",
                    fontWeight: "bold",
                    borderRadius: "4px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "2%",
                    marginLeft: "5%",
                    minWidth: "130px",
                  }}
                >
                  <span onClick={handleOpenActions}>Действия</span>

                  <ChatDropDownMenu
                    open={openActions}
                    systemId={slug}
                    anchorEl={actionsAnchor}
                    handleClose={handleCloseActions}
                    handleClearFilters={handleClearFilters}
                    handleUnreadTicket={handleUnreadTicket}
                    setSelectedAction={setSelectedAction}
                    handleArchiveTicket={handleArchiveTicket}
                    handleOpenLabelsModal={handleOpenLabeslModal}
                    handleLabelsAdd={handleLabelsAdd}
                    newChat={slug === "61ee8b87a07ddf1eb8268265" || slug === "61ee8bcaa07ddf1eb8268269" || (energySlugs[slug] && true)}
                    handleOpenStartChatModal={handleOpenStartChatModal}
                    special={true}
                    setActiveInnerFilters={setActiveInnerFilters}
                  />
                </Button>
              </div>
            </>
          ) : (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div style={{ width: "30%" }} />
              <div className={styles["buttons-container"]}>
                <div
                  className={`${styles["filterButton"]} ${styles["white"]}`}
                  onClick={() => {
                    setIsClicked(false);
                    setSelectedTickets([]);
                  }}
                >
                  Откажи
                </div>
                <div
                  className={`${styles["filterButton"]} ${selectedAction === "delete" && styles["pink"]}`}
                  onClick={() => {
                    if (selectedTickets.length < 1) {
                      Swal.fire("Не сте селектирали тикет!", "", "error");
                      return;
                    }
                    if (selectedAction === "labels") {
                      handleOpenLabeslModal();
                      setIsClicked(false);
                    } else if (selectedAction === "reminder") {
                      sendReminder({
                        tickets: selectedTickets,
                      });
                      setIsClicked(false);
                      setSelectedTickets([]);
                    } else if (selectedAction === "voting") {
                      multipleVoting({
                        tickets: selectedTickets,
                        onSuccess: () => {
                          getTicketsForSystem({
                            system: slug,
                            noPagination: true,
                          });
                          setIsClicked(false);
                          getCurrentSystem(slug);
                          setSelectedTickets([]);
                        },
                      });
                    } else if (selectedAction === "responsible") {
                      handleOpenChangeAssigneeModal();
                    } else {
                      if (selectedAction === "delete") {
                        Swal.fire({
                          title: "Наистина ли искате да изтриете тези теми?",
                          text: "Няма да можете да ги възстановите.",
                          icon: "warning",
                          showCancelButton: true,
                          confirmButtonColor: "#FD416C",
                          cancelButtonColor: "gray",
                          cancelButtonText: "Oткажи",
                          confirmButtonText: "Изтрий",
                        }).then((result) => {
                          if (result.isConfirmed) {
                            editMultipleTickets({
                              action: selectedAction,
                              tickets: selectedTickets,
                              onSuccess: () => {
                                getTicketsForSystem({
                                  system: slug,
                                  noPagination: true,
                                });
                                getCurrentSystem(slug);
                              },
                            });
                            setSelectedTickets([]);
                            setIsClicked(false);
                          }
                        });
                      } else {
                        editMultipleTickets({
                          action: selectedAction,
                          tickets: selectedTickets,
                          onSuccess: () => {
                            getTicketsForSystem({
                              system: slug,
                              noPagination: true,
                            });
                            getCurrentSystem(slug);
                          },
                        });
                        setSelectedTickets([]);
                        setIsClicked(false);
                      }
                    }
                  }}
                >
                  {selectedAction === "delete" ? "Изтрий" : "Запази"}
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      <div className={styles["topics-list"]}>
        {tickets?.length === 0 && (
          <div className={styles["no-topics"]}>
            <p>Няма теми в избрания от Вас филтър</p>
            <img src="/Chats-empty-state.png" />
          </div>
        )}
        {tickets?.length !== 0 &&
          isCompletedQuery === false &&
          tickets?.map((topic) => (
            <div ref={infiniteScrollRef} key={topic._id}>
              <ChatCard
                archivedAt={topic.archivedAt}
                topic={topic}
                archivedBy={topic.archivedBy}
                createdAt={topic.createdAt}
                completedAt={topic.completedAt}
                completedBy={topic.completedBy}
                id={topic._id}
                key={topic._id}
                name={topic.name}
                updatedAt={topic.updatedAt}
                responsible={topic.responsible}
                readed={topic.readed}
                _unread={topic._unread}
                paidUser={topic?.customer?.paidUser}
                _lastMessage={topic._lastMessage}
                lastMessageDate={topic?.lastMessageDate}
                isClicked={isClicked}
                setSelectedTickets={setSelectedTickets}
                getTicketsForSystem={getTicketsForSystem}
                selectedTickets={selectedTickets}
              />
              <div className={styles["lower-card-container"]}>
                <p className={styles["last-message"]}>
                  <b className={styles["bold"]}>
                    <>{window.innerWidth < 900 ? topic?._lastMessage?.from?.name?.split(" ")[0] : topic?._lastMessage?.from?.name}: </>
                  </b>
                  <>{window.innerWidth < 900 ? (topic?._lastMessage?.variant === "vote" ? "Изпратено за оценка" : topic?._lastMessage?.text?.length > 25 ? topic?._lastMessage?.text.slice(0, 25) + "..." : topic?._lastMessage?.text) : null}</>
                  <>{window.innerWidth > 900 ? (topic?._lastMessage?.variant === "vote" ? "Изпратено за оценка" : topic?._lastMessage?.text?.length > 110 ? topic?._lastMessage?.text.slice(0, 110) + "..." : topic?._lastMessage?.text) : null}</>
                </p>
                {topic?._unread > 0 ? (
                  <>{topic?.responsible === null ? <h5 className={styles["notification-number-red"]}>{topic?._unread}</h5> : <h5 className={styles["notification-number"]}>{topic?._unread}</h5>}</>
                ) : !topic?.readed ? (
                  <>{topic?.responsible === null ? <h5 className={styles["notification-number-red"]}>{1}</h5> : <h5 className={styles["notification-number"]}>{1}</h5>}</>
                ) : null}
              </div>
            </div>
          ))}
        {tickets?.length !== 0 &&
          isCompletedQuery &&
          tickets?.map((topic) => (
            <div ref={infiniteScrollRef} key={topic._id}>
              <ChatCard
                id={topic._id}
                key={topic?._id}
                name={topic?.name}
                responsible={topic?.responsible}
                rating={topic?.rating}
                completedAt={topic.completedAt}
                completedBy={topic.completedBy}
                createdAt={topic?.createdAt}
                _lastMessage={topic._lastMessage}
                lastMessageDate={topic?.lastMessageDate}
                isClicked={isClicked}
                setSelectedTickets={setSelectedTickets}
                selectedTickets={selectedTickets}
                getTicketsForSystem={getTicketsForSystem}
                topic={topic}
                paidUser={topic.customer.paidUser}
              />
            </div>
          ))}
      </div>

      <ModalComponent open={openChangeAssigneeModal} handleClose={handleCloseChangeAssigneeModal} children={<ChangeAssigneeModal assignee={currentAsignee} setAssignee={setCurrentAsignee} handleClose={handleCloseChangeAssigneeModal} currentSystemUsers={currentSystemUsers} special={true} />} />
      <ModalComponent
        open={openFilterModal}
        handleClose={() => setOpenFilterModal(false)}
        special={true}
        children={
          <FilterTopicsModal
            handleClose={() => setOpenFilterModal(false)}
            open={openFilterModal}
            slug={slug}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            searchDateBy={searchDateBy}
            setSearchDateBy={setSearchDateBy}
            searchSecondQuery={searchSecondQuery}
            radioBtnValueSecond={radioBtnValueSecond}
            handleRadioBtnChangeSecond={handleRadioBtnChangeSecond}
            labels={labels}
            payload={payload}
            setPayload={setPayload}
            setNextPage={setNextPage}
            setQuery={setQuery}
            getTicketsForSystem={getTicketsForSystem}
            getCurrentSystem={getCurrentSystem}
            query={query}
            setActiveInnerFilters={setActiveInnerFilters}
            filteredInfo={filteredInfo}
          />
        }
      />
      <ModalComponent open={openLabelsModal} handleClose={handleCloseLabelsModal} special={true} children={<LabelsModal handleClose={handleCloseLabelsModal} slug={slug} selectedTickets={selectedTickets} editMultipleTickets={editMultipleTickets} />} />
      <ModalComponent open={openStartChatModal} handleClose={handleCloseStartChatModal} children={<StartChatModal handleClose={handleCloseStartChatModal} slug={slug} />} />
    </Paper>
  );
}

const mapStateToProps = (state) => ({
  ticketsInfo: state.tickets.ticketsInfo,
  labels: state.labels.labels,
});
const mapDispatchToProps = (dispatch) => ({
  editMultipleTickets: (payload) => dispatch(editMultipleTickets(payload)),
  sendReminder: (payload) => dispatch(sendReminder(payload)),
  multipleVoting: (payload) => dispatch(multipleVoting(payload)),
  labelsList: (payload) => dispatch(labelsList(payload)),
  getChatHistory: (payload) => dispatch(getChatHistory(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Topics);
