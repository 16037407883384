/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { forwardRef } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import "./styles.scss";
import { getMonth, getYear } from "date-fns";
import { months, years } from "../../../config/constants";

const CustomInput = React.forwardRef((props, ref, className) => {
  return (
    <div className={`date-picker flex-container align-center ${className}`}>
      <label onClick={props.onClick} ref={ref} style={{ width: "88%", paddingLeft: "2%" }}>
        {props.value || props.placeholder}
      </label>
      <div className="date-icon" onClick={props.onClick} />
    </div>
  );
});

const TextInput = forwardRef(({ label, compulsory, style, inputStyle, disabled, className, custom, value, ...props }, ref) => {
  useEffect(() => {}, [className]);
  return (
    <div className="input-container" style={style}>
      {label && (
        <label
          style={{
            display: "flex",
            width: "100%",
            fontSize: "0.8rem",
            marginBottom: "5px",
          }}
        >
          {label} {compulsory && <span style={{ paddingLeft: "5px", color: "black" }}>*</span>}
        </label>
      )}
      <DatePicker
        renderCustomHeader={
          custom
            ? ({ date, changeYear, changeMonth, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled }) => (
                <div
                  style={{
                    margin: 10,
                    display: "flex",
                    justifyContent: "center",
                    gap:"5px"
                  }}
                >
                  <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                    {"<"}
                  </button>
                  <select value={getYear(date)} onChange={({ target: { value } }) => changeYear(value)}>
                    {years.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>

                  <select value={months[getMonth(date)]} onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}>
                    {months.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>

                  <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                    {">"}
                  </button>
                </div>
              )
            : undefined
        }
        customInput={<CustomInput />}
        dateFormat="dd/MM/yyyy"
        {...props}
        selected={value}
        wrapperClassName={className}
        ref={ref}
      />
    </div>
  );
});

export default TextInput;
