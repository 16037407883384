import React from "react";
import { useLocation, Navigate } from "react-router-dom";
import { handleValidateTokenAndSocket } from "./utils/helper";

const PrivateRoute = ({ children }) => {
  const location = useLocation();
  const credentials = localStorage.getItem("credentials");
  if (!credentials) {
    return <Navigate to={"/login"} state={{ from: location }} replace />;
  }
  handleValidateTokenAndSocket(credentials);
  return children;
};

export default PrivateRoute;
