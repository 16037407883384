import { useCallback, useEffect, useLayoutEffect, useRef } from "react";

const useKeyPress = (keys, callback, node = null) => {
  const callbackRef = useRef(callback);
  useLayoutEffect(() => {
    callbackRef.current = callback;
  });

  const handleKeyPress = useCallback(
    (event) => {
      //check if key is in array
      if (keys.some((key) => event.key.toLowerCase() === key.toLowerCase())) {
        //check if input is not onFocus
        if (
          event?.target?.tagName.includes("INPUT") ||
          event?.target?.tagName.includes("TEXTAREA")
        )
          return;
        else callbackRef.current(event);
      }
    },
    [keys]
  );

  useEffect(() => {
    // target is either the provided node or the document
    const targetNode = node ?? document;
    targetNode && targetNode.addEventListener("keydown", handleKeyPress);

    return () =>
      targetNode && targetNode.removeEventListener("keydown", handleKeyPress);
  }, [handleKeyPress, node]);
};

export default useKeyPress;
