/* eslint-disable */
import {
  Avatar,
  Button,
  FormControl,
  InputAdornment,
  Paper,
  Radio,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import styles from "./ChangeAssigneeModal.module.scss";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";

function ChangeAssigneeModal({
  handleClose,
  assignee,
  setAssignee,
  currentSystemUsers,
  getCurrentSystemUsers,
  slug,
  topicId,
  user,
}) {
  const [currentAgentId, setCurrentAgentId] = useState(assignee?._id);
  const [input, setInput] = useState("");
  const [allAgents, setAllAgents] = useState([]);

  useEffect(() => {
    getCurrentSystemUsers({
      system: slug,
      noPagination:true,
      onSuccess: (res) => {
        setAllAgents(res)
      },
    });
  }, []);


  const searchInput = async (input) => {
    const filteredAgents = allAgents.filter((agent) =>
      agent.name.toLowerCase().includes(input.toLowerCase())
    );
    setInput(input);
    setAllAgents(filteredAgents);
    if (input === "") {
      setAllAgents(currentSystemUsers);
    }
  };

  return (
    <Paper className={styles["change-assignee-modal"]}>
      {window.innerWidth < 900 ? (
        <div>
          <div className={styles["change-assignee-heading-container"]}>
            <h3>СМЯНА ОТГОВОРНИК</h3>
            <CloseIcon onClick={handleClose} />
          </div>
          <FormControl style={{width:"100%"}}>
            <TextField
              value={input}
              onChange={(e) => searchInput(e.target.value)}
              sx={{ padding: "10px" }}
              placeholder="Търси по име"
              variant="outlined"
              id="search-field"
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
          <div className={styles["select-agent"]}>
            {allAgents.map((agent, index) => (
              <div className={styles.agent} key={index}>
                <Radio
                  onChange={(e) => {
                    setCurrentAgentId(e.target.value);
                  }}
                  checked={currentAgentId == agent?._id}
                  sx={{
                    "&.Mui-checked": {
                      color: "#0DC1CE",
                    },
                  }}
                  value={agent?._id}
                />
                <Avatar src={agent?.avatar} />
                <p>{agent?.name}</p>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <>
          <div className={styles["change-assignee-heading-container"]}>
            <h3>СМЯНА ОТГОВОРНИК</h3>
            <CloseIcon onClick={handleClose} />
          </div>
          <FormControl>
            <TextField
              value={input}
              onChange={(e) => searchInput(e.target.value)}
              sx={{ padding: "10px" }}
              placeholder="Търси по име"
              variant="outlined"
              id="search-field"
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
          <div className={styles["select-agent"]}>
            {allAgents.map((agent, index) => (
              <div className={styles.agent} key={index}>
                <Radio
                  onChange={(e) => {
                    setCurrentAgentId(e.target.value);
                  }}
                  checked={currentAgentId == agent?._id}
                  sx={{
                    "&.Mui-checked": {
                      color: "#0DC1CE",
                    },
                  }}
                  value={agent?._id}
                />
                <Avatar src={agent?.avatar} />
                <p>{agent?.name}</p>
              </div>
            ))}
          </div>
        </>
      )}

      <Paper elevation={10} style={{ padding: "10px" }}>
        <Button
          onClick={() => {
            setAssignee(currentAgentId);
            handleClose();
          }}
          disableElevation
          disableRipple
          disableFocusRipple
          variant="contained"
          sx={{
            width: "100%",
            color: "white",
            backgroundColor: "#01d088",
            cursor: "pointer",
            "&:hover": {
              backgroundColor: "#01d088",
            },
          }}
        >
          Избери
        </Button>
      </Paper>
    </Paper>
  );
}

const mapStateToProps = (state) => ({
  currentSystemUsers: state.users.currentSystemUsers,
  user: state.login.user,
});

export default connect(mapStateToProps)(ChangeAssigneeModal);
