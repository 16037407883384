import { default as Button } from "./Button";
import { default as TextInput } from "./TextInput";
import { default as RadioButtons } from "./RadioButtons";
import { default as Dropdown } from "./Dropdown";
import { default as ImageInput } from "./ImageInput";
import { default as DatePicker } from "./DatePicker";
const Inputs = {
  Button,
  TextInput,
  ImageInput,
  RadioButtons,
  Dropdown,
  DatePicker
};

export default Inputs;
