export const notesTypes = {
  GET_NOTES_LIST: "notes/GET_NOTES_LIST",
  GET_NOTES_LIST_SUCCESS: "notes/GET_NOTES_LIST_SUCCESS",
  CREATE_NOTE: "notes/CREATE_NOTE",
  CREATE_NOTE_SUCCESS: "notes/CREATE_NOTE_SUCCESS",

  SEND_REMINDER_EMAILS:"notes/SEND_REMINDER_EMAILS",
};

export const getNotesList = (payload) => ({
  type: notesTypes.GET_NOTES_LIST,
  payload,
});

export const createNote = (payload) => ({
  type: notesTypes.CREATE_NOTE,
  payload,
});

export const sendEmails = (payload) => ({
  type:notesTypes.SEND_REMINDER_EMAILS,
  payload
})
