/* eslint-disable */
import React, { useState } from "react";
import { Divider, Paper } from "@mui/material";
import styles from "./HelpCenterSideTools.module.scss";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { useNavigate, useParams } from "react-router-dom";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { ClickAwayListener } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setTicketFilters } from "../../../actions/tickets";

const CompletedSideTools = ({ getTicketsForSystem, setNextt, setNextPage, setSelectedFilter, setIsCompletedQuery, currentSystem, selectedFilter, slug, nextt, TopicSelector, setQuery, query, payload, filteredInfo }) => {
  const dispatch = useDispatch();
  const filters = useSelector(({ tickets }) => tickets?.filters) || {};
  return (
    <Paper elevation={2}>
      <Paper
        className={styles["created-topics-container"]}
        elevation={2}
        onClick={() => {
          const newFilters = { ...filters, completedOnly: true };
          dispatch(setTicketFilters(newFilters));
          getTicketsForSystem({
            system: slug,
            pageNumber: 1,
            filtered: true,
            ...newFilters,
            ...payload,
          });
          setNextt(nextt + 1);
          setNextPage(0);
          setSelectedFilter({ completed: true });
          setIsCompletedQuery(true);
        }}
      >
        <div className={`${styles["completed-topics-icon"]} ${styles.icon}`} style={{ width: "40px", height: "40px" }}></div>
        <h4 className={styles["users-number"]}>{filteredInfo.current.totalTickets > 0 ? (filteredInfo.current.completed.total <= 0 ? "-" : filteredInfo.current.completed.total) : currentSystem?.completed?.total}</h4>
        <div
          style={{
            display: "flex",
            width: "90%",
            justifyContent: "space-between",
          }}
        >
          <p className={styles["paper-header"]}>ЗАВЪРШЕНИ</p>
          {selectedFilter.completed && <TopicSelector />}
        </div>
      </Paper>
      <div style={{ display: "flex" }}>
        <div style={{ border: "2px solid #01D088", borderRadius: "10px" }} />
        <div style={{ width: "100%" }}>
          <ClickAwayListener onClickAway={() => setSelectedFilter({ ...selectedFilter, notRating: false })}>
            <div
              className={styles["more-options"]}
              onClick={() => {
                const newFilters = { ...filters, notRated: true, ratedOnly: false, withRank: null };
                dispatch(setTicketFilters(newFilters));
                getTicketsForSystem({
                  system: slug,
                  noPagination: true,
                  filtered: true,
                  ...newFilters,
                  ...payload,
                });
                setSelectedFilter({ notRated: true });
                setIsCompletedQuery(true);
              }}
            >
              <div className={styles.options}>
                <p
                  className={styles.notification}
                  style={{
                    border: "1px solid #FF7C3B",
                  }}
                >
                  <b>{filteredInfo.current.totalTickets > 0 ? (filteredInfo.current.completed.withoutRating <= 0 ? "-" : filteredInfo.current.completed.withoutRating) : currentSystem?.completed?.withoutRating}</b>
                </p>
                <p style={{ fontSize: window.innerWidth < 1500 && "11px" }}>БЕЗ ДОБАВЕНИ ОЦЕНКИ</p>
              </div>
              {selectedFilter.notRated && <TopicSelector />}
            </div>
          </ClickAwayListener>
          <ClickAwayListener onClickAway={() => setSelectedFilter({ ...selectedFilter, withRating: false })}>
            <div
              className={`${styles["more-options"]}`}
              style={{ boxShadow: "0 1px 4px rgba(0, 0, 0, 0.3)", marginBottom: "0%" }}
              onClick={() => {
                const newFilters = { ...filters, ratedOnly: true, notRated: false, withRank: null };
                dispatch(setTicketFilters(newFilters));
                getTicketsForSystem({
                  system: slug,
                  pageNumber: 1,
                  filtered: true,
                  ...newFilters,
                  ...payload,
                });
                setSelectedFilter({ withRating: true });
                setIsCompletedQuery(true);
              }}
            >
              <div className={styles.options}>
                <p
                  className={styles.notification}
                  style={{
                    border: "1px solid #0DC1CE",
                  }}
                >
                  <b>{filteredInfo.current.totalTickets > 0 ? (filteredInfo.current.completed.withRating <= 0 ? "-" : filteredInfo.current.completed.withRating) : currentSystem?.completed?.withRating}</b>
                </p>
                <p style={{ fontSize: window.innerWidth < 1500 && "11px" }}>С ДОБАВЕНИ ОЦЕНКИ</p>
              </div>
              {selectedFilter.withRating && <TopicSelector />}
            </div>
          </ClickAwayListener>
          <ClickAwayListener onClickAway={() => setSelectedFilter({ ...selectedFilter, perfectRating: false })}>
            <div
              className={styles["more-options"]}
              style={{ boxShadow: "0 0 2px 0 rgba(0, 0, 0, 0.05)", marginTop: "0%" }}
              onClick={() => {
                const newFilters = { ...filters, withRank: 3, ratedOnly: false, notRated: false };
                dispatch(setTicketFilters(newFilters));
                getTicketsForSystem({
                  system: slug,
                  noPagination: true,
                  filtered: true,
                  ...newFilters,
                  ...payload,
                });
                setNextt(nextt + 1);
                setSelectedFilter({ perfectRating: true });
                setIsCompletedQuery(true);
              }}
            >
              <div className={styles.options} style={{ width: "100%" }}>
                <p
                  className={styles.notification}
                  style={{
                    border: "1px solid #01D088",
                    width: "35px",
                    height: "35px",
                    lineHeight: "2.3em",
                    marginLeft: "0.5%",
                  }}
                >
                  <b>{filteredInfo.current.totalTickets > 0 ? (filteredInfo.current.completed.hiRating === 0 ? "-" : filteredInfo.current.completed.hiRating) : currentSystem?.completed?.hiRating}</b>
                </p>
                <p style={{ fontSize: window.innerWidth < 1500 && "11px" }}>ОТЛИЧНО ОБСЛУЖВАНЕ!</p>
              </div>
              {selectedFilter.perfectRating && <TopicSelector />}
            </div>
          </ClickAwayListener>
          <Divider style={{ marginLeft: "2%", marginRight: "2%" }} />
          <ClickAwayListener onClickAway={() => setSelectedFilter({ ...selectedFilter, goodRating: false })}>
            <div
              className={styles["more-options"]}
              style={{ boxShadow: "0 0 2px 0 rgba(0, 0, 0, 0.05)", marginTop: "0%" }}
              onClick={() => {
                const newFilters = { ...filters, withRank: 2, ratedOnly: false, notRated: false };
                dispatch(setTicketFilters(newFilters));
                getTicketsForSystem({
                  system: slug,
                  noPagination: true,
                  filtered: true,
                  ...newFilters,
                  ...payload,
                });
                setNextt(nextt + 1);
                setSelectedFilter({ goodRating: true });
                setIsCompletedQuery(true);
              }}
            >
              <div className={styles.options} style={{ width: "100%" }}>
                <p
                  className={styles.notification}
                  style={{
                    border: "1px solid #8244EB",
                    width: "35px",
                    height: "35px",
                    lineHeight: "2.3em",
                    marginLeft: "0.5%",
                  }}
                >
                  <b>{filteredInfo.current.totalTickets > 0 ? (filteredInfo.current.completed.middleRating === 0 ? "-" : filteredInfo.current.completed.middleRating) : currentSystem?.completed?.middleRating}</b>
                </p>
                <p style={{ fontSize: window.innerWidth < 1500 && "11px", textTransform: "uppercase" }}>Средна работа, може още!</p>
              </div>
              {selectedFilter.goodRating && <TopicSelector />}
            </div>
          </ClickAwayListener>
          <Divider style={{ marginLeft: "2%", marginRight: "2%" }} />
          <ClickAwayListener onClickAway={() => setSelectedFilter({ ...selectedFilter, badRating: false })}>
            <div
              className={styles["more-options"]}
              style={{ boxShadow: "0 0 2px 0 rgba(0, 0, 0, 0.05)", marginTop: "0%" }}
              onClick={() => {
                const newFilters = { ...filters, withRank: 1, ratedOnly: false, notRated: false };
                dispatch(setTicketFilters(newFilters));
                getTicketsForSystem({
                  system: slug,
                  noPagination: true,
                  filtered: true,
                  ...newFilters,
                  ...payload,
                });
                setNextt(nextt + 1);
                setSelectedFilter({ badRating: true });
                setIsCompletedQuery(true);
              }}
            >
              <div className={styles.options} style={{ width: "100%" }}>
                <p
                  className={styles.notification}
                  style={{
                    border: "1px solid #FD416C",
                    width: "35px",
                    height: "35px",
                    lineHeight: "2.3em",
                    marginLeft: "0.5%",
                  }}
                >
                  <b>{filteredInfo.current.totalTickets > 0 ? (filteredInfo.current.completed.lowRating === 0 ? "-" : filteredInfo.current.completed.lowRating) : currentSystem?.completed?.lowRating}</b>
                </p>
                <p style={{ fontSize: window.innerWidth < 1500 && "11px", textTransform: "uppercase" }}>Под всякаква критика!</p>
              </div>
              {selectedFilter.badRating && <TopicSelector />}
            </div>
          </ClickAwayListener>
        </div>
      </div>
    </Paper>
  );
};

export default CompletedSideTools;
