/* eslint-disable */
import { omit } from "lodash";
import { userTypes } from "../actions/users";

const initialState = {
  allUsers: [],
  allUsersInfo: {},
  currentUser: {},
  currentSystemUsers: [],
  changePasswordAction: {
    wrongPassword: false,
    success: false,
  },
};

const handleUsers = (state, payload) => {
  let newState = [...state];
  if (payload.page === 1 || payload.hasPrevPage === false) {
    newState = [...payload.docs];
  } else {
    newState = [...state, ...payload.docs];
  }
  return newState;
};

const users = (state = initialState, { type, payload }) => {
  switch (type) {
    case userTypes.GET_ALL_USERS_SUCCESS:
      return {
        ...state,
        allUsers: handleUsers(state.allUsers, payload),
        allUsersInfo: omit(payload, "docs"),
      };
    case userTypes.GET_CURRENT_USER_SUCCESS:
      return { ...state, currentUser: payload };
    case userTypes.EDIT_CURRENT_USER_SUCCESS:
      return { ...state, currentUser: payload };
    case userTypes.DELETE_CURRENT_USER_SUCCESS:
      return { ...state, currentUser: {} };
    case userTypes.CHANGE_USER_PASSWORD_FAIL:
      return {
        ...state,
        changePasswordAction: {
          ...initialState.changePasswordAction,
          wrongPassword: true,
        },
      };
    case userTypes.CHANGE_USER_PASSWORD_SUCCESS:
      return {
        ...state,
        changePasswordAction: {
          ...initialState.changePasswordAction,
          success: true,
        },
      };
    case userTypes.GET_CURRENT_SYSTEM_USERS_SUCCESS:
      return { ...state, currentSystemUsers: payload };
    default:
      return state;
  }
};

export default users;
