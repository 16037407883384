/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import styles from "./ChatComponent.module.scss";
import {
  Paper,
  Button,
  InputBase,
  Divider,
  IconButton,
  Avatar,
  Chip,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const MobileChatComponent = ({
  currentTicket,
  setHandleOpenMobileActions,
  slug,
  handleOpen,
  handleNavigate
}) => {
  return (
    <>
      <Paper
        elevation={2}
        className={`${styles["chat-header"]} ${!currentTicket?.responsible && styles["unset"]}`}
        style={{ zIndex: "10", display: "block" }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "space-between",
            marginBottom: "3%",
          }}
        >
          <h5
            style={{
              marginRight: "3%",
              whiteSpace: "nowrap",
              fontSize: "14px",
              width: "100%",
              display: currentTicket?.customer?.paidUser === true && "flex",
              cursor: "pointer",
            }}
          >
            {currentTicket?.customer?.paidUser === true && (
              <div className={styles["special-user"]} />
            )}
            {currentTicket?.name?.length > 36
              ? currentTicket?.name?.slice(0, 36) + "..."
              : currentTicket?.name}
          </h5>
          <MoreVertIcon
            onClick={() => {
              setHandleOpenMobileActions(true);
            }}
          />
        </div>
        {!currentTicket?.responsible && (
          <>
            <Divider style={{ borderColor: "rgb(13, 193, 206)" }} />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                marginTop: "3%",
              }}
            >
              {slug === "61ee8b87a07ddf1eb8268265" && (
                <div className={styles["user-icon"]} onClick={handleNavigate} />
              )}
              <p style={{ whiteSpace: "nowrap",marginLeft:"3%"}}>
                Потребител:{" "}
                <b style={{ marginLeft: "5px" }}>
                  {currentTicket?.customer?.name === undefined
                    ? currentTicket?.customer?.phoneNumber
                    : currentTicket?.customer?.name}
                </b>
              </p>
            </div>
              <Button
                onClick={handleOpen}
                disableElevation={true}
                disableRipple
                disableFocusRipple
                variant="contained"
                sx={{
                  color: "white",
                  backgroundColor: "#0DC1CE",
                  cursor: "pointer",
                  mt:"3%",
                  width:"100%",
                  "&:hover": {
                    backgroundColor: "#0DC1CE",
                  },
                }}
              >
                Назначи тема
              </Button>
          </>
        )}
      </Paper>
    </>
  );
};
export default MobileChatComponent;
