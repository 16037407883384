/* eslint-disable */
import { Paper, Button, FormControl, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import React, { useEffect } from "react";
import styles from "./FilterTopicsModal.module.scss";
import CloseIcon from "@mui/icons-material/Close";
import Datepicker from "../../DatePicker/DatePicker";
import moment from "moment";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { getCurrentSystemUsers } from "../../../actions/users";
import Select, { components } from "react-select";
import { setTicketFilters } from "../../../actions/tickets";
import { dispatch } from "rxjs/internal/observable/pairs";

const FilterTopicsModal = ({ handleClose, open, searchDateBy, setSearchDateBy, searchSecondQuery, radioBtnValueSecond, handleRadioBtnChangeSecond, labels, payload, setPayload, setNextPage, setQuery, getCurrentSystem, getTicketsForSystem, slug, query, setActiveInnerFilters, filteredInfo, currentSystemUsers, getCurrentSystemUsers, setTicketFilters, filters }) => {
  useEffect(() => {
    if (open)
      getCurrentSystemUsers({
        system: slug,
        noPagination: true,
        onSuccess: () => {},
      });
  }, []);

  return (
    <Paper className={styles["assign-topic-modal"]}>
      <div className={styles["assign-topic-heading-container"]}>
        <h3>ФИЛТРИРАЙ ПО</h3>
        <CloseIcon onClick={handleClose} />
      </div>
      <div className={styles["filter-part"]}>
        <p style={{ fontSize: "15px" }}>ВИД НА ТЕМАТА</p>
        <div className="pretty p-default p-pulse p-curve" style={{ marginTop: "3%" }}>
          <input
            type="checkbox"
            onChange={() => {}}
            checked={payload.mineResponsible}
            onClick={() => {
              if (payload.mineResponsible)
                setPayload({
                  ...payload,
                  mineResponsible: false,
                  inTeam: false,
                  withPayment: false,
                  specialCustomers: false,
                });
              else
                setPayload({
                  ...payload,
                  mineResponsible: true,
                  inTeam: false,
                  withPayment: false,
                  specialCustomers: false,
                });
            }}
          />
          <div className="state">
            <label>Назначени към мен (Q)</label>
          </div>
        </div>
        <div className="pretty p-default p-pulse p-curve" style={{ marginTop: "3%" }}>
          <input
            type="checkbox"
            checked={payload.inTeam}
            onChange={() => {}}
            onClick={() => {
              if (payload.inTeam)
                setPayload({
                  ...payload,
                  inTeam: false,
                  mineResponsible: false,
                });
              else
                setPayload({
                  ...payload,
                  inTeam: true,
                  mineResponsible: false,
                });
            }}
          />
          <div className="state">
            <label>В които участвам (W)</label>
          </div>
        </div>

        <div className="pretty p-default p-pulse p-curve" style={{ marginTop: "3%" }}>
          <input
            type="checkbox"
            checked={payload.showTeamMembers}
            onChange={() => {}}
            onClick={() => {
              if (payload.showTeamMembers)
                setPayload({
                  ...payload,
                  showTeamMembers: false,
                  teamMembers: [],
                });
              else
                setPayload({
                  ...payload,
                  showTeamMembers: true,
                  teamMembers: [],
                });
            }}
          />
          <div className="state">
            <label>Добавен в екип</label>
          </div>
        </div>
        {payload?.showTeamMembers && (
          <div style={{ width: "100%", marginTop: "10px" }}>
            <Select
              options={currentSystemUsers.map((opt) => {
                return { label: opt.name, value: opt };
              })}
              isMulti
              // menuIsOpen={true}
              styles={{ margin: "20px 0px" }}
              components={{
                IndicatorSeparator: () => null,
                // DropdownIndicator,
              }}
              onChange={(e) => {
                setPayload({
                  ...payload,
                  showTeamMembers: true,
                  teamMembers: e?.map(({ label, value }) => value),
                });
              }}
            />
          </div>
        )}

        {slug === "61ee8b87a07ddf1eb8268265" && (
          <>
            <div className="pretty p-default p-pulse p-curve" style={{ marginTop: "3%", zIndex: "1" }}>
              <input
                type="checkbox"
                checked={payload.specialCustomers}
                onChange={() => {}}
                onClick={() => {
                  if (payload.specialCustomers)
                    setPayload({
                      ...payload,
                      inTeam: false,
                      mineResponsible: false,
                      specialCustomers: false,
                      withPayment: false,
                    });
                  else
                    setPayload({
                      ...payload,
                      inTeam: false,
                      mineResponsible: false,
                      specialCustomers: true,
                      withPayment: false,
                    });
                }}
              />
              <div className="state">
                <label>Специален потребител</label>
              </div>
            </div>
            <div className="pretty p-default p-pulse p-curve" style={{ marginTop: "3%" }}>
              <input
                type="checkbox"
                checked={payload.withPayment}
                onChange={() => {}}
                onClick={() => {
                  if (payload.withPayment)
                    setPayload({
                      ...payload,
                      inTeam: false,
                      mineResponsible: false,
                      specialCustomers: false,
                      withPayment: false,
                    });
                  else
                    setPayload({
                      ...payload,
                      inTeam: false,
                      mineResponsible: false,
                      specialCustomers: false,
                      withPayment: true,
                    });
                }}
              />
              <div className="state">
                <label>Инициирано плащане</label>
              </div>
            </div>
          </>
        )}
      </div>
      <div className={styles["filter-part"]}>
        <p style={{ fontSize: "15px" }}>ПЕРИОД</p>
        <div style={{ width: "55%", marginTop: "4%" }}>
          <span>Период</span>
          <div style={{ display: "flex" }}>
            <Datepicker
              onChange={(startDate) => {
                setPayload({ ...payload, startDate: startDate });
              }}
              pickDate={payload?.startDate ? moment(payload.startDate).toDate() : null}
              value={payload?.startDate ? moment(payload.startDate).format("DD.MM.YYYY") : null}
            />
            <p
              style={{
                marginLeft: "4%",
                marginRight: "4%",
                marginTop: "6%",
              }}
            >
              -
            </p>
            <Datepicker
              pickDate={payload?.endDate ? moment(payload.endDate).toDate() : null}
              onChange={(endDate) => {
                setPayload({ ...payload, endDate: endDate });
              }}
              value={moment(payload.endDate).format("DD.MM.YYYY")}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "2%",
          }}
        >
          <p style={{ marginBottom: "3%", whiteSpace: "nowrap" }}>Търси избрания период по</p>
          <Paper
            component={"form"}
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              width: 270,
              height: 38,
              marginRight: "3%",
            }}
          >
            <FormControl
              sx={{
                m: 0,
                height: 40,
                width: 530,
                border: 0,
                mt: 0.5,
              }}
              fullWidth={true}
            >
              <Select
                variant="standard"
                disableUnderline={true}
                onClick={() => {
                  if (!searchDateBy) setSearchDateBy(true);
                }}
                renderValue={() => {
                  return searchSecondQuery;
                }}
                displayEmpty
                style={{
                  height: "35px",
                  border: "0",
                  outline: "none",
                  width: "100%",
                  padding: "0px",
                }}
                inputProps={{ "aria-label": "Without label" }}
                open={searchDateBy}
              >
                <FormControl style={{ width: "100%" }}>
                  <RadioGroup
                    value={radioBtnValueSecond}
                    onChange={handleRadioBtnChangeSecond}
                    style={{
                      padding: "1vw",
                      zIndex: "0",
                      boxShadow: "0px 5px 15px 0px #00347026",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <h3>Търсене по</h3>
                      <b
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setSearchDateBy(false);
                        }}
                      >
                        X
                      </b>
                    </div>
                    <FormControlLabel
                      value="created"
                      control={
                        <Radio
                          sx={{
                            "&.Mui-checked": {
                              color: "#0DC1CE",
                            },
                          }}
                        />
                      }
                      label="Дата на създаване"
                    />
                    <FormControlLabel
                      value="activity"
                      control={
                        <Radio
                          sx={{
                            "&.Mui-checked": {
                              color: "#0DC1CE",
                            },
                          }}
                        />
                      }
                      label="Последна активност"
                    />
                  </RadioGroup>
                  <div
                    className="button-container"
                    style={{
                      height: "60px",
                      boxShadow: "0px 5px 15px 0px #00347026",
                      display: "flex",
                      alignItems: "center",
                      zIndex: "3",
                    }}
                  >
                    <div
                      className="button"
                      style={{
                        height: "40px",
                        width: "95%",
                        marginLeft: "2.5%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#0DC1CE",
                        color: "white",
                        cursor: "pointer",
                        borderRadius: "5px",
                      }}
                      onClick={() => {
                        setSearchDateBy(false);
                      }}
                    >
                      ЗАДАЙ
                    </div>
                  </div>
                </FormControl>
              </Select>
            </FormControl>
          </Paper>
        </div>
      </div>
      <div className={styles["filter-part"]} style={{ height: "45%", overflowY: "auto" }}>
        <p>ЗАДАДЕНИ ЕТИКЕТИ</p>
        {labels?.map((label, i) => {
          return (
            <div className={styles["filter"]} key={i}>
              <div style={{ width: "98%", marginLeft: "2%" }}>
                <div className="pretty p-default p-pulse p-curve">
                  <input
                    type="checkbox"
                    onChange={() => {}}
                    checked={payload.labels.includes(label?._id)}
                    onClick={() => {
                      const newLabels = payload.labels.slice(0);
                      if (newLabels.includes(label?._id)) newLabels.splice(newLabels.indexOf(label?._id), 1);
                      else newLabels.push(label?._id);
                      setPayload({ ...payload, labels: newLabels });
                    }}
                  />
                  <div className="state">
                    <label>{label?.name}</label>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "7%",
          padding: "0.5vw",
          justifyContent: "space-around",
          boxShadow: "0 1px 8px rgba(0, 0, 0, 0.2)",
        }}
      >
        <Button
          onClick={() => {
            const newFilters = { ...filters, mineResponsible: false, inTeam: false, showTeamMembers: false, teamMembers: [], withPayment: false, specialCustomers: false, labels: [], startDate: undefined, endDate: undefined, period: "created" };
            setPayload({ mineResponsible: false, inTeam: false, showTeamMembers: false, teamMembers: [], withPayment: false, specialCustomers: false, labels: [], startDate: undefined, endDate: undefined, period: "created" });
            setTicketFilters(newFilters);
            setNextPage(1);
            setActiveInnerFilters(false);
            filteredInfo.current.totalTickets = 0;
            getTicketsForSystem({
              system: slug,
              pageNumber: 1,
              ...newFilters,
            });
          }}
          disableElevation
          disableRipple
          disableFocusRipple
          variant="contained"
          sx={{
            width: "30%",
            color: "black",
            backgroundColor: "#E6E6E6",
            textTransform: "none",
            cursor: "pointer",
            "&:hover": {
              backgroundColor: "#E6E6E6",
            },
          }}
        >
          <b>Изчисти (F)</b>
        </Button>
        <Button
          onClick={() => {
            if (moment(payload.startDate).isAfter(payload.endDate)) toast.error("Началната дата трябва да бъде преди крайната.");
            else {
              const newFilters = {
                ...filters,
                ...payload,
                ...(payload?.startDate && { startDate: moment(payload?.startDate).startOf("day").toISOString() }),
                ...(payload?.startDate && !payload?.endDate ? { endDate: moment().endOf("day").toISOString() } : payload?.endDate ? { endDate: moment(payload?.endDate).endOf("day").toISOString() } : "---"),
                teamMembers: payload?.teamMembers?.map((user) => user?._id),
              };
              setTicketFilters({ ...newFilters });
              setNextPage(1);
              getTicketsForSystem({
                system: slug,
                pageNumber: 1,
                ...newFilters,
                filtered: true,
                teamMembers: payload?.teamMembers?.map((user) => user?._id),
              });
              getCurrentSystem(slug);
              setActiveInnerFilters(true);
              handleClose();
            }
          }}
          disableElevation
          disableRipple
          disableFocusRipple
          variant="contained"
          sx={{
            width: "30%",
            color: "white",
            backgroundColor: "#0DC1CE",
            textTransform: "none",
            cursor: "pointer",
            "&:hover": {
              backgroundColor: "#0DC1CE",
            },
          }}
        >
          <b>Задай филтри</b>
        </Button>
      </div>
    </Paper>
  );
};

const mapStateToProps = (state) => ({
  currentSystemUsers: state.users.currentSystemUsers,
  filters: state.tickets.filters,
});

const mapDispatchToProps = (dispatch) => ({
  getCurrentSystemUsers: (payload) => dispatch(getCurrentSystemUsers(payload)),
  setTicketFilters: (payload) => dispatch(setTicketFilters(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterTopicsModal);
