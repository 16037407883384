/* eslint-disable */
import React from "react";
import { Card, CardContent, Divider, Avatar } from "@mui/material";
import styles from "./ChatCard.module.scss";
import { useParams, useNavigate } from "react-router-dom";
import { format } from "date-fns";
import moment from "moment";
import { Checkbox } from "@mui/material";

export default function ChatCard({
  id,
  name,
  updatedAt,
  createdAt,
  responsible = null,
  readed = false,
  _unread,
  _lastMessage,
  archivedAt,
  archivedBy,
  completedAt,
  completedBy,
  lastMessageDate,
  isClicked,
  setSelectedTickets,
  selectedTickets,
  paidUser,
  getTicketsForSystem,
  topic,
}) {
  const navigate = useNavigate();
  const { slug } = useParams();
  const handleClick = () => {
    if (isClicked === true) {
      handleChange();
    } else {
      getTicketsForSystem(slug);
      window.open(`/system/${slug}/${id}/chat`);
    }
  };

  const handleChange = () => {
    if (selectedTickets.includes(id)) {
      setSelectedTickets(selectedTickets.filter((x) => x !== id));
    } else {
      setSelectedTickets([...selectedTickets, id]);
    }
  };

  return (
    <Card
      onClick={handleClick}
      elevation={2}
      style={
        responsible === null
          ? {
              border: "1px solid #FD416C",
              backgroundColor: "#FAFAFA",
            }
          : completedAt !== null
          ? {
              border: "1px solid rgb(1, 208, 136)",
              backgroundColor: "#FAFAFA",
            }
          : _unread > 0
          ? {
              border: "1px solid #0DC1CE",
              backgroundColor: "#FAFAFA",
            }
          : completedAt === null
          ? {
              border: "1px solid #0DC1CE",
              backgroundColor: "#FAFAFA",
            }
          : null
      }
      className={styles["chat-card-container"]}
    >
      <CardContent>
        <div className={styles["card-heading"]}>
          {window.innerWidth < 900 ? (
            <div
              className={styles["card-heading-left"]}
              style={{ display: "block" }}
            >
              {responsible === null && (
                <>
                  <span style={{ color: "#FD416C", fontWeight: "bold" }}>
                    Неназначено
                  </span>
                </>
              )}
              {archivedBy?.length > 1 && (
                <>
                  <span style={{ color: "#4F4F53", fontWeight: "bold" }}>
                    Архивирана
                  </span>
                </>
              )}
              {completedBy?.length > 1 && (
                <>
                  <span style={{ color: "#01D088", fontWeight: "bold" }}>
                    Завършена
                  </span>
                </>
              )}
              <div style={{ display: "flex",alignItems:"center" }}>
                {paidUser === true && (
                  <div className={styles["special-user"]} />
                )}
                <h5
                  className={styles["card-header"]}
                  style={{
                    paddingLeft: paidUser === true && "10px",
                    margin: "5px 0px",
                  }}
                >
                  {name.length > 65 ? name.slice(0, 65) + "..." : name}
                </h5>
              </div>
            </div>
          ) : (
            <div className={styles["card-heading-left"]}>
              {responsible === null && (
                <>
                  <span style={{ color: "#FD416C", fontWeight: "bold" }}>
                    Неназначено
                  </span>
                  <Divider
                    className={styles.divider}
                    style={{ backgroundColor: "#0DC1CE" }}
                    orientation="vertical"
                  />
                </>
              )}
              {archivedBy?.length > 1 && (
                <>
                  <span style={{ color: "#4F4F53", fontWeight: "bold" }}>
                    Архивирана
                  </span>
                  <Divider
                    className={styles.divider}
                    style={{ backgroundColor: "#0DC1CE" }}
                    orientation="vertical"
                  />
                </>
              )}
              {completedBy?.length > 1 && (
                <>
                  <span style={{ color: "#01D088", fontWeight: "bold" }}>
                    Завършена
                  </span>
                  <Divider
                    className={styles.divider}
                    style={{ backgroundColor: "#0DC1CE" }}
                    orientation="vertical"
                  />
                </>
              )}
              {paidUser === true && <div className={styles["special-user"]} />}
              <h5
                className={styles["card-header"]}
                style={{ paddingLeft: paidUser === true && "10px" }}
              >
                {name.length > 65 ? name.slice(0, 65) + "..." : name}
              </h5>
            </div>
          )}

          {isClicked && (
            <div className={styles["card-heading-right"]}>
              <Checkbox
                onChange={handleChange}
                inputProps={{ "aria-label": "controlled" }}
                checked={selectedTickets.includes(id)}
              />
            </div>
          )}
        </div>
        <div className={styles["card-info"]}>
          <p>
            Потребител: <b>{topic?.customer?.name}</b>
          </p>
          <Divider
            className={styles.divider}
            style={{ backgroundColor: "#0DC1CE" }}
            orientation="vertical"
          />
          <div className={styles["responsible"]} style={{ display: "flex" }}>
            Отговорник:{" "}
            {responsible == null ? (
              <span
                style={{ color: "black", fontWeight: "bold", marginLeft: "5%" }}
              >
                - -
              </span>
            ) : (
              <>
                <Avatar
                  src={responsible?.avatar}
                  alt="avatar"
                  style={{
                    border: "0.3px solid #0dc1ce",
                    margin: "0px 10px",
                    height: "20px",
                    width: "20px",
                  }}
                />
                <span>
                  <b>{responsible?.name}</b>
                </span>
              </>
            )}
          </div>
          {window.innerWidth < 900 && (
            <Divider
              className={styles.divider}
              style={{ backgroundColor: "#0DC1CE" }}
              orientation="vertical"
            />
          )}
        </div>
        <div className={styles["card-info"]}>
          <p className={styles["dateInfo"]}>
            Създаден на: <b>{moment(createdAt).format("DD.MM.YYYY | HH:mm")}</b>
          </p>
          <Divider
            className={styles.divider}
            style={{ backgroundColor: "#0DC1CE" }}
            orientation="vertical"
          />
          <p className={styles["dateInfo"]}>
            Последна активност:{" "}
            <b>{moment(lastMessageDate).format("DD.MM.YYYY | HH:mm")}</b>
          </p>
        </div>

        {topic?.labels?.length > 0 && (
          <>
            {window.innerWidth < 900 && (
              <Divider
                className={styles.divider}
                style={{ backgroundColor: "#0DC1CE" }}
                orientation="vertical"
              />
            )}
            <p
              className={styles["responsible"]}
              style={{ marginTop: "1%", display: "flex" }}
            >
              Етикети:{" "}
              {topic?.labels.map((label, i) => {
                return (
                  <React.Fragment key={i}>
                    <b>{label?.name}</b>
                    {i < topic?.labels?.length - 1 && (
                      <Divider
                        className={styles.divider}
                        style={{ backgroundColor: "#0DC1CE", height: "20px" }}
                        orientation="vertical"
                      />
                    )}
                  </React.Fragment>
                );
              })}
            </p>
          </>
        )}
      </CardContent>
    </Card>
  );
}
