/* eslint-disable */
import { notesTypes } from "../actions/notes";
import { omit } from "lodash";

const initialState = {
  notes: [],
  notesInfo: {},
};

const handleNotes = (state, payload) => {
  let newState = [...state];
  if (payload.page === 1 || payload.hasPrevPage === false) {
    newState = [...payload.docs];
  } else {
    newState = [...state, ...payload.docs];
  }
  return newState;
};

const notes = (state = initialState, { type, payload }) => {
  switch (type) {
    case notesTypes.GET_NOTES_LIST_SUCCESS:
      return {
        ...state,
        notes: handleNotes(state.notes, payload),
        notesInfo: omit(payload, "docs"),
      };
    case notesTypes.CREATE_NOTE_SUCCESS:
      return { ...state, notes: [...state.notes, payload] };
    default:
      return state;
  }
};

export default notes;
