/* eslint-disable */
import { Box, Button, Divider } from "@mui/material";
import React, { useEffect, useState, useRef, useCallback } from "react";
import styles from "./AmarantComponent.module.scss";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { listVignettes } from "../../actions/amarantFields";
import { connect } from "react-redux";
import moment from "moment";
import { filterByProp } from "../../utils/helperFunctions";

const columnNames = ["Рег. номер", "Дата на закупуване", "Час на закупуване", "Вид", "Сума", "Начална дата", "Статус", "Дата на активация", "Активирана"];

function VignettesComponent({ vignettes, listVignettes, customerID }) {
  const [filter, setFilter] = useState("");

  useEffect(() => {
    listVignettes({
      customerSupportId: customerID,
      //customerSupportId: "6231bc7bc8696d0016d4af13",
    });
  }, []);

  const handleValid = (vig) => {
    switch (vig?.price) {
      case 9:
      case 10:
        return moment().isAfter(moment(vig?.purchaseRequest?.saleRows?.[0]?.activationDate).add(1, "D")) ? "Изтекла" : "Валидна";
      case 13:
      case 15:
        return moment().isAfter(moment(vig?.purchaseRequest?.saleRows?.[0]?.activationDate).add(1, "W")) ? "Изтекла" : "Валидна";
      case 27:
      case 30:
        return moment().isAfter(moment(vig?.purchaseRequest?.saleRows?.[0]?.activationDate).add(1, "M")) ? "Изтекла" : "Валидна";
      case 48:
      case 54:
        return moment().isAfter(moment(vig?.purchaseRequest?.saleRows?.[0]?.activationDate).add(3, "M")) ? "Изтекла" : "Валидна";
      case 87:
      case 97:
        return moment().isAfter(moment(vig?.purchaseRequest?.saleRows?.[0]?.activationDate).add(1, "Y")) ? "Изтекла" : "Валидна";
      default:
        break;
    }
  };

  return (
    <div style={{ overflowY: "auto", height: window.innerWidth < 900 ? "120%" : "95%", width: "100%" }}>
      {window.innerWidth < 900 ? (
        <React.Fragment>
          <div className={styles["tabs-container"]} style={{ padding: "0px" }}>
            {vignettes.map((vignette, i) => {
              return (
                <div
                  className={styles["row-container"]}
                  style={{
                    marginTop: "5%",
                    display: "block",
                    height: "unset",
                  }}
                  key={i}
                >
                  <div className={styles["mobile-box"]}>
                    <p style={{ marginBottom: "1%", fontSize: "14px" }}>{columnNames[0]}</p>
                    <b style={{ marginBottom: "1%", fontSize: "14px" }}>{vignette?.licensePlate}</b>
                  </div>
                  <div className={styles["mobile-box"]}>
                    <p style={{ marginBottom: "1%", fontSize: "14px" }}>{columnNames[1]}</p>
                    <b style={{ marginBottom: "1%", fontSize: "14px" }}>{moment(vignette?.createdAt).format("DD.MM.YYYY")}</b>
                  </div>
                  <Divider />
                  <div className={styles["mobile-box"]}>
                    <p style={{ marginBottom: "1%", fontSize: "14px" }}>{columnNames[2]}</p>
                    <b style={{ marginBottom: "1%", fontSize: "14px" }}>{moment(vignette?.createdAt).format("HH:mm")}</b>
                  </div>
                  <Divider />
                  <div className={styles["mobile-box"]}>
                    <p style={{ marginBottom: "1%", fontSize: "14px" }}>{columnNames[3]}</p>
                    <b style={{ marginBottom: "1%", fontSize: "14px" }}>
                      {vignette?.price === 10 || vignette?.price === 9
                        ? "Уикенд"
                        : vignette?.price === 15 || vignette?.price === 13
                        ? "Седмична"
                        : vignette?.price === 30 || vignette?.price === 27
                        ? "Месечена"
                        : vignette?.price === 54 || vignette?.price === 48
                        ? "Тримесечна"
                        : vignette?.price === 97 || vignette?.price === 87
                        ? "Годишна"
                        : ""}
                    </b>
                  </div>
                  <Divider />
                  <div className={styles["mobile-box"]}>
                    <p style={{ marginBottom: "1%", fontSize: "14px" }}>{columnNames[4]}</p>
                    <b style={{ marginBottom: "1%", fontSize: "14px" }}>{vignette?.price}лв.</b>
                  </div>
                  <Divider />
                  <div className={styles["mobile-box"]}>
                    <p style={{ marginBottom: "1%", fontSize: "14px" }}>{columnNames[5]}</p>
                    <b style={{ marginBottom: "1%", fontSize: "14px" }}>{moment(vignette?.purchaseRequest?.saleRows?.[0]?.activationDate).format("DD.MM.YYYY")}</b>
                  </div>
                  <Divider />
                  <div className={styles["mobile-box"]}>
                    <p style={{ marginBottom: "1%", fontSize: "14px" }}>{columnNames[6]}</p>
                    <b style={{ marginBottom: "1%", fontSize: "14px" }}>{handleValid(vignette)}</b>
                  </div>
                  <Divider />
                  <div className={styles["mobile-box"]}>
                    <p style={{ marginBottom: "1%", fontSize: "14px" }}>{columnNames[8]}</p>
                    <b style={{ marginBottom: "1%", fontSize: "14px" }}>{!vignette?.newApp ? "Да" : vignette?.activationDate ? "Да" : "Не"}</b>
                  </div>
                </div>
              );
            })}
          </div>
        </React.Fragment>
      ) : (
        <>
          <div className={styles["columns-container"]}>
            <div className={styles["upper-names"]}>
              <div className={`${styles["name-icon"]}`} style={{ width: "8%" }}>
                <p>{columnNames[0]}</p>
                <KeyboardArrowDownIcon
                  style={{
                    transform: filter === "plate" && "rotate(180deg)",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (filter === "plate") {
                      setFilter("reversePlate");
                      filterByProp(vignettes, "reversePlate", "licensePlate");
                    } else {
                      setFilter("plate");
                      filterByProp(vignettes, "plate", "licensePlate");
                    }
                  }}
                />
              </div>
              <div className={`${styles["name-icon"]}`} style={{ width: "8%" }}>
                <p>{columnNames[6]}</p>
                <KeyboardArrowDownIcon
                  style={{
                    transform: filter === "status" && "rotate(180deg)",
                    cursor: "pointer",
                  }}
                  onClick={() => {}}
                />
              </div>
              <div className={`${styles["name-icon"]}`} style={{ width: "11%" }}>
                <p>{columnNames[1]}</p>
                <KeyboardArrowDownIcon
                  style={{
                    transform: filter === "buyDate" && "rotate(180deg)",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (filter === "buyDate") {
                      setFilter("reverseBuyDate");
                      filterByProp(vignettes, "reverseCreatedAt", "createdAt");
                    } else {
                      setFilter("buyDate");
                      filterByProp(vignettes, "createdAt", "createdAt");
                    }
                  }}
                />
              </div>
              <div className={`${styles["name-icon"]}`} style={{ width: "11%" }}>
                <p>{columnNames[2]}</p>
                <KeyboardArrowDownIcon
                  style={{
                    transform: filter === "buyTime" && "rotate(180deg)",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (filter === "buyTime") {
                      setFilter("reverseBuyTime");
                      vignettes.sort((a, b) => {
                        var c = moment(a.createdAt).format("HH:mm");
                        var d = moment(b.createdAt).format("HH:mm");
                        if (!c && !d) {
                          return 0;
                        } else if (c && !d) {
                          return 1;
                        } else if (!c && d) {
                          return -1;
                        } else {
                          const value1 = c.toString().toUpperCase();
                          const value2 = d.toString().toUpperCase();
                          if (value1 < value2) {
                            return 1;
                          } else if (value1 > value2) {
                            return -1;
                          } else {
                            return 0;
                          }
                        }
                      });
                    } else {
                      vignettes.sort((a, b) => {
                        var c = moment(a.createdAt).format("HH:mm");
                        var d = moment(b.createdAt).format("HH:mm");
                        if (!c && !d) {
                          return 0;
                        } else if (c && !d) {
                          return -1;
                        } else if (!c && d) {
                          return 1;
                        } else {
                          const value1 = c.toString().toUpperCase();
                          const value2 = d.toString().toUpperCase();
                          if (value1 < value2) {
                            return -1;
                          } else if (value1 > value2) {
                            return 1;
                          } else {
                            return 0;
                          }
                        }
                      });
                      setFilter("buyTime");
                    }
                  }}
                />
              </div>
              <div className={`${styles["name-icon"]}`} style={{ width: "11%" }}>
                <p>{columnNames[7]}</p>
                <KeyboardArrowDownIcon
                  style={{
                    transform: filter === "activateTime" && "rotate(180deg)",
                    cursor: "pointer",
                  }}
                  onClick={() => {}}
                />
              </div>
              <div className={`${styles["name-icon"]}`} style={{ width: "8%" }}>
                <p>{columnNames[3]}</p>
                <KeyboardArrowDownIcon
                  style={{
                    transform: filter === "type" && "rotate(180deg)",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (filter === "type") {
                      setFilter("reverseType");
                      filterByProp(vignettes, "reversePrice", "price");
                    } else {
                      setFilter("type");
                      filterByProp(vignettes, "price", "price");
                    }
                  }}
                />
              </div>
              <div className={`${styles["name-icon"]}`} style={{ width: "8%" }}>
                <p>{columnNames[4]}</p>
                <KeyboardArrowDownIcon
                  style={{
                    transform: filter === "price" && "rotate(180deg)",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (filter === "price") {
                      setFilter("reversePrice");
                      filterByProp(vignettes, "reversePrice", "price");
                    } else {
                      setFilter("price");
                      filterByProp(vignettes, "price", "price");
                    }
                  }}
                />
              </div>
              <div className={`${styles["name-icon"]}`} style={{ width: "8%" }}>
                <p>{columnNames[5]}</p>
                <KeyboardArrowDownIcon
                  style={{
                    transform: filter === "buyDate2" && "rotate(180deg)",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (filter === "buyDate2") {
                      setFilter("reverseBuyDate");
                      filterByProp(vignettes, "reverseCreatedAt", "createdAt");
                    } else {
                      setFilter("buyDate2");
                      filterByProp(vignettes, "createdAt", "createdAt");
                    }
                  }}
                />
              </div>
              <div className={`${styles["name-icon"]}`} style={{ width: "12%" }}>
                <p>{columnNames[8]}</p>
                <KeyboardArrowDownIcon
                  style={{
                    transform: filter === "activated" && "rotate(180deg)",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (filter === "activated") {
                      setFilter("reverseActivated");
                      filterByProp(vignettes, "reverseCreatedAt", "createdAt");
                    } else {
                      setFilter("activated");
                      filterByProp(vignettes, "activated", null);
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <div className={styles["tabs-container"]} style={{ overfloxY: "auto" }}>
            {vignettes.map((vignette, i) => {
              return (
                <div className={styles["row-container"]} key={i}>
                  <div className={styles["data"]}>
                    <b>{vignette?.licensePlate || "Изтрито МПС"}</b>
                  </div>
                  <Divider
                    style={{
                      backgroundColor: "#D3D3D3",
                      height: "65%",
                    }}
                    orientation="vertical"
                  />
                  <div className={styles["data"]} style={{ width: "8%" }}>
                    <b>{handleValid(vignette)}</b>
                  </div>

                  <Divider
                    style={{
                      backgroundColor: "#D3D3D3",
                      height: "65%",
                    }}
                    orientation="vertical"
                  />
                  <div className={styles["data"]} style={{ width: "11%" }}>
                    <b>{moment(vignette?.createdAt).format("DD.MM.YYYY")}</b>
                  </div>
                  <Divider
                    style={{
                      backgroundColor: "#D3D3D3",
                      height: "65%",
                    }}
                    orientation="vertical"
                  />
                  <div className={styles["data"]} style={{ width: "11%" }}>
                    <b>{moment(vignette?.createdAt).format("HH:mm")}</b>
                  </div>
                  <Divider
                    style={{
                      backgroundColor: "#D3D3D3",
                      height: "65%",
                    }}
                    orientation="vertical"
                  />
                  <div className={styles["data"]} style={{ width: "11%" }}>
                    <b>{moment(vignette?.purchaseRequest?.saleRows?.[0]?.activationDate).format("DD.MM.YYYY")}</b>
                  </div>
                  <Divider
                    style={{
                      backgroundColor: "#D3D3D3",
                      height: "65%",
                    }}
                    orientation="vertical"
                  />
                  <div className={styles["data"]}>
                    <b>
                      {vignette?.price === 10 || vignette?.price === 9
                        ? "Уикенд"
                        : vignette?.price === 15 || vignette?.price === 13
                        ? "Седмична"
                        : vignette?.price === 30 || vignette?.price === 27
                        ? "Месечена"
                        : vignette?.price === 54 || vignette?.price === 48
                        ? "Тримесечна"
                        : vignette?.price === 97 || vignette?.price === 87
                        ? "Годишна"
                        : ""}
                    </b>
                  </div>
                  <Divider
                    style={{
                      backgroundColor: "#D3D3D3",
                      height: "65%",
                    }}
                    orientation="vertical"
                  />
                  <div className={styles["data"]}>
                    <b>{vignette?.price}лв.</b>
                  </div>
                  <Divider
                    style={{
                      backgroundColor: "#D3D3D3",
                      height: "65%",
                    }}
                    orientation="vertical"
                  />
                  <div className={styles["data"]}>
                    <b>{moment(vignette?.createdAt).format("DD.MM.YYYY")}</b>
                  </div>
                  <Divider
                    style={{
                      backgroundColor: "#D3D3D3",
                      height: "65%",
                    }}
                    orientation="vertical"
                  />
                  <div className={styles["data"]}>
                    <b>{!vignette?.newApp ? "Да" : vignette?.activationDate ? "Да" : "Не"}</b>
                  </div>
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  vignettes: state.amarantFields.vignettes,
});

const mapDispatchToProps = (dispatch) => ({
  listVignettes: (payload) => dispatch(listVignettes(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VignettesComponent);
