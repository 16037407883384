/* eslint-disable */
import {
  Avatar,
  Button,
  FormControl,
  InputAdornment,
  Divider,
  Paper,
  Radio,
  TextField,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import styles from "./ForTopicModal.module.scss";
import CloseIcon from "@mui/icons-material/Close";

function ForTopicModal({ currentTicket, handleClose, slug, handleNavigate }) {
  return (
    <Paper className={styles["assign-topic-modal"]}>
      <div>
        <div className={styles["assign-topic-heading-container"]}>
          <h3>За темата</h3>
          <CloseIcon onClick={handleClose} />
        </div>
        <h4
          style={{
            marginLeft: "5%",
            whiteSpace: "nowrap",
            fontSize: "17px",
            display: currentTicket?.customer?.paidUser === true && "flex",
          }}
        >
          {currentTicket?.customer?.paidUser === true && (
            <div className={styles["special-user"]} />
          )}
          {currentTicket?.name?.length > 35
            ? currentTicket?.name?.slice(0, 35) + "..."
            : currentTicket?.name}
        </h4>
        <Divider
          style={{
            borderColor: "rgb(13, 193, 206)",
            marginTop: "6%",
            marginBottom: "6%",
          }}
        />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginLeft: "3%",
          }}
        >
          {slug === "61ee8b87a07ddf1eb8268265" && (
            <div className={styles["user-icon"]} onClick={handleNavigate} />
          )}
          <p style={{ whiteSpace: "nowrap", marginLeft: "10px" }}>
            Потребител:{" "}
            <b style={{ marginLeft: "5px" }}>
              {currentTicket?.customer?.name === undefined
                ? currentTicket?.customer?.phoneNumber
                : currentTicket?.customer?.name}
            </b>
          </p>
        </div>
        <Divider
          style={{
            borderColor: "rgb(13, 193, 206)",
            marginTop: "6%",
            marginBottom: "6%",
          }}
        />
        {currentTicket?.responsible && (
          <>
            <div className={styles["current-assignee"]}>
              <p>Отговорник:</p>
              <Avatar
                src={currentTicket?.responsible?.avatar}
                style={{ width: "25px", height: "25px" }}
              />
              <p>{currentTicket?.responsible?.name}</p>
            </div>
            <Divider
              style={{
                borderColor: "rgb(13, 193, 206)",
                marginTop: "6%",
                marginBottom: "6%",
              }}
            />
          </>
        )}

        <p style={{ marginLeft: "5%" }}>
          Екип: <b>{currentTicket?.team?.length}</b>
        </p>
      </div>
    </Paper>
  );
}

export default ForTopicModal;
