/* eslint-disable */
import { Box, Modal, Paper } from "@mui/material";
import React, { useEffect } from "react";
import styles from "../ChatActions/Activity/Activity.module.scss";
import { useState } from "react";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";

const OldTicketsModal = ({
  open,
  handleClose,
  tickets,
  mobileActions,
  mobileModal,
  special,
}) => {
  const [openEditModal, setOpenEditModal] = useState(false);

  const style = {
    position: "absolute",
    top: mobileActions ? "73%" : "50%",
    width: mobileModal ? "100%" : "unset",
    height: mobileModal ? "100%" : "unset",
    left: special === true ? (window.innerWidth < 1600 ? "70%" : "83%") : "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 30,
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Paper
          className={styles["activity-modal"]}
          style={{
            width: window.innerWidth < 900 && "100%",
            height: window.innerWidth < 900 && "100%",
          }}
        >
          <div
            className={styles["activity-heading-container"]}
            style={{
              justifyContent: window.innerWidth < 900 && "flex-end",
              flexDirection: window.innerWidth < 900 && "row-reverse",
              alignItems: window.innerWidth < 900 && "center",
            }}
          >
            <h3>ПРЕДИШНИ ТЕМИ</h3>
            {window.innerWidth < 900 ? (
              <CloseIcon
                onClick={handleClose}
                style={{ width: "1.3em", height: "1.3em" }}
              />
            ) : (
              <p onClick={handleClose} style={{ cursor: "pointer" }}>
                Скрий {`>`}
              </p>
            )}
          </div>
          <div className={styles["activity-window"]}>
            {tickets?.map((ticket) => {
              return (
                <div
                  className={styles["old-ticket"]}
                  onClick={() => {
                    window.open(
                      `/system/61ee8b87a07ddf1eb8268265/${ticket?._id}/chat`
                    );
                  }}
                >
                  <h3>{ticket?.name}</h3>
                  <p style={{ marginTop: "2%" }}>
                    Създаден на:{" "}
                    <b>{moment(ticket?.createdAt).format("DD.MM.YYYY")}</b>
                  </p>
                </div>
              );
            })}
          </div>
        </Paper>
      </Box>
    </Modal>
  );
};

export default OldTicketsModal;
