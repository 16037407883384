/* eslint-disable */
import { Divider } from "@mui/material";
import React, { useEffect } from "react";
import styles from "./AmarantComponent.module.scss";
import { listDocuments } from "../../actions/amarantFields";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

const columnNames = ["Тип", "Име", "Валидна до"];

function DocumentsComponent({ customerID }) {
  const dispatch = useDispatch();
  const { documents } = useSelector(({ amarantFields }) => amarantFields) || [];

  useEffect(() => {
    dispatch(
      listDocuments({
        customerSupportId: customerID,
      })
    );
  }, []);

  const retrieveType = (document) => {
    switch (document?.type) {
      case "drivingLicense":
        return "Книжка";
      case "identityCard":
        return "Лична карта";
      case "company":
        return "Фирма";
      default:
        return "";
    }
  };

  const retrieveNames = (document) => {
    const names = [];
    if (document?.companyName?.length) names.push(document?.companyName);
    if (document?.fullName?.length) names.push(document?.fullName);
    return names.join(" - ");
  };

  return (
    <div
      style={{
        width: "100%",
        height: window.innerWidth < 900 ? "120%" : "95%",
        overflowY: "auto",
      }}
    >
      {window.innerWidth < 900 ? (
        <>
          <div className={styles["tabs-container"]} style={{ padding: "0px" }}>
            {documents.map((doc, i) => {
              return (
                <div className={styles["row-container"]} style={{ marginTop: "5%", display: "block", height: "unset" }} key={i}>
                  <div className={styles["mobile-box"]}>
                    <p style={{ marginBottom: "1%", fontSize: "14px" }}>{columnNames[0]}</p>
                    <b style={{ marginBottom: "1%", fontSize: "14px" }}>{retrieveType(doc)}</b>
                  </div>
                  <div className={styles["mobile-box"]}>
                    <p style={{ marginBottom: "1%", fontSize: "14px" }}>{columnNames[1]}</p>
                    <b style={{ marginBottom: "1%", fontSize: "14px" }}>{retrieveNames(doc)}</b>
                  </div>
                  <Divider />
                  <div className={styles["mobile-box"]}>
                    <p style={{ marginBottom: "1%", fontSize: "14px" }}>{columnNames[2]}</p>
                    <b style={{ marginBottom: "1%", fontSize: "14px" }}>{moment(doc?.expirationDate).format("DD.MM.YYYY")}</b>
                  </div>
                </div>
              );
            })}
          </div>
        </>
      ) : (
        <>
          <div className={styles["columns-container"]}>
            <div
              className={styles["upper-names"]}
              style={{
                width: window.innerWidth < 1400 ? "150%" : "100%",
              }}
            >
              {" "}
              {columnNames.map((name) => (
                <div className={`${styles["name-icon"]}`} style={{ width: "20%" }}>
                  <p>{name}</p>
                </div>
              ))}
            </div>
          </div>
          <div className={styles["tabs-container"]}>
            {documents.map((doc, i) => {
              return (
                <React.Fragment key={i}>
                  <div
                    className={styles["row-container"]}
                    style={{
                      width: window.innerWidth < 1400 ? "150%" : "100%",
                    }}
                  >
                    <div className={styles["data"]} style={{width:"20%"}}>
                      <b>{retrieveType(doc)}</b>
                    </div>
                    <Divider
                      style={{
                        backgroundColor: "#D3D3D3",
                        height: "65%",
                      }}
                      orientation="vertical"
                    />
                    <div className={styles["data"]} style={{width:"20%"}}>
                      <b>{retrieveNames(doc)}</b>
                    </div>
                    <Divider
                      style={{
                        backgroundColor: "#D3D3D3",
                        height: "65%",
                      }}
                      orientation="vertical"
                    />
                    <div className={styles["data"]} style={{width:"20%"}}>
                      <b>{moment(doc?.expirationDate).format("DD.MM.YYYY")}</b>
                    </div>
                  </div>
                </React.Fragment>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
}

export default DocumentsComponent;
