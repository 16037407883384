/* eslint-disable */
import { Box, Button, Divider } from "@mui/material";
import React, { useEffect, useState, useRef, useCallback } from "react";
import styles from "./AmarantComponent.module.scss";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { listVignettes } from "../../actions/amarantFields";
import { connect } from "react-redux";
import moment from "moment";
import { filterByProp } from "../../utils/helperFunctions";
import axios from "axios";
import fileDownload from "js-file-download";
import { getPresignedUrlView } from "../../utils/files";

const columnNames = ["Дата", "Час", "За период", "Платена сума", "С карта", "Картодържател", "Фактура"];

function HistoryComponent({ user, customerID }) {
  const [filter, setFilter] = useState("");
  const fetchAndOpenPreSignedUrl = async (file) => {
    const url = await getPresignedUrlView(file);
    window.open(url);
  };
  return (
    <div
      style={{
        overflowY: "auto",
        height: window.innerWidth < 900 ? "120%" : "95%",
        width: "100%",
      }}
    >
      {window.innerWidth < 900 ? (
        <>
          <div className={styles["tabs-container"]} style={{ padding: "0px" }}>
            {user?._expenses.map((expense, i) => {
              return (
                <div
                  className={styles["row-container"]}
                  style={{
                    marginTop: "5%",
                    display: "block",
                    height: "unset",
                  }}
                  key={i}
                >
                  <div className={styles["mobile-box"]}>
                    <p style={{ marginBottom: "1%", fontSize: "14px" }}>{columnNames[0]}</p>
                    <b style={{ marginBottom: "1%", fontSize: "14px" }}>{moment(expense.paymentDate).format("DD.MM.YYYY")}</b>
                  </div>
                  <Divider />
                  <div className={styles["mobile-box"]}>
                    <p style={{ marginBottom: "1%", fontSize: "14px" }}>{columnNames[1]}</p>
                    <b style={{ marginBottom: "1%", fontSize: "14px" }}>{moment(expense.paymentDate).format("HH:mm")}</b>
                  </div>
                  <Divider />
                  <div className={styles["mobile-box"]}>
                    <p style={{ marginBottom: "1%", fontSize: "14px" }}>{columnNames[2]}</p>
                    <b style={{ marginBottom: "1%", fontSize: "14px" }}>
                      {moment(expense?.data.from).format("DD.MM")}-{moment(expense?.data.to).format("DD.MM.YYYY")}
                    </b>
                  </div>
                  <Divider />
                  <div className={styles["mobile-box"]}>
                    <p style={{ marginBottom: "1%", fontSize: "14px" }}>{columnNames[3]}</p>
                    <b style={{ marginBottom: "1%", fontSize: "14px" }}>{expense?.amount}лв.</b>
                  </div>
                  <Divider />
                  <div className={styles["mobile-box"]}>
                    <p style={{ marginBottom: "1%", fontSize: "14px" }}>{columnNames[4]}</p>
                    <b style={{ marginBottom: "1%", fontSize: "14px" }}>{expense?.data?.card}</b>
                  </div>
                  <Divider />
                  <div className={styles["mobile-box"]}>
                    <p style={{ marginBottom: "1%", fontSize: "14px" }}>{columnNames[5]}</p>
                    <b style={{ marginBottom: "1%", fontSize: "14px" }}>{expense?.data?.cardholder}</b>
                  </div>
                  <Divider />
                  <div className={styles["mobile-box"]}>
                    <p style={{ marginBottom: "1%", fontSize: "14px" }}>{columnNames[6]}</p>
                    <b style={{ marginBottom: "1%", fontSize: "14px" }}>{expense?.receipts[0].split("/")[4]}</b>
                  </div>
                  <Divider />
                </div>
              );
            })}
          </div>
        </>
      ) : (
        <>
          <div className={styles["columns-container"]}>
            <div className={styles["upper-names"]}>
              <div className={`${styles["name-icon"]}`} style={{ width: "8%" }}>
                <p>{columnNames[0]}</p>
                <KeyboardArrowDownIcon
                  style={{
                    transform: filter === "date" && "rotate(180deg)",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (filter === "date") {
                      setFilter("reverseDate");
                      filterByProp(user?._expenses, "reverseCreatedAt", "paymentDate");
                    } else {
                      setFilter("date");
                      filterByProp(user?._expenses, "createdAt", "paymentDate");
                    }
                  }}
                />
              </div>
              <div className={`${styles["name-icon"]}`} style={{ width: "8%" }}>
                <p>{columnNames[1]}</p>
                <KeyboardArrowDownIcon
                  style={{
                    transform: filter === "time" && "rotate(180deg)",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (filter === "time") {
                      setFilter("reverseTime");
                      filterByProp(user?._expenses, "reverseTime", "paymentDate");
                    } else {
                      setFilter("time");
                      filterByProp(user?._expenses, "time", "paymentDate");
                    }
                  }}
                />
              </div>
              <div className={`${styles["name-icon"]}`} style={{ width: "11%" }}>
                <p>{columnNames[2]}</p>
                <KeyboardArrowDownIcon
                  style={{
                    transform: filter === "fullDate" && "rotate(180deg)",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (filter === "fullDate") {
                      setFilter("reverseFullDate");
                      user?._expenses.sort((a, b) => {
                        var c = new Date(a.data.from);
                        var d = new Date(b.data.from);
                        return d - c;
                      });
                    } else {
                      setFilter("fullDate");
                      user?._expenses.sort((a, b) => {
                        var c = new Date(a.data.from);
                        var d = new Date(b.data.from);
                        return c - d;
                      });
                    }
                  }}
                />
              </div>
              <div className={`${styles["name-icon"]}`} style={{ width: "8%" }}>
                <p>{columnNames[3]}</p>
                <KeyboardArrowDownIcon
                  style={{
                    transform: filter === "price" && "rotate(180deg)",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (filter === "price") {
                      setFilter("reversePrice");
                      filterByProp(user?._expenses, "reversePrice", "amount");
                    } else {
                      setFilter("price");
                      filterByProp(user?._expenses, "price", "amount");
                    }
                  }}
                />
              </div>
              <div className={`${styles["name-icon"]}`} style={{ width: "8%" }}>
                <p>{columnNames[4]}</p>
                <KeyboardArrowDownIcon
                  style={{
                    transform: filter === "cardNumber" && "rotate(180deg)",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (filter === "cardNumber") {
                      setFilter("reverseCardNumber");
                      user?._expenses.sort((a, b) => {
                        if (!a.data.card && !b.data.card) {
                          return 0;
                        } else if (a.data.card && !b.data.card) {
                          return 1;
                        } else if (!a.data.card && b.data.card) {
                          return -1;
                        } else {
                          const value1 = a.data.card.toString().toUpperCase();
                          const value2 = b.data.card.toString().toUpperCase();
                          if (value1 < value2) {
                            return 1;
                          } else if (value1 > value2) {
                            return -1;
                          } else {
                            return 0;
                          }
                        }
                      });
                    } else {
                      setFilter("cardNumber");
                      user?._expenses.sort((a, b) => {
                        if (!a.data.card && !b.data.card) {
                          return 0;
                        } else if (a.data.card && !b.data.card) {
                          return -1;
                        } else if (!a.data.card && b.data.card) {
                          return 1;
                        } else {
                          const value1 = a.data.card.toString().toUpperCase();
                          const value2 = b.data.card.toString().toUpperCase();
                          if (value1 < value2) {
                            return -1;
                          } else if (value1 > value2) {
                            return 1;
                          } else {
                            return 0;
                          }
                        }
                      });
                    }
                  }}
                />
              </div>
              <div className={`${styles["name-icon"]}`} style={{ width: "8%" }}>
                <p>{columnNames[5]}</p>
                <KeyboardArrowDownIcon
                  style={{
                    transform: filter === "cardHolder" && "rotate(180deg)",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (filter === "cardHolder") {
                      setFilter("reverseCardHolder");
                      user?._expenses.sort((a, b) => {
                        if (!a.data.cardholder && !b.data.cardholder) {
                          return 0;
                        } else if (a.data.cardholder && !b.data.cardholder) {
                          return 1;
                        } else if (!a.data.cardholder && b.data.cardholder) {
                          return -1;
                        } else {
                          const value1 = a.data.cardholder.toString().toUpperCase();
                          const value2 = b.data.cardholder.toString().toUpperCase();
                          if (value1 < value2) {
                            return 1;
                          } else if (value1 > value2) {
                            return -1;
                          } else {
                            return 0;
                          }
                        }
                      });
                    } else {
                      setFilter("cardHolder");
                      user?._expenses.sort((a, b) => {
                        if (!a.data.cardholder && !b.data.cardholder) {
                          return 0;
                        } else if (a.data.cardholder && !b.data.cardholder) {
                          return -1;
                        } else if (!a.data.cardholder && b.data.cardholder) {
                          return 1;
                        } else {
                          const value1 = a.data.cardholder.toString().toUpperCase();
                          const value2 = b.data.cardholder.toString().toUpperCase();
                          if (value1 < value2) {
                            return -1;
                          } else if (value1 > value2) {
                            return 1;
                          } else {
                            return 0;
                          }
                        }
                      });
                    }
                  }}
                />
              </div>
              <div className={`${styles["name-icon"]}`} style={{ width: "8%" }}>
                <p>{columnNames[6]}</p>
                <KeyboardArrowDownIcon
                  style={{
                    transform: filter === "buyDate2" && "rotate(180deg)",
                    cursor: "pointer",
                  }}
                />
              </div>
            </div>
          </div>
          <div className={styles["tabs-container"]} style={{ overfloxY: "auto" }}>
            {user?._expenses.map((expense, i) => {
              return (
                <div className={styles["row-container"]} key={i}>
                  <div className={styles["data"]}>
                    <b>{moment(expense.paymentDate).format("DD.MM.YYYY")}</b>
                  </div>
                  <Divider
                    style={{
                      backgroundColor: "#D3D3D3",
                      height: "65%",
                    }}
                    orientation="vertical"
                  />
                  <div className={styles["data"]}>
                    <b>{moment(expense.paymentDate).format("HH:mm")}</b>
                  </div>
                  <Divider
                    style={{
                      backgroundColor: "#D3D3D3",
                      height: "65%",
                    }}
                    orientation="vertical"
                  />
                  <div className={styles["data"]} style={{ width: "10%" }}>
                    <b>
                      {moment(expense?.data.from).format("DD.MM")}-{moment(expense?.data.to).format("DD.MM.YYYY")}
                    </b>
                  </div>
                  <Divider
                    style={{
                      backgroundColor: "#D3D3D3",
                      height: "65%",
                    }}
                    orientation="vertical"
                  />
                  <div className={styles["data"]}>
                    <b>{expense?.amount}лв.</b>
                  </div>
                  <Divider
                    style={{
                      backgroundColor: "#D3D3D3",
                      height: "65%",
                    }}
                    orientation="vertical"
                  />
                  <div className={styles["data"]}>
                    <b>{expense?.data?.card}</b>
                  </div>
                  <Divider
                    style={{
                      backgroundColor: "#D3D3D3",
                      height: "65%",
                    }}
                    orientation="vertical"
                  />
                  <div className={styles["data"]}>
                    <b>{expense?.data?.cardholder}</b>
                  </div>
                  <Divider
                    style={{
                      backgroundColor: "#D3D3D3",
                      height: "65%",
                    }}
                    orientation="vertical"
                  />
                  <div className={styles["data"]} style={{ width: "fit-content" }}>
                    <b style={{ textDecoration: "underline" }}>{expense?.receipts[0].split("/")[4]}</b>
                    <div onClick={() => fetchAndOpenPreSignedUrl(expense?.receipts[0])}>
                      <div className={styles["download"]} style={{marginLeft:"5px"}}/>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  vignettes: state.amarantFields.vignettes,
});

const mapDispatchToProps = (dispatch) => ({
  listVignettes: (payload) => dispatch(listVignettes(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HistoryComponent);
