export const offerTypes = {
  LIST_AGENCIES: "offer/LIST_AGENCIES",
  LIST_AGENCIES_SUCCESS: "offer/LIST_AGENCIES_SUCCESS",
  CREATE_OFFER: "offer/CREATE_OFFER",
  GET_CURRENT_OFFER: "offer/GET_CURRENT_OFFER",
  GET_CURRENT_OFFER_SUCCESS: "offer/GET_CURRENT_OFFER_SUCCESS",
  DELETE_OFFER: "offer/DELETE_OFFER",
  EDIT_OFFER: "offer/EDIT_OFFER",
  TAKE_DISCOUNT_FOR_OFFER: "offer/TAKE_DISCOUNT_FOR_OFFER",
  ADD_POLICY_TO_USER: "offer/ADD_POLICY_TO_USER",
};

export const listAgencies = (payload) => ({
  type: offerTypes.LIST_AGENCIES,
  payload,
});

export const createOffer = (payload) => ({
  type: offerTypes.CREATE_OFFER,
  payload,
});

export const getCurrentOffer = (payload) => ({
  type: offerTypes.GET_CURRENT_OFFER,
  payload,
});

export const editOffer = (payload) => ({
  type: offerTypes.EDIT_OFFER,
  payload,
});

export const deleteOffer = (payload) => ({
  type: offerTypes.DELETE_OFFER,
  payload,
});

export const addPolicyToUser = (payload) => ({
  type: offerTypes.ADD_POLICY_TO_USER,
  payload,
});
export const takeDiscountForOffer = (payload) => ({
  type: offerTypes.TAKE_DISCOUNT_FOR_OFFER,
  payload,
});