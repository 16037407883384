/* eslint-disable */
import {
  Avatar,
  Button,
  Checkbox,
  FormControl,
  InputAdornment,
  Paper,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import styles from "./TeamManagementModal.module.scss";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { connect } from "react-redux";

function TeamManagementModal({
  team = [],
  handleClose,
  handleSetTeam,
  currentSystemUsers,
  topicId,
}) {
  const initialState = currentSystemUsers?.map((agent) => ({
    ...agent,
    checked: team ? Boolean(team.find((a) => a.name === agent.name)) : false,
  }));

  const [input, setInput] = useState("");
  const [allAgents, setAllAgents] = useState(initialState);
  const searchInput = async (input) => {
    const filteredAgents = allAgents.filter((agent) =>
      agent.name.toLowerCase().includes(input.toLowerCase())
    );
    setInput(input);
    setAllAgents(filteredAgents);
    if (input === "") {
      setAllAgents(initialState);
    }
  };
  const [selectedAgents, setSelectedAgents] = useState(initialState);

  const handleChange = (e) => {
    const { name, checked } = e.target;
    let newSelectedAgents = [...selectedAgents];
    newSelectedAgents = newSelectedAgents.map((a) => {
      return a.name === name ? { ...a, checked: checked } : a;
    });
    setSelectedAgents([...newSelectedAgents]);
  };

  return (
    <Paper className={styles["assign-topic-modal"]}>
      {window.innerWidth < 900 ? (
        <div>
          <div className={styles["assign-topic-heading-container"]}>
            <h3>УПРАВЛЕНИЕ НА ЕКИП</h3>
            <CloseIcon onClick={handleClose} />
          </div>
          <FormControl style={{ width: "100%" }}>
            <TextField
              value={input}
              onChange={(e) => searchInput(e.target.value)}
              sx={{ padding: "10px" }}
              placeholder="Търси по име"
              variant="outlined"
              id="search-field"
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
          <div className={styles["select-agent"]}>
            {allAgents?.map((agent, index) => (
              <div className={styles.agent} key={index}>
                <Checkbox
                  name={agent?.name}
                  onChange={handleChange}
                  defaultChecked={Boolean(
                    team.find((a) => a.name === agent?.name)
                  )}
                  sx={{
                    "&.Mui-checked": {
                      color: "#0DC1CE",
                    },
                  }}
                />
                <Avatar src={agent?.avatar} />
                <p>{agent?.name}</p>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <>
          <div className={styles["assign-topic-heading-container"]}>
            <h3>УПРАВЛЕНИЕ НА ЕКИП</h3>
            <CloseIcon onClick={handleClose} />
          </div>
          <FormControl>
            <TextField
              value={input}
              onChange={(e) => searchInput(e.target.value)}
              sx={{ padding: "10px" }}
              placeholder="Търси по име"
              variant="outlined"
              id="search-field"
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
          <div className={styles["select-agent"]}>
            {allAgents?.map((agent, index) => (
              <div className={styles.agent} key={index}>
                <Checkbox
                  name={agent?.name}
                  onChange={handleChange}
                  defaultChecked={Boolean(
                    team.find((a) => a.name === agent?.name)
                  )}
                  sx={{
                    "&.Mui-checked": {
                      color: "#0DC1CE",
                    },
                  }}
                />
                <Avatar src={agent?.avatar} />
                <p>{agent?.name}</p>
              </div>
            ))}
          </div>
        </>
      )}

      <Paper elevation={10} style={{ padding: "10px" }}>
        <Button
          onClick={() => {
            const newTeam = selectedAgents.filter((a) => a.checked);
            handleSetTeam(newTeam);
            handleClose();
          }}
          disabled={Boolean(
            team.length === 0 &&
              selectedAgents?.filter((a) => a.checked).length === 0
          )}
          disableElevation
          disableRipple
          disableFocusRipple
          variant="contained"
          sx={{
            width: "100%",
            color: "white",
            backgroundColor: "#01d088",
            cursor: "pointer",
            "&:hover": {
              backgroundColor: "#01d088",
            },
          }}
        >
          Избери
        </Button>
      </Paper>
    </Paper>
  );
}
const mapStateToProps = (state) => ({
  currentSystemUsers: state.users.currentSystemUsers,
});

export default connect(mapStateToProps)(TeamManagementModal);
