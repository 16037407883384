/* eslint-disable */
import React, { useEffect, useState } from "react";
import { format, isToday, isYesterday } from "date-fns";

export default function Timestamp({ time }) {
  const [currentDay, setCurrentDay] = useState(null);
  const [day, setDay] = useState("");

  useEffect(() => {
    setCurrentDay(format(new Date(time), "dd.MM.yyyy"));
  }, []);

  useEffect(() => {
    if (isToday(new Date(time))) {
      setDay("Днес");
    } else if (isYesterday(new Date(time))) {
      setDay("Вчера");
    } else {
      setDay(currentDay);
    }
  }, [currentDay]);

  return (
    <div
      style={{
        margin: "10px 0",
        backgroundColor: "#FAFAFA",
        display: "flex",
        width: "100%",
        height: "40px",
        justifyContent: "space-evenly",
        alignItems: "center",
        marginBottom: "10px",
        textTransform: "uppercase",
        fontWeight: "bold",
      }}
    >
      <div>{day}</div>
    </div>
  );
}
