/* eslint-disable */
import { combineEpics } from "redux-observable";
import * as starter from "./starter";
import * as login from "./login";
import * as system from "./system";
import * as users from "./users";
import * as tickets from "./tickets";
import * as socket from "./socket";
import * as activity from "./activity";
import * as notes from "./notes";
import * as payment from "./payment";
import * as amarant from "./amarantFields";
import * as templates from "./template";
import * as labels from "./labels";
import * as immotechFields from "./immotechFields";
import * as offer from "./offer";
import * as synergonFields from "./synergonFields";

export const rootEpic = combineEpics(
  login.loginRequestEpic,
  login.loginValidateTokenEpic,
  login.logoutRequestEpic,
  system.getAllSystems,
  system.getCurrentSystem,
  system.getSystemsList,
  users.getAllUsers,
  users.getCurrentUser,
  users.createUserEpic,
  users.editUser,
  users.deleteUser,
  users.changeUserPassword,
  users.getCurrentSystemUsersEpic,
  tickets.getTicketsList,
  tickets.getCurrentTicketEpic,
  tickets.ticketActionsEpic,
  tickets.editMultipleTickets,
  tickets.ticketSendReminder,
  tickets.multipleVotes,
  tickets.getChatHistory,
  tickets.createTicketEpic,
  tickets.getCurrentTicketAcceptedOfferEpic,
  tickets.editCustomerNameEpic,
  activity.getActivityListEpic,
  notes.getNotesListEpic,
  notes.createNoteEpic,
  notes.sendEmails,
  socket.supportConnectEpic,
  socket.supportListenerEpic,
  socket.supportSenderEpic,
  socket.supportDisconnectEpic,
  payment.createPayment,
  amarant.getVehicles,
  amarant.getUserInfo,
  amarant.getPolicies,
  amarant.getVignettes,
  amarant.getErrors,
  amarant.getFines,
  amarant.getDocuments,
  amarant.editUserInfo,
  amarant.checkAmarantUserEpic,
  amarant.sendTemplateEpic,
  templates.groupsListEpic,
  templates.groupCreateEpic,
  templates.groupEditEpic,
  templates.groupDeleteEpic,
  templates.templateCreateEpic,
  templates.templateListEpic,
  templates.templateDeleteEpic,
  templates.templateEditEpic,
  labels.labelsListEpic,
  labels.labelCreateEpic,
  labels.labelEditEpic,
  labels.labelDeleteEpic,
  immotechFields.checkImmotechUserEpic,
  immotechFields.getImmotechUserInfoEpic,
  offer.getAgenciesEpic,
  offer.createOfferEpic,
  offer.getCurrentOfferEpic,
  offer.deleteOfferEpic,
  offer.editOfferEpic,
  offer.addPolicyToUserEpic,
  offer.getCurrentOfferEpic,
  synergonFields.getSynergonUserInfo,
  synergonFields.getHydroProUserInfoEpic
);
