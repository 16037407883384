/* eslint-disable */
import { ofType, ActionsObservable } from "redux-observable";
import { ajax } from "rxjs/ajax";
import { switchMap, catchError } from "rxjs/operators";
import { paymentTypes } from "../actions/payment";
import { API_URL } from "../config/settings";
import { Headers } from "../utils/Headers";
import { toast } from "react-toastify";

export const createPayment = (action$) => {
  return action$.pipe(
    ofType(paymentTypes.PAYMENT_CREATE),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API_URL}/payment/create`,
        method: "POST",
        headers: Headers.auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: paymentTypes.PAYMENT_CREATE_SUCCESS,
              payload: response.payload,
            });
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            if (err.status === 401) {
              window.location.href = "/login";
            } else {
              toast.error(err.response.message);
            }
          });
        })
      );
    })
  );
};
