/* eslint-disable */
import {Button, FormControl, Paper, TextField} from "@mui/material";
import React, { useState } from "react";
import styles from "./ChangeTopicNameModal.module.scss";
import CloseIcon from "@mui/icons-material/Close";
import { ticketActions } from "../../../actions/tickets";
import { connect } from "react-redux";
import Swal from "sweetalert2";
  
const ChangeTopicNameModal = ({handleClose,ticketActions,currentTicket,getCurrentTicket,topicId}) => {

  const [name,setName] = useState("");

    return (
      <Paper className={styles["assign-topic-modal"]}>
        <div className={styles["assign-topic-heading-container"]}>
          <h3>РЕДАКЦИЯ ИМЕ</h3>
          <CloseIcon onClick={handleClose} />
        </div>
        <FormControl style={{marginTop:"-3%"}}>
          <p style={{padding:"10px"}}>Име на тема</p>
          <TextField
            value={name}
            onChange={(e) => setName(e.target.value)}
            sx={{ padding: "10px" }}
            variant="outlined"
            id="search-field"
            size="small"
          />
        </FormControl>
        <Paper elevation={10} style={{ padding: "10px" }}>
          <Button
            onClick={() => {
                ticketActions({
                    id:currentTicket._id,
                    payloadAction:{
                        name:name
                    },
                    onSuccess:() => {}
                })
                Swal.fire("Името на темата беше редактирано успешно.","","success");
                getCurrentTicket(topicId)
                handleClose();
            }}
            disableElevation
            disableRipple
            disableFocusRipple
            disabled = {name.length > 2 ? false : true}
            variant="contained"
            sx={{
              width: "100%",
              color: "white",
              backgroundColor: "#01d088",
              cursor: "pointer",
              "&:hover": {
                backgroundColor: "#01d088",
              },
            }}
          >
            Запази
          </Button>
        </Paper>
      </Paper>
    );
  }

  const mapDispatchToProps = (dispatch) => ({
    ticketActions: (payload) => dispatch(ticketActions(payload)),
  });
  
  
  export default connect(null,mapDispatchToProps)(ChangeTopicNameModal);
  