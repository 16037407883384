/* eslint-disable */
import {
  Button,
  FormControl,
  InputAdornment,
  Paper,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import styles from "./StartChatModal.module.scss";
import CloseIcon from "@mui/icons-material/Close";
import {
  checkImmotechUser,
  getEnergySystemsUserInfo,
  getHydroProUserInfo,
} from "../../../actions";
import { checkAmarantUser } from "../../../actions/amarantFields";
import { createTicket } from "../../../actions/tickets";
import { connect } from "react-redux";
import { useEffect } from "react";
import io from "socket.io-client";
import { API_URL } from "../../../config/settings";
import { toast } from "react-toastify";

const energySlugs = {
  "61efa788ffba481f0c813335": "https://synergon-fast-prod.fidweb.net",
  "6218914072d2615dc48bc1b3": "https://erp-api.energy-op.eu",
  "6267bf9eeac1000099c5afc6": "https://retc-api.fidweb.net",
  "640b008b7c8dc62a195b7660": "https://zagora-api.fidweb.net",
  "640b00987c8dc62a195b7663": "https://proakt-api.fidweb.net",
  "640b009d7c8dc62a195b7666": "https://energama-api.fidweb.net",
  // "61efa76dffba481f0c81332c": "https://erp-hydropro-api.herokuapp.com",
  "643030f780dead605384a55f": "https://wsenergy-api.fidweb.net",
};
const hydroProSlug = {
  "61efa76dffba481f0c81332c": "https://erp-hydropro-api.herokuapp.com",
};

const StartChatModal = ({
  handleClose,
  checkAmarantUser,
  checkImmotechUser,
  getEnergySystemsUserInfo,
  getHydroProUserInfo,
  createTicket,
  slug,
}) => {
  const [phone, setPhone] = useState("+359");
  const [email, setEmail] = useState("");
  const [isFailed, setIsFailed] = useState(null);
  const [username, setUsername] = useState("");
  const [payload, setPayload] = useState({
    customer: "",
    title: "",
    message: "",
  });

  const handleCheckUser = () => {
    if (slug === "61ee8b87a07ddf1eb8268265") {
      checkAmarantUser({
        phone: phone,
        onSuccess: (res) => {
          setIsFailed(false);
          setUsername(res.payload.fullName);
          setPayload({
            ...payload,
            customer: res.payload.customerSupportId,
          });
        },
      });
    } else if (hydroProSlug[slug]) {
      getHydroProUserInfo({
        url: hydroProSlug[slug],
        payload: {
          email: email,
        },
        onSuccess: (res) => {
          if (!res.found) setIsFailed(true);
          else {
            setIsFailed(false);
            setUsername(res.fullName);
            setPayload({
              ...payload,
              customer: res.customerSupportId,
            });
          }
        },
      });
    } else {
      checkImmotechUser({
        phone: phone,
        onSuccess: (res) => {
          setIsFailed(false);
          setUsername(res.user.fullName);
          setPayload({
            ...payload,
            customer: res.user.supportId,
          });
        },
      });
    }
  };


  useEffect(() => {
    if (energySlugs[slug]) {
      getEnergySystemsUserInfo({
        url: energySlugs[slug],
        onSuccess: (res) => {
          if (!res.user.supportId) {
            const socket = io(API_URL, {
              query: `systemId=${slug}&name=${res.user?.fullName}`,
            });
            socket.on("socket-message", ({ action, payload }) => {
              if (action === "socket/customer") {
                getEnergySystemsUserInfo({
                  url: energySlugs[slug],
                  payload: { supportId: payload.customer_id },
                  onSuccess: (response) => {
                    setPayload({
                      ...payload,
                      customer: response?.user?.supportId,
                    });
                  },
                });
              }
              socket.disconnect();
            });
          } else
            setPayload({
              ...payload,
              customer: res.user.supportId,
            });
        },
      });
    }
  }, []);

  return (
    <Paper className={styles["assign-topic-modal"]}>
      <div className={styles["assign-topic-heading-container"]}>
        <h3>ИНИЦИИРАНЕ НА ЧАТ</h3>
        <CloseIcon onClick={handleClose} />
      </div>
      {!energySlugs[slug] && !hydroProSlug[slug] ? (
        <FormControl style={{ marginTop: "-3%" }}>
          <p style={{ padding: "10px" }}>Телефон на потребител *</p>
          <TextField
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            sx={{ padding: "10px" }}
            variant="outlined"
            id="price-input"
            size="small"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <div
                    className={styles["search-icon"]}
                    onClick={() => {
                      handleCheckUser();
                    }}
                  />
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
      ) : !energySlugs[slug] && hydroProSlug[slug] ? (
        <FormControl style={{ marginTop: "-3%" }}>
          <p style={{ padding: "10px" }}>Имейл на потребител *</p>
          <TextField
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            sx={{ padding: "10px" }}
            variant="outlined"
            id="price-input"
            size="small"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <div
                    className={styles["search-icon"]}
                    onClick={() => {
                      handleCheckUser();
                    }}
                  />
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
      ) : null}

      {isFailed === false ? (
        <div
          style={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            marginLeft: "2%",
            marginBottom: "2%",
          }}
        >
          <div className={styles["found-icon"]} />
          <p style={{ color: "#01D088" }}>{username}</p>
        </div>
      ) : (
        isFailed === true && (
          <div
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              marginLeft: "2%",
              marginBottom: "2%",
            }}
          >
            <div className={styles["not-found-icon"]} />
            <p style={{ color: "#FD416C" }}>Несъществуващ потребител</p>
          </div>
        )
      )}
      <FormControl style={{ marginTop: "-3%" }}>
        <p style={{ padding: "10px" }}>Име на тема *</p>
        <TextField
          value={payload.title}
          onChange={(e) =>
            setPayload({
              ...payload,
              title: e.target.value,
            })
          }
          sx={{ padding: "10px" }}
          variant="outlined"
          id="price-input"
          size="small"
        />
      </FormControl>
      <FormControl style={{ marginTop: "-3%" }}>
        <p style={{ padding: "10px" }}>Първо съобщение *</p>
        <TextField
          value={payload.message}
          onChange={(e) =>
            setPayload({
              ...payload,
              message: e.target.value,
            })
          }
          sx={{ padding: "10px" }}
          variant="outlined"
          id="price-input"
          size="small"
          multiline
          rows={3}
        />
      </FormControl>
      <Paper style={{ padding: "10px" }}>
        <Button
          onClick={() => {
            createTicket({
              ...payload,
              onSuccess: (res) => {
                toast.success("Ticket created successfully!");
                window.open(
                  `/system/${res.payload.system}/${res.payload._id}/chat`
                );
                handleClose();
              },
            });
          }}
          disableElevation
          disableRipple
          disableFocusRipple
          disabled={
            payload?.customer?.length > 0 &&
            payload?.title?.length > 3 &&
            payload?.message?.length > 3
              ? false
              : true
          }
          variant="contained"
          sx={{
            width: "100%",
            color: "white",
            backgroundColor: "#01d088",
            cursor: "pointer",
            "&:hover": {
              backgroundColor: "#01d088",
            },
          }}
        >
          Потвърди
        </Button>
      </Paper>
    </Paper>
  );
};

const mapStateToProps = (state) => ({
  user: state.login.user,
});

const mapDispatchToProps = (dispatch) => ({
  checkAmarantUser: (payload) => dispatch(checkAmarantUser(payload)),
  checkImmotechUser: (payload) => dispatch(checkImmotechUser(payload)),
  createTicket: (payload) => dispatch(createTicket(payload)),
  getEnergySystemsUserInfo: (payload) =>
    dispatch(getEnergySystemsUserInfo(payload)),
  getHydroProUserInfo: (payload) => dispatch(getHydroProUserInfo(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StartChatModal);
