/* eslint-disable */
import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Paper,
  Grid,
  FormControl,
  TextField,
  InputAdornment,
  IconButton,
  Button,
  Stack,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import styles from "./Login.module.scss";
import { loginSignIn } from "../../actions/login";
import { connect } from "react-redux";
import MobileLogin from "./MobileLogin";

const validationSchema = Yup.object({
  email: Yup.string("Въведи имейл")
    .required("Това поле е задължително.")
    .email("Въведи валиден имейл"),
  password: Yup.string("Въведи парола").required("Това поле е задължително."),
  // .min(6, "The minimum length should be 6 characters.")
});

function Login(props) {
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: () => {
      props.loginSignIn(formik.values);
    },
  });

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div className={styles["login-container"]}>
      <Paper className={styles["login"]} elevation={3}>
        {window.innerWidth < 900 ? (
          <MobileLogin
            formik={formik}
            showPassword={showPassword}
            setShowPassword={setShowPassword}
            handleClickShowPassword={handleClickShowPassword}
            handleMouseDownPassword={handleMouseDownPassword}
          />
        ) : (
          <Grid container spacing={2}>
            <Grid
              item
              xs={7}
              justifyContent={"space-around"}
              alignItems={"center"}
            >
              <img src="/login-assets/login-left-picture.png" />
            </Grid>
            <Grid style={{ height: "100%" }} item xs={5}>
              <Paper elevation={2}>
                <form onSubmit={formik.handleSubmit}>
                  <Stack className={styles["login-form"]} direction={"column"}>
                    <img
                      style={{
                        width: "234px",
                        height: "78px",
                        alignSelf: "center",
                      }}
                      src="/login-assets/Logo-Log-In.svg"
                    />
                    <h3
                      className={styles.heading}
                      style={{ alignSelf: "center" }}
                    >
                      ЗДРАВЕЙТЕ!
                    </h3>
                    <FormControl className={styles.FormControl}>
                      <label htmlFor="email">Имейл</label>
                      <TextField
                        id="email"
                        type="email"
                        size="small"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.email && Boolean(formik.errors.email)
                        }
                        helperText={formik.touched.email && formik.errors.email}
                      ></TextField>
                    </FormControl>
                    <FormControl className={styles.FormControl}>
                      <label htmlFor="password">Парола</label>
                      <TextField
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.password &&
                          Boolean(formik.errors.password)
                        }
                        helperText={
                          formik.touched.password && formik.errors.password
                        }
                        size="small"
                        id="password"
                        type={showPassword ? "text" : "password"}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      ></TextField>
                    </FormControl>
                    <Button
                      disabled={!(formik.isValid && formik.dirty)}
                      type="submit"
                      variant="container"
                      className={styles["submit-button"]}
                    >
                      ВХОД
                    </Button>
                  </Stack>
                </form>
              </Paper>
            </Grid>
          </Grid>
        )}
      </Paper>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  loginSignIn: (payload) => dispatch(loginSignIn(payload)),
});

export default connect(null, mapDispatchToProps)(Login);
