/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Paper, Button } from "@mui/material";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import styles from "./EditProfilePage.module.scss";
import { getCurrentUser } from "../../actions/users";
import { getAllSystems } from "../../actions/system";
import EditProfileForm from "../../components/EditProfileForm/EditProfileForm";
import ModalComponent from "../../components/Modals/ModalComponent/ModalComponent";
import DeleteUserModal from "../../components/Modals/DeleteUserModal/DeleteUserModal";

function EditProfilePage({
  currentUser,
  getCurrentUser,
  getAllSystems,
  allSystems,
  user,
}) {
  const [saveChangesTrigger, setSaveChangesTrigger] = useState(false);
  const { slug } = useParams();
  useEffect(() => {
    getCurrentUser(slug);
  }, [getCurrentUser]);

  useEffect(() => {
    getAllSystems();
  }, [getAllSystems]);

  const currentSystem = allSystems.find((system) => system._id === slug);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [enableEdit, setEnableEdit] = useState(false);

  const [values, setValues] = useState({
    name: "",
    email: "",
    password: "",
    role: "",
    receiveEmails: {
      newTicket: true,
    },
    systems: {
      amarant: false,
      digidrive: false,
      enRpi: false,
      fightScout: false,
      hydroPro: false,
      immotech: false,
      motoza: false,
      ppa: false,
      soCourt: false,
      synergon: false,
      wink: false,
    },
  });

  return (
    <div className={styles["edit-profile-page-container"]}>
      <Paper elevation={3} className={styles["edit-profile-toolbar"]}>
        <h3 className={styles["system-heading"]}>Потребители в системата</h3>
        {user?.role === "admin" || currentUser?._id === user?._id ? (
          enableEdit ? (
            <div>
              <Button
                onClick={handleOpen}
                disableRipple
                disableFocusRipple
                variant="contained"
                sx={{
                  marginRight: "1rem",
                  color: "#FD416C",
                  backgroundColor: "#FFFFFF",
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: "#FFFFFF",
                  },
                }}
              >
                Изтрий
              </Button>
              <Button
                onClick={() => setSaveChangesTrigger(true)}
                disableElevation
                disableRipple
                disableFocusRipple
                variant="contained"
                sx={{
                  color: "white",
                  backgroundColor: "#01D088",
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: "#01D088",
                  },
                }}
              >
                Запази
              </Button>
            </div>
          ) : (
            <Button
              onClick={() => setEnableEdit(true)}
              disableElevation
              disableRipple
              disableFocusRipple
              variant="contained"
              sx={{
                color: "white",
                backgroundColor: "#0DC1CE",
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: "#0DC1CE",
                },
              }}
            >
              Редакция
            </Button>
          )
        ) : null}
      </Paper>
      <EditProfileForm
        enableEdit={enableEdit}
        currentUser={currentUser}
        saveChangesTrigger={saveChangesTrigger}
        currentSystem={currentSystem}
        values={values}
        setValues={setValues}
      />
      <ModalComponent
        open={open}
        handleClose={handleClose}
        children={<DeleteUserModal handleClose={handleClose} />}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  currentUser: state.users.currentUser,
  allSystems: state.systems.allSystems,
  user: state.login.user,
});

const mapDispatchToProps = (dispatch) => ({
  getCurrentUser: (payload) => dispatch(getCurrentUser(payload)),
  getAllSystems: () => dispatch(getAllSystems()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditProfilePage);
