/* eslint-disable */
import { Box, Modal, Paper, Button } from "@mui/material";
import React, { useEffect } from "react";
import styles from "./DiscountInfoModal.module.scss";

const DiscountInfoModal = ({ open, handleClose, type, discount }) => {
  return (
    <Paper className={styles["discount-info-modal"]}>
      <div className={styles["discount-info-heading-container"]}>
        <h3>ИНФОРМАЦИЯ ЗА НАМАЛЕНИЕТО</h3>
        <p onClick={handleClose} style={{ cursor: "pointer" }}>
          Скрий {`>`}
        </p>
      </div>
      <div className={styles["activity-window"]}>
        <div className={styles.activity}>
          <p>{"Промокод"}</p>
          <h4 style={{ marginTop: "1%" }}>{discount?.discountCode}</h4>
        </div>
        <div className={styles.activity}>
          <p>{"Тип на полицата"}</p>
          <h4 style={{ marginTop: "1%" }}>{discount?.policyType}</h4>
        </div>
        <div className={styles.activity}>
          <p>{"Намаление в проценти"}</p>
          <h4 style={{ marginTop: "1%" }}>{discount?.discountCampaign?.discountInPercent === true ? "Да" : "Не"}</h4>
        </div>
        {discount?.discountCampaign?.customDiscounts?.map((disc, i) => {
          return (
            <div className={styles.activity} key={i}>
              <p>Намалениe {i + 1}</p>
              <h4 style={{ marginTop: "1%" }}>
                Полица: {disc?.policyType}{" "}
                {disc?.genericDiscount && `- ${disc?.discount} ${discount?.discountCampaign?.discountInPercent === true ? "%" : "лв."}`}
              </h4>
              <div style={{ marginTop: "1%" }}>
                {disc?.customDiscountsPerInsuranceAgency?.map((disc) => {
                  return (
                    <p style={{ display: "flex" }} key={disc?.insuranceAgencyName}>
                      {disc?.insuranceAgencyName} - {disc?.discount}
                    </p>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </Paper>
  );
};

export default DiscountInfoModal;
