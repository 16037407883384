/* eslint-disable */
import { Box, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import SystemCard from "../SystemCard/SystemCard";
import styles from "./SystemUsers.module.scss";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useNavigate, useParams } from "react-router-dom";
import { getCurrentSystemUsers } from "../../actions/users";
import { connect } from "react-redux";
import { format } from "date-fns";

function SystemUsers({ getCurrentSystemUsers, currentSystemUsers }) {
  const [reverseSort, setReverseSort] = useState(false);
  const [filters, setFilters] = useState("");
  const { slug } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    if (filters) {
      const payload = {
        system: slug,
        sortBy: { [filters]: reverseSort ? -1 : 1 },
        onSuccess:() => {}
      };
      getCurrentSystemUsers(payload);
    } else {
      const payload = {
        system: slug,
        onSuccess:() => {}
      };
      getCurrentSystemUsers(payload);
    }
  }, [getCurrentSystemUsers, filters, reverseSort]);
  return (
    <div className={styles["system-users-container"]}>
      <div className={styles["heading-container"]}>
        <h3 className={styles["system-heading"]}>Потребители в системата</h3>
        <Button
          onClick={() => navigate(`/system/${slug}/add-users`)}
          disableElevation
          disableRipple
          disableFocusRipple
          variant="contained"
          sx={{
            color: "white",
            backgroundColor: "#01D088",
            cursor: "pointer",
            "&:hover": {
              backgroundColor: "#01D088",
            },
          }}
        >
          Добави
        </Button>
      </div>
      <div>
        <div className={styles["system-content"]}>
          <div className={styles["filters-container"]}>
            <Button
              onClick={() => {
                setFilters("name");
                setReverseSort(!reverseSort);
              }}
              style={{ width: "150px", backgroundColor: "transparent" }}
              disableElevation
              disableRipple
              disableFocusRipple
              endIcon={
                <KeyboardArrowDownIcon
                  style={
                    filters === "name" && reverseSort
                      ? { transform: "rotate(180deg)" }
                      : {}
                  }
                />
              }
              className={styles["filter-button"]}
            >
              Имена
            </Button>
            <Button
              onClick={() => {
                setFilters("email");
                setReverseSort(!reverseSort);
              }}
              style={{ width: "170px", backgroundColor: "transparent" }}
              disableElevation
              disableRipple
              disableFocusRipple
              endIcon={
                <KeyboardArrowDownIcon
                  style={
                    filters === "email" && reverseSort
                      ? { transform: "rotate(180deg)" }
                      : {}
                  }
                />
              }
              className={styles["filter-button"]}
            >
              Имейл
            </Button>
            <Button
              onClick={() => {
                setFilters("createdAt");
                setReverseSort(!reverseSort);
              }}
              style={{ width: "200px", backgroundColor: "transparent" }}
              disableElevation
              disableRipple
              disableFocusRipple
              endIcon={
                <KeyboardArrowDownIcon
                  style={
                    filters === "createdAt" && reverseSort
                      ? { transform: "rotate(180deg)" }
                      : {}
                  }
                />
              }
              className={styles["filter-button"]}
            >
              Добавен на
            </Button>
            <Button
              onClick={() => {
                setFilters("_responsibleCount");
                setReverseSort(!reverseSort);
              }}
              style={{ width: "50px", backgroundColor: "transparent" }}
              disableElevation
              disableRipple
              disableFocusRipple
              endIcon={
                <KeyboardArrowDownIcon
                  style={
                    filters === "_responsibleCount" && reverseSort
                      ? { transform: "rotate(180deg)" }
                      : {}
                  }
                />
              }
              className={styles["filter-button"]}
            >
              Назначени
            </Button>
          </div>
          <Box
            sx={{
              overflow: "auto",
              height: "calc(100vh - 300px)",
            }}
          >
            {currentSystemUsers?.map((user) => (
              <SystemCard
                key={user._id}
                arg1={user?.name}
                arg2={user?.email}
                arg3={format(new Date(user?.createdAt), "dd.MM.yyyy")}
                arg4={`${user?._responsibleCount} теми`}
                arg5={null}
                handleClick={() => navigate(`/profile/${user?._id}`)}
              />
            ))}
          </Box>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  currentSystemUsers: state.users.currentSystemUsers,
});

const mapDispatchToProps = (dispatch) => ({
  getCurrentSystemUsers: (payload) => dispatch(getCurrentSystemUsers(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SystemUsers);
