/* eslint-disable */
import React, { useState } from "react";
import styles from "./LabelsModal.module.scss";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Divider } from "@mui/material";

const LabelsContainer = ({
  label,
  i,
  setEditModal,
  setDeleteModal,
  setSelLabel,
  setPayload,
  payload,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleDropDownClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={styles["label"]} key={i}>
      <div style={{ width: "98%", marginLeft: "2%" }}>
        <div className="pretty p-default p-pulse p-curve">
          <input
            type="checkbox"
            checked={payload.includes(label?._id)}
            onClick={() => {
              if (payload.includes(label?._id)) {
                setPayload(payload.filter((x) => x !== label?._id));
              } else setPayload((old) => [...old, label?._id]);
            }}
          />
          <div className="state">
            <label>{label?.name}</label>
          </div>
        </div>
      </div>
      <div>
        <MoreVertIcon style={{ cursor: "pointer" }} onClick={handleClick} />
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleDropDownClose}
          id={"1234"}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          PaperProps={{
            style: {
              width: 200,
            },
          }}
        >
          <MenuItem
            onClick={() => {
              setEditModal(true);
              setSelLabel(label);
              handleDropDownClose();
            }}
          >
            <div className={styles["edit-group"]}></div>{" "}
            <p style={{ fontSize: "13px" }}>Редактирай етикет</p>
          </MenuItem>
          <Divider sx={{ background: "#0DC1CE" }} />
          <MenuItem
            onClick={() => {
              setDeleteModal(true);
              setSelLabel(label);
              handleDropDownClose();
            }}
          >
            <div
              className={`${styles["edit-group"]} ${styles["delete-group"]}`}
            ></div>{" "}
            <p style={{ fontSize: "13px" }}>Изтрий етикет</p>
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
};

export default LabelsContainer;
