/* eslint-disable */
import { Box, Button, Divider, Typography, Popover } from "@mui/material";
import React, { useEffect, useState, useRef, useCallback } from "react";
import styles from "./AmarantComponent.module.scss";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { listPolicies } from "../../actions/amarantFields";
import { connect } from "react-redux";
import moment from "moment";
import { filterByProp } from "../../utils/helperFunctions";

const columnNames = [
  "Вид",
  "Статус",
  "Компания",
  "Застрахован обект",
  "Закупена от Амарант",
  "Номер на полица",
  "Начална дата",
  "Крайна дата",
  "Поръчана на",
  "Вноски",
  "Статус вноски",
  "Вид пакет",
  "Сума",
  "Начин на плащане",
  "Изтрита",
];

const fieldNames = [
  "policyType", //string
  "state", //string
  "insuranceAgency", //string
  "insuredObject", //string
  "externalPolicy", //string
  "policyNumber", //string
  "policyStartDate", // string
  "policyEndDate", //string
  "ordered", // string
  "installmentsCount", //integer
  "installmentsCount", //integer
  "package", //string
  "amount", //integer
  "paymentType", //string
  "deletedAt", //date
];

function PoliciesComponent({ policies, listPolicies, customerID }) {
  const [filter, setFilter] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [currentInstallments, setCurrentInstallments] = useState([]);
  const [currentPolicy, setCurrentPolicy] = useState({
    insuredObject: "",
    client: "",
  });

  useEffect(() => {
    listPolicies({
      //customerSupportId: "6231bc7bc8696d0016d4af13",
      customerSupportId: customerID,
    });
  }, []);

  const getInstallements = (policy) => {
    let paid = 0;
    policy.installments.map((install) => {
      if (install.paid === true) paid += 1;
    });
    return paid;
  };

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <div className={styles["blurred-background"]} id="blurred">
        <div className={styles["propertyModal"]}>
          <div className={styles["headerModal"]}>
            <b style={{ textTransform: "uppercase", fontSize: "16px" }}>
              детайли на застраховка
            </b>
            <p
              style={{ color: "#C6C6DE", cursor: "pointer" }}
              onClick={() => {
                document.getElementById("blurred").style.display = "none";
              }}
            >
              Скрий {`>`}
            </p>
          </div>
          <div className={styles["bodyModal"]}>
            <div className={styles["modalLine"]}>
              <p style={{ marginBottom: "0.5%" }}>Адрес</p>
              <b>{currentPolicy?.insuredObject}</b>
            </div>
            <div className={styles["modalLine"]}>
              <p style={{ marginBottom: "0.5%" }}>Размер на имота</p>
              <b>---</b>
            </div>
            <div className={styles["modalLine"]}>
              <p style={{ marginBottom: "0.5%" }}>
                Година на построяване на имота
              </p>
              <b>---</b>
            </div>
            <div className={styles["modalLine"]}>
              <p style={{ marginBottom: "0.5%" }}>
                Регистрирана ли е щета през последните 12 месеца
              </p>
              <b>Не</b>
            </div>
            <div className={styles["modalLine"]}>
              <p style={{ marginBottom: "0.5%" }}>Мерки за сигурност</p>
              <b>---</b>
            </div>
            <div className={styles["modalLine"]}>
              <p style={{ marginBottom: "0.5%" }}>Противопожарни мерки</p>
              <b>---</b>
            </div>
            <div className={styles["modalLine"]}>
              <p style={{ marginBottom: "0.5%" }}>
                Данни за собственика на имота
              </p>
              <p style={{ marginBottom: "0.5%" }}>
                Име <b>{currentPolicy?.client.split(" ")[0]}</b>
              </p>
              <p style={{ marginBottom: "0.5%" }}>
                Презиме <b>{currentPolicy?.client.split(" ")[1]}</b>
              </p>
              <p style={{ marginBottom: "0.5%" }}>
                Фамилия <b>{currentPolicy?.client.split(" ")[2]}</b>
              </p>
              <p style={{ marginBottom: "0.5%" }}>
                ЕГН <b>{currentPolicy?.clientIdentifier}</b>
              </p>
              <p style={{ marginBottom: "0.5%" }}>
                Адрес <b>{currentPolicy.insuredObject}</b>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          height: window.innerWidth < 900 ? "120%" : "95%",
          width: "100%",
          overflowX: "scroll",
          overflowY: "auto",
        }}
      >
        {window.innerWidth < 900 ? (
          <React.Fragment>
            <div
              className={styles["tabs-container"]}
              style={{ padding: "0px" }}
            >
              {policies.map((policy, i) => {
                return (
                  <div
                    className={styles["row-container"]}
                    style={{
                      marginTop: "5%",
                      display: "block",
                      height: "unset",
                    }}
                    key={i}
                  >
                    <div className={styles["mobile-box"]}>
                      <p style={{ marginBottom: "1%", fontSize: "14px" }}>
                        {columnNames[0]}
                      </p>
                      <b style={{ marginBottom: "1%", fontSize: "14px" }}>
                        {policy?.policyType}
                      </b>
                    </div>
                    <Divider />
                    <div className={styles["mobile-box"]}>
                      <p style={{ marginBottom: "1%", fontSize: "14px" }}>
                        {columnNames[1]}
                      </p>
                      <b style={{ marginBottom: "1%", fontSize: "14px" }}>
                      {policy?.state === "active" ? "Активна" : "Изтекла"}
                      </b>
                    </div>
                    <Divider />
                    <div className={styles["mobile-box"]}>
                      <p style={{ marginBottom: "1%", fontSize: "14px" }}>
                        {columnNames[2]}
                      </p>
                      <b style={{ marginBottom: "1%", fontSize: "14px" }}>
                      {policy?.insuranceAgency}
                      </b>
                    </div>
                    <Divider />
                    <div className={styles["mobile-box"]}>
                      <p style={{ marginBottom: "1%", fontSize: "14px" }}>
                        {columnNames[3]}
                      </p>
                      <b style={{ marginBottom: "1%", fontSize: "14px" }}>
                      {policy?.insuredObject}
                      </b>
                    </div>
                    <Divider />
                    <div className={styles["mobile-box"]}>
                      <p style={{ marginBottom: "1%", fontSize: "14px" }}>
                        {columnNames[4]}
                      </p>
                      <b style={{ marginBottom: "1%", fontSize: "14px" }}>
                      {" "}
                        {policy.externalPolicy === true
                          ? "Не"
                          : policy.externalPolicy === "-"
                          ? "-"
                          : "Да"}
                      </b>
                    </div>
                    <Divider />
                    <div className={styles["mobile-box"]}>
                      <p style={{ marginBottom: "1%", fontSize: "14px" }}>
                        {columnNames[5]}
                      </p>
                      <b style={{ marginBottom: "1%", fontSize: "14px" }}>
                      {policy?.policyNumber}
                      </b>
                    </div>
                    <Divider />
                    <div className={styles["mobile-box"]}>
                      <p style={{ marginBottom: "1%", fontSize: "14px" }}>
                        {columnNames[6]}
                      </p>
                      <b style={{ marginBottom: "1%", fontSize: "14px" }}>
                      {policy?.policyStartDate.split(" ")[0]}
                      </b>
                    </div>
                    <Divider />
                    <div className={styles["mobile-box"]}>
                      <p style={{ marginBottom: "1%", fontSize: "14px" }}>
                        {columnNames[7]}
                      </p>
                      <b style={{ marginBottom: "1%", fontSize: "14px" }}>
                      {policy?.policyEndDate.split(" ")[0]}
                      </b>
                    </div>
                    <Divider />
                    <div className={styles["mobile-box"]}>
                      <p style={{ marginBottom: "1%", fontSize: "14px" }}>
                        {columnNames[8]}
                      </p>
                      <b style={{ marginBottom: "1%", fontSize: "14px" }}>
                      {policy?.ordered.split(" ")[0].length > 0
                          ? policy?.ordered.split(" ")[0]
                          : "-"}
                      </b>
                    </div>
                    <Divider />
                    <div className={styles["mobile-box"]}>
                      <p style={{ marginBottom: "1%", fontSize: "14px" }}>
                        {columnNames[9]}
                      </p>
                      <b style={{ marginBottom: "1%", fontSize: "14px" }}>
                      {policy.installmentsCount}
                      </b>
                    </div>
                    <Divider />
                    <div className={styles["mobile-box"]}>
                      <p style={{ marginBottom: "1%", fontSize: "14px" }}>
                        {columnNames[10]}
                      </p>
                      <b style={{ marginBottom: "1%", fontSize: "14px" }}>
                      {getInstallements(policy)}/{policy.installments.length}
                      </b>
                    </div>
                    <Divider />
                    <div className={styles["mobile-box"]}>
                      <p style={{ marginBottom: "1%", fontSize: "14px" }}>
                        {columnNames[11]}
                      </p>
                      <b style={{ marginBottom: "1%", fontSize: "14px" }}>
                      {policy.package}
                      </b>
                    </div>
                    <Divider />
                    <div className={styles["mobile-box"]}>
                      <p style={{ marginBottom: "1%", fontSize: "14px" }}>
                        {columnNames[12]}
                      </p>
                      <b style={{ marginBottom: "1%", fontSize: "14px" }}>
                      {policy.amount === "-"
                          ? "-"
                          : Math.round(policy.amount * 100) / 100 + "лв"}
                      </b>
                    </div>
                    <Divider />
                    <div className={styles["mobile-box"]}>
                      <p style={{ marginBottom: "1%", fontSize: "14px" }}>
                        {columnNames[13]}
                      </p>
                      <b style={{ marginBottom: "1%", fontSize: "14px" }}>
                      {policy.paymentType === "card"
                          ? "Банкова карта"
                          : policy.paymentType === "onDelivery"
                          ? "Наложен платеж"
                          : policy.paymentType === "delivery"
                          ? "Наложен платеж"
                          : policy.paymentType}
                      </b>
                    </div>
                    <Divider />
                    <div className={styles["mobile-box"]}>
                      <p style={{ marginBottom: "1%", fontSize: "14px" }}>
                        {columnNames[14]}
                      </p>
                      <b style={{ marginBottom: "1%", fontSize: "14px" }}>
                      {policy.deletedAt.length > 1
                          ? moment(policy.deletedAt).format("DD.MM.YYYY")
                          : "-"}
                      </b>
                    </div>
                    <Divider />
                  </div>
                );
              })}
            </div>
          </React.Fragment>
        ) : (
          <>
            <div className={styles["columns-container"]}>
              <div
                className={styles["upper-names"]}
                style={{ width: window.innerWidth > 1600 ? "159%" : "158%" }}
              >
                {columnNames.map((column, i) => {
                  return (
                    <div
                      className={`${styles["name-icon"]}`}
                      key={i}
                      style={{
                        width:
                          i === 0
                            ? "560px"
                            : i === 9
                            ? "400px"
                            : i === 2
                            ? "500px"
                            : "410px",
                        marginLeft: i === 0 || (i === 10 && "0%"),
                      }}
                    >
                      <p
                        style={{
                          fontSize: window.innerWidth < 1500 ? "11px" : "13px",
                        }}
                      >
                        {column}
                      </p>
                      <KeyboardArrowDownIcon
                        style={{
                          transform:
                            filter === fieldNames[i] && "rotate(180deg)",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          if (filter === fieldNames[i]) {
                            setFilter(`reverse${fieldNames[i]}`);
                            filterByProp(
                              policies,
                              i > 8 && i < 11
                                ? "reversePrice"
                                : i === 12
                                ? "reversePrice"
                                : i === 14
                                ? "reverseCreatedAt"
                                : "reversePlate",
                              fieldNames[i]
                            );
                          } else {
                            setFilter(fieldNames[i]);
                            filterByProp(
                              policies,
                              i > 8 && i < 11
                                ? "price"
                                : i === 12
                                ? "price"
                                : i === 14
                                ? "createdAt"
                                : "plate",
                              fieldNames[i]
                            );
                          }
                        }}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
            <div className={styles["tabs-container"]}>
              {policies.map((policy, i) => {
                return (
                  <div
                    className={styles["row-container"]}
                    style={{ width: "158%" }}
                    key={i}
                  >
                    <div
                      className={styles["data"]}
                      style={{ marginLeft: "1%", width: "9%" }}
                    >
                      <b>{policy?.policyType}</b>
                    </div>
                    <Divider
                      style={{
                        backgroundColor: "#D3D3D3",
                        height: "65%",
                      }}
                      orientation="vertical"
                    />
                    <div
                      className={styles["data"]}
                      style={{ marginLeft: "0%", width: "5%" }}
                    >
                      <b style={{ marginLeft: "5%" }}>
                        {policy?.state === "active" ? "Активна" : "Изтекла"}
                      </b>
                    </div>
                    <Divider
                      style={{
                        backgroundColor: "#D3D3D3",
                        height: "65%",
                      }}
                      orientation="vertical"
                    />
                    <div
                      className={styles["data"]}
                      style={{ marginLeft: "0%", width: "11%" }}
                    >
                      <b style={{ marginLeft: "5%" }}>
                        {policy?.insuranceAgency}
                      </b>
                    </div>
                    <Divider
                      style={{
                        backgroundColor: "#D3D3D3",
                        height: "65%",
                      }}
                      orientation="vertical"
                    />
                    <div
                      className={styles["data"]}
                      style={{ marginLeft: "0%", width: "8.2%" }}
                    >
                      <b style={{ marginLeft: "5%" }}>
                        {policy?.insuredObject}
                      </b>
                    </div>
                    <Divider
                      style={{
                        backgroundColor: "#D3D3D3",
                        height: "65%",
                      }}
                      orientation="vertical"
                    />
                    <div
                      className={styles["data"]}
                      style={{ marginLeft: "0%", width: "8%" }}
                    >
                      <b style={{ marginLeft: "5%" }}>
                        {policy.externalPolicy === true
                          ? "Не"
                          : policy.externalPolicy === "-"
                          ? "-"
                          : "Да"}
                      </b>
                    </div>
                    <Divider
                      style={{
                        backgroundColor: "#D3D3D3",
                        height: "65%",
                      }}
                      orientation="vertical"
                    />
                    <div
                      className={styles["data"]}
                      style={{ marginLeft: "0%", width: "7.9%" }}
                    >
                      <b style={{ marginLeft: "5%" }}>{policy?.policyNumber}</b>
                    </div>
                    <Divider
                      style={{
                        backgroundColor: "#D3D3D3",
                        height: "65%",
                      }}
                      orientation="vertical"
                    />
                    <div
                      className={styles["data"]}
                      style={{ marginLeft: "0%", width: "7%" }}
                    >
                      <b style={{ marginLeft: "5%" }}>
                        {policy?.policyStartDate.split(" ")[0]}
                      </b>
                    </div>
                    <Divider
                      style={{
                        backgroundColor: "#D3D3D3",
                        height: "65%",
                      }}
                      orientation="vertical"
                    />
                    <div
                      className={styles["data"]}
                      style={{ marginLeft: "0%", width: "7%" }}
                    >
                      <b style={{ marginLeft: "5%" }}>
                        {policy?.policyEndDate.split(" ")[0]}
                      </b>
                    </div>
                    <Divider
                      style={{
                        backgroundColor: "#D3D3D3",
                        height: "65%",
                      }}
                      orientation="vertical"
                    />
                    <div
                      className={styles["data"]}
                      style={{ marginLeft: "0%", width: "7%" }}
                    >
                      <b style={{ marginLeft: "5%" }}>
                        {policy?.ordered.split(" ")[0].length > 0
                          ? policy?.ordered.split(" ")[0]
                          : "-"}
                      </b>
                    </div>
                    <Divider
                      style={{
                        backgroundColor: "#D3D3D3",
                        height: "65%",
                      }}
                      orientation="vertical"
                    />
                    <div
                      className={styles["data"]}
                      style={{ marginLeft: "0%", width: "7%" }}
                    >
                      <b style={{ marginLeft: "5%" }}>
                        {policy.installmentsCount}
                      </b>
                    </div>
                    <Divider
                      style={{
                        backgroundColor: "#D3D3D3",
                        height: "65%",
                      }}
                      orientation="vertical"
                    />
                    <div
                      className={styles["data"]}
                      style={{ marginLeft: "0%", width: "7%" }}
                    >
                      <b style={{ marginLeft: "5%" }}>
                        {getInstallements(policy)}/{policy.installments.length}
                      </b>
                    </div>
                    <Divider
                      style={{
                        backgroundColor: "#D3D3D3",
                        height: "65%",
                      }}
                      orientation="vertical"
                    />
                    <div
                      className={styles["data"]}
                      style={{ marginLeft: "0%", width: "7%" }}
                    >
                      <b style={{ marginLeft: "5%" }}>{policy.package}</b>
                    </div>
                    <Divider
                      style={{
                        backgroundColor: "#D3D3D3",
                        height: "65%",
                      }}
                      orientation="vertical"
                    />
                    <div
                      className={styles["data"]}
                      style={{ marginLeft: "0%", width: "7%" }}
                    >
                      <b style={{ marginLeft: "5%" }}>
                        {policy.amount === "-"
                          ? "-"
                          : Math.round(policy.amount * 100) / 100 + "лв"}
                      </b>
                      {policy.amount !== "-" && policy.installments.length > 1 && (
                        <div style={{ marginLeft: "3%" }}>
                          <Typography
                            aria-owns={`mouse-over-popover${i}`}
                            aria-haspopup="true"
                            onMouseEnter={(event) => {
                              setCurrentInstallments(policy.installments);
                              handlePopoverOpen(event);
                            }}
                            onMouseLeave={(event) => {
                              handlePopoverClose(event);
                            }}
                            sx={{ ml: "3%" }}
                            id={i}
                          >
                            <div className={styles["infoIcon"]} id={i} />
                          </Typography>
                          {currentInstallments.length > 1 && (
                            <Popover
                              id={`mouse-over-popover${i}`}
                              sx={{
                                pointerEvents: "none",
                                borderRadius: "10px",
                                boxShadow: 0,
                              }}
                              open={open}
                              anchorEl={anchorEl}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                              onClose={handlePopoverClose}
                              disableRestoreFocus
                            >
                              <div style={{ width: "200px" }} id={i}>
                                {currentInstallments.sort((a, b) => a.installmentNumber - b.installmentNumber).map((install, j) => {
                                  return (
                                    <div
                                      className="line"
                                      id={j + 1}
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        width: "95%",
                                        marginBottom: "3%",
                                        borderBottom: "1px solid #d3f0ed",
                                        marginLeft: "3%",
                                        height: "40px",
                                        alignItems: "center",
                                      }}
                                    >
                                      <p style={{ fontSize: "13px" }}>
                                        Вноска {j + 1}
                                      </p>
                                      <b style={{ fontSize: "13px" }}>
                                        {install.amount === "-"
                                          ? "-"
                                          : Math.round(install.amount * 100) /
                                              100 +
                                            "лв"}
                                      </b>
                                    </div>
                                  );
                                })}
                              </div>
                            </Popover>
                          )}
                        </div>
                      )}
                    </div>
                    <Divider
                      style={{
                        backgroundColor: "#D3D3D3",
                        height: "65%",
                      }}
                      orientation="vertical"
                    />
                    <div
                      className={styles["data"]}
                      style={{ marginLeft: "0%", width: "7%" }}
                    >
                      <b style={{ marginLeft: "5%" }}>
                        {policy.paymentType === "card"
                          ? "Банкова карта"
                          : policy.paymentType === "onDelivery"
                          ? "Наложен платеж"
                          : policy.paymentType === "delivery"
                          ? "Наложен платеж"
                          : policy.paymentType}
                      </b>
                    </div>
                    <Divider
                      style={{
                        backgroundColor: "#D3D3D3",
                        height: "65%",
                      }}
                      orientation="vertical"
                    />
                    <div
                      className={styles["data"]}
                      style={{ marginLeft: "0%", width: "7%" }}
                    >
                      <b style={{ marginLeft: "5%" }}>
                        {policy.deletedAt.length > 1
                          ? moment(policy.deletedAt).format("DD.MM.YYYY")
                          : "-"}
                      </b>
                      {policy.policyType === "Имущество" &&
                      policy.externalPolicy === false ? (
                        <div
                          className={styles["infoIcon"]}
                          onClick={() => {
                            document.getElementById("blurred").style.display =
                              "block";
                            setCurrentPolicy(policy);
                          }}
                        />
                      ) : null}
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  policies: state.amarantFields.policies,
});

const mapDispatchToProps = (dispatch) => ({
  listPolicies: (payload) => dispatch(listPolicies(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PoliciesComponent);
