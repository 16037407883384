/* eslint-disable */
import { Box, Modal, Paper } from "@mui/material";
import React from "react";
import styles from "../ChatActions/Activity/Activity.module.scss";

const CollectedDataModal = ({
  open,
  handleClose,
  currentError,
  special,
  mobileModal,
}) => {
  const style = {
    position: "absolute",
    top: mobileModal ? "50%" : "55%",
    left: special === true ? (window.innerWidth < 1600 ? "70%" : "83%") : "50%",
    width: mobileModal ? "100%" : "unset",
    height: mobileModal ? "100%" : "unset",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 30,
  };
  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Paper
          className={styles["activity-modal"]}
          style={{
            justifyContent: "flex-start",
            padding: window.innerWidth < 900 ? "14px" : "1vw",
            width: window.innerWidth < 900 && "100%",
            height: window.innerWidth < 900 && "100%",
          }}
        >
          <div className={styles["activity-heading-container"]}>
            <h3>DATA</h3>
            <p onClick={handleClose} style={{ cursor: "pointer" }}>
              Скрий {`>`}
            </p>
          </div>
          <h4 style={{ marginTop: "5%" }}>COLLECTED DATA</h4>
          <p style={{ marginTop: "3%" }}>
            {JSON.stringify(currentError?.collectedData, null, " ")}
          </p>
          <h4 style={{ marginTop: "5%" }}>COLLECTED ADDITIONAL DATA</h4>
          <p style={{ marginTop: "3%" }}>
            {JSON.stringify(currentError?.collectedAdditionalData, null, " ")}
          </p>
        </Paper>
      </Box>
    </Modal>
  );
};

export default CollectedDataModal;
